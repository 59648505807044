import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

const CustomAccordion = props => {
  const { expanded, onChange, expandIcon, name, children, summaryContent } = props;
  return (
    <Accordion
      expanded={expanded}
      sx={{ margin: '1rem 0', borderRadius: 'unset !important', background: '#131E68' }}
      onChange={onChange}
    >
      <AccordionSummary expandIcon={expandIcon} aria-controls="panel-content" id="panel-header">
        <Typography
          sx={theme => ({
            width: '33%',
            flexShrink: 0,
            marginRight: 'auto',
            color: '#fff',
            [theme.breakpoints.down('lg')]: {
              fontSize: '.9rem',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '.7rem',
            },
          })}
        >
          {name}
        </Typography>
        {summaryContent}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
export default CustomAccordion;
