import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from 'actions/authAction';
import { toast } from 'react-toastify';
import { cookie } from 'helpers/cookies';
import { setLocalStorage } from 'helpers/localStorage';
import { TOKEN_NAME, USER_ROLE } from 'constants/token';

const initialState = {
  loginData: {},
  error: '',
  isLoginLoading: false,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN: {
      return {
        ...state,
        error: '',
        isLoginLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      toast.success('Login successfully');
      setLocalStorage(TOKEN_NAME.ACCESS_TOKEN, payload.tokens.access.token);
      cookie.set(TOKEN_NAME.ACCESS_TOKEN, payload.tokens.access.token);
      cookie.set(TOKEN_NAME.REFRESH_TOKEN, payload.tokens.refresh.token);
      cookie.set(USER_ROLE.ROLE, payload.user.roles);
      cookie.set(USER_ROLE.USER, payload.user.name);

      return {
        ...state,
        loginData: payload.user,
        isLoginLoading: false,
      };
    }

    case LOGIN_FAIL: {
      return {
        ...state,
        error: payload.message,
        isLoginLoading: false,
      };
    }

    default:
      return { ...state };
  }
};
export default authReducer;
