import React, { useCallback, useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Cancel from '@material-ui/icons/Cancel';
import { Formik, Form } from 'formik';
import { map, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Add } from '@material-ui/icons';
import { MdDoNotDisturb } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { alertGroup } from 'actions/ActionTypes';
import { fetchAlertGroup } from 'actions/lunaAction';
import { TOKEN_NAME } from 'constants/token';
import { cookie } from 'helpers/cookies';

const AlertGroupModalSchema = Yup.object().shape({
  name: Yup.string().required('Alert Group Name is required!'),
});

const AlertGroupModal = props => {
  const { openAlertGroupModal, setOpenAlertGroupModal, coins } = props;
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const accessToken = cookie.get(TOKEN_NAME.ACCESS_TOKEN);
  const ownerId = JSON.parse(window.atob(accessToken.split('.')[1])).sub;

  const addAlertGroup = useCallback(
    data => {
      const alertGroupData = {
        ...data,
        owner: ownerId,
      };
      dispatch({
        type: alertGroup.CREATE_ALERT_GROUP,
        payload: alertGroupData,
        cb: res => {
          if (res) {
            if (res.status === 200) {
              toast.success('Alert Group added successfully');
              dispatch(fetchAlertGroup());
              setOpenAlertGroupModal(false);
            }
          }
        },
      });
    },
    [accessToken, ownerId],
  );

  return (
    <Box>
      <Modal
        open={openAlertGroupModal}
        sx={{
          background: '#131E68',
          width: '470px',
          height: 'max-content',
          margin: 'auto',
        }}
      >
        <Box sx={{ border: 'none', padding: '1rem' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Cancel
              style={{ marginTop: '-12px', marginRight: '-11px', color: '#FFFFFF', cursor: 'pointer' }}
              onClick={() => {
                setOpenAlertGroupModal(false);
              }}
            />
          </Box>
          <Box
            sx={{
              mt: '1rem',
              textAlign: 'center',
            }}
          >
            <label style={{ color: '#FFFFFF', fontWeight: '600', fontSize: '1.4rem' }}>Create New Alert Group</label>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: '10%',
            }}
          >
            <Formik
              enableReinitialize
              initialValues={{
                name: '',
                scripts: [],
              }}
              validationSchema={AlertGroupModalSchema}
              onSubmit={(values, { setSubmitting }) => {
                if (values.scripts && !isEmpty(values.scripts)) {
                  // const selectedCoin = map(values.scripts, (data) => data.value);
                  // addAlertGroup({...values, scripts: selectedCoin})
                  addAlertGroup({ ...values, scripts: values.scripts.value });
                }
                isEmpty(values.scripts) && setError('Coin is required!');
                setSubmitting(false);
              }}
            >
              {({ values, handleSubmit, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Box>
                      <Grid container spacing={{ md: 2, lg: 3 }}>
                        <Grid item xs={12}>
                          <TextField
                            placeholder="Enter Alert Group Name"
                            size="small"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                              sx: {
                                '&::placeholder': {
                                  color: '#FFFFFF',
                                },
                              },
                            }}
                            sx={theme => ({
                              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid  #3FD2F2',
                                borderRadius: '5px 5px 0 0',
                              },
                              input: {
                                color: '#fff',
                                background: '#000000',
                                border: '2px solid #3FD2F2',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                          />
                        </Grid>
                        {errors.name && touched.name ? (
                          <div
                            style={{
                              textAlign: 'right',
                              color: 'red',
                              padding: '5px 5px 0 0',
                              width: '100%',
                              fontSize: '0.8rem',
                            }}
                          >
                            {errors.name}
                          </div>
                        ) : null}
                        <Grid item xs={12}>
                          <Autocomplete
                            name="scripts"
                            options={map(coins, data => {
                              return {
                                value: data.id,
                                label: data.name,
                              };
                            })}
                            size="small"
                            sx={theme => ({
                              backgroundColor: 'black',
                              borderRadius: 2,
                            })}
                            onChange={(event, value) => {
                              setFieldValue('scripts', value);
                            }}
                            value={values.scripts}
                            renderInput={params => (
                              <TextField
                                placeholder="Search by Coin Ticker or Name"
                                sx={theme => ({
                                  border: '2px solid #3FD2F2',
                                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #484850',
                                  },
                                  '& .MuiAutocomplete-tag': {
                                    backgroundColor: 'aliceblue !important',
                                  },
                                  svg: {
                                    color: '#31EABD',
                                  },
                                  input: {
                                    color: '#fff',
                                    [theme.breakpoints.up('lg')]: {
                                      fontSize: '14px',
                                    },
                                    [theme.breakpoints.down('lg')]: {
                                      fontSize: '12px',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                      fontSize: '11px',
                                    },
                                  },
                                })}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        {error && isEmpty(values.scripts) && (
                          <div
                            style={{
                              textAlign: 'right',
                              color: 'red',
                              padding: '5px 5px 0 0',
                              width: '100%',
                              fontSize: '0.8rem',
                            }}
                          >
                            {error}
                          </div>
                        )}
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              flexDirection: 'row',
                              mt: '9%',
                              mr: '5%',
                            }}
                          >
                            <Button
                              startIcon={<Add />}
                              variant="outlined"
                              type="submit"
                              sx={{
                                textTransform: 'unset !important',
                                cursor: 'pointer',
                                color: '#FFFFFF',
                                padding: '0.5rem 1.5rem',
                                backgroundColor: '#0072F8',
                                border: '1px solid #3FD2F2',
                                fontSize: '0.9rem',
                                borderRadius: '0.5rem',
                              }}
                              onClick={() => setOpenAlertGroupModal(true)}
                            >
                              Create
                            </Button>
                            <Button
                              startIcon={<MdDoNotDisturb />}
                              variant="outlined"
                              sx={{
                                textTransform: 'unset !important',
                                cursor: 'pointer',
                                color: '#FFFFFF',
                                padding: '0.5rem 1.5rem',
                                backgroundColor: '#0072F8',
                                border: '1px solid #3FD2F2',
                                fontSize: '0.9rem',
                                borderRadius: '0.5rem',
                              }}
                              onClick={() => setOpenAlertGroupModal(false)}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AlertGroupModal;
