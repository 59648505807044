import React, { useCallback, useState } from 'react';
import { Box, Card, Checkbox, Grid, Typography, styled, Tooltip } from '@mui/material';
import BearIcon from 'assets/ico_bear.svg';
import BullIcon from 'assets/ico_bull.svg';
import RangeIcon from 'assets/ico_range.svg';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import optimizedBlue from 'assets/optimizedblue.svg';
import { round, filter } from 'lodash';
import { Edit } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { BsStarFill, BsStar, BsBoxes } from 'react-icons/bs';
import { getBulkModel, getModel, getRunningModel } from 'actions/simAction';
import { models } from 'actions/ActionTypes';
import { useDispatch } from 'react-redux';
import SingleModel from './SingleModel';
import BulkModal from './BulkModal';
import InfoIcon from '../common/InfoIcon';
import { PiInfinityBold, PiRecycleBold } from 'react-icons/pi';
import { formatNumberWithLocale } from 'utils/common';
import { isEmpty } from 'lodash';

function SparkIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        fontSize: '1.8rem',
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0718 10.0719L8.21558 11H11L10.0718 10.0719Z" fill="#FFEE6E" />
        <path d="M10.9999 8.2157L10.0718 10.0719L10.9999 11.0001V8.2157Z" fill="#FFBC36" />
        <path d="M11.9281 10.0719L11 8.2157V11.0001L11.9281 10.0719Z" fill="#FFEE6E" />
        <path d="M8.21558 11L10.0718 11.9281L11 11H8.21558Z" fill="#FFBC36" />
        <path d="M11 11H13.7844L11.9281 10.0719L11 11Z" fill="#FFBC36" />
        <path d="M11.9281 11.9281L13.7844 11H11L11.9281 11.9281Z" fill="#FFA426" />
        <path d="M11 13.7844L11.9281 11.9281L11 11V13.7844Z" fill="#FFBC36" />
        <path d="M10.0718 11.9281L10.9999 13.7844V11L10.0718 11.9281Z" fill="#FF9500" />
        <path
          d="M2.04803 11C2.04803 11.3718 1.74666 11.6731 1.3749 11.6731C1.00315 11.6731 0.701777 11.3718 0.701777 11C0.701777 10.6283 1.00315 10.3269 1.3749 10.3269C1.74666 10.3269 2.04803 10.6283 2.04803 11Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path
          d="M21.4013 11C21.4013 11.3718 21.0999 11.6732 20.7282 11.6732C20.3564 11.6732 20.055 11.3718 20.055 11C20.055 10.6283 20.3564 10.3269 20.7282 10.3269C21.0999 10.3269 21.4013 10.6283 21.4013 11Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path d="M11.4811 3.1969H10.553V5.01877H11.4811V3.1969Z" fill="#FFF48F" />
        <path d="M11.4811 5.9469H10.553V6.87502H11.4811V5.9469Z" fill="#FFC247" />
        <path d="M11.4811 16.9469H10.553V18.7688H11.4811V16.9469Z" fill="#FFF48F" />
        <path d="M11.4811 15.125H10.553V16.0531H11.4811V15.125Z" fill="#FFC247" />
        <path d="M18.7687 10.5532H16.9468V11.4813H18.7687V10.5532Z" fill="#FFF48F" />
        <path d="M16.0531 10.5532H15.125V11.4813H16.0531V10.5532Z" fill="#FFC247" />
        <path d="M5.01865 10.5532H3.19678V11.4813H5.01865V10.5532Z" fill="#FFF48F" />
        <path d="M6.8749 10.5532H5.94678V11.4813H6.8749V10.5532Z" fill="#FFC247" />
        <path d="M21.064 20.4304L20.4077 21.0867L21.3314 22.0103L21.9876 21.354L21.064 20.4304Z" fill="#FFF48F" />
        <path d="M14.115 13.4696L13.4587 14.1259L19.4868 20.1539L20.143 19.4976L14.115 13.4696Z" fill="#FFC247" />
        <path d="M2.51297 1.84531L1.85669 2.50159L7.88472 8.52961L8.54099 7.87334L2.51297 1.84531Z" fill="#FFC247" />
        <path
          d="M0.668484 -0.010159L0.012207 0.646118L0.935856 1.56977L1.59213 0.91349L0.668484 -0.010159Z"
          fill="#FFF48F"
        />
        <path
          d="M0.912907 20.4079L-0.0107422 21.3315L0.645535 21.9878L1.56918 21.0642L0.912907 20.4079Z"
          fill="#FFF48F"
        />
        <path d="M7.87373 13.4587L1.8457 19.4867L2.50198 20.143L8.53001 14.1149L7.87373 13.4587Z" fill="#FFC247" />
        <path d="M19.4975 1.85674L13.4695 7.88477L14.1258 8.54104L20.1538 2.51302L19.4975 1.85674Z" fill="#FFC247" />
        <path
          d="M21.3533 0.0123859L20.4297 0.936035L21.086 1.59231L22.0096 0.668663L21.3533 0.0123859Z"
          fill="#FFF48F"
        />
        <path d="M21.0419 5.93771L20.1033 6.36475L20.4876 7.20952L21.4263 6.78248L21.0419 5.93771Z" fill="#FFC247" />
        <path d="M19.1431 6.7972L14.5125 8.90393L14.8968 9.74871L19.5274 7.64198L19.1431 6.7972Z" fill="#FF7045" />
        <path d="M20.5569 14.0231L20.231 14.8921L21.1965 15.2543L21.5225 14.3853L20.5569 14.0231Z" fill="#FFC247" />
        <path d="M14.9322 11.9663L14.6116 12.8373L19.2567 14.5475L19.5773 13.6766L14.9322 11.9663Z" fill="#FF7045" />
        <path d="M15.635 20.1059L14.79 20.49L15.2168 21.4288L16.0617 21.0448L15.635 20.1059Z" fill="#FFC247" />
        <path d="M13.0974 14.5127L12.2524 14.8967L14.3576 19.5284L15.2026 19.1443L13.0974 14.5127Z" fill="#FF7045" />
        <path d="M14.3848 0.476804L14.0225 1.44226L14.8914 1.7684L15.2538 0.802948L14.3848 0.476804Z" fill="#FFC247" />
        <path d="M13.6912 2.38083L11.9443 7.12268L12.8153 7.44353L14.5622 2.70168L13.6912 2.38083Z" fill="#FF7045" />
        <path d="M1.1452 5.94709L0.756592 6.78992L1.66185 7.20731L2.05046 6.36448L1.1452 5.94709Z" fill="#FFC247" />
        <path d="M2.94915 6.76606L2.56055 7.60889L7.08686 9.69584L7.47546 8.85301L2.94915 6.76606Z" fill="#FF7045" />
        <path d="M1.56142 14.0522L0.629639 14.4066L0.959587 15.2741L1.89137 14.9197L1.56142 14.0522Z" fill="#FFC247" />
        <path d="M7.1074 11.959L2.50244 13.678L2.82701 14.5474L7.43196 12.8285L7.1074 11.959Z" fill="#FF7045" />
        <path d="M6.42233 20.0825L6.00024 21.0234L6.84707 21.4033L7.26916 20.4624L6.42233 20.0825Z" fill="#FFC247" />
        <path d="M8.91044 14.5332L6.82812 19.175L7.67495 19.5549L9.75726 14.9131L8.91044 14.5332Z" fill="#FF7045" />
        <path d="M7.66761 0.488703L6.79712 0.810669L7.15486 1.77788L8.02535 1.45591L7.66761 0.488703Z" fill="#FFC247" />
        <path d="M8.38049 2.3917L7.50806 2.70837L9.18527 7.32906L10.0577 7.01239L8.38049 2.3917Z" fill="#FF7045" />
        <path
          d="M11.8449 1.1C11.8449 1.56668 11.4666 1.945 10.9999 1.945C10.5332 1.945 10.1549 1.56668 10.1549 1.1C10.1549 0.63332 10.5332 0.255 10.9999 0.255C11.4666 0.255 11.8449 0.633319 11.8449 1.1Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path
          d="M11.8449 20.9C11.8449 21.3667 11.4666 21.745 10.9999 21.745C10.5332 21.745 10.1549 21.3667 10.1549 20.9C10.1549 20.4334 10.5332 20.055 10.9999 20.055C11.4666 20.055 11.8449 20.4334 11.8449 20.9Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <defs>
          <clipPath id="clip0_166_3710">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

function GrayOutSparkIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        fontSize: '1.8rem',
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0718 10.0719L8.21558 11H11L10.0718 10.0719Z" fill="gray" />
        <path d="M10.9999 8.2157L10.0718 10.0719L10.9999 11.0001V8.2157Z" fill="gray" />
        <path d="M11.9281 10.0719L11 8.2157V11.0001L11.9281 10.0719Z" fill="gray" />
        <path d="M8.21558 11L10.0718 11.9281L11 11H8.21558Z" fill="gray" />
        <path d="M11 11H13.7844L11.9281 10.0719L11 11Z" fill="gray" />
        <path d="M11.9281 11.9281L13.7844 11H11L11.9281 11.9281Z" fill="gray" />
        <path d="M11 13.7844L11.9281 11.9281L11 11V13.7844Z" fill="gray" />
        <path d="M10.0718 11.9281L10.9999 13.7844V11L10.0718 11.9281Z" fill="gray" />
        <path
          d="M2.04803 11C2.04803 11.3718 1.74666 11.6731 1.3749 11.6731C1.00315 11.6731 0.701777 11.3718 0.701777 11C0.701777 10.6283 1.00315 10.3269 1.3749 10.3269C1.74666 10.3269 2.04803 10.6283 2.04803 11Z"
          fill="black"
          stroke="gray"
          stroke-width="0.51"
        />
        <path
          d="M21.4013 11C21.4013 11.3718 21.0999 11.6732 20.7282 11.6732C20.3564 11.6732 20.055 11.3718 20.055 11C20.055 10.6283 20.3564 10.3269 20.7282 10.3269C21.0999 10.3269 21.4013 10.6283 21.4013 11Z"
          fill="black"
          stroke="gray"
          stroke-width="0.51"
        />
        <path d="M11.4811 3.1969H10.553V5.01877H11.4811V3.1969Z" fill="gray" />
        <path d="M11.4811 5.9469H10.553V6.87502H11.4811V5.9469Z" fill="gray" />
        <path d="M11.4811 16.9469H10.553V18.7688H11.4811V16.9469Z" fill="gray" />
        <path d="M11.4811 15.125H10.553V16.0531H11.4811V15.125Z" fill="gray" />
        <path d="M18.7687 10.5532H16.9468V11.4813H18.7687V10.5532Z" fill="gray" />
        <path d="M16.0531 10.5532H15.125V11.4813H16.0531V10.5532Z" fill="gray" />
        <path d="M5.01865 10.5532H3.19678V11.4813H5.01865V10.5532Z" fill="gray" />
        <path d="M6.8749 10.5532H5.94678V11.4813H6.8749V10.5532Z" fill="gray" />
        <path d="M21.064 20.4304L20.4077 21.0867L21.3314 22.0103L21.9876 21.354L21.064 20.4304Z" fill="gray" />
        <path d="M14.115 13.4696L13.4587 14.1259L19.4868 20.1539L20.143 19.4976L14.115 13.4696Z" fill="gray" />
        <path d="M2.51297 1.84531L1.85669 2.50159L7.88472 8.52961L8.54099 7.87334L2.51297 1.84531Z" fill="gray" />
        <path
          d="M0.668484 -0.010159L0.012207 0.646118L0.935856 1.56977L1.59213 0.91349L0.668484 -0.010159Z"
          fill="gray"
        />
        <path d="M0.912907 20.4079L-0.0107422 21.3315L0.645535 21.9878L1.56918 21.0642L0.912907 20.4079Z" fill="gray" />
        <path d="M7.87373 13.4587L1.8457 19.4867L2.50198 20.143L8.53001 14.1149L7.87373 13.4587Z" fill="gray" />
        <path d="M19.4975 1.85674L13.4695 7.88477L14.1258 8.54104L20.1538 2.51302L19.4975 1.85674Z" fill="gray" />
        <path d="M21.3533 0.0123859L20.4297 0.936035L21.086 1.59231L22.0096 0.668663L21.3533 0.0123859Z" fill="gray" />
        <path d="M21.0419 5.93771L20.1033 6.36475L20.4876 7.20952L21.4263 6.78248L21.0419 5.93771Z" fill="gray" />
        <path d="M19.1431 6.7972L14.5125 8.90393L14.8968 9.74871L19.5274 7.64198L19.1431 6.7972Z" fill="gray" />
        <path d="M20.5569 14.0231L20.231 14.8921L21.1965 15.2543L21.5225 14.3853L20.5569 14.0231Z" fill="gray" />
        <path d="M14.9322 11.9663L14.6116 12.8373L19.2567 14.5475L19.5773 13.6766L14.9322 11.9663Z" fill="gray" />
        <path d="M15.635 20.1059L14.79 20.49L15.2168 21.4288L16.0617 21.0448L15.635 20.1059Z" fill="gray" />
        <path d="M13.0974 14.5127L12.2524 14.8967L14.3576 19.5284L15.2026 19.1443L13.0974 14.5127Z" fill="gray" />
        <path d="M14.3848 0.476804L14.0225 1.44226L14.8914 1.7684L15.2538 0.802948L14.3848 0.476804Z" fill="gray" />
        <path d="M13.6912 2.38083L11.9443 7.12268L12.8153 7.44353L14.5622 2.70168L13.6912 2.38083Z" fill="gray" />
        <path d="M1.1452 5.94709L0.756592 6.78992L1.66185 7.20731L2.05046 6.36448L1.1452 5.94709Z" fill="gray" />
        <path d="M2.94915 6.76606L2.56055 7.60889L7.08686 9.69584L7.47546 8.85301L2.94915 6.76606Z" fill="gray" />
        <path d="M1.56142 14.0522L0.629639 14.4066L0.959587 15.2741L1.89137 14.9197L1.56142 14.0522Z" fill="gray" />
        <path d="M7.1074 11.959L2.50244 13.678L2.82701 14.5474L7.43196 12.8285L7.1074 11.959Z" fill="gray" />
        <path d="M6.42233 20.0825L6.00024 21.0234L6.84707 21.4033L7.26916 20.4624L6.42233 20.0825Z" fill="gray" />
        <path d="M8.91044 14.5332L6.82812 19.175L7.67495 19.5549L9.75726 14.9131L8.91044 14.5332Z" fill="gray" />
        <path d="M7.66761 0.488703L6.79712 0.810669L7.15486 1.77788L8.02535 1.45591L7.66761 0.488703Z" fill="gray" />
        <path d="M8.38049 2.3917L7.50806 2.70837L9.18527 7.32906L10.0577 7.01239L8.38049 2.3917Z" fill="gray" />
        <path
          d="M11.8449 1.1C11.8449 1.56668 11.4666 1.945 10.9999 1.945C10.5332 1.945 10.1549 1.56668 10.1549 1.1C10.1549 0.63332 10.5332 0.255 10.9999 0.255C11.4666 0.255 11.8449 0.633319 11.8449 1.1Z"
          fill="black"
          stroke="gray"
          stroke-width="0.51"
        />
        <path
          d="M11.8449 20.9C11.8449 21.3667 11.4666 21.745 10.9999 21.745C10.5332 21.745 10.1549 21.3667 10.1549 20.9C10.1549 20.4334 10.5332 20.055 10.9999 20.055C11.4666 20.055 11.8449 20.4334 11.8449 20.9Z"
          fill="black"
          stroke="gray"
          stroke-width="0.51"
        />
        <defs>
          <clipPath id="clip0_166_3710">
            <rect width="22" height="22" fill="gray" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

const LabelFontSize = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.8rem !important',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.6rem !important',
  },
}));

const OptimizedCard = props => {
  const {
    modelName,
    startDate,
    endDate,
    modelId,
    setSelectedCheckBox,
    selectedCheckBox,
    dataSource,
    up,
    down,
    stopLoss,
    primaryCrypto,
    currency,
    win,
    profitability,
    rebuyMode,
    isStarred,
    isPromoted,
    promotedStatus,
    modelType,
    simModelStatus,
    allModel,
    setValue,
    bias,
    sortByItem,
    ascDescOrder,
  } = props;

  const dispatch = useDispatch();

  const modelStartDate = startDate ? moment(startDate).format('DD/MM/YY') : '';
  const modelEndDate = endDate ? moment.tz(endDate.replace('Z', ''), 'America/Denver').format('DD/MM/YY') : '';
  const winValue = win ? `${round(win, 2)}%` : 0;
  const rrrValue = up && stopLoss ? `${round(up / stopLoss, 2)}` : 0;
  const profitabilityValue = profitability ? formatNumberWithLocale(round(profitability, 2), 'decimal') : 0;
  const cryptoPairs = primaryCrypto && currency ? `${primaryCrypto}-${currency}` : 'BTC-USD';

  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [openEditBulkModel, setOpenEditBulkModel] = useState(false);

  const editStar = useCallback(
    isStarred => {
      dispatch({
        type: models.UPDATE_MODEL,
        payload: { id: modelId, isStarred: !isStarred },
        cb: res => {
          if (res) {
            if (res.status === 200) {
              if (sortByItem.order === 'isStarred') {
                dispatch(getRunningModel({ isStarred: true }));
              }
            }
          }
        },
      });
    },
    [modelId],
  );

  const handleChange = event => {
    if (event.target.checked) {
      setSelectedCheckBox([...selectedCheckBox, event.target.value]);
    } else {
      setSelectedCheckBox(filter(selectedCheckBox, ele => ele !== modelId));
    }
  };

  return (
    <>
      <Card
        sx={{
          marginBottom: '0.5rem',
          padding: '0.3rem',
          background: '#040d3e !important',
          border: '0.2rem solid #175982',
        }}
      >
        <Grid container xs={12} spacing={1}>
          <Grid container sm={2.5}>
            <Grid item xs={1} lg={4} sm={12}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon htmlColor="#175982" />}
                checked={selectedCheckBox.includes(modelId)}
                onChange={handleChange}
                value={modelId}
              />
            </Grid>
            <Grid
              item
              xs={1}
              lg={8}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Box>
                  {bias === 'bull' ? (
                    <img src={BullIcon} alt="bull icon" height="25rem" />
                  ) : bias === 'bear' ? (
                    <Box>
                      <img src={BearIcon} alt="bear icon" height="25rem" />
                    </Box>
                  ) : bias === 'range_bound' ? (
                    <img src={RangeIcon} alt="range icon" height="25rem" />
                  ) : (
                    <Box fontSize="2rem" display="flex" justifyContent="center" alignItems="center">
                      <BsBoxes style={{ color: '#1CAFFE', width: '3rem' }} />
                    </Box>
                  )}
                </Box>
                <Box>
                  <div style={{ cursor: 'pointer' }} onClick={() => editStar(isStarred)}>
                    {isStarred ? <BsStarFill size={32} color={'white'} /> : <BsStar size={32} color={'white'} />}
                  </div>
                </Box>

                <Box>{isPromoted && promotedStatus === 'promoted' ? <SparkIcon /> : <GrayOutSparkIcon />}</Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={9.5} sm={8.5}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0.5rem',
              }}
            >
              <Tooltip title={modelName} placement="top-start">
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontWeight: '700',
                    paddingBottom: '0.5rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '16rem',
                  }}
                >
                  {modelName}
                </Typography>
              </Tooltip>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #C2FFFF',
                  paddingBottom: '0.4rem',
                  width: '100%',
                }}
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box display="flex" justifyContent="center" alignItems="center" gap="0.5rem">
                    <InfoIcon>D</InfoIcon>
                    <LabelFontSize
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                      }}
                    >
                      {down && `${down}%`}
                    </LabelFontSize>
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center" gap="0.5rem">
                    <InfoIcon>U</InfoIcon>
                    <LabelFontSize
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                      }}
                    >
                      {up && `${up}%`}
                    </LabelFontSize>
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center" gap="0.5rem">
                    <InfoIcon>SL</InfoIcon>
                    <LabelFontSize
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                      }}
                    >
                      {stopLoss && `${stopLoss}%`}
                    </LabelFontSize>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: '0.5rem',
                }}
              >
                <Grid container>
                  <Grid xl={6} lg={12} md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Box
                          component="img"
                          sx={theme => ({
                            [theme.breakpoints.up('xl')]: {
                              width: 35,
                              height: 30,
                            },
                            [theme.breakpoints.up('lg')]: {
                              width: 30,
                              height: 30,
                            },
                            [theme.breakpoints.down('lg')]: {
                              width: 20,
                              height: 20,
                            },
                            [theme.breakpoints.down('md')]: {
                              width: 25,
                              height: 25,
                            },
                          })}
                          alt="icon"
                          src={optimizedBlue}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LabelFontSize
                            sx={{
                              color: '#7877B1',
                              fontWeight: '600',
                            }}
                          >
                            {cryptoPairs}
                          </LabelFontSize>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Box>
                          <LabelFontSize sx={{ color: '#7877B1', fontWeight: '600' }}>Data:</LabelFontSize>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LabelFontSize
                            sx={{
                              color: '#7877B1',
                              fontWeight: '600',
                            }}
                          >
                            {dataSource}
                          </LabelFontSize>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Box>
                          <LabelFontSize sx={{ color: '#7877B1', fontWeight: '600' }}>Date:</LabelFontSize>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LabelFontSize
                            sx={{
                              color: '#7877B1',
                              fontWeight: '600',
                            }}
                          >
                            {modelStartDate}-{modelEndDate}
                          </LabelFontSize>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={12}
                    md={12}
                    sx={theme => ({
                      paddingTop: '0.5rem',
                      [theme.breakpoints.up('xl')]: {
                        display: 'block',
                      },
                      [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                      },
                    })}
                  >
                    <Box>
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                        })}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <LabelFontSize sx={{ color: '#7877B1', fontWeight: '600' }}>WIN:</LabelFontSize>
                          <LabelFontSize sx={{ color: '#50E3C2' }}>{winValue}</LabelFontSize>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <LabelFontSize sx={{ color: '#7877B1', fontWeight: '600' }}>RRR:</LabelFontSize>
                          <LabelFontSize sx={{ display: 'flex' }}>
                            <Box sx={{ color: '#E3507A' }}>{rrrValue}</Box>
                          </LabelFontSize>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <LabelFontSize sx={{ color: '#7877B1', fontWeight: '600' }}>{'<P:'}</LabelFontSize>
                          <LabelFontSize
                            sx={{
                              color: profitability >= 0 ? '#50E3C2' : 'red',
                            }}
                          >
                            {profitabilityValue}
                          </LabelFontSize>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={0.5}
            sm={1}
            lg={1}
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              marginTop: '1rem',
            })}
          >
            {!isEmpty(simModelStatus) && simModelStatus !== 'running' && (
              <div
                onClick={() => {
                  if (modelType === 'historicalModel') {
                    dispatch(getModel(modelId));
                    setOpenUpdateModel(true);
                  } else {
                    dispatch(getBulkModel(modelId));
                    setOpenBulkModal(true);
                    setOpenEditBulkModel(true);
                  }
                }}
              >
                <Edit
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                  }}
                />
              </div>
            )}
            <Grid
              sx={{
                color: 'white',
                fontSize: '1.5rem',
              }}
            >
              {rebuyMode === 'fixedRebuy' ? <PiRecycleBold /> : <PiInfinityBold />}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {openUpdateModel && (
        <SingleModel
          openUpdateModel={openUpdateModel}
          setOpenUpdateModel={setOpenUpdateModel}
          selectedRow={modelId}
          setValue={setValue}
          ascDescOrder={ascDescOrder}
        />
      )}
      {openBulkModal && (
        <BulkModal
          openBulkModal={openBulkModal}
          setOpenBulkModal={setOpenBulkModal}
          allModel={allModel}
          setValue={setValue}
          openEditBulkModel={openEditBulkModel}
          setOpenEditBulkModel={setOpenEditBulkModel}
        />
      )}
    </>
  );
};

export default OptimizedCard;
