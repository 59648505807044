import { all, call, put, takeLatest } from 'redux-saga/effects';
import { exchanges } from 'actions/ActionTypes';
import { API } from 'apis';
import {
  fetchExchangesFailure,
  fetchExchangesSuccess,
  getFrdCryptoListFail,
  getFrdCryptoListSuccess,
  getFrdDataFail,
  getFrdDataSuccess,
} from 'actions/classificationAction';

function* fetchExchangesSaga() {
  try {
    const response = yield call(API.getExchange);
    if (response.status === 200) {
      yield put(
        fetchExchangesSuccess({
          exchanges: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchExchangesFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchExchangesFailure({
        error: e.message,
      }),
    );
  }
}

function* getFrdCryptoList() {
  try {
    const response = yield call(API.getFrdCryptoList);
    if (response.status === 200) {
      yield put(getFrdCryptoListSuccess(response.data.data));
    } else {
      yield put(
        getFrdCryptoListFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getFrdCryptoListFail({
        error: e.message,
      }),
    );
  }
}

function* getFrdData(action) {
  try {
    const response = yield call(API.getFrdData, action.payload);
    if (response.status === 200) {
      yield put(getFrdDataSuccess(response.data.data));
    } else {
      yield put(
        getFrdDataFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getFrdDataFail({
        error: e.message,
      }),
    );
  }
}

function* classificationSaga() {
  yield all([takeLatest(exchanges.FETCH_EXCHANGES, fetchExchangesSaga)]);
  yield all([takeLatest(exchanges.GET_FRD_CRYPTO_LIST, getFrdCryptoList)]);
  yield all([takeLatest(exchanges.GET_FRD_DATA, getFrdData)]);
}

export default classificationSaga;
