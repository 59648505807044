import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import {
  AppBar,
  Divider,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Warning, ExpandMore } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@mui/material/Box';
import chartIcon from '../assets/chart.svg';
import settingIcon from '../assets/setting.svg';
import notificationIcon from '../assets/notification.svg';
import avatar from '../assets/avatar.svg';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_PATHNAME } from '../constants/app-pathname';
import sim from '../assets/sim.svg';
import spark from '../assets/spark.svg';
import dashboard from '../assets/dashboard.svg';
import luna from '../assets/luna.svg';
import notification from '../assets/notification.svg';
import { APP_TITLE } from '../constants/app-title';
import useLogout from '../security/hooks/useLogout';
import { Stack } from '@mui/material';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '400px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#171616',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#175982',
      borderRadius: 2,
    },
  },
  fixHeight: {
    height: '315px',
    overflow: 'hidden',
  },
}));

export const Header = ({ classes, open }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [showAllNotification, setShowAllNotification] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const style = useStyles();

  const menuOpen = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowAllNotification(false);
  };

  const logout = useLogout();

  const getTitleDetail = pathname => {
    switch (pathname) {
      case APP_PATHNAME.SIM:
        setTitle(APP_TITLE.SIM);
        setImage(sim);
        break;
      case APP_PATHNAME.SPARK:
        setTitle(APP_TITLE.SPARK);
        setImage(spark);
        break;
      case APP_PATHNAME.LUNA:
        setTitle(APP_TITLE.LUNA);
        setImage(luna);
        break;
      case APP_PATHNAME.NOTIFICATION:
        setTitle(APP_TITLE.NOTIFICATION);
        setImage(notification);
        break;
      case APP_PATHNAME.CLASSIFICATION:
        setTitle(APP_TITLE.CLASSIFICATION);
        setImage(spark);
        break;
      default:
        setTitle(APP_TITLE.DASHBOARD);
        setImage(dashboard);
        break;
    }
  };

  useEffect(() => {
    getTitleDetail(location.pathname);
  }, [location]);

  return (
    <>
      <AppBar
        position="fixed"
        className={classes?.appBar}
        fooJon={classNames(classes?.appBar, {
          [classes?.appBarShift]: open,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #fff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/* <Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
          </Box> */}
            <Box className={open ? classes.simTitleOpen : classes.simTitleClose}>
              <IconButton>
                <img src={image} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <h3>{title}</h3>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit">
                <Tooltip title="Chart" placement="bottom">
                  <img src={chartIcon}></img>
                </Tooltip>
              </IconButton>
              <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit">
                <Tooltip title="Setting" placement="bottom">
                  <img src={settingIcon}></img>
                </Tooltip>
              </IconButton>
              <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit">
                <Tooltip title="Notification" placement="bottom">
                  <Typography
                    component={'div'}
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={menuOpen ? 'notification-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                  >
                    <img src={notificationIcon} alt={''} />
                  </Typography>
                </Tooltip>
              </IconButton>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={logout}
              >
                <img src={avatar}></img>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={menuOpen}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiList-padding': {
              padding: '0',
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        MenuListProps={{
          style: {
            padding: '0',
            border: '1px solid',
            background: '#040d3e',
            color: '#fff',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box className={showAllNotification ? style.fullHeight : style.fixHeight}>
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            onClick={() => setShowAllNotification(!showAllNotification)}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
          <NotificationDetail
            preIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            postIcon={<Warning fontSize="large" style={{ color: 'red' }} />}
            notificationTime={'11 min'}
            notification={'Spark Stopped'}
            text={'By User James'}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid',
            marginTop: '0.5rem',
          }}
        >
          <Stack direction={'column'} spacing={0}>
            <Typography
              variant={'h6'}
              component={'div'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/notification');
                setTimeout(() => {
                  handleClose();
                }, 500);
              }}
            >
              Notification Page
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setShowAllNotification(!showAllNotification)}
            >
              <ExpandMore fontSize="large" />
            </Box>
          </Stack>
        </Box>
      </Menu>
    </>
  );
};

const NotificationDetail = props => {
  const { preIcon, postIcon, notificationTime, notification, text, onClick } = props;

  return (
    <>
      <MenuItem onClick={onClick}>
        <Stack direction={'row'} spacing={1}>
          {preIcon && <ListItemIcon>{preIcon}</ListItemIcon>}

          <Box sx={{ marginRight: '2rem !important' }}>
            <Stack direction={'column'} spacing={0.5}>
              <Typography component={'span'}>{notification}</Typography>
              <Typography component={'span'}>{text}</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack direction={'column'} spacing={1}>
              <Typography component={'span'}>{notificationTime}</Typography>
              {postIcon && <ListItemIcon>{postIcon}</ListItemIcon>}
            </Stack>
          </Box>
        </Stack>
      </MenuItem>
      <Divider style={{ background: 'white', height: '2px' }} />
    </>
  );
};
