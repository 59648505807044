import { useState, useEffect } from 'react';
import useAxiosAuth from '../security/hooks/useAxiosAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'utils/api';
const Users = () => {
  const [users, setUsers] = useState();
  const AxiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        const response = await get(`/v1/client/user/`);
        isMounted && setUsers(response.data.data);
      } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <article>
      <h2> Users List</h2>
      {users?.length ? (
        <ul>
          {users.map((user, i) => (
            <li key={i}>{user?.email}</li>
          ))}
        </ul>
      ) : (
        <p> No users to display</p>
      )}
      <br />
    </article>
  );
};

export default Users;
