import { alertGroup, alerts } from 'actions/ActionTypes';
import { concat } from 'lodash';

const initialState = {
  pending: false,
  isDeleteAlertPending: false,
  alerts: [],
  alertGroup: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case alerts.FETCH_ALERTS:
      return {
        ...state,
        pending: true,
      };
    case alerts.FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        pending: false,
        alerts: action.payload.alerts,
      };
    case alerts.FETCH_ALERTS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case alerts.CREATE_ALERT:
      return {
        ...state,
        pending: true,
      };
    case alerts.CREATE_ALERT_SUCCESS:
      return {
        ...state,
        pending: false,
        alerts: concat(state.alerts, action.payload.alerts),
      };
    case alerts.CREATE_ALERT_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case alerts.DELETE_ALERT: {
      return {
        ...state,
        isDeleteAlertPending: true,
      };
    }
    case alerts.DELETE_ALERT_SUCCESS: {
      return {
        ...state,
        isDeleteAlertPending: false,
        alerts: state.alerts.filter(data => data.id !== action.payload.alerts),
      };
    }
    case alerts.DELETE_ALERT_FAIL: {
      return {
        ...state,
        isDeleteAlertPending: false,
        error: action.payload.error,
      };
    }
    case alertGroup.FETCH_ALERT_GROUP:
      return {
        ...state,
        pending: true,
      };
    case alertGroup.FETCH_ALERT_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        alertGroup: action.payload.alertGroup,
      };
    case alertGroup.FETCH_ALERT_GROUP_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case alertGroup.CREATE_ALERT_GROUP:
      return {
        ...state,
        pending: true,
      };
    case alertGroup.CREATE_ALERT_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        alertGroup: concat(state.alertGroup, action.payload.alertGroup),
      };
    case alertGroup.CREATE_ALERT_GROUP_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
