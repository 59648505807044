import { sim } from './sim';
import { user } from './user';
import { auth } from './auth';
import { luna } from './luna';
import { classification } from './classification';
import { spark } from './spark';
import { dashboard } from './dashboard';

export const API = {
  ...sim,
  ...user,
  ...auth,
  ...luna,
  ...classification,
  ...spark,
  ...dashboard,
};
