import React from 'react';
import { Box, Grid, InputBase, Paper, Stack, Typography } from '@mui/material';
import { Edit, Delete } from '@material-ui/icons';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { map, filter, get, isEmpty } from 'lodash';
import { useQuill } from 'react-quilljs';
import { startsWith } from '../common/utils';
import { Quill } from 'react-quill';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import './classificationDetail.scss';

const ClassificationDetail = props => {
  const { cryptoName } = props;
  const [searchNotes, setSearchNotes] = React.useState('');
  const [allNotes, setAllNotes] = React.useState([]);

  Quill.register(
    {
      'formats/emoji': quillEmoji.EmojiBlot,
      'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
      'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
      'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
    },
    true,
  );

  const { quillRef } = useQuill({
    modules: {
      toolbar: '#toolbar',
      'emoji-toolbar': true,
      'emoji-shortname': true,
    },
    formats: ['bold', 'italic', 'underline', 'align', 'image', 'emoji'],
  });

  const notes = [
    {
      note: 'Note 1',
      editIcon: <Edit style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
      deleteIcon: <Delete style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
    },
    {
      note: 'Note 2',
      editIcon: <Edit style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
      deleteIcon: <Delete style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
    },
    {
      note: 'Note 3',
      editIcon: <Edit style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
      deleteIcon: <Delete style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
    },
    {
      note: 'Note 4',
      editIcon: <Edit style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
      deleteIcon: <Delete style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
    },
    {
      note: 'Note 5',
      editIcon: <Edit style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
      deleteIcon: <Delete style={{ color: '#3FD2F2', cursor: 'pointer' }} />,
    },
  ];

  React.useEffect(() => {
    setAllNotes(filter(notes, item => startsWith(get(item, 'note', ''), searchNotes)));
  }, [searchNotes]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
        height: '100%',
      }}
    >
      <Box sx={{ height: '60%', width: '100%', borderBottom: '1px solid #3FD2F2' }}>
        <Typography component={'div'} sx={{ marginLeft: '1rem' }}>
          {cryptoName}
        </Typography>
        <Box sx={{ height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AiOutlineAreaChart size={80} color={'#3FD2F2'} />
        </Box>
      </Box>
      <Box sx={{ height: '40%', width: '100%' }}>
        <Box sx={{ padding: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={4}>
              <Paper
                component="form"
                sx={theme => ({
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    width: 200,
                  },
                  background: '#000000',
                  border: '1px solid #3FD2F2',
                })}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, color: '#fff' }}
                  value={searchNotes}
                  placeholder="Search Notes"
                  onChange={e => {
                    setSearchNotes(e.target.value);
                  }}
                />
              </Paper>
            </Grid>
            <Grid item md={3} xs={3}>
              <Typography
                component={'div'}
                variant={'h6'}
                sx={theme => ({
                  borderBottom: '1px solid #3FD2F2',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '0.9rem',
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '0.9rem',
                  },
                })}
              >
                Untitled Note 1
              </Typography>
            </Grid>
            <Grid item md={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>
                <Stack direction={'row'} spacing={1}>
                  <Box id="toolbar" sx={{ color: 'white', border: 'unset !important' }}>
                    <Stack direction="row" spacing="2">
                      <button className="ql-bold" style={{ background: '#3FD2F2', marginRight: '0.5rem' }} />
                      <button className="ql-italic" style={{ background: '#3FD2F2', marginRight: '0.5rem' }} />
                      <button className="ql-underline" style={{ background: '#3FD2F2', marginRight: '0.5rem' }} />
                      <select className="ql-align" style={{ background: '#3FD2F2' }} />
                      <button className="ql-image" style={{ background: '#3FD2F2', marginLeft: '0.5rem' }} />
                      <button className="ql-emoji" style={{ background: '#3FD2F2', marginLeft: '0.5rem' }} />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={1} sx={{ background: '#040d3e', margin: 0, padding: 0, width: '100%' }}>
            <Grid
              item
              md={4}
              xs={4}
              sx={{
                height: 'calc(100vh - 690.39px)',
                overflowX: 'hidden',
                overflowY: 'scroll',
                direction: 'rtl',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#171616',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#175982',
                  borderRadius: 2,
                },
              }}
            >
              {isEmpty(allNotes) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: '600',
                    color: '#3FD2F2',
                    marginTop: '1.5rem',
                  }}
                >
                  Notes is not available
                </Box>
              ) : (
                map(allNotes, data => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid #3FD2F2',
                        padding: '1rem',
                        cursor: 'pointer',
                        direction: 'ltr',
                      }}
                    >
                      <Typography component={'div'}>{data.note}</Typography>
                      <Stack direction={'row'} spacing={1}>
                        {data.editIcon}
                        {data.deleteIcon}
                      </Stack>
                    </Box>
                  );
                })
              )}
            </Grid>
            <Grid item md={8} xs={8} sx={{ padding: '1rem', height: 'calc(100vh - 690.39px)' }}>
              <div
                style={{
                  width: '100%',
                  color: '#FFF',
                  borderColor: '1px solid #3FD2F2',
                  height: '100%',
                }}
              >
                <div ref={quillRef} />
                <Box id="editor" sx={{ fontSize: '1rem !important' }} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ClassificationDetail;
