import React, { useEffect } from 'react';
import useLogout from '../security/hooks/useLogout';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const signOut = async () => {
    await logout();
  };

  useEffect(() => {
    signOut();
    navigate('/login');
  });

  return <div>Sucessfully Logged out</div>;
};

export default Logout;
