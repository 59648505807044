import { get, post } from 'utils/api';

export const getExchange = () => {
  return get(`/v1/client/exchange/`);
};

export const getFrdCryptoList = () => {
  return get(`/v1/client/frd-coin-data-store/get-frd-crypto-list`);
};

export const getFrdData = data => {
  return post(`/v1/client/frd-coin-data-store/get-frd-data`, data);
};

export const classification = {
  getExchange,
  getFrdCryptoList,
  getFrdData,
};
