import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Collapse, ListItemButton } from '@mui/material';
import { Drawer, List, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import analysis from 'assets/analysis.svg';
import angel from 'assets/angel.svg';
import cold from 'assets/cold.svg';
import cyber from 'assets/cyber.svg';
import dashboard from 'assets/dashboard.svg';
import force from 'assets/force.svg';
import invest from 'assets/invest.svg';
import logo from 'assets/logoKK 1.svg';
import luna from 'assets/luna.svg';
import sentry from 'assets/sentry.svg';
import sim from 'assets/sim.svg';
import spark from 'assets/spark.svg';
import trade from 'assets/trade.svg';
import transection from 'assets/transection.svg';
import utility from 'assets/utility.svg';
import SparkModal from './sim/SparkModal';

const links = [
  {
    name: 'DASHBOARD',
    icon: dashboard,
    url: '/dashboard',
    issubmenu: false,
  },
  {
    name: 'CYBER',
    icon: cyber,
    url: '/spark',
    issubmenu: true,
  },
  {
    name: 'INVEST',
    icon: invest,
    // url: '/invest',
    issubmenu: false,
  },
  {
    name: 'ANALYSIS',
    icon: analysis,
    // url: '/analysis',
    issubmenu: false,
  },
  {
    name: 'TRADE',
    icon: trade,
    // url: '/trade',
    issubmenu: false,
  },
  {
    name: 'UTILITY',
    icon: utility,
    // url: '/utility',
    issubmenu: false,
  },
  {
    name: 'COLD',
    icon: cold,
    // url: '/cold',
    issubmenu: false,
  },
  {
    name: 'TRANSECTION',
    icon: transection,
    // url: '/transection',
    issubmenu: false,
  },
];

const cybersublinks = [
  {
    name: 'SIM',
    icon: sim,
    url: '/sim',
  },
  {
    name: 'SPARK',
    icon: spark,
    url: '/spark',
  },
  {
    name: 'LUNA',
    icon: luna,
    // url: '/luna',
    // url: '#',
  },
  {
    name: 'CLASSIFICATION',
    icon: spark,
    // url: '/classification',
    // url: '#',
  },
  {
    name: 'ANGEL',
    icon: angel,
    // url: '#',
  },
  {
    name: 'SENTRY',
    icon: sentry,
    // url: '#',
  },
  {
    name: 'FORCE',
    icon: force,
    // url: '#',
  },
];

const NavBar = ({ classes, open, handleDrawerOpen }) => {
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [pageClicked, setPageClicked] = useState('');
  const [abandonChangesModal, setAbandonChangesModal] = useState(false);

  const isNoteEdit = useSelector(state => state.sim.isNoteEdit);
  const isNoteTextEdited = useSelector(state => state.sim.isNoteTextEdited);
  const isChartDrawingChanged = useSelector(state => state.sim.isChartDrawingChanged);

  const handleSubMenu = () => {
    if (!open) {
      handleDrawerOpen();
      setOpenSubMenu(true);
    } else {
      setOpenSubMenu(!openSubMenu);
    }
  };

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.paper}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box
            onClick={() => {
              handleDrawerOpen();
              if (open) {
                setOpenSubMenu(false);
              }
            }}
          >
            {open ? (
              <>
                <img src={logo} />
              </>
            ) : (
              <>
                <Tooltip title="Hafinum" placement="right">
                  <img src={logo} />
                </Tooltip>
              </>
            )}
          </Box>
          {open && (
            <>
              <Box
                sx={{
                  fontWeight: 700,
                  paddingLeft: '0.5rem',
                  color: '#ffff',
                  fontSize: '1rem',
                  // lineHeight: '22px',
                }}
              >
                Hafinum
              </Box>
            </>
          )}
        </Box>
        <List className={classes.list}>
          {links.map((text, index) => (
            <Box>
              <ListItemButton
                key={text.name}
                className={classes.listButton}
                onClick={() => {
                  if (text.issubmenu) {
                    handleSubMenu();
                  } else {
                    if (isNoteEdit && (isNoteTextEdited || isChartDrawingChanged)) {
                      setPageClicked(text.url);
                      setAbandonChangesModal(true);
                    } else {
                      navigate(text.url);
                    }
                  }
                }}
              >
                <ListItemIcon className={classes.MuiListItemIcon}>
                  {open ? (
                    <>
                      <img src={text.icon} />
                    </>
                  ) : (
                    <>
                      <Tooltip title={text.name} placement="right">
                        <img src={text.icon} />
                      </Tooltip>
                    </>
                  )}
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText
                      className={classes.ListItemText}
                      secondary={<p className={classes.listItem}>{text.name}</p>}
                    />
                    {text.issubmenu ? (
                      openSubMenu ? (
                        <ExpandLess style={{ color: '#fff' }} />
                      ) : (
                        <ExpandMore style={{ color: '#fff' }} />
                      )
                    ) : null}
                  </>
                )}
              </ListItemButton>
              {open && text.issubmenu && (
                <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {cybersublinks.map((stext, index) => (
                      <Box>
                        <ListItemButton
                          className={classes.listButton}
                          sx={theme => ({
                            padding: '0.5rem 1rem 0.5rem 3rem !important',
                            [theme.breakpoints.down('lg')]: {
                              padding: '0.5rem 1rem 0.5rem 2.5rem !important',
                            },
                            [theme.breakpoints.down('md')]: {
                              padding: '0.5rem 1rem 0.5rem 2rem !important',
                            },
                          })}
                          onClick={() => {
                            if (isNoteEdit && (isNoteTextEdited || isChartDrawingChanged)) {
                              setPageClicked(stext.url);
                              setAbandonChangesModal(true);
                            } else {
                              navigate(stext.url);
                            }
                          }}
                        >
                          <ListItemIcon
                            className={classes.MuiListItemIcon}
                            // style={{ minWidth: '2.2rem' }}
                          >
                            <Tooltip title={stext.name} placement="right">
                              <img src={stext.icon} />
                            </Tooltip>
                          </ListItemIcon>
                          {open && (
                            <>
                              <ListItemText
                                className={classes.ListItemText}
                                secondary={<p className={classes.listItem}>{stext.name}</p>}
                              />
                            </>
                          )}
                        </ListItemButton>
                      </Box>
                    ))}
                  </List>
                </Collapse>
              )}
            </Box>
          ))}
        </List>
      </div>
      {pageClicked && (
        <SparkModal
          abandonChangesModal={abandonChangesModal}
          setAbandonChangesModal={setAbandonChangesModal}
          pageClicked={pageClicked}
          setPageClicked={setPageClicked}
        />
      )}
    </Drawer>
  );
};

export default NavBar;
