import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './pages/Admin';
import Error from './pages/Error';
import Home from './pages/Home';
import Logout from './pages/Logout';
import Unauthorized from './pages/Unauthorized';
import RequireAuth from './security/components/RequireAuth';
import Layout from './components/Layout';
import PersistLogin from './security/components/PersistentLogin';
import Dashboard from './pages/Dashboard';
import Sim from './pages/Sim';
import Spark from './pages/Spark';
import Luna from './pages/Luna';
import Notification from './pages/Notification';
import Classification from './pages/Classification';
import HistoricalModelContextProvider from 'contexts/historicalModel';

const Hafnium = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="login" element={<Login />} />
      <Route path="Unauthorized" element={<Unauthorized />} />
      <Route path="logout" element={<Logout />} />

      {/* Protected Routes */}
      <Route element={<PersistLogin />}>
        <Route element={<Layout />}>
          <Route element={<RequireAuth allowedRoles={['USER']} />}>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/sim"
              element={
                <HistoricalModelContextProvider>
                  <Sim />
                </HistoricalModelContextProvider>
              }
            />
            <Route path="/luna" element={<Luna />} />
            <Route
              path="/spark"
              element={
                <HistoricalModelContextProvider>
                  <Spark />
                </HistoricalModelContextProvider>
              }
            />
            <Route path="/notification" element={<Notification />} />
            <Route path="/classification" element={<Classification />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={['TRADE_ADMIN']} />}></Route>
          <Route element={<RequireAuth allowedRoles={['SITE_ADMIN']} />}>
            <Route path="admin" element={<Admin />} />
          </Route>
        </Route>
      </Route>
      {/* Error Routes */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default Hafnium;
