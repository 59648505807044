import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Divider, SvgIcon } from '@mui/material';
import { Form, Formik } from 'formik';
import { filter, find, get, isEmpty, map, round, upperFirst } from 'lodash';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FaFlask, FaAngleDoubleLeft } from 'react-icons/fa';
import { PiInfinityBold, PiRecycleBold } from 'react-icons/pi';
import { getSparkModalById, getSparkLimitedModels } from 'actions/sparkAction';
import {
  sparkModels,
  sparkInstanceRunModels,
  sparkInstanceStopAllModels,
  sparkInstanceClearModels,
} from 'actions/ActionTypes';
import { formatNumberWithLocale } from 'utils/common';
import BullIcon from 'assets/ico_bull.svg';
import BearIcon from 'assets/ico_bear.svg';
import RangeIcon from 'assets/ico_range.svg';
import InfoIcon from '../common/InfoIcon';
import moment from 'moment';
import { isModelApplied } from 'actions/sparkAction';

function OptimizeBlueIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1em',
          fontSize: '2.5rem !important',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1em',
          fontSize: '2rem',
        },
        [theme.breakpoints.down('md')]: {
          width: '0.7em',
          fontSize: '2rem',
        },
      })}
    >
      <svg
        width="20"
        fontSize="2.5rem !important"
        height="23"
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.10982 18.2549L4.71434 15.657C4.16266 15.3309 3.82593 14.73 3.82593 14.0814V8.88927C3.82593 8.24071 4.16625 7.63978 4.71434 7.31367L9.10982 4.71577C9.6615 4.38966 10.3386 4.38966 10.8902 4.71577L15.2857 7.31367C15.8374 7.63978 16.1741 8.24071 16.1741 8.88927V14.0814C16.1741 14.73 15.8338 15.3309 15.2857 15.657L10.8902 18.2549C10.3386 18.581 9.6615 18.581 9.10982 18.2549Z"
          fill="#00FFD1"
          fontSize="2.5rem"
        />
        <path
          d="M0.268673 10.7616C0.121798 10.7616 0 10.6371 0 10.4868V10.0764C0 9.92622 0.121798 9.80164 0.268673 9.80164C0.415547 9.80164 0.537345 9.92622 0.537345 10.0764V10.4868C0.537345 10.6371 0.415547 10.7616 0.268673 10.7616Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99821 22.9707C9.63998 22.9707 9.28533 22.8754 8.97367 22.6922L1.02454 17.9947C0.390471 17.621 0 16.9285 0 16.181V11.3516C0 11.2014 0.121798 11.0768 0.268673 11.0768C0.415547 11.0768 0.537345 11.2014 0.537345 11.3516V16.181C0.537345 16.7343 0.827512 17.2436 1.29321 17.5184L9.24592 22.2159C9.71521 22.4907 10.2884 22.4907 10.7577 22.2159L18.7104 17.5184C19.1761 17.2399 19.4662 16.7306 19.4662 16.181V6.78971C19.4662 6.23642 19.1761 5.7271 18.7104 5.45229L10.7541 0.75482C10.2848 0.480007 9.71162 0.480007 9.24234 0.75482L1.29321 5.45229C0.827512 5.73076 0.537345 6.24008 0.537345 6.78971V9.20806C0.537345 9.35829 0.415547 9.48287 0.268673 9.48287C0.121798 9.48287 0 9.36196 0 9.20806V6.78971C0 6.04222 0.390471 5.34969 1.02454 4.97595L8.97725 0.278478C9.61132 -0.0952674 10.3923 -0.0952674 11.0263 0.278478L18.979 4.97595C19.6131 5.34969 20.0036 6.04222 20.0036 6.78971V16.181C20.0036 16.9285 19.6131 17.621 18.979 17.9947L11.0263 22.6922C10.7111 22.8754 10.36 22.9707 9.99821 22.9707Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99828 18.7715C9.64004 18.7715 9.2854 18.6762 8.97374 18.493L4.57825 15.8951C3.94418 15.5214 3.55371 14.8289 3.55371 14.0814V8.88924C3.55371 8.14174 3.94418 7.44922 4.57825 7.07547L8.97374 4.47757C9.6078 4.10383 10.3887 4.10383 11.0228 4.47757L15.4183 7.07547C16.0524 7.44922 16.4428 8.14174 16.4428 8.88924V14.0814C16.4428 14.8289 16.0524 15.5214 15.4183 15.8951L11.0228 18.493C10.7112 18.6762 10.3601 18.7715 9.99828 18.7715ZM9.99828 4.74872C9.73318 4.74872 9.47168 4.81834 9.24241 4.95392L4.84692 7.55181C4.38122 7.82663 4.09106 8.33961 4.09106 8.88924V14.0814C4.09106 14.6347 4.38122 15.144 4.84692 15.4188L9.24241 18.0167C9.71169 18.2915 10.2849 18.2915 10.7541 18.0167L15.1496 15.4188C15.6153 15.144 15.9055 14.631 15.9055 14.0814V8.88924C15.9055 8.33595 15.6153 7.82663 15.1496 7.55181L10.7541 4.95392C10.5249 4.81834 10.2634 4.74872 9.99828 4.74872Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99816 23C9.85129 23 9.72949 22.8754 9.72949 22.7252V18.5004C9.72949 18.3502 9.85129 18.2256 9.99816 18.2256C10.145 18.2256 10.2668 18.3502 10.2668 18.5004V22.7252C10.2668 22.8754 10.1486 23 9.99816 23Z"
          fill="#10FAC2"
        />
        <path
          d="M15.9628 8.29936C15.8159 8.29936 15.6941 8.17478 15.6941 8.02455C15.6941 7.92561 15.7442 7.83767 15.8266 7.78638L19.3588 5.64284C19.4878 5.56589 19.6525 5.60619 19.7278 5.7381C19.803 5.87001 19.7636 6.03857 19.6346 6.11551C19.6346 6.11551 19.6346 6.11551 19.631 6.11551L16.0989 8.25905C16.0595 8.28837 16.0093 8.29936 15.9628 8.29936Z"
          fill="#10FAC2"
        />
        <path
          d="M4.03717 8.29938C3.9906 8.29938 3.94045 8.28472 3.90104 8.26274L0.379639 6.12652C0.250676 6.04591 0.211271 5.87736 0.286499 5.74911C0.361727 5.62087 0.526513 5.5769 0.651894 5.65384L4.1733 7.79006C4.30226 7.867 4.34167 8.03556 4.26644 8.16747C4.21987 8.24808 4.13031 8.29938 4.03717 8.29938Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99816 11.762C9.85129 11.762 9.72949 11.6374 9.72949 11.4872V4.59854C9.72949 4.44831 9.85129 4.32373 9.99816 4.32373C10.145 4.32373 10.2668 4.44831 10.2668 4.59854V11.4872C10.2668 11.6374 10.1486 11.762 9.99816 11.762Z"
          fill="#315048"
        />
        <path
          d="M15.8123 15.4079C15.7621 15.4079 15.712 15.3932 15.669 15.3639L10.3815 11.9526C10.1523 11.806 9.85851 11.806 9.62566 11.9526L4.33818 15.3639C4.2128 15.4445 4.04802 15.4079 3.96921 15.2796C3.8904 15.1514 3.92622 14.9828 4.0516 14.9022L9.33908 11.4909C9.74388 11.2307 10.2597 11.2307 10.6681 11.4909L15.9556 14.9022C16.081 14.9828 16.1204 15.1514 16.038 15.2796C15.9914 15.3603 15.9054 15.4079 15.8123 15.4079Z"
          fill="#315048"
        />
      </svg>
    </SvgIcon>
  );
}

function DownArrow() {
  return (
    <svg fill="#fff" width="14px" height="14px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z" />
    </svg>
  );
}

const SparkStopModelSchema = Yup.object().shape({
  // coinBase: Yup.string().required('required'),
  coinBase: Yup.object().shape({
    label: Yup.string().typeError('label must be a value').required('required'),
    value: Yup.string().typeError('value must be an id').required('required'),
  }),
  maxDrawdown: Yup.number().when('drawdown', {
    is: true,
    then: Yup.number().min(1, 'maxDrawDown must be greater than 0').required('Consecutive Losses is required'),
  }),
  durationTimer: Yup.string().when('timer', {
    is: true,
    then: Yup.string()
      .test('hours', 'Hours must be between 00 and 23', value => {
        if (!value) return true; // Allow empty input
        const hours = parseInt(value.split(':')[1], 10);
        return hours >= 0 && hours <= 23;
      })
      .test('minutes', 'Minutes must be between 00 and 59', value => {
        if (!value) return true; // Allow empty input
        const minutes = parseInt(value.split(':')[2], 10);
        return minutes >= 0 && minutes <= 59;
      })
      .test('seconds', 'Seconds must be between 00 and 59', value => {
        if (!value) return true; // Allow empty input
        const seconds = parseInt(value.split(':')[3], 10);
        return seconds >= 0 && seconds <= 59;
      })
      .required('Countdown Duration is required'),
  }),
  rollingTrades: Yup.number().when('rate', {
    is: true,
    then: Yup.number().required('rolling trades is required'),
  }),

  losses: Yup.number().when(['rate', 'rollingTrades'], {
    is: (rate, rollingTrades) => rate && rollingTrades !== undefined,
    then: Yup.number()
      .required('Losses is required')
      .test('max', 'Losses must be less than Rolling Trades', function (value) {
        return value < this.parent.rollingTrades;
      }),
  }),
});

const SparkSettings = props => {
  const {
    modelId,
    onClick,
    setModelId,
    setClearInstanceButton,
    clearInstanceButton,
    modelRiskToRewardRatio,
    modelType,
    setOnClick,
    setShowInstanceDetailPage,
    modelIdOfApplyModel,
    setShowModelSelectionScreen,
    setSelectedSparkModelData,
    handleReplaceModel = () => {},
    selectedModelKey,
    isSelectedModelUnique,
    selectedModelIdFromModelSelection,
    setSelectedModelIdFromModelSelection,
    selectedModelIdFromInstanceDetails,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonClick, setButtonClick] = useState(false);
  const [ModelButtonClick, setModelButtonClick] = useState(false);

  const exchangesData = useSelector(state => state.classification.exchanges);
  const sparkModel = useSelector(state => state.spark.sparkModel);
  const isGetSparkModelLoading = useSelector(state => state.spark.isGetSparkModelLoading);
  const isStartSparkModelLoading = useSelector(state => state.spark.isStartSparkModelLoading);
  const isStopSparkModelLoading = useSelector(state => state.spark.isStopSparkModelLoading);
  const sparkLimitedModelsData = useSelector(state => state.spark.sparkLimitedModels);
  const dataFromApplyModel = useSelector(state => state.spark.isModelApplied);

  const formatDuration = value => {
    const newValue = value?.replace(/[^0-9]/g, '');
    const formattedValue = formatInputValue(newValue);
    return formattedValue.slice(0, 11);
  };

  const formatInputValue = value => {
    return value?.replace(/(\d{2})(?=\d)/g, '$1:');
  };

  const previousRunPerformanceValues = get(
    sparkModel,
    'performanceOnEveryRunTime[' + (get(sparkModel, 'performanceOnEveryRunTime.length', 0) - 1) + ']',
  );

  useEffect(() => {
    if (
      (onClick || dataFromApplyModel?.openSparkInstanceTabSection) &&
      (!isEmpty(modelId) || !isEmpty(modelIdOfApplyModel))
    ) {
      dispatch(getSparkModalById(modelId ? modelId : modelIdOfApplyModel));
    }
  }, [onClick, modelId, modelIdOfApplyModel, dataFromApplyModel?.openSparkInstanceTabSection]);

  const exchangeOptions = filter(exchangesData, item => {
    if (item.name !== 'FRD TOP50cc') {
      return {
        label: item.name,
        value: item.id,
      };
    }
  });

  const sparkInstanceRunModel = useCallback(
    (data, resetForm) => {
      dispatch({
        type: sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL,
        payload: data,
        cb: res => {
          if (res) {
            setSelectedModelIdFromModelSelection('');
            if (res.status === 200) {
              toast.success('Spark model started successfully');
              setOnClick(false);
              setSelectedSparkModelData({
                id: data.sparkModelId,
                riskToRewardRatio: modelRiskToRewardRatio,
                modelType: modelType,
              });
              dataFromApplyModel &&
                dispatch(
                  isModelApplied({
                    ...dataFromApplyModel,
                    openSparkInstanceDrawer: true,
                    openSparkInstanceTabSection: false,
                    modelStatus: '',
                  }),
                );
              dispatch(getSparkLimitedModels());

              resetForm();
            }
          }
        },
      });
    },
    [modelRiskToRewardRatio],
  );

  const handleBeakerClick = useCallback(() => {
    dispatch({
      type: sparkModels.HANDLE_BEAKER,
      payload: modelId,
      cb: res => {
        if (res.status === 200) {
          navigate(`/sim`, {
            state: {
              isModelTabOpen: true,
            },
          });
        }
      },
    });
  }, [modelId]);

  const initialValues = {
    coinBase:
      sparkModel?.exchange?.name === 'FRD TOP50cc'
        ? { label: '', value: '' }
        : {
            label: `Live ${upperFirst(sparkModel?.exchange?.name)}`,
            value: get(
              find(exchangesData, data => sparkModel?.exchange?.id === data.id),
              'id',
              '',
            ),
          },
    drawdown: false,
    maxDrawdown: '',
    durationTimer: '',
    rollingTrades: '',
    losses: '',
    timer: false,
    rate: false,
  };

  const sparkInstanceStopModel = useCallback(() => {
    dispatch({
      type: sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS,
      payload: { sparkModelId: modelId || modelIdOfApplyModel },
      cb: res => {
        if (res) {
          setSelectedModelIdFromModelSelection('');
          if (res.status === 200) {
            setOnClick(false);
            toast.success('Spark model stopped successfully');
            dataFromApplyModel &&
              dispatch(
                isModelApplied({
                  ...dataFromApplyModel,
                  openSparkInstanceDrawer: true,
                  openSparkInstanceTabSection: false,
                  modelStatus: '',
                }),
              );
            setSelectedSparkModelData({
              id: modelId ? modelId : modelIdOfApplyModel,
              riskToRewardRatio: modelRiskToRewardRatio,
              modelType: modelType,
            });
            dispatch(getSparkLimitedModels());
          }
        }
      },
    });
  }, [modelId, modelRiskToRewardRatio]);

  const sparkInstanceClearModel = useCallback(data => {
    dispatch({
      type: sparkInstanceClearModels.SPARK_INSTANCE_CLEAR_MODEL,
      payload: { sparkModelId: data },
      cb: res => {
        if (res) {
          setSelectedModelIdFromModelSelection('');
          if (res.status === 200) {
            toast.success('Spark model clear successfully');
            dispatch(
              getSparkModalById(
                modelIdOfApplyModel
                  ? modelIdOfApplyModel
                  : selectedModelIdFromModelSelection
                  ? selectedModelIdFromModelSelection
                  : selectedModelIdFromInstanceDetails,
              ),
            );
            dataFromApplyModel &&
              dispatch(
                isModelApplied({
                  ...dataFromApplyModel,
                  openSparkInstanceDrawer: true,
                  openSparkInstanceTabSection: true,
                  modelStatus: '',
                }),
              );
            dispatch(getSparkLimitedModels());
          }
        }
      },
    });
  }, []);

  const isSelectedSparkModelRunning = sparkModel?.modelStatus !== 'running';
  const isSelectedModelCleared =
    sparkModel?.modelStatus === 'idle' &&
    sparkModel?.modelStartReason === 'Cleared' &&
    !modelIdOfApplyModel &&
    selectedModelIdFromModelSelection === '';

  return (
    <>
      {isGetSparkModelLoading ? (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={SparkStopModelSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            const startModelData = {
              sparkModelId: modelId || modelIdOfApplyModel,
              exchange: values?.coinBase ? values?.coinBase?.value : '',
              ...(values?.maxDrawdown && { maxDrawdown: values?.maxDrawdown }),
              ...(values?.durationTimer && {
                durationTimer: values?.durationTimer,
              }),
              ...(values?.rollingTrades && {
                rollingTrades: values?.rollingTrades,
              }),
              ...(values?.losses && { losses: values?.losses }),
              ...((isSelectedModelUnique || dataFromApplyModel?.selectedInstanceNumber) && {
                selectedInstanceNumber: selectedModelKey || dataFromApplyModel?.selectedInstanceNumber,
              }),
            };
            setButtonClick(true);
            setModelButtonClick(true);
            handleReplaceModel(modelId || modelIdOfApplyModel);
            if (isSelectedSparkModelRunning) {
              sparkInstanceRunModel(startModelData, resetForm);
            } else {
              sparkInstanceStopModel();
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur, resetForm }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box>
                  <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                    {sparkModel?.modelStatus === 'halted' && (
                      <Box
                        width="15rem"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="2px solid red"
                        padding="1rem"
                        color="red"
                        fontWeight="bold"
                      >
                        INSTANCE STOPPED
                      </Box>
                    )}
                    {sparkModel?.modelStatus === 'idle' && (
                      <Box
                        width="15rem"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="2px solid gray"
                        padding="1rem"
                        color="gray"
                        fontWeight="bold"
                      >
                        INSTANCE IDLE
                      </Box>
                    )}
                    {sparkModel?.modelStatus === 'running' && (
                      <Box
                        width="15rem"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="2px solid #48f3bc"
                        padding="1rem"
                        color="#48f3bc"
                        fontWeight="bold"
                      >
                        INSTANCE RUNNING
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={theme => ({
                      color: '#B1AFCD',
                      fontSize: '1.125rem',
                      fontWeight: 600,
                      padding: '0.5rem',
                      [theme.breakpoints.down('lg')]: {},
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.813rem',
                      },
                    })}
                  >
                    Model
                  </Box>
                  <Grid
                    sx={{
                      color: 'white',
                      border: '0.125rem solid #175982',
                      borderRadius: '1.25rem',
                      margin: '1.5rem',
                      marginTop: '0rem',
                    }}
                  >
                    {clearInstanceButton || isSelectedModelCleared ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '20rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '1rem',
                          fontSize: '1rem',
                          color: '#7877B1',
                        }}
                      >
                        No Model Selected
                      </Box>
                    ) : (
                      <>
                        <Grid
                          sx={theme => ({
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem',
                            [theme.breakpoints.up('lg')]: {
                              display: 'flex',
                              flexDirection: 'row',
                            },
                            [theme.breakpoints.down('lg')]: {
                              display: 'flex',
                              flexDirection: 'column',
                            },
                            [theme.breakpoints.down('md')]: {
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          })}
                        >
                          <Grid sx={{ display: 'flex', gap: '1rem' }}>
                            <Grid
                              sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5rem',
                                [theme.breakpoints.down('lg')]: {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                },
                                [theme.breakpoints.down('md')]: {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'centre',
                                },
                              })}
                            >
                              <Box>
                                {sparkModel?.bias === 'bull' ? (
                                  <img src={BullIcon} alt="bull icon" height="27rem" />
                                ) : sparkModel?.bias === 'bear' ? (
                                  <img src={BearIcon} alt="bear icon" height="27rem" />
                                ) : (
                                  sparkModel?.bias === 'range_bound' && (
                                    <img src={RangeIcon} alt="range icon" height="27rem" />
                                  )
                                )}
                              </Box>
                              <OptimizeBlueIcon />
                            </Grid>
                            <Grid
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Tooltip title={sparkModel?.name} placement="top-start">
                                <Typography
                                  sx={theme => ({
                                    fontWeight: '900 !important',
                                    maxWidth: '19.5rem !important',
                                    minWidth: '19.5rem !important',
                                    overflow: 'hidden !important',
                                    textOverflow: 'ellipsis',
                                    [theme.breakpoints.up('xl')]: {
                                      fontSize: '1.3rem !important',
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                      fontSize: '0.9rem !important',
                                    },
                                    [theme.breakpoints.down('lg')]: {
                                      fontSize: '1rem !important',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                      fontSize: '0.875rem !important',
                                    },
                                  })}
                                >
                                  {sparkModel?.name}
                                </Typography>
                              </Tooltip>
                              <Typography
                                fontSize="0.9rem !important"
                                color="gold !important"
                                fontStyle="italic !important"
                              >
                                v{sparkModel?.currentVersion}.0
                              </Typography>
                              <Typography
                                sx={theme => ({
                                  color: '#B1AFCD',
                                  minWidth: '5rem',
                                  [theme.breakpoints.down('lg')]: {
                                    fontSize: '1.063rem',
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    fontSize: '1rem',
                                  },
                                })}
                              >
                                {sparkModel?.pair?.primaryCrypto?.symbol.concat(
                                  [' - '],
                                  sparkModel?.pair.currency.name,
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              paddingRight: '1rem',
                            }}
                          >
                            <Box
                              rowGap={0.8}
                              sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.6rem',
                                [theme.breakpoints.down('lg')]: {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingLeft: 0,
                                  justifyContent: 'space-evenly',
                                  paddingTop: '0.75rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingLeft: 0,
                                  justifyContent: 'space-evenly',
                                  paddingTop: '0.75rem',
                                },
                              })}
                            >
                              <Grid
                                sx={{ paddingTop: '0.4rem', cursor: 'pointer' }}
                                onClick={() => handleBeakerClick()}
                              >
                                <FaFlask size="1.5rem" />
                              </Grid>
                              <Grid sx={{ fontSize: '1.5rem' }}>
                                {sparkModel?.rebuyMode === 'fixedRebuy' ? <PiRecycleBold /> : <PiInfinityBold />}
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>

                        <Divider
                          component=""
                          sx={{
                            borderBottomWidth: 2,
                            marginLeft: '1rem',
                            marginRight: '1rem',
                            borderColor: '#4f9fb0',
                            paddingTop: 1,
                          }}
                        />

                        <Grid>
                          <Box
                            sx={theme => ({
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 2,
                              padding: '1rem 0 0.5rem 1rem',
                              [theme.breakpoints.down('lg')]: {
                                paddingTop: '0.625rem',
                                gap: '2.4rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                paddingTop: '0.625rem',
                                gap: '2rem',
                              },
                            })}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.3rem',
                                paddingLeft: '1rem',
                              }}
                            >
                              <InfoIcon>D</InfoIcon>
                              <InfoIcon>U</InfoIcon>
                              <InfoIcon>SL</InfoIcon>
                              <InfoIcon>Q</InfoIcon>
                            </Box>

                            <Box
                              sx={theme => ({
                                minWidth: '3.75rem !important',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: '0.3rem',
                                gap: '1.3rem',
                                color: '#B1AFCD',
                                [theme.breakpoints.down('lg')]: {
                                  textAlign: 'end',
                                },
                                [theme.breakpoints.down('md')]: {
                                  textAlign: 'end',
                                },
                              })}
                            >
                              <Box>{sparkModel?.down + '%'}</Box>
                              <Box>{sparkModel?.up + '%'}</Box>
                              <Box>{sparkModel?.stopLoss + '%'}</Box>
                              <Box>{'$' + round(sparkModel?.quantity, 2)}</Box>
                            </Box>

                            <Box
                              sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: '4rem',
                                gap: 2,
                                [theme.breakpoints.down('lg')]: {
                                  paddingTop: '0.625rem',
                                  gap: '2.4rem',
                                  background: 'pink',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  gap: '0.438rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  gap: '0.375rem',
                                },
                              })}
                            >
                              <Typography sx={{ display: 'flex', justifyContent: 'end' }}> RRR:</Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Box sx={{ padding: '0.2rem 0.3rem 0rem 0rem' }}>
                                  {' '}
                                  <FaAngleDoubleLeft style={{ color: '#3FD2F2' }} />
                                </Box>{' '}
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                  }}
                                >
                                  {' '}
                                  WIN:
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Box sx={{ padding: '0.4rem 0.3rem 0 0' }}>
                                  {' '}
                                  <FaAngleDoubleLeft style={{ color: '#3FD2F2' }} />
                                </Box>{' '}
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    paddingTop: '0.188rem',
                                  }}
                                >
                                  TRDs:
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Box sx={{ padding: '0.2rem 0.3rem 0 0' }}>
                                  {' '}
                                  <FaAngleDoubleLeft style={{ color: '#3FD2F2' }} />
                                </Box>{' '}
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                  }}
                                >
                                  {' '}
                                  P:
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={theme => ({
                                minWidth: '3.75rem !important',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: '0.1rem',
                                gap: '1.3rem',
                                [theme.breakpoints.down('lg')]: {
                                  textAlign: 'end',
                                },
                                [theme.breakpoints.down('md')]: {
                                  textAlign: 'end',
                                },
                              })}
                            >
                              <Box sx={{ color: '#B1AFCD' }}>
                                {sparkModel?.riskToRewardRatio ? round(sparkModel?.riskToRewardRatio, 2) : '-'}
                              </Box>
                              <Box sx={{ color: '#3FD2F2' }}>
                                {previousRunPerformanceValues?.win !== undefined
                                  ? round(previousRunPerformanceValues?.win, 2) + '%'
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#3FD2F2' }}>
                                {previousRunPerformanceValues?.trades !== undefined
                                  ? previousRunPerformanceValues?.trades
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#3FD2F2' }}>
                                {previousRunPerformanceValues?.profitability !== undefined
                                  ? formatNumberWithLocale(
                                      round(previousRunPerformanceValues?.profitability, 2),
                                      'decimal',
                                    )
                                  : '-'}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      disabled={sparkModel?.modelStatus === 'running'}
                      onClick={() => {
                        setShowModelSelectionScreen(true);
                        setShowInstanceDetailPage(false);
                        setClearInstanceButton(false);
                      }}
                      sx={{
                        color: '#FFFFFF !important',
                        backgroundColor: '#000000 !important',
                        border: '0.125rem solid #3FD2F2 !important',
                        borderRadius: '0.625rem !important',
                        cursor: sparkModel?.modelStatus === 'running' ? 'not-allowed !important' : 'pointer !important',
                        padding: '0.5rem 4rem',
                        textTransform: 'unset !important',
                        '&.Mui-disabled': {
                          color: '#494949',
                          border: '0.125rem solid #494949 !important',
                          cursor:
                            sparkModel?.modelStatus === 'running' ? 'not-allowed !important' : 'pointer !important',
                          pointerEvents: 'all !important',
                        },
                      }}
                    >
                      Select Model
                    </Button>
                  </Grid>
                  <Box
                    sx={theme => ({
                      color: '#B1AFCD',
                      fontSize: '1.125rem',
                      fontWeight: 600,
                      padding: '0.5rem',
                      [theme.breakpoints.down('lg')]: {},
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.813rem',
                      },
                    })}
                  >
                    Exchange
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '0.625rem 0.938rem 1.563rem 0.938rem !important',
                      borderBottom: '0.063rem solid grey',
                    }}
                  >
                    {exchangeOptions?.length > 0 ? (
                      <Autocomplete
                        size="small"
                        id="coinBase"
                        name="coinBase"
                        options={map(exchangeOptions, item => {
                          return {
                            label: `Live ${upperFirst(item.name)}`,
                            value: item.id,
                          };
                        })}
                        value={values?.coinBase}
                        onBlur={handleBlur}
                        disabled={!isSelectedSparkModelRunning || isSelectedModelCleared}
                        sx={theme => ({
                          borderRadius: 2,
                          width: '100%',
                          marginLeft: '0rem !important',
                          color: '#fff',
                          '& .Mui-disabled': {
                            '-webkit-text-fill-color':
                              !isSelectedSparkModelRunning || isSelectedModelCleared
                                ? 'grey !important'
                                : 'white !important',
                          },
                        })}
                        onChange={(e, value) => {
                          setFieldValue('coinBase', value);
                        }}
                        inputValue={values?.coinBase ? values?.coinBase?.label : ''}
                        renderInput={params => (
                          <TextField
                            sx={theme => ({
                              svg: {
                                color: '#fff',
                              },
                              border: '0.063rem solid grey',
                              background: '#000',
                              borderRadius: '0.25rem',
                              cursor: !isSelectedSparkModelRunning || isSelectedModelCleared ? 'not-allowed' : 'unset',

                              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #484850',
                                borderRadius: '5px 5px 0 0',
                              },
                              '& .MuiAutocomplete-endAdornment': {
                                cursor: !isSelectedSparkModelRunning ? 'not-allowed' : 'unset',
                              },
                              input: {
                                color: 'white',
                                cursor: !isSelectedSparkModelRunning ? 'not-allowed' : 'unset',

                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                            {...params}
                          />
                        )}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body2" color="red">
                          No Available Exchanges for Coin-Pairing
                        </Typography>
                      </Box>
                    )}
                    {errors.coinBase && touched.coinBase ? (
                      <div
                        style={{
                          color: 'red',
                          padding: '5px 5px 0 0',
                          width: '100%',
                          fontSize: '0.85rem',
                          fontWeight: '545',
                          textAlign: 'right',
                        }}
                      >
                        {errors.coinBase}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sx={{ pointerEvents: 'none', padding: '0 !important' }}>
                    <MenuItem sx={{ paddingTop: '1rem !important' }}>
                      <DownArrow />
                      <label
                        style={{
                          color: '#B1AFCD',
                          fontWeight: '700',
                          marginLeft: '0.313rem',
                        }}
                      >
                        Halts
                      </label>
                    </MenuItem>
                  </Grid>

                  <Grid
                    sx={{
                      color: '#B1AFCD',
                      width: '100% !important',
                      fontWeight: '800 !important',
                      borderBottom: '0.063rem solid grey !important',
                      pointerEvents: 'none !important',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '1rem',
                    }}
                  >
                    <MenuItem
                      sx={{
                        padding: '0 !important',
                        minHeight: '0.063rem !important',
                        display: 'flex !important',
                        marginLeft: '28px',
                        justifyContent: 'start !important',
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: '#B1AFCD',
                          pointerEvents: isSelectedSparkModelRunning ? 'auto' : 'not-allowed',
                          '&.Mui-disabled': {
                            color: '#B1AFCD',
                            pointerEvents: isSelectedSparkModelRunning ? 'pointer' : 'fill !important',
                            cursor: isSelectedSparkModelRunning ? 'pointer' : 'not-allowed !important',
                          },
                        }}
                        name="drawdown"
                        checked={
                          values.drawdown || (!isSelectedSparkModelRunning && sparkModel.maxDrawdown) ? true : false
                        }
                        onChange={handleChange}
                        value={values.drawdown}
                        disabled={(!isSelectedSparkModelRunning && !buttonClick) || isSelectedModelCleared}
                      />
                      <Typography sx={{ fontWeight: '550' }}>Max Drawdown</Typography>
                    </MenuItem>
                    {(values.drawdown || (!isSelectedSparkModelRunning && sparkModel.maxDrawdown)) && (
                      <Box
                        sx={theme => ({
                          padding: '0 !important',
                          marginLeft: '28px !important',
                          display: 'flex',
                          width: '100%',
                          gap: '7rem',
                          alignItems: 'center',
                          [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                          },
                          [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                          },
                        })}
                      >
                        <Typography sx={{ fontSize: '0.9rem', paddingLeft: '4rem' }}>Consecutive Losses</Typography>
                        <Grid
                          item
                          xs={10}
                          sx={theme => ({
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                              marginLeft: '0.2rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              // marginLeft: '9.375rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              // marginLeft: '6.875rem',
                            },
                          })}
                        >
                          <TextField
                            placeholder="Losses"
                            size="small"
                            type="number"
                            name="maxDrawdown"
                            id="maxDrawdown"
                            value={
                              !isSelectedSparkModelRunning && sparkModel?.maxDrawdown
                                ? sparkModel?.maxDrawdown
                                : values.maxDrawdown
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              sx: {
                                pointerEvents: 'auto',
                                '&::placeholder': {
                                  color: 'white',
                                },
                              },
                            }}
                            sx={theme => ({
                              input: {
                                color: 'white',
                                width: '5.625rem',
                                height: '0.938rem',
                                background: 'black !important',

                                borderRadius: '0.25rem',
                                backgroundColor: '#fff',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '0.875rem',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '0.75rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '0.688rem',
                                },
                              },
                            })}
                          />
                        </Grid>
                      </Box>
                    )}
                    {errors.maxDrawdown && touched.maxDrawdown ? (
                      <div
                        style={{
                          color: 'red',
                          padding: '0px 5px 0 0',
                          width: '100%',
                          fontSize: '0.85rem',
                          fontWeight: '545',
                          textAlign: 'right',
                        }}
                      >
                        {errors.maxDrawdown}
                      </div>
                    ) : null}

                    <MenuItem
                      sx={{
                        padding: '0 !important',
                        marginLeft: '28px',
                        minHeight: '1px !important',
                        display: 'flex !important',
                        justifyContent: 'start !important',
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: '#B1AFCD',
                          pointerEvents: isSelectedSparkModelRunning ? 'auto' : 'not-allowed',
                          '&.Mui-disabled': {
                            color: '#B1AFCD',
                            pointerEvents: isSelectedSparkModelRunning ? 'pointer' : 'fill !important',
                            cursor: isSelectedSparkModelRunning ? 'pointer' : 'not-allowed !important',
                          },
                        }}
                        name="timer"
                        checked={
                          values.timer || (!isSelectedSparkModelRunning && sparkModel?.durationTimer) ? true : false
                        }
                        onChange={handleChange}
                        value={values.timer}
                        disabled={(!isSelectedSparkModelRunning && !buttonClick) || isSelectedModelCleared}
                      />
                      <Typography sx={{ fontWeight: '550' }}>Duration Timer</Typography>
                    </MenuItem>
                    {(values.timer || (!isSelectedSparkModelRunning && sparkModel?.durationTimer)) && (
                      <Box
                        sx={theme => ({
                          padding: '0 !important',
                          marginLeft: '28px',
                          display: 'flex',
                          width: '100%',
                          gap: '7rem',
                          alignItems: 'center',
                          [theme.breakpoints.up('lg')]: {
                            flexDirection: 'row',
                          },
                          [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                          },
                          [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                          },
                        })}
                      >
                        <Typography sx={{ fontSize: '0.9rem', paddingLeft: '4rem' }}>Countdown Duration</Typography>
                        <Grid
                          item
                          xs={10}
                          sx={theme => ({
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                              marginLeft: '0.1rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              // marginLeft: '9.375rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              // marginLeft: '6.875rem',
                            },
                          })}
                        >
                          <TextField
                            placeholder="DD:HH:MM:SS"
                            size="small"
                            type="text"
                            name="durationTimer"
                            id="durationTimer"
                            value={
                              !isSelectedSparkModelRunning && sparkModel?.durationTimer
                                ? sparkModel?.durationTimer
                                : values.durationTimer
                            }
                            onChange={event => {
                              const formattedValue = formatDuration(event.target.value);
                              handleChange({
                                target: {
                                  name: 'durationTimer',
                                  value: formattedValue,
                                },
                              });
                            }}
                            onBlur={handleBlur}
                            inputProps={{
                              sx: {
                                pointerEvents: 'auto',
                                '&::placeholder': {
                                  color: 'white',
                                },
                              },
                            }}
                            sx={theme => ({
                              input: {
                                background: 'black !important',
                                color: 'white',
                                width: '5.625rem',
                                height: '0.938rem',
                                borderRadius: '0.25rem',
                                backgroundColor: '#fff',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                          />
                        </Grid>
                      </Box>
                    )}
                    {errors.durationTimer && touched.durationTimer ? (
                      <div
                        style={{
                          color: 'red',
                          padding: '0px 5px 0 0',
                          width: '100%',
                          fontSize: '0.85rem',
                          fontWeight: '545',
                          textAlign: 'right',
                        }}
                      >
                        {errors.durationTimer}
                      </div>
                    ) : null}

                    <MenuItem
                      sx={{
                        padding: '0 !important',
                        marginLeft: '28px',
                        minHeight: '1px !important',
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: '#B1AFCD',
                          pointerEvents: isSelectedSparkModelRunning ? 'auto' : 'not-allowed',
                          '&.Mui-disabled': {
                            color: '#B1AFCD',
                            pointerEvents: isSelectedSparkModelRunning ? 'pointer' : 'fill !important',
                            cursor: isSelectedSparkModelRunning ? 'pointer' : 'not-allowed !important',
                          },
                        }}
                        name="rate"
                        checked={
                          values.rate || (!isSelectedSparkModelRunning && sparkModel.losses && sparkModel.rollingTrades)
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        value={values.rate}
                        disabled={(!isSelectedSparkModelRunning && !buttonClick) || isSelectedModelCleared}
                      />
                      <Typography sx={{ fontWeight: '550' }}>Loss Rate</Typography>
                    </MenuItem>
                    {(values.rate || (!isSelectedSparkModelRunning && sparkModel.rollingTrades)) && (
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          gap: '7.5rem',
                          [theme.breakpoints.up('lg')]: {
                            flexDirection: 'row',
                          },
                          [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                          },
                          [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                          },
                        })}
                      >
                        <Typography
                          sx={theme => ({
                            fontSize: '13px',
                            marginLeft: '28px',
                            [theme.breakpoints.up('lg')]: {
                              paddingLeft: '4rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              paddingLeft: '1.9rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              paddingLeft: '1.9rem',
                            },
                          })}
                        >
                          Rolling Trades
                        </Typography>
                        <Grid
                          item
                          xs={10}
                          sx={theme => ({
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                              marginLeft: '42px',
                            },
                            [theme.breakpoints.down('lg')]: {
                              // marginLeft: '150px',
                            },
                            [theme.breakpoints.down('md')]: {
                              // marginLeft: '110px',
                            },
                          })}
                        >
                          <TextField
                            placeholder="Count"
                            size="small"
                            type="number"
                            name="rollingTrades"
                            id="rollingTrades"
                            value={
                              !isSelectedSparkModelRunning && sparkModel?.rollingTrades
                                ? sparkModel?.rollingTrades
                                : values.rollingTrades
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              sx: {
                                pointerEvents: 'auto',
                                '&::placeholder': {
                                  color: 'white',
                                },
                              },
                            }}
                            sx={theme => ({
                              input: {
                                background: 'black !important',
                                color: 'white',
                                width: '90px',
                                height: '15px',
                                borderRadius: '4px',
                                backgroundColor: '#fff',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                          />
                        </Grid>
                      </Box>
                    )}
                    {errors.rollingTrades && touched.rollingTrades ? (
                      <div
                        style={{
                          color: 'red',
                          padding: '0px 5px 0 0',
                          width: '100%',
                          fontSize: '0.85rem',
                          fontWeight: '545',
                          textAlign: 'right',
                        }}
                      >
                        {errors.rollingTrades}
                      </div>
                    ) : null}
                    {(values.rate || (!isSelectedSparkModelRunning && sparkModel.losses)) && (
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          width: '100%',
                          gap: '7.5rem',
                          padding: '0px 0px 0px 15px !important',
                          [theme.breakpoints.up('lg')]: {
                            flexDirection: 'row',
                          },
                          [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                          },
                          [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                          },
                        })}
                      >
                        <Typography
                          sx={theme => ({
                            fontSize: '13px',
                            paddingLeft: '4rem',
                            marginLeft: '28px',
                            [theme.breakpoints.down('lg')]: {
                              paddingLeft: '5rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              paddingLeft: '3.5rem',
                            },
                          })}
                        >
                          Losses
                        </Typography>
                        <Grid
                          item
                          xs={10}
                          sx={theme => ({
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                              marginLeft: '70px',
                            },
                            [theme.breakpoints.down('lg')]: {
                              marginLeft: '80px',
                              marginTop: '0px',
                            },
                            [theme.breakpoints.down('md')]: {
                              marginLeft: '56px',
                              marginTop: '0.6px',
                            },
                          })}
                        >
                          <TextField
                            placeholder="Losses"
                            size="small"
                            type="number"
                            name="losses"
                            id="losses"
                            value={
                              !isSelectedSparkModelRunning && sparkModel?.losses ? sparkModel?.losses : values.losses
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              sx: {
                                pointerEvents: 'auto',
                                '&::placeholder': {
                                  color: 'white',
                                },
                              },
                            }}
                            sx={theme => ({
                              input: {
                                background: 'black !important',
                                color: 'white',
                                width: '90px',
                                height: '15px',
                                borderRadius: '4px',
                                backgroundColor: '#fff',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                          />
                        </Grid>
                      </Box>
                    )}
                    {errors.losses && touched.losses ? (
                      <div
                        style={{
                          color: 'red',
                          padding: '0 5px 5px 0',
                          width: '100%',
                          fontSize: '0.85rem',
                          fontWeight: '545',
                          textAlign: 'right',
                        }}
                      >
                        {errors.losses}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <MenuItem
                      sx={theme => ({
                        paddingTop: '1rem',
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.9rem',
                        },
                      })}
                    >
                      <DownArrow />
                      <label
                        style={{
                          color: '#B1AFCD',
                          fontWeight: '700',
                          marginLeft: '5px',
                        }}
                      >
                        {' '}
                        Previous Run Performance
                      </label>
                    </MenuItem>
                  </Grid>
                  <Box>
                    {clearInstanceButton || isSelectedModelCleared ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '15rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '1rem',
                          fontSize: '1rem',
                          color: '#7877B1',
                        }}
                      >
                        No Model Selected
                      </Box>
                    ) : (
                      <>
                        <Box
                          sx={{
                            color: '#B1AFCD',
                            width: '100%',

                            padding: '0.5rem 0 0.5rem 1rem',
                          }}
                        >
                          <Typography>System: SPARK </Typography>
                          <Typography sx={{ paddingTop: '0.3rem' }}>
                            Data: Live {upperFirst(sparkModel?.exchange?.name)}
                          </Typography>
                          <Typography sx={{ paddingTop: '0.3rem' }}>
                            Date: {
                            sparkModel.performanceOnEveryRunTime && sparkModel.performanceOnEveryRunTime.length > 0
                                ? `${moment(sparkModel.performanceOnEveryRunTime[sparkModel.performanceOnEveryRunTime.length - 1].startDate).format('MM/DD/YYYY')} - ${moment(sparkModel.performanceOnEveryRunTime[sparkModel.performanceOnEveryRunTime.length - 1].endDate).format('MM/DD/YYYY')}`
                                : '-'
                          }
                          </Typography>
                        </Box>
                        <Grid>
                          <Box
                            sx={theme => ({
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '3rem',
                              padding: '0.5rem 0 0.5rem 1rem',
                              [theme.breakpoints.down('lg')]: {
                                paddingTop: '0.625rem',
                                gap: '2.4rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                paddingTop: '0.625rem',
                                gap: '2rem',
                              },
                            })}
                          >
                            <Box
                              sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',

                                color: '#7877B1',
                                justifyContent: 'start',
                                gap: '0.1rem',
                                [theme.breakpoints.down('lg')]: {
                                  paddingTop: '0.625rem',
                                  gap: '2.4rem',
                                  background: 'pink',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  gap: '0.438rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  gap: '0.375rem',
                                },
                              })}
                            >
                              <Typography sx={{ display: 'flex' }}> {' < Win:'}</Typography>
                              <Typography sx={{ display: 'flex' }}> {'< Rev:'}</Typography>
                              <Typography sx={{ display: 'flex' }}>{' < Fees:'}</Typography>
                              <Typography sx={{ display: 'flex' }}> {'< P:'}</Typography>
                              <Typography sx={{ display: 'flex' }}> {' < TRDs:'}</Typography>
                            </Box>
                            <Box
                              sx={theme => ({
                                minWidth: '3.75rem !important',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                                gap: '0.4rem',
                                [theme.breakpoints.down('lg')]: {
                                  textAlign: 'end',
                                },
                                [theme.breakpoints.down('md')]: {
                                  textAlign: 'end',
                                },
                              })}
                            >
                              <Box sx={{ color: '#50E3C2', justifyContent: 'end' }}>
                                {previousRunPerformanceValues?.win
                                  ? round(previousRunPerformanceValues?.win, 2) + '%'
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#7877B1' }}>
                                {previousRunPerformanceValues?.revenue
                                  ? '$' + round(previousRunPerformanceValues?.revenue, 2)
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#7877B1' }}>
                                {previousRunPerformanceValues?.fees !== undefined
                                  ? formatNumberWithLocale(round(previousRunPerformanceValues?.fees, 3), 'decimal')
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#50E3C2' }}>
                                {previousRunPerformanceValues?.profitability !== undefined
                                  ? formatNumberWithLocale(
                                      round(previousRunPerformanceValues?.profitability, 2),
                                      'decimal',
                                    )
                                  : '-'}
                              </Box>
                              <Box sx={{ color: '#7877B1' }}>
                                {previousRunPerformanceValues?.trades ? previousRunPerformanceValues?.trades : '-'}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Box>

                  <Box
                    container
                    xs={12}
                    marginTop="2px"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10px 0px 0px 15px !important',
                    }}
                  >
                    <Box item xs={6} sx={{ width: '100%', paddingTop: '1rem' }}>
                      <Button
                        variant="outlined"
                        type="submit"
                        disabled={
                          (isSelectedSparkModelRunning &&
                            (errors.coinBase ||
                              errors.drawdown ||
                              clearInstanceButton ||
                              (!values.drawdown && !values.timer && !values.rate))) ||
                          isStartSparkModelLoading ||
                          isStopSparkModelLoading
                        }
                        sx={{
                          color: '#FFFFFF !important',
                          backgroundColor: '#000000 !important',
                          border: isSelectedSparkModelRunning
                            ? '2px solid #f1c40f !important'
                            : '2px solid  #FF1730 !important',
                          borderRadius: isSelectedSparkModelRunning ? '0.2rem !important' : '0.6rem !important',
                          width: '98%',
                          padding: '0.3rem !important',
                          textTransform: 'unset !important',
                          '&.Mui-disabled': {
                            color: '#494949 !important',
                            border: '0.125rem solid #494949 !important',
                          },
                        }}
                      >
                        {(isStartSparkModelLoading || isStopSparkModelLoading) && <CircularProgress size={17} />}
                        {isSelectedSparkModelRunning ? 'Start Model' : 'Stop Model'}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    container
                    xs={12}
                    marginTop="2px !important"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10px 0px 0px 15px !important',
                    }}
                  >
                    <Box item xs={6} sx={{ width: '100%' }}>
                      <Button
                        disabled={
                          sparkModel?.modelStatus === 'running' || clearInstanceButton || isSelectedModelCleared
                        }
                        variant="outlined"
                        onClick={() => {
                          setModelId('');
                          resetForm();
                          setClearInstanceButton(true);
                          sparkInstanceClearModel(modelId ? modelId : modelIdOfApplyModel);
                        }}
                        sx={{
                          color: '#FFFFFF !important',
                          backgroundColor: '#000000 !important',
                          border: '2px solid #FF1730 !important',
                          borderRadius: '0.6rem !important',
                          width: '98%',
                          padding: '0.3rem !important',
                          textTransform: 'unset !important',
                          '&.Mui-disabled': {
                            color: '#494949 !important',
                            border: '2px solid #494949 !important',
                          },
                        }}
                      >
                        Clear Instance
                      </Button>
                    </Box>
                  </Box>
                  <Grid xs={12} />
                  <Grid sx={{ marginTop: '40%' }} />
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default SparkSettings;
