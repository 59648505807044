import { all, fork } from 'redux-saga/effects';
import simSaga from './simSaga';
import usersSaga from './usersSaga';
import auth from './authSaga';
import lunaSaga from './lunaSaga';
import classificationSaga from './classificationSaga';
import sparkSaga from './sparkSaga';
import dashboardSaga from './dashboardSaga';

export function* rootSaga() {
  yield all([fork(usersSaga)]);
  yield all([fork(simSaga)]);
  yield all([fork(auth)]);
  yield all([fork(lunaSaga)]);
  yield all([fork(classificationSaga)]);
  yield all([fork(sparkSaga)]);
  yield all([fork(dashboardSaga)]);
}
