import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, CircularProgress, Grid, Modal, SvgIcon, Typography } from '@mui/material';
import { get, isEmpty, map, filter } from 'lodash';
import { toast } from 'react-toastify';
import { models, sparkPromoteModel } from 'actions/ActionTypes';
import {
  getRunningModel,
  isChartDrawingEdited,
  isNoteEdit,
  isNoteEditedFromOutside,
  isNoteTextEdited,
} from 'actions/simAction';
import { getSparkModels } from 'actions/sparkAction';

function CloseIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.6em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
      </svg>
    </SvgIcon>
  );
}

const SparkModal = props => {
  const {
    openSparkPromoteModal,
    setOpenSparkPromoteModal = () => {},
    openDeleteModal,
    setOpenDeleteModal = () => {},
    openDangerModal,
    setOpenDangerModal = () => {},
    openStopModal,
    setOpenStopModal = () => {},
    abandonChangesModal,
    setAbandonChangesModal = () => {},
    openAbandonModalForNewNoteButton,
    openAbandonModalFromSingleModal,
    setOpenAbandonModalFromSingleModal = () => {},
    openAbandonModalFromBulkModel,
    setOpenAbandonModalFromBulkModel = () => {},
    setOpenBulkModal = () => {},
    deleteNoteModal,
    setDeleteNoteModal = () => {},
    removeNote,
    deleteNoteData,
    quill = null,
    currentEditNoteId,
    setSelectedId = () => {},
    selectedModel,
    selectedRowId,
    noteEditMode,
    setNoteEditMode = () => {},
    setOpenDataLoadErrorModal = () => {},
    selectedCheckBox,
    setSelectedCheckBox = () => {},
    setOpenUpdateModel = () => {},
    setOpenEditModal = () => {},
    setOpenSingleModal = () => {},
    setIsOpenApplyModel = () => {},
    openAbandonModelFromResults,
    setOpenAbandonModelFromResults = () => {},
    openAbandonModelFromModels,
    setOpenAbandonModelFromModels = () => {},
    onClickAbandon = () => {},
    handleRowSelected = () => {},
    selectedRowData,
    openAbandonModelFromPerformance,
    setOpenAbandonModelFromPerformance = () => {},
    value,
    handleChange = () => {},
    openAbandonModelFromBottomTab,
    setOpenAbandonModelFromBottomTab = () => {},
    valueFromBottomTab,
    handleChangeFromBottomTab = () => {},
    selectedId,
    setCurrentEditNoteId = () => {},
    selectedModelPromotedStatus,
    setQuillEnabled = () => {},
    setShowSaveButton = () => {},
    setIsNotNew = () => {},
    setNoteNameState = () => {},
    setFieldError = () => {},
    ascDescOrder,
    selectedModelsData,
    noteNameRef = () => {},
    pageClicked,
    setPageClicked = () => {},
    selectedBox,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allNotes = useSelector(state => state.sim.notes);
  const getAllModelWithFilter = useSelector(state => state.sim.runningModels);
  const isDeleteModelLoading = useSelector(state => state.sim.isDeleteModelLoading);
  const isSparkPromoteModelLoading = useSelector(state => state.spark.isSparkPromoteModelLoading);
  const isNoteEditedFromOutsideFromReducer = useSelector(state => state.sim.isNoteEditedFromOutsideFromReducer);

  const [selectedModelState, setSelectedModelState] = useState('');

  useEffect(() => {
    if (selectedModel && selectedModel.id) {
      setSelectedModelState(selectedModel.id);
    }
  }, [selectedModel]);

  const handleSparkPromoteModal = useCallback(() => {
    const bodyData = {
      simModelIds: map(
        filter(
          selectedModelsData,
          model =>
            model.promotedStatus !== 'promoted' || (model.isPromoted === false && model.promotedStatus === 'promoted'),
        ),
        model => model.id,
      ),
    };
    dispatch({
      type: sparkPromoteModel.SPARK_PROMOTE_MODEL,
      payload: bodyData,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            if (openDangerModal) {
              setIsOpenApplyModel(true);
              setOpenDangerModal(false);
            } else {
              dispatch({
                type: models.GET_RUNNING_MODEL,
                payload:
                  selectedBox === 'A-Z'
                    ? { sort: 'name', order: ascDescOrder ? 'asc' : 'desc' }
                    : selectedBox === 'Starred'
                    ? { isStarred: true }
                    : selectedBox === 'Bias' && { bias: allClassification },
              });
              dispatch(getSparkModels());
              toast.success('Historical Model Promoted to Spark successfully');
              setSelectedCheckBox([]);
              setOpenSparkPromoteModal(false);
            }
          }
        }
      },
    });
  }, [selectedCheckBox]);

  const handleStopModel = useCallback(() => {
    dispatch({
      type: models.STOP_SIM_MODEL,
      payload: selectedRowId,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            dispatch(getRunningModel());
            toast.success('Model stopped successfully');
            setOpenStopModal(false);
          }
        }
      },
    });
  }, [selectedCheckBox]);

  const deleteSelectedModel = useCallback(() => {
    dispatch({
      type: models.DELETE_MODEL,
      payload: selectedCheckBox,

      cb: res => {
        if (res) {
          if (res.status === 200) {
            dispatch({
              type: models.GET_RUNNING_MODEL,
              payload:
                selectedBox === 'A-Z'
                  ? { sort: 'name', order: ascDescOrder ? 'asc' : 'desc' }
                  : selectedBox === 'Starred'
                  ? { isStarred: true }
                  : selectedBox === 'Bias' && { bias: allClassification },
            });
            setSelectedCheckBox([]);
            setOpenDeleteModal(false);
            toast.success('Model delete successfully');
          }
        }
      },
    });
  }, [selectedModel]);

  return (
    <Modal
      open={
        openDeleteModal ||
        openSparkPromoteModal ||
        openDangerModal ||
        openStopModal ||
        openAbandonModalFromSingleModal ||
        openAbandonModalFromBulkModel ||
        openAbandonModelFromResults ||
        openAbandonModelFromModels ||
        openAbandonModelFromPerformance ||
        openAbandonModelFromBottomTab ||
        (abandonChangesModal && openAbandonModalForNewNoteButton) ||
        (abandonChangesModal && !isEmpty(selectedModel)) ||
        (abandonChangesModal && pageClicked && pageClicked !== '/sim') ||
        deleteNoteModal
      }
      sx={theme => ({
        maxWidth: '23%',
        height: 'max-content',
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
          maxWidth: '27%',
        },
        [theme.breakpoints.down('lg')]: {
          maxWidth: '35%',
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '50%',
        },
      })}
    >
      <Grid
        sx={{
          color: '#fff',
          border: '0.125rem solid #62647A',
          padding: '1rem',
          background: '#040d3e',
        }}
      >
        <Grid
          sx={{
            fontSize: '1.2rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.5rem',
          }}
        >
          {openDeleteModal
            ? 'Delete Models'
            : openSparkPromoteModal
            ? 'SPARK Promote'
            : openDangerModal
            ? 'DANGER!'
            : deleteNoteModal
            ? 'Delete Note?'
            : abandonChangesModal ||
              openAbandonModalFromSingleModal ||
              openAbandonModalFromBulkModel ||
              openAbandonModelFromResults ||
              openAbandonModelFromModels ||
              openAbandonModelFromPerformance ||
              openAbandonModelFromBottomTab
            ? 'Abandon Changes?'
            : openStopModal && 'Stop Model?'}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenDeleteModal(false) ||
                setOpenDangerModal(false) ||
                setOpenStopModal(false) ||
                setAbandonChangesModal(false) ||
                setDeleteNoteModal(false) ||
                setOpenSparkPromoteModal(false) ||
                setOpenAbandonModalFromSingleModal(false) ||
                setOpenAbandonModalFromBulkModel(false) ||
                setOpenAbandonModelFromResults(false) ||
                setOpenAbandonModelFromModels(false) ||
                setOpenAbandonModelFromPerformance(false) ||
                setOpenAbandonModelFromBottomTab(false);
            }}
          >
            <CloseIcon />
          </span>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            paddingTop:
              openStopModal ||
              openDangerModal ||
              deleteNoteModal ||
              abandonChangesModal ||
              openAbandonModalFromSingleModal ||
              openAbandonModalFromBulkModel ||
              openAbandonModelFromResults ||
              openAbandonModelFromModels ||
              openAbandonModelFromBottomTab ||
              openAbandonModelFromPerformance
                ? '3.2rem !important'
                : '1.2rem !important',
            paddingRight: '0.625rem',
            paddingLeft: '1.5rem',
          }}
        >
          <Typography>
            {openDeleteModal
              ? 'Are you sure you want to delete the following Model(s)?'
              : openSparkPromoteModal
              ? 'Are you sure you want to promote the following Model(s) to the SPARK Models list?'
              : openDangerModal
              ? 'This model is UNTESTED, are you sure you want to run it in LIVE SPARK Instance?'
              : abandonChangesModal ||
                openAbandonModalFromSingleModal ||
                openAbandonModalFromBulkModel ||
                openAbandonModelFromResults ||
                openAbandonModelFromModels ||
                openAbandonModelFromBottomTab ||
                openAbandonModelFromPerformance
              ? 'You have unsaved changes. Would you like to continue editing or abandon your changes?'
              : deleteNoteModal
              ? 'Are you sure you want to permanently delete this note?'
              : openStopModal && 'Are you sure you wish to stop this SIM Model?'}
          </Typography>
        </Grid>

        <Grid
          sx={{
            color: '#3FD2F2',
            paddingTop: '1rem !important',
            paddingLeft: '3.5rem',
          }}
        >
          {(openDeleteModal || openSparkPromoteModal) && (
            <Box
              sx={{
                height: '5rem !important',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '0.438rem',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'gray',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#3FD2F2',
                  borderRadius: '0.5rem',
                },
              }}
            >
              {openSparkPromoteModal
                ? selectedCheckBox.map(modelName => {
                    return (
                      <Box>
                        {
                          filter(
                            getAllModelWithFilter,
                            model =>
                              model.id === modelName &&
                              (model.promotedStatus !== 'promoted' ||
                                (model.isPromoted === false && model.promotedStatus === 'promoted')),
                          )[0]?.name
                        }
                      </Box>
                    );
                  })
                : selectedCheckBox.map(modelName => {
                    return <Box>{filter(getAllModelWithFilter, model => model.id === modelName)[0]?.name}</Box>;
                  })}
            </Box>
          )}
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop:
              openDangerModal || openStopModal
                ? '3rem !important'
                : deleteNoteModal || abandonChangesModal
                ? '3.5rem !important'
                : '1.2rem !important',
            paddingBottom: '0.7rem !important',
          }}
        >
          <Button
            sx={{
              borderRadius: '0.5rem !important',
              border: '1px solid #3FD2F2 !important',
              color: 'white',
              background: 'black !important',
              paddingX: '1.9rem !important',
              textTransform: 'unset !important',
            }}
            onClick={() => {
              setDeleteNoteModal(false) ||
                setOpenSparkPromoteModal(false) ||
                setOpenDeleteModal(false) ||
                setOpenDangerModal(false) ||
                setOpenStopModal(false) ||
                setAbandonChangesModal(false) ||
                setOpenAbandonModalFromSingleModal(false) ||
                setOpenAbandonModalFromBulkModel(false) ||
                setOpenAbandonModelFromResults(false) ||
                setOpenAbandonModelFromModels(false) ||
                setOpenAbandonModelFromBottomTab(false) ||
                setOpenAbandonModelFromPerformance(false);
              if (!pageClicked) {
                setNoteEditMode(false);
              } else {
                setPageClicked('');
              }

              if (abandonChangesModal || openAbandonModalFromSingleModal) {
                setCurrentEditNoteId(selectedId);
              }
              dispatch(isNoteEditedFromOutside(false));
            }}
          >
            {abandonChangesModal ||
            openAbandonModalFromSingleModal ||
            openAbandonModalFromBulkModel ||
            openAbandonModelFromResults ||
            openAbandonModelFromModels ||
            openAbandonModelFromBottomTab ||
            openAbandonModelFromPerformance
              ? 'Continue'
              : 'Cancel'}
          </Button>

          <Button
            disabled={isDeleteModelLoading || isSparkPromoteModelLoading}
            sx={{
              borderRadius: '0.5rem !important',
              border: '1px solid #3FD2F2 !important',
              color:
                openDeleteModal ||
                deleteNoteModal ||
                abandonChangesModal ||
                openAbandonModalFromSingleModal ||
                openAbandonModalFromBulkModel ||
                openAbandonModelFromResults ||
                openAbandonModelFromModels ||
                openAbandonModelFromBottomTab ||
                openAbandonModelFromPerformance ||
                openStopModal
                  ? 'white !important'
                  : 'black !important',
              background: openSparkPromoteModal || openDangerModal ? '#f1c40f !important' : '#922121 !important',
              paddingX: '2rem !important',
              fontWeight: openSparkPromoteModal || openDangerModal ? '600 !important' : 'none',
              '&:hover': {
                color: openSparkPromoteModal || openDangerModal ? 'white  !important' : '',
              },
              textTransform: 'unset !important',

              '&:disabled': {
                cursor: 'not-allowed  !important',
                pointerEvents: 'all !important',
                backgroundColor: 'grey  !important',
                color: 'white  !important',
                border: 'none !important',
              },
            }}
            onClick={() => {
              if (!pageClicked) {
                setNoteEditMode(false);
              } else {
                navigate(pageClicked);
                setPageClicked('');
              }

              openDeleteModal && deleteSelectedModel();
              openSparkPromoteModal && handleSparkPromoteModal();
              openAbandonModalFromSingleModal && setOpenUpdateModel(false);
              openAbandonModalFromSingleModal && setOpenEditModal(false);
              openAbandonModalFromSingleModal && setOpenSingleModal(false);
              openAbandonModalFromSingleModal && setOpenAbandonModalFromSingleModal(false);
              openAbandonModalFromBulkModel && setOpenBulkModal(false);
              openAbandonModalFromBulkModel && setOpenAbandonModalFromBulkModel(false);
              openStopModal && handleStopModel();
              deleteNoteModal && setDeleteNoteModal(false);
              deleteNoteModal && removeNote(get(deleteNoteData, 'id', ''));

              if (selectedModel) {
                if (selectedModelState) {
                  dispatch({
                    type: models.GET_MODEL_TRADE_DATA,
                    payload: selectedModelState,
                    cb: res => {
                      if (res) {
                        if (res?.status === 200) {
                          isEmpty(res.data.data.tradesWithBulkBackTestAnalysis.trades) &&
                            setOpenDataLoadErrorModal(true);
                        }
                      }
                    },
                  });
                }
              }

              if (isEmpty(selectedModelState) && !pageClicked) {
                setSelectedId(currentEditNoteId);
                quill && quill.setText(get(filter(allNotes, note => note.id === currentEditNoteId)[0], 'note', ''));
              }

              setAbandonChangesModal(false);

              if (openDangerModal) {
                if (
                  selectedModelPromotedStatus === 'previousVersion' ||
                  selectedModelPromotedStatus === 'notPromoted'
                ) {
                  handleSparkPromoteModal();
                } else {
                  setIsOpenApplyModel(true);
                  setOpenDangerModal(false);
                }
              }

              openAbandonModelFromResults && handleRowSelected(selectedRowData);
              openAbandonModelFromResults && setOpenAbandonModelFromResults(false);

              openAbandonModelFromModels && onClickAbandon();
              openAbandonModelFromModels && setOpenAbandonModelFromModels(false);

              if (openAbandonModelFromPerformance) {
                const customAddNewNoteBtn = document.getElementById('new-note');
                customAddNewNoteBtn.addEventListener('click', () => {
                  // Export the chart's current state
                  // window.tvWidget.save((chartData) => {
                  //   dispatch({ type: models.CHART_DATA, payload: chartData });
                  // });
                });
              }

              openAbandonModelFromPerformance && handleChange(_, value === 1 ? 0 : 1);
              openAbandonModelFromPerformance && setOpenAbandonModelFromPerformance(false);

              openAbandonModelFromBottomTab && handleChangeFromBottomTab(_, valueFromBottomTab === 1 ? 0 : 1);
              openAbandonModelFromBottomTab && setOpenAbandonModelFromBottomTab(false);

              if (abandonChangesModal && openAbandonModalForNewNoteButton) {
                quill.setText('');
                setSelectedId('');
                setShowSaveButton(true);
                setIsNotNew(false);
                setNoteNameState('');
                noteNameRef.current && noteNameRef.current.focus();
                setFieldError('noteName', '');
                dispatch({ type: models.CHART_DATA, payload: {} });
                dispatch(isChartDrawingEdited(false));
              }
              setQuillEnabled(false);

              if (isNoteEditedFromOutsideFromReducer || pageClicked) {
                dispatch(isNoteEdit(false));
              }
              dispatch(isNoteTextEdited(false));
              dispatch(isChartDrawingEdited(false));
            }}
          >
            {(isDeleteModelLoading || isSparkPromoteModelLoading) && <CircularProgress size={17} />}
            {openDeleteModal || deleteNoteModal
              ? 'Delete'
              : openSparkPromoteModal
              ? 'Promote'
              : openDangerModal
              ? 'Proceed'
              : abandonChangesModal ||
                openAbandonModalFromSingleModal ||
                openAbandonModalFromBulkModel ||
                openAbandonModelFromResults ||
                openAbandonModelFromModels ||
                openAbandonModelFromBottomTab ||
                openAbandonModelFromPerformance
              ? 'Abandon'
              : openStopModal && 'Stop'}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SparkModal;
