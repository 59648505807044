import { all, call, put, takeLatest } from 'redux-saga/effects';
import { usersTypes } from 'actions/ActionTypes';
import { fetchUsersFailure, fetchUsersSuccess } from 'actions/usersAction';
import { API } from 'apis';

function* fetchUsersSaga() {
  try {
    const response = yield call(API.getUsers);
    yield put(
      fetchUsersSuccess({
        users: response.data.data,
      }),
    );
  } catch (e) {
    yield put(
      fetchUsersFailure({
        error: e.message,
      }),
    );
  }
}

function* usersSaga() {
  yield all([takeLatest(usersTypes.FETCH_USERS_REQUEST, fetchUsersSaga)]);
}

export default usersSaga;
