import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import {
  Box,
  Button,
  SvgIcon,
  Checkbox,
  Grid,
  Input,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  CircularProgress,
  TableContainer,
} from '@mui/material';
import OptimizedCard from './OptimizedCard';
import deleteButton from 'assets/btnicons/delete.svg';
import duplicateButton from 'assets/btnicons/duplicate.svg';
import exportButton from 'assets/btnicons/export.svg';
import loadButton from 'assets/btnicons/load.svg';
import StarIcon from '@mui/icons-material/Star';
import applyModelButton from 'assets/btnicons/applymodel.svg';
import classLeftSideIcon from 'assets/classleftside.svg';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getRunningModel } from 'actions/simAction';
import { isEmpty, includes, get, filter, map, size, head, every, uniq, upperCase } from 'lodash';
import { startsWith } from 'components/common/utils';
import { models, simInstanceRunModels, sparkPromoteModel } from 'actions/ActionTypes';
import { toast } from 'react-toastify';
import { Add } from '@material-ui/icons';
import SparkModal from './SparkModal';
import DuplicateModal from './DuplicateModal';
import BulkModal from './BulkModal';
import SingleModel from './SingleModel';
import { EnumTypeOfModel } from 'constants/model-enums';
import { BsSortAlphaDown, BsSortAlphaDownAlt } from 'react-icons/bs';
import { pusher } from 'utils/common';
import { EnumPusherChannelName, EnumPusherEventName } from 'constants/pusher-enums';
import Pusher from 'pusher-js';

function SparkIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        fontSize: '1.5rem',
        paddingRight: '0.313rem',
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0718 10.0719L8.21558 11H11L10.0718 10.0719Z" fill="#FFEE6E" />
        <path d="M10.9999 8.2157L10.0718 10.0719L10.9999 11.0001V8.2157Z" fill="#FFBC36" />
        <path d="M11.9281 10.0719L11 8.2157V11.0001L11.9281 10.0719Z" fill="#FFEE6E" />
        <path d="M8.21558 11L10.0718 11.9281L11 11H8.21558Z" fill="#FFBC36" />
        <path d="M11 11H13.7844L11.9281 10.0719L11 11Z" fill="#FFBC36" />
        <path d="M11.9281 11.9281L13.7844 11H11L11.9281 11.9281Z" fill="#FFA426" />
        <path d="M11 13.7844L11.9281 11.9281L11 11V13.7844Z" fill="#FFBC36" />
        <path d="M10.0718 11.9281L10.9999 13.7844V11L10.0718 11.9281Z" fill="#FF9500" />
        <path
          d="M2.04803 11C2.04803 11.3718 1.74666 11.6731 1.3749 11.6731C1.00315 11.6731 0.701777 11.3718 0.701777 11C0.701777 10.6283 1.00315 10.3269 1.3749 10.3269C1.74666 10.3269 2.04803 10.6283 2.04803 11Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path
          d="M21.4013 11C21.4013 11.3718 21.0999 11.6732 20.7282 11.6732C20.3564 11.6732 20.055 11.3718 20.055 11C20.055 10.6283 20.3564 10.3269 20.7282 10.3269C21.0999 10.3269 21.4013 10.6283 21.4013 11Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path d="M11.4811 3.1969H10.553V5.01877H11.4811V3.1969Z" fill="#FFF48F" />
        <path d="M11.4811 5.9469H10.553V6.87502H11.4811V5.9469Z" fill="#FFC247" />
        <path d="M11.4811 16.9469H10.553V18.7688H11.4811V16.9469Z" fill="#FFF48F" />
        <path d="M11.4811 15.125H10.553V16.0531H11.4811V15.125Z" fill="#FFC247" />
        <path d="M18.7687 10.5532H16.9468V11.4813H18.7687V10.5532Z" fill="#FFF48F" />
        <path d="M16.0531 10.5532H15.125V11.4813H16.0531V10.5532Z" fill="#FFC247" />
        <path d="M5.01865 10.5532H3.19678V11.4813H5.01865V10.5532Z" fill="#FFF48F" />
        <path d="M6.8749 10.5532H5.94678V11.4813H6.8749V10.5532Z" fill="#FFC247" />
        <path d="M21.064 20.4304L20.4077 21.0867L21.3314 22.0103L21.9876 21.354L21.064 20.4304Z" fill="#FFF48F" />
        <path d="M14.115 13.4696L13.4587 14.1259L19.4868 20.1539L20.143 19.4976L14.115 13.4696Z" fill="#FFC247" />
        <path d="M2.51297 1.84531L1.85669 2.50159L7.88472 8.52961L8.54099 7.87334L2.51297 1.84531Z" fill="#FFC247" />
        <path
          d="M0.668484 -0.010159L0.012207 0.646118L0.935856 1.56977L1.59213 0.91349L0.668484 -0.010159Z"
          fill="#FFF48F"
        />
        <path
          d="M0.912907 20.4079L-0.0107422 21.3315L0.645535 21.9878L1.56918 21.0642L0.912907 20.4079Z"
          fill="#FFF48F"
        />
        <path d="M7.87373 13.4587L1.8457 19.4867L2.50198 20.143L8.53001 14.1149L7.87373 13.4587Z" fill="#FFC247" />
        <path d="M19.4975 1.85674L13.4695 7.88477L14.1258 8.54104L20.1538 2.51302L19.4975 1.85674Z" fill="#FFC247" />
        <path
          d="M21.3533 0.0123859L20.4297 0.936035L21.086 1.59231L22.0096 0.668663L21.3533 0.0123859Z"
          fill="#FFF48F"
        />
        <path d="M21.0419 5.93771L20.1033 6.36475L20.4876 7.20952L21.4263 6.78248L21.0419 5.93771Z" fill="#FFC247" />
        <path d="M19.1431 6.7972L14.5125 8.90393L14.8968 9.74871L19.5274 7.64198L19.1431 6.7972Z" fill="#FF7045" />
        <path d="M20.5569 14.0231L20.231 14.8921L21.1965 15.2543L21.5225 14.3853L20.5569 14.0231Z" fill="#FFC247" />
        <path d="M14.9322 11.9663L14.6116 12.8373L19.2567 14.5475L19.5773 13.6766L14.9322 11.9663Z" fill="#FF7045" />
        <path d="M15.635 20.1059L14.79 20.49L15.2168 21.4288L16.0617 21.0448L15.635 20.1059Z" fill="#FFC247" />
        <path d="M13.0974 14.5127L12.2524 14.8967L14.3576 19.5284L15.2026 19.1443L13.0974 14.5127Z" fill="#FF7045" />
        <path d="M14.3848 0.476804L14.0225 1.44226L14.8914 1.7684L15.2538 0.802948L14.3848 0.476804Z" fill="#FFC247" />
        <path d="M13.6912 2.38083L11.9443 7.12268L12.8153 7.44353L14.5622 2.70168L13.6912 2.38083Z" fill="#FF7045" />
        <path d="M1.1452 5.94709L0.756592 6.78992L1.66185 7.20731L2.05046 6.36448L1.1452 5.94709Z" fill="#FFC247" />
        <path d="M2.94915 6.76606L2.56055 7.60889L7.08686 9.69584L7.47546 8.85301L2.94915 6.76606Z" fill="#FF7045" />
        <path d="M1.56142 14.0522L0.629639 14.4066L0.959587 15.2741L1.89137 14.9197L1.56142 14.0522Z" fill="#FFC247" />
        <path d="M7.1074 11.959L2.50244 13.678L2.82701 14.5474L7.43196 12.8285L7.1074 11.959Z" fill="#FF7045" />
        <path d="M6.42233 20.0825L6.00024 21.0234L6.84707 21.4033L7.26916 20.4624L6.42233 20.0825Z" fill="#FFC247" />
        <path d="M8.91044 14.5332L6.82812 19.175L7.67495 19.5549L9.75726 14.9131L8.91044 14.5332Z" fill="#FF7045" />
        <path d="M7.66761 0.488703L6.79712 0.810669L7.15486 1.77788L8.02535 1.45591L7.66761 0.488703Z" fill="#FFC247" />
        <path d="M8.38049 2.3917L7.50806 2.70837L9.18527 7.32906L10.0577 7.01239L8.38049 2.3917Z" fill="#FF7045" />
        <path
          d="M11.8449 1.1C11.8449 1.56668 11.4666 1.945 10.9999 1.945C10.5332 1.945 10.1549 1.56668 10.1549 1.1C10.1549 0.63332 10.5332 0.255 10.9999 0.255C11.4666 0.255 11.8449 0.633319 11.8449 1.1Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <path
          d="M11.8449 20.9C11.8449 21.3667 11.4666 21.745 10.9999 21.745C10.5332 21.745 10.1549 21.3667 10.1549 20.9C10.1549 20.4334 10.5332 20.055 10.9999 20.055C11.4666 20.055 11.8449 20.4334 11.8449 20.9Z"
          fill="black"
          stroke="#F7C906"
          stroke-width="0.51"
        />
        <defs>
          <clipPath id="clip0_166_3710">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

function OptimizeBlueIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        fontSize: '1.3rem',
        [theme.breakpoints.up('lg')]: {
          fontSize: '0.9rem !important',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: '0.9rem',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
      })}
    >
      <svg width="20" fontSize="2.5rem" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.10982 18.2549L4.71434 15.657C4.16266 15.3309 3.82593 14.73 3.82593 14.0814V8.88927C3.82593 8.24071 4.16625 7.63978 4.71434 7.31367L9.10982 4.71577C9.6615 4.38966 10.3386 4.38966 10.8902 4.71577L15.2857 7.31367C15.8374 7.63978 16.1741 8.24071 16.1741 8.88927V14.0814C16.1741 14.73 15.8338 15.3309 15.2857 15.657L10.8902 18.2549C10.3386 18.581 9.6615 18.581 9.10982 18.2549Z"
          fill=""
          fontSize="2.5rem"
        />
        <path
          d="M0.268673 10.7616C0.121798 10.7616 0 10.6371 0 10.4868V10.0764C0 9.92622 0.121798 9.80164 0.268673 9.80164C0.415547 9.80164 0.537345 9.92622 0.537345 10.0764V10.4868C0.537345 10.6371 0.415547 10.7616 0.268673 10.7616Z"
          fill="white"
        />
        <path
          d="M9.99821 22.9707C9.63998 22.9707 9.28533 22.8754 8.97367 22.6922L1.02454 17.9947C0.390471 17.621 0 16.9285 0 16.181V11.3516C0 11.2014 0.121798 11.0768 0.268673 11.0768C0.415547 11.0768 0.537345 11.2014 0.537345 11.3516V16.181C0.537345 16.7343 0.827512 17.2436 1.29321 17.5184L9.24592 22.2159C9.71521 22.4907 10.2884 22.4907 10.7577 22.2159L18.7104 17.5184C19.1761 17.2399 19.4662 16.7306 19.4662 16.181V6.78971C19.4662 6.23642 19.1761 5.7271 18.7104 5.45229L10.7541 0.75482C10.2848 0.480007 9.71162 0.480007 9.24234 0.75482L1.29321 5.45229C0.827512 5.73076 0.537345 6.24008 0.537345 6.78971V9.20806C0.537345 9.35829 0.415547 9.48287 0.268673 9.48287C0.121798 9.48287 0 9.36196 0 9.20806V6.78971C0 6.04222 0.390471 5.34969 1.02454 4.97595L8.97725 0.278478C9.61132 -0.0952674 10.3923 -0.0952674 11.0263 0.278478L18.979 4.97595C19.6131 5.34969 20.0036 6.04222 20.0036 6.78971V16.181C20.0036 16.9285 19.6131 17.621 18.979 17.9947L11.0263 22.6922C10.7111 22.8754 10.36 22.9707 9.99821 22.9707Z"
          fill="white"
        />
        <path
          d="M9.99828 18.7715C9.64004 18.7715 9.2854 18.6762 8.97374 18.493L4.57825 15.8951C3.94418 15.5214 3.55371 14.8289 3.55371 14.0814V8.88924C3.55371 8.14174 3.94418 7.44922 4.57825 7.07547L8.97374 4.47757C9.6078 4.10383 10.3887 4.10383 11.0228 4.47757L15.4183 7.07547C16.0524 7.44922 16.4428 8.14174 16.4428 8.88924V14.0814C16.4428 14.8289 16.0524 15.5214 15.4183 15.8951L11.0228 18.493C10.7112 18.6762 10.3601 18.7715 9.99828 18.7715ZM9.99828 4.74872C9.73318 4.74872 9.47168 4.81834 9.24241 4.95392L4.84692 7.55181C4.38122 7.82663 4.09106 8.33961 4.09106 8.88924V14.0814C4.09106 14.6347 4.38122 15.144 4.84692 15.4188L9.24241 18.0167C9.71169 18.2915 10.2849 18.2915 10.7541 18.0167L15.1496 15.4188C15.6153 15.144 15.9055 14.631 15.9055 14.0814V8.88924C15.9055 8.33595 15.6153 7.82663 15.1496 7.55181L10.7541 4.95392C10.5249 4.81834 10.2634 4.74872 9.99828 4.74872Z"
          fill="white "
        />
        <path
          d="M9.99816 23C9.85129 23 9.72949 22.8754 9.72949 22.7252V18.5004C9.72949 18.3502 9.85129 18.2256 9.99816 18.2256C10.145 18.2256 10.2668 18.3502 10.2668 18.5004V22.7252C10.2668 22.8754 10.1486 23 9.99816 23Z"
          fill="#10FAC2"
        />
        <path
          d="M15.9628 8.29936C15.8159 8.29936 15.6941 8.17478 15.6941 8.02455C15.6941 7.92561 15.7442 7.83767 15.8266 7.78638L19.3588 5.64284C19.4878 5.56589 19.6525 5.60619 19.7278 5.7381C19.803 5.87001 19.7636 6.03857 19.6346 6.11551C19.6346 6.11551 19.6346 6.11551 19.631 6.11551L16.0989 8.25905C16.0595 8.28837 16.0093 8.29936 15.9628 8.29936Z"
          fill="#10FAC2"
        />
        <path
          d="M4.03717 8.29938C3.9906 8.29938 3.94045 8.28472 3.90104 8.26274L0.379639 6.12652C0.250676 6.04591 0.211271 5.87736 0.286499 5.74911C0.361727 5.62087 0.526513 5.5769 0.651894 5.65384L4.1733 7.79006C4.30226 7.867 4.34167 8.03556 4.26644 8.16747C4.21987 8.24808 4.13031 8.29938 4.03717 8.29938Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99816 11.762C9.85129 11.762 9.72949 11.6374 9.72949 11.4872V4.59854C9.72949 4.44831 9.85129 4.32373 9.99816 4.32373C10.145 4.32373 10.2668 4.44831 10.2668 4.59854V11.4872C10.2668 11.6374 10.1486 11.762 9.99816 11.762Z"
          fill="#315048"
        />
        <path
          d="M15.8123 15.4079C15.7621 15.4079 15.712 15.3932 15.669 15.3639L10.3815 11.9526C10.1523 11.806 9.85851 11.806 9.62566 11.9526L4.33818 15.3639C4.2128 15.4445 4.04802 15.4079 3.96921 15.2796C3.8904 15.1514 3.92622 14.9828 4.0516 14.9022L9.33908 11.4909C9.74388 11.2307 10.2597 11.2307 10.6681 11.4909L15.9556 14.9022C16.081 14.9828 16.1204 15.1514 16.038 15.2796C15.9914 15.3603 15.9054 15.4079 15.8123 15.4079Z"
          fill="#315048"
        />
      </svg>
    </SvgIcon>
  );
}

const Models = ({
  value,
  classes,
  setIsOpenApplyModel,
  setValue,
  setOpenDataLoadErrorModal,
  setSelectedApplyModelData,
  setIsModelSelected,
  isModelTabOpenFromBeaker,
  selectedCheckBox,
  setSelectedCheckBox,
  setLoadModelId,
}) => {
  const biasRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const getAllModelWithFilter = useSelector(state => state.sim.runningModels);
  const loader = useSelector(state => state.sim.pending);
  const isRunModelLoading = useSelector(state => state.sim.isRunModelLoading);
  const isGetModelTradeDataLoading = useSelector(state => state.sim.isGetModelTradeDataLoading);
  const mostRecentVersionModel = useSelector(state => state.spark.mostRecentVersionModel.historicalModel);
  const isVersionMatch = useSelector(state => state.spark.mostRecentVersionModel.isVersionMatch);
  const isNoteEdit = useSelector(state => state.sim.isNoteEdit);
  const isNoteTextEdited = useSelector(state => state.sim.isNoteTextEdited);
  const isChartDrawingChanged = useSelector(state => state.sim.isChartDrawingChanged);

  const [search, setSearch] = useState();
  const [openSingleModal, setOpenSingleModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [openSparkPromoteModal, setOpenSparkPromoteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDangerModal, setOpenDangerModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openAbandonModelFromModels, setOpenAbandonModelFromModels] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [allModel, setAllModel] = useState([]);
  const [duplicateModalName, setDuplicateModalName] = useState('');
  const [selectedBox, setSelectedBox] = useState('Stacked');
  const [sortByItem, setSortByItem] = useState({ item: 1, order: 'asc' });
  const [allClassification, setAllClassification] = useState(['bull', 'range_bound', 'bear']);
  const [isSelectBiasOption, setIsSelectBiasOption] = useState(false);
  const [ascDescOrder, setAscDescOrder] = useState(true);

  const singleModelsData = filter(getAllModelWithFilter, model => model.modelType === 'historicalModel');
  const selectedModelsData = filter(singleModelsData, item => includes(selectedCheckBox, item.id));
  const selectedModelData = head(selectedModelsData);
  const classification = [
    { label: 'BULL', value: 'bull' },
    { label: 'RANGE', value: 'range_bound' },
    { label: 'BEAR', value: 'bear' },
  ];

  useEffect(() => {
    if (value === 0) {
      setSelectedCheckBox([]);
    }
  }, [value]);

  useEffect(() => {
    if (!isEmpty(mostRecentVersionModel) && location?.state?.isModelTabOpen && !isModelTabOpenFromBeaker) {
      setSelectedCheckBox([mostRecentVersionModel.id]);
    }
  }, [mostRecentVersionModel, isModelTabOpenFromBeaker]);

  useEffect(() => {
    if (
      !isEmpty(mostRecentVersionModel) &&
      !isVersionMatch &&
      location?.state?.isModelTabOpen &&
      !isModelTabOpenFromBeaker
    ) {
      toast.warning('Redirect to newest version of model.');
    }
  }, [mostRecentVersionModel, isVersionMatch, isModelTabOpenFromBeaker]);

  useEffect(() => {
    if (!isEmpty(singleModelsData)) {
      setAllModel(singleModelsData);
    }
  }, []);

  useEffect(() => {
    setAllModel(filter(singleModelsData, item => startsWith(get(item, 'name', ''), search)));
  }, [search, getAllModelWithFilter]);

  const getSortByItem = useCallback(data => {
    dispatch(getRunningModel({ ...data, filter: true }));
  }, []);

  useEffect(() => {
    if (allClassification.length > 0 && isSelectBiasOption) {
      getSortByItem({ bias: allClassification });
    }
  }, [allClassification, isSelectBiasOption]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (biasRef.current && !biasRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
    };
    if (openPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openPopup]);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      let sortOrder = dataItem.order;
      if (selectedBox === 'A-Z') {
        sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      }
      const data = {
        sort: sortItem === 1 && 'name',
        order: sortOrder,
      };
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(data);
    }
  };

  useEffect(() => {
    handleSorting(1, sortByItem);
    setSelectedBox('A-Z');
  }, []);

  // ::::: Pusher Functionality  :::::
  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;
    const channel = pusher.subscribe(EnumPusherChannelName.MODEL_STATUS);
    channel.bind(EnumPusherEventName.SIM_MODEL_STATUS, function () {
      if (selectedBox === 'A-Z') {
        const data = {
          sort: 'name',
          order: ascDescOrder ? 'asc' : 'desc',
        };
        getSortByItem(data);
      } else if (selectedBox === 'Starred') {
        handleSortByStar(true);
      } else if (selectedBox === 'Bias') {
        getSortByItem({ bias: allClassification });
      }
    });
    return () => {
      pusher.unsubscribe(EnumPusherChannelName.MODEL_STATUS);
    };
  }, [selectedBox]);

  const handleSparkPromoteModal = useCallback(() => {
    const bodyData = { simModelIds: selectedCheckBox };
    dispatch({
      type: sparkPromoteModel.SPARK_PROMOTE_MODEL,
      payload: bodyData,

      cb: res => {
        if (res) {
          if (res.status === 200) {
            setIsOpenApplyModel(true);
          }
        }
      },
    });
  }, [selectedCheckBox]);

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleSortByStar = isStarred => {
    const sortedStar = isStarred || 'true';
    const data = {
      isStarred: sortedStar,
      filter: true,
    };
    dispatch(getRunningModel(data));
  };

  const handleLoadRunClick = () => {
    const data = {
      historicalModelId: selectedModelData.id,
      quantity: selectedModelData.quantity,
      down: selectedModelData.down,
      up: selectedModelData.up,
      stopLoss: selectedModelData.stopLoss,
      availableFunds: selectedModelData.availableFunds,
    };

    if (!selectedModelData.isDataBackTested) {
      dispatch({
        type: simInstanceRunModels.SIM_INSTANCE_RUN_MODEL,
        payload: data,
        cb: res => {
          if (res) {
            if (res.status === 200) {
              toast.success('Sim model start successfully');
              setSelectedCheckBox([]);
              setLoadModelId('');
              setIsModelSelected(true);
              setValue(0);
              dispatch(getRunningModel(data));
            }
          }
        },
      });
    } else {
      dispatch({
        type: models.GET_MODEL_TRADE_DATA,
        payload: {
          id: selectedModelData.id,
          modelType: EnumTypeOfModel.HISTORICAL_MODEL,
        },
        cb: res => {
          if (res) {
            if (res?.status === 200) {
              setSelectedCheckBox([]);
              setLoadModelId(selectedModelData.id);
              setValue(0);
              setIsModelSelected(true);
              if (isEmpty(res?.data?.data)) {
                setOpenDataLoadErrorModal(true);
              }
            }
          }
        },
      });
    }
  };

  const handleLoadRunButtons = useCallback(() => {
    if (!isEmpty(selectedCheckBox)) {
      if (isNoteEdit && (isNoteTextEdited || isChartDrawingChanged)) {
        setOpenAbandonModelFromModels(true);
      } else {
        setOpenAbandonModelFromModels(false);
        handleLoadRunClick();
      }
    } else {
      toast.error('Please select a Model to load.');
    }
  }, [selectedCheckBox]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} margin="0.625rem 0 4rem 0" width="100%">
        <Grid
          container
          xs={12}
          sx={theme => ({
            padding: '0.625rem 0rem 0rem 1rem !important',
            [theme.breakpoints.down('lg')]: {
              display: 'flex',
              gap: '1rem',
              padding: '0rem 1rem 0rem 1rem !important',
            },
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              gap: '1rem',
              padding: '0rem 1rem 0rem 1rem !important',
            },
          })}
        >
          <Grid item sm={12} md={12} lg={6}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              className={classes.commonTextStyle}
              sx={{
                color: '#FFFFFF !important',
                backgroundColor: '#000000 !important',
                border: '0.125rem solid #3FD2F2 !important',
                borderRadius: '0.625rem !important',
                textTransform: 'unset !important',
                padding: '0.375rem 3.5rem !important',
              }}
              onClick={() => {
                setOpenSingleModal(true);
              }}
            >
              Single Model
            </Button>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Button
              startIcon={<Add />}
              variant="outlined"
              type="submit"
              className={classes.commonTextStyle}
              sx={{
                color: '#FFFFFF !important',
                borderRadius: '0.625rem !important',
                backgroundColor: '#000000 !important',
                border: '0.125rem solid #3FD2F2 !important',
                textTransform: 'unset !important',
                padding: '0.375rem 4rem !important',
                '&.Mui-disabled': {
                  color: '#FFF',
                  border: '0.063rem solid #1976d2',
                },
              }}
              onClick={() => {
                setOpenBulkModal(true);
              }}
            >
              Bulk Model
            </Button>
          </Grid>
        </Grid>

        <Box
          item
          xs={12}
          sx={{
            width: '100%',
            padding: '1rem',
          }}
        >
          <Input
            id="standard-adornment-weight"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            placeholder="Search"
            sx={theme => ({
              width: '100%',
              color: '#FFF',
              [theme.breakpoints.up('lg')]: {
                fontSize: '0.875rem',
              },
              [theme.breakpoints.down('lg')]: {
                fontSize: '0.75rem',
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '0.625rem',
              },
            })}
            endAdornment={
              <InputAdornment position="end">
                <Search style={{ color: '#C4C4C4' }} />
              </InputAdornment>
            }
          />
        </Box>

        <Grid
          item
          container
          xs={12}
          sx={theme => ({
            display: 'flex',
            flexWrap: 'nowrap',
            paddingLeft: '0rem !important',
            fontSize: '0.625rem',
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              flexWrap: 'wrap',
            },
          })}
        >
          <Grid
            xs={12}
            md={3.5}
            sm={12}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.5,
              border: '1px solid #2C3E4B',
              cursor: 'pointer',
              background: `${selectedBox === 'A-Z' && '#2C4AF1'}`,
            }}
            onClick={() => {
              if (selectedBox === 'A-Z') {
                setAscDescOrder(!ascDescOrder);
              }
              handleSorting(1, sortByItem);
              setIsSelectBiasOption(false);
              setSelectedBox('A-Z');
            }}
          >
            {ascDescOrder ? (
              <BsSortAlphaDown style={{ color: 'white', fontSize: 15 }} />
            ) : (
              <BsSortAlphaDownAlt style={{ color: 'white', fontSize: 15 }} />
            )}
            <label
              style={{
                color: '#F4F9F8',
                fontSize: '0.75rem',
              }}
            >
              {ascDescOrder ? 'A-Z' : 'Z-A'}
            </label>
          </Grid>

          <Grid
            xs={12}
            md={3.5}
            sm={12}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.4,
              border: '1px solid #2C3E4B',
              cursor: 'pointer',
              background: `${selectedBox === 'Starred' && '#2C4AF1'}`,
            }}
            onClick={() => {
              handleSortByStar(true);
              setIsSelectBiasOption(false);
              setSelectedBox('Starred');
            }}
          >
            <StarIcon style={{ color: 'white', fontSize: 17 }} />
            <label
              style={{
                color: '#F4F9F8',
                fontSize: '0.75rem',
              }}
            >
              Starred
            </label>
          </Grid>

          <Grid
            xs={12}
            md={4}
            sm={12}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.4,
              cursor: 'pointer',
              border: '1px solid #2C3E4B',
              background: `${selectedBox === 'Bias' && '#2C4AF1'}`,
            }}
            onClick={() => {
              handleOpenPopup();
              setIsSelectBiasOption(true);
              setSelectedBox('Bias');
            }}
          >
            <img src={classLeftSideIcon} width={13} height={13} />
            <label
              style={{
                color: '#F4F9F8',
                fontSize: '0.75rem',
              }}
            >
              Bias
            </label>
          </Grid>

          <Grid
            xs={12}
            md={3.5}
            sm={12}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.4,
              border: '1px solid #2C3E4B',
              cursor: 'pointer',
            }}
          >
            <OptimizeBlueIcon />
            <label
              style={{
                color: '#F4F9F8',
                fontSize: '0.75rem',
              }}
            >
              Class
            </label>
          </Grid>
        </Grid>
        <TableContainer
          sx={{
            paddingBottom: '1rem',
            height: 'calc(100vh - 313px)',
            '&::-webkit-scrollbar': {
              width: '0.4rem',
              height: '0.4rem',
              display: 'none',
            },
            '&::-webkit-scrollbar-track': {
              background: 'gray',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#3FD2F2',
              borderRadius: '0.5rem',
            },
          }}
        >
          <TableContainer
            sx={{
              height: '55vh',
              '@media screen and (max-height: 2000px)': {
                height: '75vh',
              },
              '@media screen and (max-height: 1700px)': {
                height: '70vh',
              },
              '@media screen and (max-height: 1400px)': {
                height: '68vh',
              },
              '@media screen and (max-height: 1300px)': {
                height: '65vh',
              },
              '@media screen and (max-height: 1000px)': {
                height: '53vh',
              },
              '&::-webkit-scrollbar': {
                width: '0.438rem',
              },
              '&::-webkit-scrollbar-track': {
                background: 'gray',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#3FD2F2',
                borderRadius: 2,
              },
            }}
          >
            <Box
              item
              xs={12}
              sx={{
                width: '100%',
                padding: '0.6rem 0.3rem 0.6rem 0.3rem',
              }}
            >
              {loader ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : isEmpty(allModel) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: '600',
                    color: 'white',
                    marginTop: '1rem',
                  }}
                >
                  Model is not available
                </Box>
              ) : (
                map(allModel, (data, index) => {
                  return (
                    <div key={index}>
                      <OptimizedCard
                        modelName={get(data, 'name', '')}
                        dataSource={get(data, 'exchange.name', '')}
                        startDate={get(data, 'startTime', '')}
                        endDate={get(data, 'endTime', '')}
                        modelId={get(data, 'id', '')}
                        up={get(data, 'up', '')}
                        down={get(data, 'down', '')}
                        stopLoss={get(data, 'stopLoss', '')}
                        rebuyMode={get(data, 'rebuyMode', '')}
                        setSelectedCheckBox={setSelectedCheckBox}
                        selectedCheckBox={selectedCheckBox}
                        primaryCrypto={get(data, 'pair.primaryCrypto.symbol', '')}
                        currency={get(data, 'pair.currency.name', '')}
                        win={get(data, 'winRate', '')}
                        profitability={get(data, 'profitability', '')}
                        isStarred={get(data, 'isStarred', '')}
                        isPromoted={get(data, 'isPromoted', false)}
                        promotedStatus={get(data, 'promotedStatus', '')}
                        modelType={get(data, 'modelType', '')}
                        simModelStatus={get(data, 'simModelStatus', '')}
                        allModel={allModel}
                        setValue={setValue}
                        bias={get(data, 'bias', '')}
                        sortByItem={sortByItem}
                        ascDescOrder={ascDescOrder}
                      />
                    </div>
                  );
                })
              )}
            </Box>
          </TableContainer>

          <Box width="100%">
            <Grid
              container
              xs={12}
              marginTop="0.125rem"
              sx={theme => ({
                padding: '0.625rem 0rem 0rem 1rem !important',
                [theme.breakpoints.down('lg')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
              })}
            >
              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  disabled={
                    size(selectedCheckBox) > 1 ||
                    isEmpty(selectedCheckBox) ||
                    selectedModelData?.simModelStatus === 'running'
                  }
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey !important',
                      color: 'white !important',
                      border: 'none !important ',
                    },
                    color: '#FFFFFF !important',
                    backgroundColor: '#000000 !important',
                    border: '0.125rem solid #3FD2F2 !important ',
                    borderRadius: '0.3rem !important',
                    width: '95% !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={<img src={loadButton} width={15} height={15} />}
                  onClick={() => handleLoadRunButtons()}
                >
                  <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                    {(isRunModelLoading || isGetModelTradeDataLoading) && <CircularProgress size={17} />}
                    {selectedModelData ? (selectedModelData?.isDataBackTested ? 'Load' : 'Run') : 'Run/Load'}
                  </Box>
                </Button>
              </Grid>

              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  disabled={isEmpty(selectedCheckBox)}
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey !important',
                      color: 'white !important',
                      border: 'none !important ',
                    },
                    color: '#FFFFFF !important',
                    backgroundColor: '#000000 !important',
                    border: '0.125rem solid #FF1730 !important',
                    borderRadius: '0.3rem !important',
                    width: '95% !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={<img src={deleteButton} width={15} height={15} alt="" />}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              marginTop="0.125rem"
              sx={theme => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.625rem 0rem 0rem 1rem !important',
                [theme.breakpoints.down('xl')]: {
                  padding: '0rem 0rem 0rem 1rem !important',
                },
                [theme.breakpoints.down('lg')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
              })}
            >
              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  disabled={size(selectedCheckBox) > 1 || isEmpty(selectedCheckBox)}
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey !important',
                      color: 'white !important',
                      border: 'none !important ',
                    },
                    color: '#FFFFFF !important',
                    backgroundColor: '#000000 !important',
                    border: '0.125rem solid #3FD2F2 !important',
                    borderRadius: '0.3rem !important',
                    width: '95% !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={<img src={duplicateButton} width={15} height={15} />}
                  onClick={() => setOpenDuplicateModal(true)}
                >
                  Duplicate
                </Button>
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  // NOTE :::::::: Export button is kept disabled because, its functionality is not implemented yet :::::::::
                  disabled={true || isEmpty(selectedCheckBox)}
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey !important',
                      color: 'white !important',
                      border: 'none !important ',
                    },
                    color: '#FFFFFF !important',
                    backgroundColor: '#000000 !important',
                    border: '0.125rem solid #3FD2F2 !important',
                    borderRadius: '0.3rem !important',
                    width: '95% !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={<img src={exportButton} width={15} height={15} />}
                >
                  Export
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              marginTop="0.125rem"
              sx={theme => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.625rem 0rem 0rem 1rem !important',
                [theme.breakpoints.down('xl')]: {
                  padding: '0rem 0rem 0rem 1rem !important',
                },
                [theme.breakpoints.down('lg')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '0rem 1rem 0rem 1.875rem !important',
                },
              })}
            >
              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  disabled={every(selectedModelsData, {
                    isPromoted: true,
                    promotedStatus: 'promoted',
                  })}
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed  !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey  !important',
                      color: 'white  !important',
                      border: 'none !important ',
                    },
                    color: '#FFFFFF  !important',
                    backgroundColor: '#000000  !important',
                    border: '0.125rem solid #F1A305  !important',
                    borderRadius: '0.3rem !important',
                    width: '95%  !important',
                    textTransform: 'unset !important',
                  }}
                  onClick={() => {
                    if (!isEmpty(selectedCheckBox)) {
                      setOpenSparkPromoteModal(true);
                    } else {
                      toast.error('Please select a Model.');
                    }
                  }}
                >
                  <SparkIcon /> SPARK Promote
                </Button>
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  disabled={
                    size(selectedCheckBox) > 1 ||
                    isEmpty(selectedCheckBox) ||
                    selectedModelData?.simModelStatus === 'running'
                  }
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed  !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey  !important',
                      color: 'white  !important',
                      border: 'none !important',
                    },
                    color: '#FFFFFF  !important',
                    backgroundColor: '#000000  !important',
                    border: '0.125rem solid #F1A305  !important',
                    borderRadius: '0.3rem !important',
                    width: '95%  !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={<img src={applyModelButton} width={15} height={15} />}
                  onClick={() => {
                    setSelectedApplyModelData(selectedModelData);

                    if (selectedModelData?.isDataBackTested) {
                      if (
                        selectedModelData?.promotedStatus === 'previousVersion' ||
                        selectedModelData?.promotedStatus === 'notPromoted'
                      ) {
                        handleSparkPromoteModal();
                      } else {
                        setIsOpenApplyModel(true);
                      }
                    } else {
                      setOpenDangerModal(true);
                    }
                  }}
                >
                  Apply Model
                </Button>
              </Grid>
            </Grid>
          </Box>
        </TableContainer>
      </Grid>

      {openPopup && (
        <Box
          ref={biasRef}
          sx={{
            width: '7rem',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'right',
            flexDirection: 'column',
            background: '#000000',
            border: '0.125rem solid #010D0F',
            zIndex: '1',
            color: '#FFFFFF',
            left: '16.5rem !important',
            top: '15.5rem !important',
            gap: '0.5rem',
          }}
        >
          {map(classification, (item, key) => {
            return (
              <>
                <MenuItem sx={{ padding: '0' }} key={key}>
                  <ListItemIcon>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon htmlColor="#175982" />}
                      value={allClassification}
                      checked={includes(allClassification, item?.value)}
                      onChange={e => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          // add to list
                          setAllClassification(uniq([...allClassification, item?.value]));
                        } else {
                          // remove from list
                          setAllClassification(uniq(allClassification.filter(data => data !== item?.value)));
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ textAlign: 'left' }}>
                    <label style={{ fontSize: '0.75rem' }}>{upperCase(item?.label)}</label>
                  </ListItemText>
                </MenuItem>
              </>
            );
          })}
        </Box>
      )}

      {(openSingleModal || openEditModal) && (
        <SingleModel
          openSingleModal={openSingleModal}
          setOpenSingleModal={setOpenSingleModal}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          duplicateModalName={duplicateModalName}
          setSelectedCheckBox={setSelectedCheckBox}
          setSelectedBox={setSelectedBox}
          setValue={setValue}
          ascDescOrder={ascDescOrder}
          selectedBox={selectedBox}
          allClassification={allClassification}
        />
      )}

      {openDuplicateModal && (
        <DuplicateModal
          setOpenEditModal={setOpenEditModal}
          selectedCheckBox={selectedCheckBox}
          allModel={allModel}
          openDuplicateModal={openDuplicateModal}
          setOpenDuplicateModal={setOpenDuplicateModal}
          setDuplicateModalName={setDuplicateModalName}
        />
      )}

      {openBulkModal && (
        <BulkModal
          openBulkModal={openBulkModal}
          setOpenBulkModal={setOpenBulkModal}
          allModel={allModel}
          setValue={setValue}
        />
      )}

      {(openDangerModal || openDeleteModal || openSparkPromoteModal || openAbandonModelFromModels) && (
        <SparkModal
          selectedCheckBox={selectedCheckBox}
          selectedModelsData={selectedModelsData}
          setSelectedCheckBox={setSelectedCheckBox}
          openDangerModal={openDangerModal}
          setOpenDangerModal={setOpenDangerModal}
          setIsOpenApplyModel={setIsOpenApplyModel}
          openDeleteModal={openDeleteModal}
          openAbandonModelFromModels={openAbandonModelFromModels}
          setOpenAbandonModelFromModels={setOpenAbandonModelFromModels}
          setOpenDeleteModal={setOpenDeleteModal}
          openSparkPromoteModal={openSparkPromoteModal}
          setOpenSparkPromoteModal={setOpenSparkPromoteModal}
          openStopModal={openStopModal}
          setOpenStopModal={setOpenStopModal}
          onClickAbandon={handleLoadRunClick}
          selectedModelPromotedStatus={get(selectedModelData, 'promotedStatus', '')}
          ascDescOrder={ascDescOrder}
          selectedBox={selectedBox}
        />
      )}
    </Box>
  );
};

export default Models;
