import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography, Switch, Stack, styled, Tabs, Tab, TextField, Button } from '@mui/material';
import { LibraryAdd, Notifications } from '@material-ui/icons';
import { useStyles } from 'components/common/utils';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { enUS } from '@mui/material/locale';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from '@mui/material/Modal';
import { cookie } from 'helpers/cookies';
import { TOKEN_NAME } from 'constants/token';
import { fetchAlertGroup } from 'actions/lunaAction';
import { alerts } from 'actions/ActionTypes';
import { toast } from 'react-toastify';

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
const localeSettings = {
  locale: {
    short: 'en',
    full: 'en-IN',
    material: enUS,
    mui: enUS,
  },
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger',
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger',
  },
};

const config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    ...localeSettings,

    valueSourcesInfo: {
      value: {
        label: 'Value',
      },
      field: {
        label: 'Field',
        widget: 'field',
      },
      func: {
        label: 'Function',
        widget: 'func',
      },
    },
    // canReorder: true,
    // canRegroup: true,
    // showNot: true,
    // showLabels: true,
    maxNesting: 3,
    canLeaveEmptyGroup: true, //after deletion
  },
  fields: {
    Price: {
      label: 'Price',
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
    Volume: {
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
    MarketCap: {
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 43,
  height: 26,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px 6px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#ba02ba',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    borderRadius: 13,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fad41e',
    boxSizing: 'border-box',
  },
}));

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const emptyInitValue = { id: QbUtils.uuid(), type: 'group' };

// const queryValue = loadedInitValue && Object.keys(loadedInitValue).length > 0 ? loadedInitValue : emptyInitValue;

const AlertBoxQueryBuilder = props => {
  const { alertDetail } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openAlertDeleteModal, setOpenAlertDeleteModal] = useState(false);
  const accessToken = cookie.get(TOKEN_NAME.ACCESS_TOKEN);
  const ownerId = JSON.parse(window.atob(accessToken.split('.')[1])).sub;
  // const [description, setDescription] = useState('');
  // const [note, setNote] = useState('');
  const classes = useStyles();

  // const [state, setState] = useState({
  //     tree: QbUtils.checkTree(QbUtils.loadFromJsonLogic(JSON.parse(val), config), config),
  //     config,
  // });

  // const onChange = (immutableTree, config) => {
  //     setState({tree: immutableTree, config: config});
  //
  //     console.log('immutableTree',immutableTree)
  //
  //     const jsonTree = QbUtils.getTree(immutableTree);
  //     // console.log('jsonTree', jsonTree)
  //     // console.log('json====>>', JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config), null, 2))
  //     // console.log('logic===', JSON.stringify(QbUtils.getTree(immutableTree, config), undefined, 2))
  //     // console.log('queryString', JSON.stringify(QbUtils.queryString(immutableTree, config)));
  //     // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  // };

  const renderBuilder = propsData => {
    return (
      <Box sx={{ margin: '1rem 0' }}>
        <Builder {...propsData} />
      </Box>
    );
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getAlertGroup = useCallback(() => {
    dispatch(fetchAlertGroup());
  }, []);

  const removeAlert = id => {
    dispatch({
      type: alerts.DELETE_ALERT,
      payload: id,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            toast.success('Alert deleted successfully');
            setOpenAlertDeleteModal(false);
            getAlertGroup();
          }
        }
      },
    });
  };

  return (
    <>
      <Grid
        sx={{
          width: '100%',
          border: '2px solid #3FD2F2',
          padding: '1rem',
          flexGrow: 1,
          marginBottom: '1rem',
        }}
      >
        <Grid container spacing={{ md: 2, lg: 3 }} sx={{ padding: '1rem 0', display: 'flex', alignItems: 'center' }}>
          <Grid item xs={2}>
            <Box>
              <Typography
                component="div"
                variant="h6"
                sx={theme => ({
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '2px solid #fff',
                  color: '#fff',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '.9rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.7rem',
                  },
                })}
              >
                {alertDetail.name}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                component="div"
                sx={theme => ({
                  color: '#fff',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '.8rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.6rem',
                  },
                })}
              >
                Alert
              </Typography>
              <AntSwitch checked={!alertDetail.isAlertOrWarning} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography
                component="div"
                sx={theme => ({
                  color: '#fff',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '.8rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.6rem',
                  },
                })}
              >
                Warning
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box>
              <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={theme => ({
                    border: '1px solid #3FD2F2',
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    cursor: 'pointer',
                    [theme.breakpoints.down('lg')]: {
                      fontSize: '.8rem',
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.6rem',
                    },
                  })}
                >
                  8 Events Triggered
                </Box>
                <LibraryAdd style={{ cursor: 'pointer', color: '#3FD2F2' }} />
                <Notifications style={{ cursor: 'pointer', color: '#3FD2F2' }} />
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 1, lg: 1 }}>
          <Grid
            item
            lg={`${ownerId === alertDetail.user.id ? '8.5' : '9'}`}
            md={12}
            sx={{
              background: 'white',
              borderRadius: '5px',
              width: '100%',
              maxWidth: 'unset !important',
            }}
          >
            <Query
              {...config}
              value={QbUtils.checkTree(QbUtils.loadFromJsonLogic(JSON.parse(alertDetail.logic), config), config)}
              // onChange={onChange}
              renderBuilder={renderBuilder}
            />
          </Grid>
          <Grid item lg={3} md={11}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                border: '1px solid #3FD2F2',
              }}
            >
              <Tabs
                aria-label="Tabs where each tab needs to be selected manually"
                onChange={handleChangeTab}
                value={value}
                variant="fullWidth"
                sx={theme => ({
                  justifyContent: 'space-around',
                })}
                indicatorColor={'#3FD2F2'}
                textColor={value === 0 ? '#FFD41F' : '#B1AFCD'}
              >
                <Tab
                  label="Description"
                  className={classes.commonTextStyle}
                  sx={theme => ({
                    borderRight: '1px solid #3FD2F2',
                    borderBottom: '1px solid #3FD2F2',
                    color: value === 0 ? '#FFD41F' : '#B1AFCD',
                    [theme.breakpoints.down('md')]: {
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    },
                  })}
                />
                <Tab
                  label="Notes"
                  className={classes.commonTextStyle}
                  sx={theme => ({
                    color: value === 1 ? '#FFD41F' : '#B1AFCD',
                    borderBottom: '1px solid #3FD2F2',
                    [theme.breakpoints.down('md')]: {
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    },
                  })}
                />
              </Tabs>
              {value === 0 && (
                <Box>
                  <TextField
                    id="description"
                    name="description"
                    size="small"
                    multiline
                    rows={6}
                    value={alertDetail.description ?? ''}
                    fullWidth
                    sx={theme => ({
                      input: {
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '14px',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '12px',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '11px',
                        },
                      },
                    })}
                    inputProps={{ sx: { color: '#fff' } }}
                  />
                </Box>
              )}
              {value === 1 && (
                <Box>
                  <TextField
                    id="day"
                    name="notes"
                    size="small"
                    multiline
                    rows={6}
                    value={alertDetail.notes ?? ''}
                    fullWidth
                    sx={theme => ({
                      input: {
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '14px',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '12px',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '11px',
                        },
                      },
                    })}
                    inputProps={{ sx: { color: '#fff' } }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          {ownerId === alertDetail.user.id && (
            <Grid item lg={0.5} md={1} sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Button onClick={() => setOpenAlertDeleteModal(true)}>
                <RiDeleteBin6Line size={25} color={'#fff'} />
              </Button>
            </Grid>
          )}
        </Grid>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography
            component={'div'}
            sx={theme => ({
              color: '#fff',
              [theme.breakpoints.down('lg')]: {
                fontSize: '.9rem',
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '.7rem',
              },
            })}
          >
            Owner: {alertDetail.user.name}
          </Typography>
        </Box>
      </Grid>
      <Box>
        <Modal
          open={openAlertDeleteModal}
          sx={{
            background: '#131E68',
            width: '470px',
            height: 'max-content',
            margin: 'auto',
          }}
        >
          <Box sx={{ padding: '1.5rem' }}>
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 500,
                color: '#fff',
                padding: '1rem 0',
              }}
            >
              Are you sure delete this Alert?
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'row',
                mt: '9%',
                mr: '5%',
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  textTransform: 'unset !important',
                  cursor: 'pointer',
                  marginRight: '1rem',
                  color: '#FFFFFF',
                  padding: '0.5rem 1.5rem',
                  backgroundColor: '#0072F8',
                  border: '1px solid #3FD2F2',
                  fontSize: '0.9rem',
                  borderRadius: '0.5rem',
                }}
                onClick={() => removeAlert(alertDetail.id)}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'unset !important',
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  padding: '0.5rem 1.5rem',
                  backgroundColor: '#0072F8',
                  border: '1px solid #3FD2F2',
                  fontSize: '0.9rem',
                  borderRadius: '0.5rem',
                }}
                onClick={() => setOpenAlertDeleteModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default AlertBoxQueryBuilder;
