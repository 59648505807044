import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export const HistoricalModelContext = createContext(null);

export const useHistoricalModelContextData = () => {
  return useContext(HistoricalModelContext);
};

const HistoricalModelContextProvider = props => {
  const { children } = props;
  const [selectedModelId, setSelectedModelId] = useState('');

  const handleSelectModelId = useCallback(id => {
    setSelectedModelId(id);
  }, []);

  const HistoricalModelContextProviderValue = useMemo(
    () => ({
      handleSelectModelId,
      selectedModelId,
    }),
    [selectedModelId],
  );

  return (
    <HistoricalModelContext.Provider value={HistoricalModelContextProviderValue}>
      {children}
    </HistoricalModelContext.Provider>
  );
};

export default HistoricalModelContextProvider;
