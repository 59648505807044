import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import 'quill/dist/quill.snow.css';
import styles from './styles/Sim.module.scss';
import { useStyles } from 'components/common/utils';
import { initOnReady } from 'components/chart/main';
import { getModelTradeData } from 'actions/simAction';
import { sparkModels } from 'actions/ActionTypes';
import { EnumTypeOfModel } from 'constants/model-enums';
import BottomTab from 'components/sim/BottomTab';
import DataLoadErrorModal from 'components/common/DataLoadErrorModal';
import Performance from 'components/sim/Performance';
import SingleModel from 'components/sim/SingleModel';
import SparkModal from 'components/sim/SparkModal';
import { fetchExchanges, getFrdCryptoList } from 'actions/classificationAction';

const Sim = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const modelTradeData = useSelector(state => state.sim.modelTradeData);
  const exchangesData = useSelector(state => state.classification.exchanges);
  const cryptoList = useSelector(state => state.classification.cryptoList);
  const selectedModelData = useSelector(state => state.sim.currentSelectedModel);

  const [open, setOpen] = useState(true);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [openSparkPromoteModal, setOpenSparkPromoteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSingleModal, setOpenSingleModal] = useState(false);
  // NOTE :::::::: Currently not using Bulk Model here, but maybe needed in future
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openDangerModal, setOpenDangerModal] = useState(false);
  const [deleteNoteModal, setDeleteNoteModal] = useState(false);
  const [abandonChangesModal, setAbandonChangesModal] = useState(false);
  const [isTextEdited, setIsTextEdited] = useState(false);
  const [noteEditMode, setNoteEditMode] = useState(false);
  const [openDataLoadErrorModal, setOpenDataLoadErrorModal] = useState(false);
  const [isModelSelected, setIsModelSelected] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedModelDataForChart, setSelectedModelDataForChart] = useState();
  const [showDataLoadErrorModel, setShowDataLoadErrorModel] = useState(
    JSON.parse(localStorage.getItem('showDataLoadErrorModel') || 'false'),
  );

  const getSparkModels = useCallback(() => {
    dispatch({
      type: sparkModels.GET_SPARK_MODELS,
    });
  }, []);

  const getExchange = useCallback(() => {
    dispatch(fetchExchanges());
  }, []);

  const getListOfFrdCrypto = useCallback(() => {
    dispatch(getFrdCryptoList());
  }, []);

  useEffect(() => {
    if (isEmpty(cryptoList)) {
      getListOfFrdCrypto();
    }
  }, [cryptoList]);

  useEffect(() => {
    if (isEmpty(exchangesData)) {
      getExchange();
    }
  }, [exchangesData]);

  useEffect(() => {
    getSparkModels();
  }, []);

  useEffect(() => {
    initOnReady(selectedModelDataForChart, dispatch);
    localStorage.setItem('analyze_historical_model', JSON.stringify(modelTradeData?.simTableData));
  }, [selectedModelDataForChart, modelTradeData]);

  useEffect(() => {
    if (!isEmpty(selectedModelDataForChart)) {
      localStorage.setItem('symbol', selectedModelDataForChart?.pair?.primaryCrypto?.symbol);
      localStorage.setItem('dateFrom', selectedModelDataForChart?.startTime);
      localStorage.setItem('dateTo', selectedModelDataForChart?.endTime);
      localStorage.setItem('modelType', selectedModelDataForChart?.modelType);
      localStorage.setItem('modelStatus', '');
    }
  }, [selectedModelDataForChart]);

  /**
   * For: Show the Data load error model when the chart data is not available.
   */
  useEffect(() => {
    // Function to handle changes in localStorage
    const handleStorageChange = e => {
      if (e.key === 'showDataLoadErrorModel') {
        setShowDataLoadErrorModel(JSON.parse(e.newValue || 'false'));
      }
    };

    // Add a 'storage' event listener to listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Add a custom event listener to listen for the custom event
    const handleCustomEvent = () => {
      // Update the state when the custom event is triggered
      setShowDataLoadErrorModel(true);
    };

    const handleCustomEventForClosed = () => {
      // Update the state when the custom event is triggered
      setShowDataLoadErrorModel(false);
    };

    window.addEventListener('showDataLoadErrorModelChanged', handleCustomEvent);
    window.addEventListener('showDataLoadErrorModelChangedClosed', handleCustomEventForClosed);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('showDataLoadErrorModelChanged', handleCustomEvent);
      window.removeEventListener('showDataLoadErrorModelChangedClosed', handleCustomEventForClosed);
    };
  }, []);

  useEffect(() => {
    if (showDataLoadErrorModel) {
      setOpenDataLoadErrorModal(true);
    }
  }, [showDataLoadErrorModel]);

  useEffect(() => {
    if (!isEmpty(selectedModelDataForChart)) {
      if (selectedModelDataForChart?.modelType === 'historicalModel') {
        dispatch(
          getModelTradeData({
            id: selectedModelDataForChart?.id,
            modelType: EnumTypeOfModel.HISTORICAL_MODEL,
          }),
        );
      }
    }
  }, [selectedModelDataForChart]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.main}>
        {' '}
        <div className={styles.content}>
          <div id="tv_chart_container" className={styles.chart} />
          <div className={[styles.note, open ? styles.openTabCss : styles.closedTabCss]}>
            <BottomTab
              classes={classes}
              deleteNoteModal={deleteNoteModal}
              setDeleteNoteModal={setDeleteNoteModal}
              abandonChangesModal={abandonChangesModal}
              setAbandonChangesModal={setAbandonChangesModal}
              setIsTextEdited={setIsTextEdited}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              noteEditMode={noteEditMode}
              setNoteEditMode={setNoteEditMode}
              isModelSelected={isModelSelected}
              setIsModelSelected={setIsModelSelected}
              selectedModelData={selectedModelData}
              modelTradeData={modelTradeData}
            />
          </div>
        </div>
        <div className={[styles.sidebar, open ? styles.openSideTab : styles.closeSideTab]}>
          <Performance
            classes={classes}
            open={open}
            setOpen={setOpen}
            handleDrawerOpen={handleDrawerOpen}
            setSelectedModelDataForChart={setSelectedModelDataForChart}
            setOpenStopModal={setOpenStopModal}
            setOpenSingleModal={setOpenSingleModal}
            setOpenBulkModal={setOpenBulkModal}
            setOpenDuplicateModal={setOpenDuplicateModal}
            setOpenSparkPromoteModal={setOpenSparkPromoteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenDangerModal={setOpenDangerModal}
            setAbandonChangesModal={setAbandonChangesModal}
            isTextEdited={isTextEdited}
            openDataLoadErrorModal={openDataLoadErrorModal}
            setOpenDataLoadErrorModal={setOpenDataLoadErrorModal}
            isModelSelected={isModelSelected}
            setIsModelSelected={setIsModelSelected}
          />
        </div>
      </div>
      {openSingleModal && <SingleModel openSingleModal={openSingleModal} setOpenSingleModal={setOpenSingleModal} />}
      {/*{openBulkModal && <BulkModal openBulkModal={openBulkModal} setOpenBulkModal={setOpenBulkModal} />}*/}
      {/* {openDuplicateModal && (
        <DuplicateModal openDuplicateModal={openDuplicateModal} setOpenDuplicateModal={setOpenDuplicateModal} />
      )} */}
      {(openDangerModal ||
        openDeleteModal ||
        openSparkPromoteModal ||
        openStopModal ||
        deleteNoteModal ||
        (abandonChangesModal && !noteEditMode)) && (
        <SparkModal
          openDangerModal={openDangerModal}
          setOpenDangerModal={setOpenDangerModal}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          openSparkPromoteModal={openSparkPromoteModal}
          setOpenSparkPromoteModal={setOpenSparkPromoteModal}
          openStopModal={openStopModal}
          setOpenStopModal={setOpenStopModal}
          abandonChangesModal={abandonChangesModal}
          setAbandonChangesModal={setAbandonChangesModal}
          setIsTextEdited={setIsTextEdited}
          setSelectedId={setSelectedId}
          noteEditMode={noteEditMode}
          setNoteEditMode={setNoteEditMode}
          setOpenDataLoadErrorModal={setOpenDataLoadErrorModal}
        />
      )}
      {openDataLoadErrorModal && (
        <DataLoadErrorModal
          openDataLoadErrorModal={openDataLoadErrorModal}
          setOpenDataLoadErrorModal={setOpenDataLoadErrorModal}
        />
      )}
    </>
  );
};

export default Sim;
