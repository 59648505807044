import {
  makeApiRequest,
  generateSymbol,
  parseFullSymbol,
  getFrdDataForChart,
  // getHistoricalModelData
} from './helpers.js';
import React from 'react';
import { isEmpty } from 'lodash';
import stream from './stream.js';
const lastBarsCache = new Map();
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

const configurationData = {
  supported_resolutions: ['1D', '1W', '1M', '1', '15', '30', '60', '240'],
  supports_marks: true,
  exchanges: [
    {
      value: 'Bitfinex',
      name: 'Bitfinex',
      desc: 'Bitfinex',
    },
    {
      value: 'Coinbase',
      name: 'Coinbase',
      desc: 'Coinbase',
    },
    {
      value: 'Binance',
      name: 'Binance',
      desc: 'Binance',
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: 'Kraken',

      // filter name
      name: 'Kraken',

      // full exchange name displayed in the filter popup
      desc: 'Kraken bitcoin exchange',
    },
  ],
  symbols_types: [
    {
      name: 'crypto',

      // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
      value: 'crypto',
    },
    // ...
  ],
};

// const configurationData = {
// 	supported_resolutions: ['1D', '1W', '1M'],
// 	exchanges: [{
// 		value: "Bitcoin",
// 		name: "Bitcoin",
// 	},
// 		{
// 			// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
// 			value: "Etherium",
//
// 			// filter name
// 			name: "Etherium",
//
// 		},
// 	],
// 	symbols_types: [{
// 		name: 'crypto',
//
// 		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
// 		value: 'crypto',
// 	},
// 		// ...
// 	],
// };

async function getAllSymbols() {
  const data = await makeApiRequest('data/v3/all/exchanges');
  let allSymbols = [];

  for (const exchange of configurationData.exchanges) {
    const pairs = data.Data[exchange.value].pairs;

    for (const leftPairPart of Object.keys(pairs)) {
      const symbols = pairs[leftPairPart].map(rightPairPart => {
        const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
        return {
          symbol: symbol.short,
          full_name: symbol.full,
          description: symbol.short,
          exchange: exchange.value,
          type: 'crypto',
        };
      });
      allSymbols = [...allSymbols, ...symbols];
    }
  }
  return allSymbols;
}

// async function getAllSymbols() {
// 	// const data = await makeApiRequest('data/v3/all/exchanges');
// 	const data = await makeApiRequest();
// 	let allSymbols = [];
//
// 	console.log('data1====>',data.data.data)
//
// 	for (const exchange of configurationData.exchanges) {
// 		// const pairs = data.data[exchange.value].pairs;
// 		// console.log('pairs',pairs)
// 		// for (const leftPairPart of Object.keys(pairs)) {
// 			const symbols = data.data.data.map(item => {
// 				const symbol = generateSymbol(exchange.value, item.primaryCrypto.symbol, item.currency.name);
// 				console.log('symbol',symbol)
// 				return {
// 					symbol: symbol.short,
// 					full_name: symbol.full,
// 					description: symbol.short,
// 					exchange: exchange.value,
// 					type: 'crypto',
// 				};
// 			});
// 			allSymbols = [...allSymbols, ...symbols];
// 		// }
// 	}
// 	return allSymbols;
// }

// fetchHistoricalDataRecursively: May be need in future
const fetchHistoricalDataRecursively = async (
  symbolInfo,
  resolution,
  from,
  to,
  isSparkModel,
  startDate,
  endDate,
  historicalPrices,
  onDataFetched,
) => {
  try {
    const parsedSymbol = symbolInfo.exchange !== 'FRD TOP50cc' && parseFullSymbol(symbolInfo.full_name);
    const urlParameters = {
      e: parsedSymbol.exchange,
      fsym: parsedSymbol.fromSymbol,
      tsym: parsedSymbol.toSymbol,
      toTs: isSparkModel ? to : endDate,
      limit: 2000,
    };
    const query = Object.keys(urlParameters)
      .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join('&');
    const url =
      resolution === ('1D' || '1w') ? 'data/histoday' : resolution >= 60 ? 'data/histohour' : 'data/histominute';
    const data = await makeApiRequest(`${url}?${query}`);

    if (!isSparkModel) {
      const newHistoricalPrices = data.Data.filter(item => item.time >= startDate && item.time <= endDate);
      historicalPrices.unshift(...newHistoricalPrices);

      // Check if the fetched data size is the same as the limit
      if (_.size(data.Data) === _.size(newHistoricalPrices)) {
        // Fetch the next batch of data
        const updatedDate = _.head(newHistoricalPrices).time;
        await fetchHistoricalDataRecursively(
          symbolInfo,
          resolution,
          from,
          to,
          isSparkModel,
          startDate,
          updatedDate,
          historicalPrices,
          onDataFetched,
        );
      } else {
        onDataFetched(historicalPrices);
      }
    } else {
      onDataFetched(data.Data);
    }
  } catch (error) {
    // Handle errors
    console.log('error=>', error);
  }
};
let previousResolution = '';

export default {
  onReady: callback => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log('[searchSymbols]: Method call');
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter(symbol => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    // console.log('[resolveSymbol]: Method call', symbolName);
    const symbols = await getAllSymbols();
    let symbolItem;
    const symbol = localStorage.getItem('symbol');
    const dateFrom = localStorage.getItem('dateFrom');
    const dateTo = localStorage.getItem('dateTo');

    // Get the current date
    const currentDate = moment();

    // Calculate the date 7 days ago from the current date
    const sevenDaysAgo = moment().subtract(7, 'days');

    // Define the date you want to check (replace with your desired date)
    const yourDate = moment(dateFrom, 'YYYY-MM-DD');

    if (
      _.head(_.split(symbolName, ':')) === 'FRD TOP50cc' ||
      (_.head(_.split(symbolName, ':')) === 'Coinbase' && !yourDate.isBetween(sevenDaysAgo, currentDate, null, '[]')) ||
      (_.head(_.split(symbolName, ':')) === 'Binance' && !yourDate.isBetween(sevenDaysAgo, currentDate, null, '[]'))
    ) {
      symbolItem = {
        symbol: `${symbol}/USD`,
        full_name: `FRD TOP50cc:${symbol}/USD`,
        description: `${symbol}/USD`,
        exchange: 'FRD TOP50cc',
        type: 'crypto',
      };
    } else {
      symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
    }

    if (!symbolItem) {
      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }

    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '24x7',
      timezone: 'US/Mountain',
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: 'streaming',
    };

    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    const dateFrom = localStorage.getItem('dateFrom');
    const dateTo = localStorage.getItem('dateTo');
    const modelType = localStorage.getItem('modelType');
    const modelStatus = localStorage.getItem('modelStatus');
    const startTime = new Date(dateFrom).getTime() / 1000;
    const endTime = moment?.tz(dateTo?.replace('Z', ''), 'America/Denver')?.unix();

    const isSparkModel = modelType === 'sparkModel';
    const isModelRunning = modelStatus ? modelStatus === 'running' : false;

    if (
      from < startTime &&
      moment.unix(startTime).format('MM/DD/YYYY') !== moment.unix(from).format('MM/DD/YYYY') &&
      resolution === previousResolution
    ) {
      // If 'from' is before the start date, return an empty response
      onHistoryCallback([], { noData: true });
      return;
    }
    previousResolution = resolution;

    try {
      // const data = await getHistoricalModelData();
      //May be need in future
      // const data =
      //   symbolInfo.exchange === 'FRD TOP50cc'
      //     ? await getFrdDataForChart(_.head(_.split(symbolInfo.name, '/')), dateFrom, dateTo)
      //     : await makeApiRequest(`${url}?${query}`);
      //

      let historicalPrices = [];
      if (symbolInfo.exchange === 'FRD TOP50cc') {
        historicalPrices = await getFrdDataForChart(_.head(_.split(symbolInfo.name, '/')), dateFrom, dateTo);
      } else {
        await fetchHistoricalDataRecursively(
          symbolInfo,
          resolution,
          from,
          to,
          isSparkModel,
          startTime,
          endTime,
          historicalPrices,
          async finalHistoricalPrices => {
            historicalPrices = _.uniq(finalHistoricalPrices);
          },
        );
      }

      let bars = [];
      _.forEach(symbolInfo.exchange === 'FRD TOP50cc' ? historicalPrices.data.data : historicalPrices, (bar, index) => {
        const time = symbolInfo.exchange === 'FRD TOP50cc' ? moment(bar.openTime).unix() : bar.time;
        const startDate =
          symbolInfo.exchange === 'FRD TOP50cc' ? moment(dateFrom).unix() : isSparkModel ? from : startTime;
        const endDate = symbolInfo.exchange === 'FRD TOP50cc' ? moment(dateTo).unix() : isSparkModel ? to : endTime;

        if (time >= startDate && time < endDate) {
          bars = [
            ...bars,
            {
              time: symbolInfo.exchange === 'FRD TOP50cc' ? time * 1000 : bar.time * 1000,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            },
          ];
        }
      });

      if (symbolInfo.exchange !== 'FRD TOP50cc') {
        if (_.size(historicalPrices) === 0) {
          localStorage.setItem('showDataLoadErrorModel', JSON.stringify(true));

          // Dispatch a custom event to notify the React component
          const event = new Event('showDataLoadErrorModelChanged');
          window.dispatchEvent(event);

          toast.error('Minute data for BTC-USDT is only available for the last 7 days.');
          localStorage.setItem('isChartDataNotAvailable', JSON.stringify(true));
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
      } else {
        if (_.size(historicalPrices.data.data) === 0) {
          localStorage.setItem('showDataLoadErrorModel', JSON.stringify(true));

          // Dispatch a custom event to notify the React component
          const event = new Event('showDataLoadErrorModelChanged');
          window.dispatchEvent(event);
          toast.error('Minute data for BTC-USDT is only available for the last 7 days.');
          localStorage.setItem('isChartDataNotAvailable', JSON.stringify(true));
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
      }

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }

      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      // console.log('[getBars]: Get error', error);
      onErrorCallback(error);
    }
  },

  // getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
  //   const { from, to, firstDataRequest } = periodParams;
  //   const dateFrom = localStorage.getItem('dateFrom');
  //   const dateTo = localStorage.getItem('dateTo');
  //   // const startDate = new Date('2023-08-16').getTime() / 1000;
  //   // const endDate = new Date('2023-08-19').getTime() / 1000;
  //
  //   const parsedSymbol = symbolInfo.exchange !== 'FRD TOP50cc' && parseFullSymbol(symbolInfo.full_name);
  //   const urlParameters = {
  //     e: parsedSymbol.exchange,
  //     fsym: parsedSymbol.fromSymbol,
  //     tsym: parsedSymbol.toSymbol,
  //     // fromTs: startDate,
  //     toTs: to,
  //     limit: 2000,
  //   };
  //   const items = JSON.parse(localStorage.getItem('modelId'));
  //   const query = Object.keys(urlParameters)
  //     .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
  //     .join('&');
  //   const url =
  //     resolution === ('1D' || '1w') ? 'data/histoday' : resolution >= 60 ? 'data/histohour' : 'data/histominute';
  //
  //   try {
  //     // const data = await getHistoricalModelData();
  //     const data =
  //       symbolInfo.exchange === 'FRD TOP50cc'
  //         ? await getFrdDataForChart(_.head(_.split(symbolInfo.name, '/')))
  //         : await makeApiRequest(`${url}?${query}`);
  //
  //     let bars = [];
  //     _.forEach(symbolInfo.exchange === 'FRD TOP50cc' ? data.data.data : data.Data, bar => {
  //       const time = symbolInfo.exchange === 'FRD TOP50cc' ? moment(bar.openTime).unix() : bar.time;
  //       const startDate = symbolInfo.exchange === 'FRD TOP50cc' ? moment(dateFrom).unix() : from;
  //       const endDate = symbolInfo.exchange === 'FRD TOP50cc' ? moment(dateTo).unix() : to;
  //       if (time >= startDate && time < endDate) {
  //         bars = [
  //           ...bars,
  //           {
  //             time: symbolInfo.exchange === 'FRD TOP50cc' ? time * 1000 : bar.time * 1000,
  //             low: bar.low,
  //             high: bar.high,
  //             open: bar.open,
  //             close: bar.close,
  //           },
  //         ];
  //       }
  //     });
  //
  //     if (symbolInfo.exchange !== 'FRD TOP50cc') {
  //       if ((data.Response && data.Response === 'Error') || data.Data.length === 0) {
  //         onHistoryCallback([], {
  //           noData: true,
  //         });
  //         return;
  //       }
  //     } else {
  //       if (_.size(data.data.data) === 0) {
  //         onHistoryCallback([], {
  //           noData: true,
  //         });
  //         return;
  //       }
  //     }
  //
  //     if (firstDataRequest) {
  //       lastBarsCache.set(symbolInfo.full_name, {
  //         ...bars[bars.length - 1],
  //       });
  //     }
  //     // console.log(`[getBars]: returned ${bars.length} bar(s)`);
  //     onHistoryCallback(bars, {
  //       noData: false,
  //     });
  //   } catch (error) {
  //     // console.log('[getBars]: Get error', error);
  //     onErrorCallback(error);
  //   }
  // },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    stream.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache,
      // lastBarsCache.get(symbolInfo.full_name),
    );
  },
  unsubscribeBars: subscriberUID => {
    stream.unsubscribeBars(subscriberUID);
  },

  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    const totalData = !isEmpty(localStorage.getItem('analyze_historical_model'))
      ? JSON.parse(localStorage.getItem('analyze_historical_model'))
      : [];

    const uniqueTimestamps = new Set();
    const marks = [];

    totalData.forEach(
      (item, index) => {
        const date = new Date(item.entryDate);
        const milliseconds = date.getTime();
        const timestampInSeconds = Math.floor(Math.floor(milliseconds / 1000) / 60) * 60;

        // :::::::: MAYBE NEDDED IN FUTURE ::::::::::
        // if (!uniqueTimestamps.has(timestampInSeconds)) {
        uniqueTimestamps.add(timestampInSeconds);

        const mark = {
          id: index,
          time: timestampInSeconds,
          minSize: 15,
        };

        if (item.action === 'BUY') {
          mark.color = 'green';
          mark.text = 'BUY';
          mark.label = 'B';
          mark.labelFontColor = 'white';
          marks.push(mark);
        } else if (['STOP LOSS', 'SELL', 'SELL ALL'].includes(item.action)) {
          mark.color = 'red';
          mark.text = 'SELL';
          mark.label = 'S';
          mark.labelFontColor = 'white';
          marks.push(mark);
        }
      },
      // }
    );

    onDataCallback(marks);
  },
};
