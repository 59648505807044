import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useRefreshToken from 'security/hooks/useRefreshToken';
import useAuth from 'security/hooks/useAuth';
import { cookie } from 'helpers/cookies';
import { localStorageClear } from 'helpers/localStorage';
import { TOKEN_NAME } from 'constants/token';

const PersistentLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        const refreshToken = cookie.get(TOKEN_NAME.REFRESH_TOKEN);
        if (!refreshToken) {
          setIsLoading(false); // Set loading to false since we can't refresh
          return;
        }
        await refresh();
      } catch (err) {
        console.error(err);
        cookie.clean();
        localStorageClear();
      } finally {
        setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, []);

  return <>{isLoading ? <p> Loading...</p> : <Outlet />}</>;
};

export default PersistentLogin;
