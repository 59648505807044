import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useStyles } from 'components/common/utils';
import Crypto from 'components/luna/Crypto';
import { fetchCoinRequest } from 'actions/simAction';
import { useDispatch, useSelector } from 'react-redux';
import AlertGroupModal from 'components/luna/AlertGroupModal';

const Luna = () => {
  const [value, setValue] = useState(0);
  const [openAlertGroupModal, setOpenAlertGroupModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const coins = useSelector(state => state.sim.coins);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchCoinRequest());
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', background: '#040d3e', height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            aria-label="Tabs where each tab needs to be selected manually"
            onChange={handleChange}
            value={value}
            variant="fullWidth"
            sx={theme => ({
              justifyContent: 'space-around',
            })}
            textColor={value === 0 ? '#FFD41F' : '#B1AFCD'}
          >
            <Tab
              label="Crypto"
              className={classes.commonTextStyle}
              sx={theme => ({
                color: value === 0 ? '#FFD41F' : '#B1AFCD',
                borderRight: '1px solid #1a2147',
                [theme.breakpoints.down('md')]: { paddingTop: '6px', paddingBottom: '6px' },
              })}
            />
            <Tab
              label="Futures"
              className={classes.commonTextStyle}
              sx={theme => ({
                color: value === 1 ? '#FFD41F' : '#B1AFCD',
                borderRight: '1px solid #1a2147',
                [theme.breakpoints.down('md')]: { paddingTop: '6px', paddingBottom: '6px' },
              })}
            />
            <Tab
              label="Forex"
              className={classes.commonTextStyle}
              sx={theme => ({
                color: value === 2 ? '#FFD41F' : '#B1AFCD',
                borderRight: '1px solid #1a2147',
                [theme.breakpoints.down('md')]: { paddingTop: '6px', paddingBottom: '6px' },
              })}
            />
            <Tab
              label="Stocks"
              className={classes.commonTextStyle}
              sx={theme => ({
                color: value === 3 ? '#FFD41F' : '#B1AFCD',
                [theme.breakpoints.down('md')]: { paddingTop: '6px', paddingBottom: '6px' },
              })}
            />
          </Tabs>
        </Box>
        {value === 0 && <Crypto coin={coins} setOpenAlertGroupModal={setOpenAlertGroupModal} />}
        {value === 1 && <Box>Futures</Box>}
        {value === 2 && <Box>Forex</Box>}
        {value === 3 && <Box>Stocks</Box>}
      </Box>
      <AlertGroupModal
        coins={coins}
        openAlertGroupModal={openAlertGroupModal}
        setOpenAlertGroupModal={setOpenAlertGroupModal}
      />
    </React.Fragment>
  );
};

export default Luna;
