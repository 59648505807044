import React from 'react';

const InfoIcon = ({ children }) => {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: '#87dde5',
    width: '35px',
    height: '35px',
    borderRadius: '50px',
    outline: '3px solid #013f3e',
    outlineOffset: '-5px',
    fontSize: '0.9rem',
    color: '#030F34',
  };

  return <div style={styles}>{children}</div>;
};

export default InfoIcon;
