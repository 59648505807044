import { get } from 'lodash';
import Pusher from 'pusher-js';

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
  cluster: 'ap2',
});

export const formatTime = (days, hours, minutes, seconds) =>
  `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;

export const coinPair = data => `${get(data.pair, 'primaryCrypto.symbol')}-${get(data.pair, 'currency.name')}`;

export const formatNumberWithLocale = (number, style, totalFees) => {
  if (totalFees) {
    return `-$${number.toLocaleString('en-US', {
      style: style,
    })}`;
  } else {
    if (number >= 0) {
      return `$${number.toLocaleString('en-US', {
        style: style,
      })}`;
    } else {
      return `-$${Math.abs(number).toLocaleString('en-US', {
        style: style,
      })}`;
    }
  }
};

export const normalizeEndDate = date => date.replace('Z', '');
