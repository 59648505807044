import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { fetchUsersRequest } from 'actions/usersAction';
import { dailyPerformanceData } from 'actions/ActionTypes';
import { getDailyPerformanceData } from 'actions/dashboardAction';
import TableComponent from 'components/dashboard/TableComponent';

const Dashboard = () => {
  const dispatch = useDispatch();
  const getPerformanceData = useSelector(state => state.dashboard.getPerformanceData);

  useEffect(() => {
    dispatch(fetchUsersRequest());
  }, []);

  useEffect(() => {
    dispatch({
      type: dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_REQUEST,
      cb: res => {
        if (res && res.status === 200) {
          dispatch(getDailyPerformanceData());
        }
      },
    });
  }, []);

  return (
    <Box sx={{ background: '#040D3E', minHeight: 'calc(100vh - 48px)' }}>
      <div>
        <Grid>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: 'white',
              fontWeight: '900 !important',
              fontSize: '1.2rem !important',
              paddingBottom: '1rem',
              paddingTop: '1rem',
            }}
          >
            Monthly Performance
          </Typography>

          <Grid
            item
            md={3}
            sx={{
              color: 'white',

              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {/* Table 1 */}
            <Grid
              sx={{
                display: 'flex',
                border: '1px solid white',
                textAlign: 'center',
                margin: 0,
              }}
            >
              <TableComponent
                month={Object.keys(getPerformanceData)[0]}
                tableData={Object.values(getPerformanceData)[0]}
              />
            </Grid>

            {/* Table 2 */}
            <Grid
              sx={{
                display: 'flex',
                border: '1px solid white',
                textAlign: 'center',
              }}
            >
              <TableComponent
                month={Object.keys(getPerformanceData)[1]}
                tableData={Object.values(getPerformanceData)[1]}
              />
            </Grid>

            {/* Table 3 */}
            <Grid
              sx={{
                display: 'flex',
                border: '1px solid white',
                textAlign: 'center',
              }}
            >
              <TableComponent
                month={Object.keys(getPerformanceData)[2]}
                tableData={Object.values(getPerformanceData)[2]}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Dashboard;
