import { dailyPerformanceData } from 'actions/ActionTypes';

const initialState = {
  storePerformanceData: [],
  isStorePerformanceDataPending: false,
  getPerformanceData: [],
  isGetPerformanceDataPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_REQUEST:
      return {
        ...state,
        isStorePerformanceDataPending: true,
      };
    case dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        isStorePerformanceDataPending: false,
        storePerformanceData: action.payload.storePerformanceData,
      };
    case dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        isStorePerformanceDataPending: false,
        storePerformanceData: [],
      };

    case dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_REQUEST:
      return {
        ...state,
        isGetPerformanceDataPending: true,
      };
    case dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        isGetPerformanceDataPending: false,
        getPerformanceData: action.payload.getPerformanceData,
      };
    case dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        isGetPerformanceDataPending: false,
        getPerformanceData: [],
      };

    default:
      return {
        ...state,
      };
  }
};
