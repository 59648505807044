import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { cookie } from 'helpers/cookies';
import { localStorageClear } from 'helpers/localStorage';

const Unauthorized = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();

  const goHome = () => {
    cookie.clean();
    localStorageClear();
    navigate('/login');
  };

  return (
    <>
      <div>You are not authorized to view {from}</div>
      <button onClick={goHome}> Go Home</button>
    </>
  );
};

export default Unauthorized;
