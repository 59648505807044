import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Grid, IconButton, InputBase, Paper, Stack, TextField, Typography } from '@mui/material';
import { Search, ArrowDownward } from '@material-ui/icons';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs';
import { FiTriangle } from 'react-icons/fi';
import { RiArrowLeftRightLine } from 'react-icons/ri';
import cold from 'assets/cold.svg';
import ripple from 'assets/btnicons/applymodel.svg';
import doge from 'assets/btnicons/doge.svg';
import bitCoin from 'assets/optimizedblue.svg';
import { filter, get, map, isEmpty } from 'lodash';
import ClassificationCard from 'components/classification/ClassificationCard';
import ClassificationDetail from 'components/classification/ClassificationDetail';
import { startsWith } from 'components/common/utils';
import { fetchClassificationRequest, fetchCoinRequest } from 'actions/simAction';
import { fetchExchanges } from 'actions/classificationAction';

const Classification = () => {
  const [search, setSearch] = React.useState('');
  const [selectedClassification, setSelectedClassification] = useState('');
  const [selectedCoin, setSelectedCoin] = useState([]);
  const dispatch = useDispatch();
  const [cryptoName, setCryptoName] = React.useState('');
  const [allClassificationCard, setAllClassificationCard] = React.useState([]);
  const classifications = useSelector(state => state.sim.classifications);
  const coins = useSelector(state => state.sim.coins);
  const exchanges = useSelector(state => state.classification.exchanges);

  const amount = [
    { amount: '$52,123', img: <RiArrowLeftRightLine color="white" size={19} /> },
    { amount: '$52,123', img: <BsFillArrowUpCircleFill color="white" size={19} /> },
    { amount: '$52,123', img: <BsFillArrowDownCircleFill color={'white'} size={19} /> },
    { amount: '$52,123', img: <FiTriangle color={'white'} size={19} /> },
  ];

  useEffect(() => {
    dispatch(fetchClassificationRequest());
    dispatch(fetchCoinRequest());
    dispatch(fetchExchanges());
  }, []);

  const isDataSelected = (coindetails, selectedItems, itemKey) => {
    return !selectedItems.length || selectedItems.includes(coindetails[itemKey]);
  };

  const coinDetails = useMemo(() => {
    return coins.filter(data => {
      return !isEmpty(selectedCoin)
        ? isDataSelected(
            data,
            map(selectedCoin, data => data.label),
            'name',
          )
        : false;
    });
  }, [selectedCoin]);

  useEffect(() => {
    setAllClassificationCard(filter(coinDetails, item => startsWith(get(item, 'name', ''), search)));
  }, [search, coinDetails]);

  return (
    <Box sx={{ background: '#040d3e', height: '100%', width: '100%' }}>
      <Grid
        container
        spacing={2}
        sx={{ margin: 0, width: '100%', height: '100%', border: '1px solid #3FD2F2', background: '#040d3e' }}
      >
        <Grid
          item
          md={4}
          xs={12}
          sx={{ border: '1px solid #3FD2F2', padding: '0px !important', background: '#040d3e' }}
        >
          <Box>
            <Box sx={{ padding: '1rem' }}>
              <Paper
                component="form"
                sx={theme => ({
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    width: 350,
                  },
                  background: '#000000',
                  border: '1px solid #3FD2F2',
                })}
              >
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                  <Search style={{ color: '#3FD2F2' }} />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, color: '#fff' }}
                  value={search}
                  placeholder="Search Coin/Token Ticker or Name"
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                />
              </Paper>
              <Stack direction={'row'} spacing={2} sx={{ margin: '1rem 0', width: '100%' }}>
                <Box sx={{ width: '50%' }}>
                  <Autocomplete
                    id=""
                    name=""
                    options={map(classifications, data => {
                      return {
                        value: data.id,
                        label: data.name,
                      };
                    })}
                    sx={theme => ({
                      borderRadius: 2,
                    })}
                    onChange={(event, value) => {
                      setSelectedCoin([]);
                      setSelectedClassification(value ? value.label : '');
                    }}
                    value={selectedClassification}
                    size="small"
                    renderInput={params => (
                      <TextField
                        onChange={(event, value) => {
                          setSelectedClassification(value.label ?? '');
                        }}
                        value={selectedClassification}
                        sx={theme => ({
                          svg: {
                            color: '#fff',
                          },
                          border: '1px solid #3FD2F2',
                          borderRadius: '4px',
                          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #484850',
                            borderRadius: '4px',
                          },
                          input: {
                            color: '#fff',
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '14px',
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: '12px',
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: '11px',
                            },
                          },
                        })}
                        {...params}
                        placeholder="All Classes + Unclassified"
                      />
                    )}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Autocomplete
                    disablePortal
                    id=""
                    name=""
                    options={map(exchanges, data => {
                      return {
                        value: data.id,
                        label: data.name,
                      };
                    })}
                    sx={theme => ({
                      borderRadius: 2,
                    })}
                    size="small"
                    renderInput={params => (
                      <TextField
                        sx={theme => ({
                          svg: {
                            color: '#fff',
                          },
                          border: '1px solid #3FD2F2',
                          borderRadius: '4px',
                          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #484850',
                            borderRadius: '4px',
                          },
                          input: {
                            color: '#fff',
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '14px',
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: '12px',
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: '11px',
                            },
                          },
                        })}
                        {...params}
                        placeholder="All Exchanges"
                      />
                    )}
                  />
                </Box>
              </Stack>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={1} xs={1.5}>
                  <Typography component={'div'} sx={{ color: '#fff', cursor: 'pointer' }}>
                    Sort
                  </Typography>
                </Grid>
                <Grid item lg={9.5} xs={8}>
                  <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      // disablePortal
                      multiple
                      id=""
                      name=""
                      size="small"
                      options={map(
                        filter(coins, data => data.classification.name === selectedClassification),
                        data => {
                          return {
                            value: data.id,
                            label: data.name,
                          };
                        },
                      )}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      filterSelectedOptions
                      onChange={(event, value) => {
                        // setSelectedCoin(value ? value.label : '')
                        setSelectedCoin(value);
                      }}
                      value={selectedCoin}
                      sx={theme => ({
                        borderRadius: 2,
                      })}
                      renderInput={params => (
                        <TextField
                          sx={theme => ({
                            svg: {
                              color: '#fff',
                            },
                            border: '1px solid #3FD2F2',
                            borderRadius: '4px',
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #484850',
                              borderRadius: '4px',
                            },
                            '& .MuiAutocomplete-tag': {
                              backgroundColor: 'aliceblue !important',
                            },
                            input: {
                              color: '#fff',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '14px',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '12px',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '11px',
                              },
                            },
                          })}
                          {...params}
                          placeholder="Coin Ticker"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item lg={0.5} xs={1}>
                  <ArrowDownward style={{ color: 'white' }} />
                </Grid>
                <Grid item lg={1} xs={1.5}>
                  <Typography component={'div'} sx={{ color: '#fff', cursor: 'pointer' }}>
                    ASC
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginTop: '1rem',
                height: 'calc(100vh - 334px)',
                overflowX: 'hidden',
                overflowY: 'scroll',
                direction: 'rtl',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#171616',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#175982',
                  borderRadius: 2,
                },
              }}
            >
              {_.isEmpty(allClassificationCard) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: '600',
                    color: '#3FD2F2',
                    marginTop: '1.5rem',
                  }}
                >
                  No Classification
                </Box>
              ) : (
                map(allClassificationCard, data => {
                  return (
                    <ClassificationCard
                      name={`${data.symbol && data.symbol} ${data.name && `(${data.name})`}`}
                      amounts={map(amount, data => {
                        return (
                          <Stack direction="row" spacing={1}>
                            {data.img}
                            <Typography component="div" sx={{ color: '#fff' }}>
                              {data.amount}
                            </Typography>
                          </Stack>
                        );
                      })}
                      icons={
                        <>
                          <img src={cold} alt="" />
                          <img src={cold} alt="" />
                          <img src={cold} alt="" />
                          <img src={cold} alt="" />
                        </>
                      }
                      classificationDetails={<ClassificationBox />}
                      onClick={() => setCryptoName(data.name)}
                    />
                  );
                })
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} xs={12} sx={{ padding: '0px !important', height: '100%', background: '#040d3e' }}>
          <ClassificationDetail cryptoName={!_.isEmpty(coinDetails) ? cryptoName : ''} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Classification;

export const ClassificationBox = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingLeft: '0px !important',
        width: '100%',
        fontSize: '11px',
      }}
    >
      <Grid
        xs={3}
        lg={3}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid #3FD2F2',
          padding: '0.5rem',
        }}
      >
        <img src={cold} width={25} height={25} alt={''} />
      </Grid>
      <Grid
        xs={3}
        lg={3}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid #3FD2F2',
          background: '#0B267A',
          padding: '0.5rem',
        }}
      >
        <img src={bitCoin} width={25} height={25} alt={''} />
      </Grid>
      <Grid
        xs={3}
        lg={3}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid #3FD2F2',
          padding: '0.5rem',
        }}
      >
        <img src={ripple} width={25} height={25} alt={''} />
      </Grid>
      <Grid
        xs={3}
        lg={3}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid #3FD2F2',
          padding: '0.5rem',
        }}
      >
        <img src={doge} width={25} height={25} alt={''} />
      </Grid>
    </Grid>
  );
};
