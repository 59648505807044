import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Stack,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  Select,
  ListItemIcon,
  ListItemText,
  FormControl,
  Grid,
  Paper,
  IconButton,
  OutlinedInput,
  InputBase,
} from '@mui/material';
import { Add, Search, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { map, isEmpty, get, filter } from 'lodash';
import { fetchAlert, fetchAlertGroup } from 'actions/lunaAction';
import { fetchUsersRequest } from 'actions/usersAction';
import { startsWith } from '../common/utils';
import Accordion from './Accordion';
import AddAlertBoxQueryBuilder from './AddAlertBoxQueryBuilder';
import AlertBoxQueryBuilder from './AlertBoxQueryBuilder';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    background: '#000000',
    border: '1px solid #3FD2F2 !important',
    borderRadius: '4px',
    width: 310,
    [theme.breakpoints.down('lg')]: {
      width: 220,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const Crypto = props => {
  const { coin, setOpenAlertGroupModal } = props;
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [allAlertGroup, setAllAlertGroup] = useState([]);
  const [addedNewAlert, setAddedNewAlert] = useState(false);
  const [value, setValue] = useState(0);
  const users = useSelector(state => state.users.users);
  const options = map(users, item => item.name);
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertGroups = useSelector(state => state.luna.alertGroup);
  const alerts = useSelector(state => state.luna.alerts);

  const [selected, setSelected] = useState(options);

  useEffect(() => {
    dispatch(fetchAlertGroup());
    dispatch(fetchAlert());
    dispatch(fetchUsersRequest());
  }, []);

  useEffect(() => {
    setSelected(options);
  }, [users]);

  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isDataSelected = (alertGroupDetails, selectedItems, itemKey) => {
    return !selectedItems.length || selectedItems.includes(alertGroupDetails[itemKey]);
  };

  const alertGroupDetails = useMemo(() => {
    return alertGroups.filter(data => {
      return !isEmpty(selected)
        ? isDataSelected(
            data.owner,
            map(selected, data => data),
            'name',
          )
        : false;
    });
  }, [selected, alertGroups]);

  useEffect(() => {
    if (!isEmpty(selected) && search) {
      setAllAlertGroup(filter(alertGroupDetails, item => startsWith(_.get(item, 'name', ''), search)));
    } else if (selected) {
      setAllAlertGroup(alertGroupDetails);
    } else {
      setAllAlertGroup(filter(alertGroups, item => startsWith(_.get(item, 'name', ''), search)));
    }
  }, [search, alertGroups, selected, alertGroupDetails]);

  return (
    <Box sx={{ padding: '1rem', background: '#040d3e' }}>
      <Box sx={{ marginTop: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={{ sm: 2, lg: 4 }}>
            <Typography
              component="h6"
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                  fontSize: '0.9rem',
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: '0.72rem',
                },
                color: 'white',
              })}
            >
              Class / Coin / Token
            </Typography>
            <Paper
              component="form"
              sx={theme => ({
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 500,
                [theme.breakpoints.down('lg')]: {
                  width: 220,
                  p: '1.5px 3px',
                },
                [theme.breakpoints.down('md')]: {
                  width: 120,
                  p: '1px 2px',
                },
                background: '#000000',
                border: '1px solid #3FD2F2',
              })}
            >
              <IconButton
                type="submit"
                sx={theme => ({
                  p: '10px',
                  [theme.breakpoints.down('lg')]: {
                    p: '6px',
                  },
                  [theme.breakpoints.down('md')]: {
                    p: '3px',
                  },
                })}
                aria-label="search"
              >
                <Search style={{ color: '#3FD2F2' }} />
              </IconButton>
              <InputBase
                sx={theme => ({
                  ml: 1,
                  flex: 1,
                  color: '#fff',
                  input: {
                    textOverflow: 'ellipsis',
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '.9rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.7rem',
                  },
                })}
                value={search}
                placeholder="Search"
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />
            </Paper>
            <FormControl className={classes.formControl}>
              <Select
                multiple
                displayEmpty
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        component="div"
                        sx={{
                          color: '#fff',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        See All Owners
                      </Typography>
                    );
                  }
                  return selected.join(', ');
                }}
                sx={theme => ({
                  svg: {
                    color: '#fff',
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #484850',
                    borderRadius: '4px',
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '.9rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.7rem',
                  },
                })}
                inputProps={{ sx: { color: '#fff' } }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={selected.length > 0 && selected.length < options.length}
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Button
            startIcon={<Add />}
            variant="outlined"
            sx={{
              color: '#FFFFFF',
              backgroundColor: '#000000',
              border: '2px solid #3FD2F2',
              textTransform: 'unset !important',
              cursor: 'pointer',
            }}
            onClick={() => setOpenAlertGroupModal(true)}
          >
            New Alert Group
          </Button>
        </Box>
      </Box>
      {isEmpty(allAlertGroup) ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '600',
            color: '#3FD2F2',
            marginTop: '1.5rem',
          }}
        >
          alertGroups is not available
        </Box>
      ) : (
        map(allAlertGroup, (data, item) => {
          return (
            <Box sx={{ margin: '1rem 0' }} key={item}>
              <Accordion
                expanded={expanded === get(data, 'id', '')}
                onChange={handleOnChange(get(data, 'id', ''))}
                expandIcon={<ExpandMore style={{ color: '#fff' }} />}
                name={get(data, 'name', '')}
                summaryContent={
                  expanded === get(data, 'id', '') ? (
                    <Button
                      startIcon={<Add />}
                      variant="outlined"
                      sx={theme => ({
                        color: '#FFFFFF',
                        backgroundColor: '#000000',
                        border: '2px solid #3FD2F2',
                        textTransform: 'unset !important',
                        marginRight: '1rem',
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '.9rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.7rem',
                        },
                      })}
                      onClick={event => {
                        event.stopPropagation();
                        setAddedNewAlert(true);
                        // addAlert();
                      }}
                    >
                      New Alert
                    </Button>
                  ) : (
                    <Typography sx={{ color: '#fff', marginRight: '1rem' }}>
                      {get(data, 'alerts.length', 0)} Alerts
                    </Typography>
                  )
                }
              >
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  sx={{ display: 'flex', alignItems: 'flex-start' }}
                  spacing={{ sm: 2, lg: 4 }}
                >
                  <Box
                    sx={theme => ({
                      color: '#fff',
                      [theme.breakpoints.up('lg')]: {
                        width: '10%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '100%',
                      },
                    })}
                  >
                    <Typography
                      variant="h6"
                      sx={theme => ({
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '.9rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.7rem',
                        },
                      })}
                      component="div"
                    >
                      Coins in class
                    </Typography>
                    <Stack
                      direction={'column'}
                      spacing={0.5}
                      sx={{
                        overflow: 'auto',
                        maxHeight: 270,
                        '&::-webkit-scrollbar': {
                          width: '7px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#171616',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#175982',
                          borderRadius: 2,
                        },
                      }}
                    >
                      {isEmpty(data.scripts) ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            fontWeight: '600',
                            color: 'white',
                            marginTop: '1rem',
                          }}
                        >
                          coin is not available
                        </Box>
                      ) : (
                        map(data.scripts, data => {
                          return (
                            <Box
                              sx={theme => ({
                                fontSize: '1rem',
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '.9rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '.7rem',
                                },
                              })}
                            >
                              <Box>
                                {data.name} {data.symbol && `(${data.symbol})`}
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </Stack>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    {!addedNewAlert && isEmpty(data.alerts) ? (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'red',
                        }}
                      >
                        Not alert found, please click on the above button (+ New Alert)
                      </Typography>
                    ) : addedNewAlert ? (
                      <AddAlertBoxQueryBuilder setAddedNewAlert={setAddedNewAlert} alertGroupId={data.id} />
                    ) : (
                      map(alerts, data => {
                        return expanded === get(data, 'alertGroup', '') && <AlertBoxQueryBuilder alertDetail={data} />;
                      })
                    )}
                  </Box>
                </Stack>
              </Accordion>
            </Box>
          );
        })
      )}
    </Box>
  );
};

const BitcoinDetail = props => {
  const { status, value } = props;
  return (
    <Grid container xs={12} spacing={2} sx={{ width: '100%', marginBottom: '0.5rem' }}>
      <Grid item xs={4}>
        <Typography component={'span'}>{`${status}:`}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography component={'span'}>{`$${value}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default Crypto;
