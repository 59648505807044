import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { DeleteOutline, Search } from '@material-ui/icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { cloneDeep, map, get, trim, isEmpty, filter, find, includes } from 'lodash';
import { toast } from 'react-toastify';
import { useQuill } from 'react-quilljs';
import * as Yup from 'yup';
import 'quill/dist/quill.snow.css';
import { TOKEN_NAME, USER_ROLE } from 'constants/token';
import { startsWith } from 'components/common/utils';
import { cookie } from 'helpers/cookies';
import { fetchNotes, isNoteEdit, isNoteEditedFromOutside, isNoteTextEdited } from 'actions/simAction';
import { models, notes as noteAction } from 'actions/ActionTypes';
import SparkModal from './SparkModal';
import { initOnReady } from '../chart/main';
import { fetchUsersRequest } from 'actions/usersAction';

const NoteComponent = ({
  classes,
  deleteNoteModal,
  setDeleteNoteModal,
  abandonChangesModal,
  setAbandonChangesModal,
  setIsTextEdited,
  selectedId,
  setSelectedId,
  setNoteEditMode,
  noteEditMode,
  isModelSelected,
  setIsModelSelected,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const noteNameRef = useRef(null);
  const user = cookie.get(USER_ROLE.USER);
  const userId = JSON.parse(window.atob(cookie.get(TOKEN_NAME.ACCESS_TOKEN).split('.')[1])).sub;

  const allNotes = useSelector(state => state.sim.notes);
  const usersData = useSelector(state => state.users.users);
  const loader = useSelector(state => state.sim.noteLoading);
  const chartData = useSelector(state => state.sim.chartData);
  const isNoteEditFromReducer = useSelector(state => state.sim.isNoteEdit);
  const selectedModel = useSelector(state => state.sim.currentSelectedModel);
  const isChartDrawingChanged = useSelector(state => state.sim.isChartDrawingChanged);
  const isNoteTextEditedFromReducer = useSelector(state => state.sim.isNoteTextEdited);
  const isNoteEditedFromOutsideFromReducer = useSelector(state => state.sim.isNoteEditedFromOutside);

  const [text, setText] = useState('');
  const [notes, setNotes] = useState('');
  const [search, setSearch] = useState('');
  const [isNotNew, setIsNotNew] = useState(false);
  const [noteNameState, setNoteNameState] = useState('');
  const [quillEnabled, setQuillEnabled] = useState(false);
  const [deleteNoteData, setDeleteNoteData] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [currentEditNoteId, setCurrentEditNoteId] = useState('');
  const [editedNoteNameState, setEditedNoteNameState] = useState('');
  const [openAbandonModalForNewNoteButton, setOpenAbandonModalForNewNoteButton] = useState(false);

  const selectedNote = find(allNotes, item => item.id === selectedId);

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
    },
    clipboard: { matchVisual: false },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'size',
    'color',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
  ];

  const { quillRef, quill } = useQuill({ modules, formats });

  useEffect(() => {
    setNotes(filter(allNotes, item => startsWith(get(item, 'noteName', ''), search)));
  }, [search, allNotes]);

  useEffect(() => {
    if (isEmpty(usersData)) {
      dispatch(fetchUsersRequest());
    }
  }, [usersData]);

  useEffect(() => {
    setSelectedId('');
    dispatch(isNoteEdit(false));
    dispatch(isNoteTextEdited(false));
  }, []);

  useEffect(() => {
    const clonedNoteDetail = selectedNote && cloneDeep(selectedNote);
    initOnReady(selectedModel, dispatch, selectedNote && clonedNoteDetail.chartDrawingData);
  }, [selectedNote, dispatch, selectedModel]);

  useEffect(() => {
    if (!isEmpty(selectedModel)) {
      getAllNotes(selectedModel.id);
    }
  }, [selectedModel]);

  // ::::: For Quill ::::::::
  useEffect(() => {
    //For: got a written text
    if (quill) {
      quill.on('text-change', () => {
        // :::::::: Set Normal Data for Quill :::::::::
        // setText(quill.getText()); // Get text only
        setText(quillRef.current?.querySelector('.ql-editor')?.innerHTML); // Get text only
      });
    }
  }, [quill, quillEnabled]);

  useEffect(() => {
    if (isModelSelected) {
      setIsNotNew(false);
      if (quill) {
        quill.setText('');
      }
      setQuillEnabled(false);
      setSelectedId('');
      setIsModelSelected(false);
      setNoteNameState('');
      if (formikRef.current) {
        formikRef.current.resetForm();
        formikRef.current.setErrors({});
      }
    }
  }, [isModelSelected]);

  useEffect(() => {
    if (isNoteEditedFromOutsideFromReducer) {
      dispatch({ type: models.CHART_DATA, payload: {} });
      dispatch(isNoteEditedFromOutside(false));
      if (quill) {
        quill.setText('');
      }
      setSelectedId('');
      setShowSaveButton(true);
      setIsNotNew(false);
      setNoteNameState('');
      setQuillEnabled(false);
      // Note :::::: Nedded in the functionality later ::::::
      // if (errors.noteName) {
      //   formik.setFieldError('noteName', '');
      // }
    }
  }, [quill, isNoteTextEditedFromReducer]);

  const getAllNotes = useCallback(id => {
    dispatch(fetchNotes(id));
  }, []);

  const addNote = useCallback(
    values => {
      dispatch({
        type: noteAction.CREATE_NOTE,
        payload: {
          note: trim(text),
          noteName: values.noteName,
          chartDrawingData: !isEmpty(chartData) && chartData,
          historicalModel: selectedModel.id,
        },
        cb: res => {
          if (res) {
            if (res.status === 200) {
              toast.success('Historical Note added successfully');
              getAllNotes(selectedModel.id);
              quill.setText('');
              setNoteNameState('');
              setQuillEnabled(false);
            }
          }
        },
      });
    },
    [text, userId, chartData, selectedModel],
  );

  const editNote = useCallback(
    values => {
      dispatch({
        type: noteAction.UPDATE_NOTE,
        payload: {
          note: trim(text),
          noteName: values.noteName,
          lastUpdatedBy: userId,
          id: selectedId,
          chartDrawingData: !isEmpty(chartData) && chartData,
        },
        cb: res => {
          if (res) {
            if (res.status === 200) {
              toast.success('Historical Note updated successfully');
              setQuillEnabled(false);
              setIsNotNew(true);
              getAllNotes(selectedModel.id);
            }
          }
        },
      });
    },
    [text, userId, selectedId, selectedModel, chartData],
  );

  const removeNote = useCallback(
    id => {
      dispatch({
        type: noteAction.DELETE_NOTE,
        payload: id,
        cb: res => {
          if (res) {
            if (res.status === 200) {
              toast.success('Historical Note deleted successfully');
              getAllNotes(selectedModel.id);
              setShowSaveButton(true);
              setIsNotNew(false);
              setSelectedId('');
              quill.setText('');
            }
          }
        },
      });
    },
    [selectedModel],
  );

  const validationSchema = useCallback(() => {
    return Yup.object().shape({
      noteName: Yup.string().required('*required').min(6, 'Name must be longer than 5 characters').trim(),
    });
  }, [currentEditNoteId]);

  return (
    <Box sx={{ background: '#000000' }}>
      <Formik
        ref={formikRef}
        initialValues={{ noteName: noteNameState }}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={(values, { resetForm, setFieldError }) => {
          if (
            currentEditNoteId &&
            includes(
              map(
                filter(allNotes, note => note.id !== currentEditNoteId),
                'noteName',
              ),
              values.noteName,
            )
          ) {
            setFieldError('noteName', 'Name already exists, please enter a new unique name');
          } else if (!currentEditNoteId && includes(map(allNotes, 'noteName'), values.noteName)) {
            setFieldError('noteName', 'Name already exists, please enter a new unique name');
          } else if (!isNoteEditFromReducer || isEmpty(selectedId)) {
            addNote(values);
            resetForm();
          } else {
            editNote(values);
          }
        }}
      >
        {({ setFieldValue, handleBlur, touched, setFieldError, errors }) => {
          useEffect(() => {
            if (!isNoteTextEditedFromReducer && editedNoteNameState !== '') {
              setNoteNameState(editedNoteNameState);
              setFieldValue('noteName', editedNoteNameState);
              setIsNotNew(true);
              setEditedNoteNameState('');
            }
          }, [editedNoteNameState, isNoteTextEditedFromReducer]);
          return (
            <Form>
              <Grid container xs={12}>
                <Grid
                  item
                  xs={2.615}
                  sx={{
                    minHeight: '13rem',
                    height: 'calc(100vh - 593px)',
                    borderRight: '1px solid #D0D7FF80',
                  }}
                >
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      padding: '0.5rem',
                      [theme.breakpoints.down('lg')]: {
                        flexDirection: 'column',
                      },
                    })}
                  >
                    <Button
                      variant="outlined"
                      id="new-note"
                      onClick={() => {
                        if (errors.noteName) {
                          setFieldError('noteName', '');
                        }
                        if (isNoteEditFromReducer && (isNoteTextEditedFromReducer || isChartDrawingChanged)) {
                          setAbandonChangesModal(true);
                          setOpenAbandonModalForNewNoteButton(true);
                        } else {
                          dispatch(isNoteEdit(false));
                          dispatch(isNoteTextEdited(false));
                          setQuillEnabled(false);
                          dispatch({ type: models.CHART_DATA, payload: {} });
                          quill.setText('');
                          setSelectedId('');
                          setShowSaveButton(true);
                          setIsNotNew(false);
                          setNoteNameState('');
                          setFieldValue('noteName', '');
                        }
                        noteNameRef.current && noteNameRef.current.focus();
                        setCurrentEditNoteId('');
                        if (formikRef.current) {
                          formikRef.current.resetForm();
                          formikRef.current.setErrors({});
                        }
                      }}
                      className={classes.commonTextStyle}
                      sx={theme => ({
                        color: '#FFFFFF !important',
                        border: '1px solid #20FFC9 !important',
                        background: '#0A3847 !important',
                        padding: '0.3rem !important',
                        textTransform: 'unset !important',
                        [theme.breakpoints.down('lg')]: {
                          width: '100%',
                        },
                      })}
                    >
                      + New
                    </Button>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        border: '1px solid #3FD2F2  !important',
                        paddingX: '0.5rem',
                      }}
                    >
                      <Box
                        sx={{
                          paddingTop: '0.5rem',
                        }}
                      >
                        <Search style={{ color: '#C4C4C4' }} />
                      </Box>
                      <TextField
                        variant="standard"
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value);
                        }}
                        color="info"
                        focused
                        inputProps={{
                          sx: {
                            '&::placeholder': {
                              color: '#FFFFFF',
                            },
                          },
                        }}
                        sx={{
                          input: { color: '#FFF' },
                          width: '100%',
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={theme => ({
                      padding: '0.5rem',
                    })}
                  >
                    {loader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '1rem',
                        }}
                      >
                        <CircularProgress size={30} />
                      </Box>
                    ) : isEmpty(notes) ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: '600',
                          color: 'white',
                          marginTop: '1rem',
                        }}
                      >
                        Notes are not available
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          minHeight: '9rem',
                          height: 'calc(100vh - 670px)',
                          overflow: 'auto',
                          '&::-webkit-scrollbar': {
                            width: '0.4rem',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'gray',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#3FD2F2',
                            borderRadius: '1rem',
                          },
                        }}
                      >
                        {map(notes, (data, item) => {
                          return (
                            <Box
                              className={classes.commonTextStyle}
                              key={item}
                              onClick={() => {
                                if (
                                  !(
                                    isNoteEditFromReducer &&
                                    (isNoteTextEditedFromReducer || isChartDrawingChanged) &&
                                    currentEditNoteId &&
                                    currentEditNoteId !== data.id
                                  )
                                ) {
                                  setSelectedId(get(data, 'id', ''));
                                  // ::::::::: Set Normal Data in Text Box ::::::::
                                  // quill.setText(get(data, 'note', ''));
                                  quill.clipboard.dangerouslyPasteHTML(get(data, 'note', ''));
                                  setFieldValue('noteName', get(data, 'noteName', ''));
                                  setNoteNameState(get(data, 'noteName', ''));
                                  setIsNotNew(true);
                                }

                                setShowSaveButton(true);
                                dispatch(isNoteEdit(true));
                                setFieldError('noteName', '');
                                setQuillEnabled(false);
                                if (
                                  isNoteEditFromReducer &&
                                  (isNoteTextEditedFromReducer || isChartDrawingChanged) &&
                                  currentEditNoteId &&
                                  currentEditNoteId !== data.id
                                ) {
                                  setNoteEditMode(true);
                                  setQuillEnabled(true);
                                  setEditedNoteNameState(data.noteName);
                                  setAbandonChangesModal(true);
                                }
                                setCurrentEditNoteId(data.id);
                              }}
                              sx={{
                                background: '#0B1C21',
                                color: '#FFF',
                                border: `${selectedId === get(data, 'id', '') ? '0.063rem solid #3FD2F2' : ''}`,
                                padding: '2rem 1rem',
                                marginBottom: '0.5rem',
                                height: '2.25rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Box fontSize="larger">
                                  <label
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {get(data, 'noteName', '')}
                                  </label>
                                </Box>
                                <Box fontStyle="italic">
                                  <label
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {get(data, 'createdBy.name', '')}
                                  </label>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex' }}>
                                {user === get(data, 'createdBy.name', '') && (
                                  <DeleteOutline
                                    style={{
                                      cursor: 'pointer',
                                      color: '#fff',
                                      fontSize: '1.5rem',
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      setDeleteNoteModal(true);
                                      setDeleteNoteData(data);
                                      dispatch(isNoteEdit(false));
                                      quill.setText('');
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box
                    sx={{
                      color: '#B1AFCD',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      borderTop: '0.063rem solid #4D568D',
                      padding: '0.3rem 0 0 0.9rem',
                    }}
                  />
                  <Box
                    sx={{
                      padding: '0rem 1.25rem 0.188rem 1.5rem',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop="0.6rem"
                      paddingX="0.5rem"
                      paddingBottom="0.75rem"
                      color="white"
                    >
                      <Box display="flex" flexDirection="column">
                        <Field
                          type="text"
                          name="noteName"
                          className="note_name"
                          autoFocus
                          ref={noteNameRef}
                          value={noteNameState}
                          placeholder="Enter Note Name"
                          label="Enter Note Name"
                          variant="standard"
                          onBlur={handleBlur}
                          touched={touched.noteName}
                          style={{
                            borderBottom: isNotNew ? 'none' : '0.125rem solid #53d5fd',
                            cursor: isNotNew ? 'pointer' : 'default',
                            fontSize: isNotNew ? '1.5rem' : '1.2rem',
                            fontStyle: isNotNew ? 'normal' : 'italic',
                          }}
                          onClick={e => {
                            // Check if the input is not already focused
                            if (document.activeElement !== e.target) {
                              e.preventDefault(); // Prevent default click behavior
                              e.target.focus(); // Set focus on the input
                            }
                            // Move the cursor to the end of the text
                            const input = e.target;
                            const length = input.value.length;
                            input.setSelectionRange(length, length);
                            setIsNotNew(false);
                          }}
                          onChange={e => {
                            if (!isNotNew) {
                              dispatch(isNoteTextEdited(true));
                            }
                            setIsNotNew(false);

                            setNoteNameState(e.target.value);
                            setFieldValue('noteName', e.target.value);
                          }}
                        />
                        <ErrorMessage name="noteName" component="div" style={{ color: 'red' }} />
                      </Box>
                      <Typography marginRight="0.7rem" color="white">
                        Author:{' '}
                        {selectedNote
                          ? selectedNote?.createdBy?.name
                          : get(
                              find(usersData, user => user.id === userId),
                              'name',
                              '',
                            )}
                      </Typography>
                    </Box>

                    <Box
                      sx={
                        errors.noteName || isEmpty(noteNameState)
                          ? {
                              height: 'fit-content',
                              maxWidth: '58vw',
                              pointerEvents: 'none',
                              cursor: 'not-allowed',
                            }
                          : {
                              height: 'fit-content',
                              maxWidth: '58vw',
                            }
                      }
                    >
                      <Box
                        sx={{
                          width: '100%',
                          color: quillEnabled ? '#fff' : 'grey',
                          minHeight: '6rem',
                          height: quillEnabled && 'calc(100vh - 800px)',
                          transition: 'height 1s smooth',
                        }}
                      >
                        <Box
                          ref={quillRef}
                          sx={{
                            wordWrap: 'break-word !important',
                          }}
                          theme="snow"
                          onChange={() => {
                            dispatch(isNoteTextEdited(true));
                          }}
                          onClick={() => {
                            dispatch(isNoteTextEdited(true));
                            setQuillEnabled(true);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        alignItems: 'center',
                        marginTop: quillEnabled ? { xs: '4.2rem', lg: '3.3rem' } : '0.3rem',
                        gap: '0.5rem',
                      }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.commonTextStyle}
                        sx={{
                          color: '#FFFFFF !important',
                          backgroundColor: '#000  !important',
                          border: '0.063rem solid #3FD2F2  !important',
                          borderRadius: '4px !important',
                          // width: '89px !important',
                        }}
                        onClick={() => {
                          dispatch(isNoteTextEdited(false));
                          if (errors.noteName) {
                            setFieldError('noteName', '');
                          }
                          if (isNoteEditFromReducer && selectedId) {
                            quill.setText(find(notes, note => note.id === selectedId)?.note);
                          } else {
                            quill.setText('');
                            setText('');
                            setNoteNameState('');
                          }
                          setQuillEnabled(false);
                        }}
                      >
                        Cancel
                      </Button>
                      {showSaveButton && (
                        <Button
                          id="custom_save_button"
                          variant="outlined"
                          type="submit"
                          className={classes.commonTextStyle}
                          sx={{
                            color: '#FFFFFF !important',
                            backgroundColor: '#0072F8 !important',
                            borderRadius: '0.25rem !important',
                            border: '1px solid #3FD2F2 !important',
                            padding: '0.3rem !important',

                            '&:disabled': {
                              color: '#fff !important',
                              filter: 'brightness(60%)',
                              cursor: 'not-allowed !important',
                              pointerEvents: 'all !important',
                            },
                          }}
                          disabled={text.length === 0 || text.length === 1}
                          onClick={() => {
                            isNoteEditFromReducer && selectedId && setNoteEditMode(false);
                            dispatch(isNoteTextEdited(false));
                            dispatch({
                              type: models.IS_CHART_DRAWING_CHANGED,
                              payload: false,
                            });
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <SparkModal
                deleteNoteModal={deleteNoteModal}
                setDeleteNoteModal={setDeleteNoteModal}
                deleteNoteData={deleteNoteData}
                abandonChangesModal={abandonChangesModal}
                setAbandonChangesModal={setAbandonChangesModal}
                openAbandonModalForNewNoteButton={openAbandonModalForNewNoteButton}
                setOpenAbandonModalForNewNoteButton={setOpenAbandonModalForNewNoteButton}
                removeNote={removeNote}
                quillRef={quillRef}
                quill={quill}
                currentEditNoteId={currentEditNoteId}
                setCurrentEditNoteId={setCurrentEditNoteId}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                setIsTextEdited={setIsTextEdited}
                noteEditMode={noteEditMode}
                setNoteEditMode={setNoteEditMode}
                setQuillEnabled={setQuillEnabled}
                setShowSaveButton={setShowSaveButton}
                setIsNotNew={setIsNotNew}
                setNoteNameState={setNoteNameState}
                setFieldError={setFieldError}
                noteNameRef={noteNameRef}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default NoteComponent;
