import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, Modal, SvgIcon, Typography, Input } from '@mui/material';
import { trim } from 'lodash';
import { useFormik } from 'formik';
import { models } from 'actions/ActionTypes';

function CloseIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.6em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
      </svg>
    </SvgIcon>
  );
}

const DuplicateModal = props => {
  const {
    openDuplicateModal,
    setOpenDuplicateModal,
    selectedCheckBox,
    allModel,
    setOpenEditModal,
    setDuplicateModalName,
  } = props;

  const dispatch = useDispatch();

  const updateSelectedModel = useCallback(() => {
    dispatch({
      type: models.GET_MODEL,
      payload: selectedCheckBox[0],
    });
  }, []);

  const validate = values => {
    const errors = {};
    if (trim(values.modelName).length === 0 || !values.modelName) {
      errors.modelName = '*required';
    } else if (trim(values.modelName).length <= 5) {
      errors.modelName = 'Name should be longer than 5 characters, please enter a longer name.';
    } else if (values.modelName && find(allModel, ele => trim(ele.name) === trim(values.modelName))) {
      errors.modelName = 'Name already exists, please enter a new unique name.';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      modelName: '',
    },
    validate,
    onSubmit: ({ setSubmitting }) => {
      updateSelectedModel();
      setOpenEditModal(true);
      setOpenDuplicateModal(false);
      setSubmitting(false);
    },
  });

  return (
    <Modal
      open={openDuplicateModal}
      sx={theme => ({
        maxWidth: '24%',
        height: 'max-content',
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
          maxWidth: '24%',
        },
        [theme.breakpoints.down('lg')]: {
          maxWidth: '35%',
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '50%',
        },
      })}
    >
      <Grid
        sx={{
          color: '#fff',
          border: '2px solid #62647A',
          padding: '1rem',
          background: '#040d3e',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Grid
          sx={{
            fontSize: '1.2rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.6rem',
          }}
        >
          Duplicate Model
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenDuplicateModal(false);
            }}
          >
            <CloseIcon />
          </span>
        </Grid>

        <Grid
          sx={{
            paddingLeft: '1.5rem',
          }}
        >
          <Typography>Please chose a new unique name for this model.</Typography>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box
              item
              xs={12}
              sx={theme => ({
                width: '90%',
                padding: '0.7rem 1rem 0.5rem 1rem',
                [theme.breakpoints.down('lg')]: {
                  padding: '0.3rem 0.6rem 0.063rem 0.6rem',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '0.3rem 0.6rem 0.06rem 0.6rem',
                },
              })}
            >
              <Input
                id="standard-adornment-weight"
                name="modelName"
                placeholder="Model Name"
                value={formik.values.modelName}
                autoFocus
                onChange={e => {
                  formik.setFieldValue('modelName', e.target.value);
                  setDuplicateModalName(e.target.value);
                }}
                sx={theme => ({
                  width: '100%',
                  color: '#FFF',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '0.7rem',
                  },
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '1.25rem',
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '0.75rem',
                  },
                })}
              />
              <div
                style={{
                  textAlign: 'right',
                  color: 'red',
                  padding: '5px 5px 0 0',
                  width: '100%',
                  fontSize: '0.7rem',
                }}
              >
                {formik.errors.modelName}
              </div>
            </Box>

            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '1rem',
              }}
            >
              <Button
                sx={{
                  borderRadius: '0.5rem !important',
                  border: '1px solid #3FD2F2 !important',
                  color: 'white',
                  background: 'black !important',
                  paddingX: '1.5rem !important',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  setOpenDuplicateModal(false);
                }}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  borderRadius: '0.5rem !important',
                  border: '1px solid #3FD2F2 !important',
                  color: 'white',
                  backgroundColor: '#0072F8 !important',
                  paddingX: '1.8rem !important',
                  textTransform: 'capitalize',
                }}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

export default DuplicateModal;
