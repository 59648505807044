import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { round, get } from 'lodash';
import { getSparkInstanceMetrics } from 'actions/sparkAction';
import { formatNumberWithLocale } from 'utils/common';
import Metrics from './Metrics';

const SparkMetrics = props => {
  const { modelId, modelIdOfApplyModel } = props;
  const dispatch = useDispatch();
  const modelData = useSelector(state => state.spark.sparkModelDetail);
  const isGetSparkMetricsDataLoading = useSelector(state => state.spark.isGetSparkMetricsDataLoading);

  useEffect(() => {
    if (modelId || modelIdOfApplyModel) {
      dispatch(getSparkInstanceMetrics(modelId || modelIdOfApplyModel));
    }
  }, []);

  return (
    <>
      {isGetSparkMetricsDataLoading ? (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid margin="1rem 0 5rem 0">
          <Metrics
            title={'Ongoing'}
            text1={
              get(modelData, 'ongoing.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'ongoing.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'ongoing.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'ongoing.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'ongoing.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'ongoing.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'ongoing.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'ongoing.totalTrades', 0)}
          />
          <Metrics
            title={'24-Hours'}
            text1={
              get(modelData, 'twentyFourHourAgo.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'twentyFourHourAgo.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'twentyFourHourAgo.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'twentyFourHourAgo.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'twentyFourHourAgo.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'twentyFourHourAgo.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'twentyFourHourAgo.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'twentyFourHourAgo.totalTrades', 0)}
          />
          <Metrics
            title={'3-Day'}
            text1={
              get(modelData, 'tradesOfLastThreeDays.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastThreeDays.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'tradesOfLastThreeDays.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'tradesOfLastThreeDays.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastThreeDays.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'tradesOfLastThreeDays.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'tradesOfLastThreeDays.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'tradesOfLastThreeDays.totalTrades', 0)}
          />
          <Metrics
            title={'7-Day'}
            text1={
              get(modelData, 'tradesOfLastSevenDays.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastSevenDays.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'tradesOfLastSevenDays.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'tradesOfLastSevenDays.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastSevenDays.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'tradesOfLastSevenDays.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'tradesOfLastSevenDays.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'tradesOfLastSevenDays.totalTrades', 0)}
          />
          <Metrics
            title={'1-Month'}
            text1={
              get(modelData, 'tradesOfLastOneMonth.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastOneMonth.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'tradesOfLastOneMonth.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'tradesOfLastOneMonth.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'tradesOfLastOneMonth.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'tradesOfLastOneMonth.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'tradesOfLastOneMonth.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'tradesOfLastOneMonth.totalTrades', 0)}
          />
          <Metrics
            title={'All-Time'}
            text1={
              get(modelData, 'allTime.initialFund', 0) &&
              formatNumberWithLocale(round(get(modelData, 'allTime.initialFund'), 2), 'decimal')
            }
            text2={round(get(modelData, 'allTime.profitPrOnTotalTrade', 0), 2)}
            total={
              get(modelData, 'allTime.finalCapital', 0) &&
              formatNumberWithLocale(round(get(modelData, 'allTime.finalCapital'), 2), 'decimal')
            }
            winValue={`${round(get(modelData, 'allTime.winingTrades', 0), 2)}%`}
            rrrValue={round(get(modelData, 'allTime.riskRewardRatio', 0), 2)}
            trdsValue={get(modelData, 'allTime.totalTrades', 0)}
          />
        </Grid>
      )}
    </>
  );
};
export default SparkMetrics;
