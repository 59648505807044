import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Radio,
  TextField,
  SvgIcon,
  CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import moment from 'moment';
import { map, trim, head, split, get, isEmpty, upperFirst, includes, find, isEqual, filter, last } from 'lodash';
import { toast } from 'react-toastify';
import runButton from 'assets/btnicons/run.svg';
import resetButton from 'assets/btnicons/reset.svg';
import { bulkModel } from 'actions/ActionTypes';
import { getFrdData } from 'actions/classificationAction';
import SparkModal from './SparkModal';
import { makeApiRequest } from '../chart/helpers';

function CloseIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.6em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
      </svg>
    </SvgIcon>
  );
}

const BulkModal = ({
  setOpenBulkModal,
  openBulkModal,
  allModel,
  setValue,
  openEditBulkModel,
  setOpenEditBulkModel,
}) => {
  const dispatch = useDispatch();
  const exchangesData = useSelector(state => state.classification.exchanges);
  const cryptoPairsData = useSelector(state => state.sim.cryptopairs);
  const isRunLoading = useSelector(state => state.sim.pending);
  const [isBlur, setIsBlur] = useState({ isCoin: false, isDataSource: false });
  const [openAbandonModalFromBulkModel, setOpenAbandonModalFromBulkModel] = useState(false);
  const modelDetail = useSelector(state => state.sim.modelDetail);
  const cryptoList = useSelector(state => state.classification.cryptoList);
  const frdData = useSelector(state => state.classification.frdData);
  // :::::::: Needed in Future ::::::
  // const isGetFrdDataLoading = useSelector(state => state.classification.isGetFrdDataLoading);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedDataSource, setSelectedDataSource] = useState({});
  const coinTextFsym = trim(head(split(get(selectedCoin, 'label', ''), '-')));
  const coinTextTsym = trim(last(split(get(selectedCoin, 'label', ''), '-')));
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  const dataSourceOptions = map(exchangesData, item => {
    return {
      label: upperFirst(item.name),
      value: item.id,
    };
  });

  const coinOptions = map(cryptoPairsData, item => {
    return {
      label: `${item.primaryCrypto.symbol} - ${item.currency.name}`,
      value: item.id,
    };
  });

  const fetchAvailableDates = async () => {
    const startDate = '2005-01-01';
    const endDate = new Date().toISOString().split('T')[0];
    try {
      const startTimestamp = new Date(startDate).getTime() / 1000;
      const endTimestamp = new Date(endDate).getTime() / 1000;
      const response = await makeApiRequest(
        `data/histoday?fsym=${coinTextFsym}&tsym=${coinTextTsym}&limit=2000&aggregate=1&allData=true&fromTs=${startTimestamp}&toTs=${endTimestamp}`,
      );

      // Extract dates from the response where data is available
      const datesWithData = response.Data.map(entry => new Date(entry.time * 1000).toDateString());
      setAvailableDates(datesWithData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const isDateDisabled = date => {
    const dateString = date.toDateString();
    return !availableDates.includes(dateString);
  };

  // :::::::: Needed in Future ::::::  Used for Date Validation for Frd
  // useEffect(() => {
  //   if (selectedStartDate && selectedEndDate && selectedDataSource?.label === 'FRD TOP50cc') {
  //     const bodyData = {
  //       dateFrom: moment(selectedStartDate).format(),
  //       dateTo: moment(selectedEndDate).format(),
  //       cryptoName: coinTextFsym,
  //     };
  //     dispatch(getFrdData(bodyData));
  //   }
  // }, [selectedStartDate, selectedEndDate, selectedDataSource]);

  useEffect(() => {
    if (
      !isEmpty(selectedDataSource) &&
      !isEmpty(selectedCoin) &&
      (selectedDataSource?.label === 'Live Coinbase' ||
        selectedDataSource?.label === 'Live Binance' ||
        selectedDataSource?.label === 'Coinbase' ||
        selectedDataSource?.label === 'Binance')
    ) {
      fetchAvailableDates();
    }
  }, [selectedDataSource, selectedCoin]);

  useEffect(() => {
    if (!isEmpty(frdData) && selectedDataSource?.label === 'FRD TOP50cc') {
      const datesWithData = map(frdData, item => moment(item.openTime).format('ddd MMM DD YYYY'));
      setAvailableDates(datesWithData);
    }
  }, [frdData]);

  const validate = values => {
    const errors = {};
    if (!values.coins) {
      errors.coins = '*required';
    }
    if (!values.dataSource) {
      errors.dataSource = '*required';
    }
    if (values.increment && values.increment < 0) {
      errors.increment = 'invalid number';
    }
    if (!values.increment) {
      errors.increment = '*required';
    }
    if (values.rangeStart && values.rangeStart < 0) {
      errors.rangeStart = 'invalid number';
    }
    if (!values.rangeStart) {
      errors.rangeStart = '*required';
    }

    if (values.rangeEnd && values.rangeEnd < 0) {
      errors.rangeEnd = 'invalid number';
    } else if (!values.rangeEnd) {
      errors.rangeEnd = '*required';
    } else if (values.rangeStart && values.rangeStart && values.rangeStart >= values.rangeEnd) {
      errors.rangeEnd = 'must be greater than range';
    } else if (
      values.range &&
      !isEmpty(availableDates) &&
      selectedDataSource?.label !== 'FRD TOP50cc' &&
      !includes(availableDates, moment(values.endDate).format('ddd MMM DD YYYY'))
    ) {
      errors.endDate = 'Data is not available for this date, please choose another date.';
    }

    if (values.range && (!values.startDate || values.startDate === 'Invalid date')) {
      errors.startDate = '*required';
    } else if (
      values.range &&
      !isEmpty(availableDates) &&
      selectedDataSource?.label !== 'FRD TOP50cc' &&
      !includes(availableDates, moment(values.startDate).format('ddd MMM DD YYYY'))
    ) {
      errors.startDate = 'Data is not available for this date, please choose another date.';
    }

    if (values.range && (!values.endDate || values.endDate === 'Invalid date')) {
      errors.endDate = '*required';
    } else if (values.startDate && values.endDate && values.startDate >= values.endDate) {
      errors.endDate = 'must be greater than start date';
    }
    if (!values.modelName === '' || !values.modelName) {
      errors.modelName = '*required';
    } else if (trim(values.modelName).length <= 5) {
      errors.modelName = 'Name should be longer than 5 characters, please enter a longer name.';
    } else if (values.modelName && find(allModel, ele => ele.name === values.modelName)) {
      errors.modelName = 'Name already exists, please enter a new unique name.';
    }

    if (!values.increment) {
      errors.increment = '*required';
    }
    return errors;
  };

  const createBulkModel = useCallback(data => {
    dispatch({
      type: bulkModel.CREATE_BULK_MODEL,
      payload: data,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            setOpenBulkModal(false);
            setValue(0);
            toast.success('Bulk Model created successfully');
          }
        }
      },
    });
  }, []);

  const initialValues =
    openEditBulkModel && !isEmpty(modelDetail)
      ? {
          modelName: modelDetail.name,
          dataSource: {
            label: get(
              find(dataSourceOptions, data => modelDetail.exchangeId === data.value),
              'label',
              '',
            ),
            value: get(
              find(dataSourceOptions, data => modelDetail.exchangeId === data.value),
              'value',
              '',
            ),
          },
          coins: {
            label: get(
              find(coinOptions, data => modelDetail.pairId === data.value),
              'label',
              '',
            ),
            value: get(
              find(coinOptions, data => modelDetail.pairId === data.value),
              'value',
              '',
            ),
          },
          allAvailable: modelDetail.backTestAnalysisDateType === 'allAvailable',
          range: modelDetail.backTestAnalysisDateType === 'range',
          startDate: modelDetail.dateFrom,
          endDate: modelDetail.dateTo,
          increment: modelDetail.increment,
          rangeStart: modelDetail.low,
          rangeEnd: modelDetail.high,
        }
      : {
          modelName: '',
          coins: null,
          dataSource: null,
          allAvailable: true,
          range: false,
          rangeStart: '',
          quantity: '',
          startDate: null,
          endDate: null,
          increment: '',
          rangeEnd: '',
        };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: ({ setSubmitting }) => {
      const backTestAnalysisDateType = formik.values.allAvailable ? 'allAvailable' : 'range';

      const bulkModelData = {
        name: formik.values.modelName,
        exchangeId: formik.values.dataSource.value,
        pairId: formik.values.coins.value,
        dateFrom: formik.values.range
          ? formik.values.startDate
            ? moment(formik.values.startDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment().subtract(1, 'months'),
        dateTo: formik.values.range
          ? formik.values.endDate
            ? moment(formik.values.endDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment(new Date()).format(),
        low: formik.values.rangeStart,
        high: formik.values.rangeEnd,
        increment: formik.values.increment,
        backTestAnalysisDateType,
      };

      createBulkModel(bulkModelData);
      setSubmitting(false);
    },
  });

  return (
    <Modal open={openBulkModal}>
      <Box
        sx={theme => ({
          color: '#fff',
          padding: '1rem',
          background: '#040d3e',
          maxWidth: '23%',
          margin: 'auto',
          marginTop: '1rem',
          [theme.breakpoints.up('lg')]: {
            maxWidth: '23%',
          },
          [theme.breakpoints.down('lg')]: {
            maxWidth: '38%',
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: '40%',
          },
        })}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              fontSize: '1.2rem',
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '0.02rem',
            }}
          >
            <span>{`${openEditBulkModel && !isEmpty(modelDetail) ? 'Edit' : 'New'} Bulk Model`}</span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (openBulkModal && !isEqual(initialValues, formik.values)) {
                  setOpenAbandonModalFromBulkModel(true);
                } else {
                  setOpenBulkModal(false);
                }
              }}
            >
              <CloseIcon />
            </span>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            padding="0.625rem"
            sx={theme => ({
              [theme.breakpoints.down('lg')]: {
                padding: '0.313rem',
              },
              [theme.breakpoints.down('md')]: {
                marginTop: '0.100rem',
              },
            })}
          >
            <>
              <Grid item xs={12}>
                <Input
                  id="standard-adornment-weight"
                  name="modelName"
                  autoFocus
                  placeholder="Model Name"
                  onBlur={formik.handleBlur}
                  value={formik.values.modelName}
                  onChange={formik.handleChange}
                  sx={theme => ({
                    width: '100%',
                    color: '#FFF',
                    paddingLeft: '2rem',
                    [theme.breakpoints.down('md')]: { fontSize: '0.688rem' },
                    [theme.breakpoints.up('lg')]: {
                      fontSize: '1.25rem',
                    },
                    [theme.breakpoints.down('lg')]: {
                      fontSize: '0.75rem',
                    },
                  })}
                />
                {formik.errors.modelName && formik.touched.modelName ? (
                  <div
                    style={{
                      textAlign: 'right',
                      color: 'red',
                      padding: '0.313rem 0.313rem 0 0',
                      fontSize: '0.625rem',
                    }}
                  >
                    {formik.errors.modelName}
                  </div>
                ) : null}
                <Grid sx={{ paddingTop: '0.8rem' }}>
                  <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Model Conditions</label>
                </Grid>
              </Grid>
            </>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="coins"
                name="coins"
                options={coinOptions}
                size="small"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 2,
                }}
                value={formik.values.coins}
                onChange={(e, value) => {
                  formik.setFieldValue('coins', value);
                  setSelectedCoin(value);
                  if (selectedCoin) {
                    formik.setFieldValue('dataSource', '');
                    formik.setFieldValue('endDate', null);
                    formik.setFieldValue('startDate', null);
                    setSelectedStartDate('');
                    setSelectedEndDate('');
                  }
                }}
                onBlur={() => {
                  setIsBlur({ ...isBlur, isCoin: true });
                }}
                inputValue={formik.values.coins ? formik.values.coins.label : ''}
                renderInput={params => (
                  <TextField
                    sx={theme => ({
                      svg: {
                        color: '#31EABD',
                      },
                      input: {
                        color: '#FFF',
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '0.875rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '0.75rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.688rem',
                        },
                      },
                    })}
                    {...params}
                    placeholder="Coins-Token"
                  />
                )}
              />
            </Grid>
            {formik.errors.coins && isBlur.isCoin ? (
              <div
                style={{
                  textAlign: 'right',
                  color: 'red',
                  padding: '0.313rem 0.313rem 0 0',
                  width: '100%',
                  fontSize: '0.625rem',
                }}
              >
                {formik.errors.coins}
              </div>
            ) : null}
            {formik.values.coins && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={!formik.values.coins}
                    disablePortal
                    id="datasource"
                    name="dataSource"
                    options={
                      includes(cryptoList, trim(head(split(formik.values.coins.label, '-'))))
                        ? map(exchangesData, item => {
                            return {
                              label: item.name === 'FRD TOP50cc' ? item.name : `Live ${upperFirst(item.name)}`,
                              value: item.id,
                            };
                          })
                        : filter(dataSourceOptions, item => item.label !== 'FRD TOP50cc')
                    }
                    size="small"
                    sx={{
                      backgroundColor: 'black',
                      borderRadius: 2,
                    }}
                    onBlur={() => {
                      setIsBlur(prevState => ({
                        ...prevState,
                        isDataSource: true,
                      }));
                    }}
                    value={formik.values.dataSource}
                    onChange={(e, value) => {
                      formik.setFieldValue('dataSource', value);
                      setSelectedDataSource(value);
                    }}
                    renderInput={params => (
                      <TextField
                        sx={theme => ({
                          svg: {
                            color: '#31EABD',
                          },
                          input: {
                            color: '#FFF',
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '0.875rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: '0.75rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: '0.688rem',
                            },
                          },
                        })}
                        {...params}
                        placeholder="Data Source"
                      />
                    )}
                  />
                </Grid>

                {formik.errors.dataSource && isBlur.isDataSource ? (
                  <div
                    style={{
                      textAlign: 'right',
                      width: '100%',
                      color: 'red',
                      padding: '0.313rem 0.313rem 0 0',
                      fontSize: '0.625rem',
                    }}
                  >
                    {formik.errors.dataSource}
                  </div>
                ) : null}
              </>
            )}
            <Grid item xs={12}>
              <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Date Range</label>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: '0.313rem !important',
              }}
            >
              <Radio
                checked={formik.values.allAvailable}
                name="allAvailable"
                value={formik.values.allAvailable}
                onChange={(e, value) => {
                  formik.setFieldValue('allAvailable', value);
                  formik.setFieldValue('range', !value);
                }}
                sx={{
                  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                    color: '#404878',
                  },
                }}
              />
              <label style={{ color: '#B1AFCD', fontWeight: '700' }}>All Available</label>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                paddingLeft: '0.313rem !important',
              }}
            >
              <Grid item lg={3} xs={12} md={12} sm={12}>
                <Radio
                  checked={formik.values.range}
                  value={formik.values.range}
                  onChange={value => {
                    formik.setFieldValue('range', value);
                    formik.setFieldValue('allAvailable', !value);
                  }}
                  name="range"
                  sx={{
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                      color: '#404878',
                    },
                  }}
                />
                <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Range</label>
              </Grid>
              {formik.values.range && (
                <Grid item lg={9} xs={5} md={5} sm={5} sx={{ display: 'flex' }}>
                  <Grid
                    item
                    xs={5}
                    md={5}
                    sm={5}
                    sx={theme => ({
                      '&.MuiGrid-item': {
                        paddingTop: '0rem !important',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                      },
                      [theme.breakpoints.down('lg')]: {
                        display: 'flex',
                      },
                      [theme.breakpoints.down('md')]: {
                        display: 'flex',
                      },
                    })}
                  >
                    <DatePicker
                      label=""
                      shouldDisableDate={selectedDataSource?.label !== 'FRD TOP50cc' && isDateDisabled}
                      value={formik.values.startDate}
                      name="startDate"
                      maxDate={
                        selectedDataSource?.label === 'FRD TOP50cc' ? moment().subtract(2, 'days').toDate() : new Date()
                      }
                      minDate={
                        selectedDataSource?.label !== 'FRD TOP50cc'
                          ? moment().subtract(6, 'days')
                          : new Date(2005, 0, 1)
                      }
                      onChange={e => {
                        formik.setFieldValue('startDate', e);
                        setSelectedStartDate(e);
                      }}
                      onBlur={() => formik.setFieldTouched('startDate', true)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          sx={theme => ({
                            [theme.breakpoints.up('lg')]: {
                              marginLeft: '0rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              marginLeft: '1rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              marginLeft: '1rem',
                            },
                            svg: {
                              color: '#54DAFF',
                            },
                            input: {
                              color: 'white',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.75rem',
                                paddingRight: '0rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                                paddingRight: '0rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                                paddingRight: '0rem',
                              },
                            },
                          })}
                        />
                      )}
                      InputAdornmentProps={{
                        position: 'start',
                        color: '#54DAFF',
                      }}
                    />

                    {formik.errors.startDate ? (
                      <div
                        style={{
                          textAlign: 'right',
                          width: '100%',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={2}
                    sm={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '0 !important',
                      '&.MuiGrid-item': {
                        paddingTop: '0rem !important',
                      },
                    }}
                  >
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>to</label>
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    md={5}
                    sm={5}
                    sx={theme => ({
                      '&.MuiGrid-item': {
                        paddingTop: '0rem !important',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                      },
                      [theme.breakpoints.down('lg')]: {
                        display: 'flex',
                      },
                      [theme.breakpoints.down('md')]: {
                        display: 'flex',
                      },
                    })}
                  >
                    <DatePicker
                      name="endDate"
                      shouldDisableDate={selectedDataSource?.label !== 'FRD TOP50cc' && isDateDisabled}
                      value={formik.values.endDate}
                      maxDate={
                        selectedDataSource?.label === 'FRD TOP50cc' ? moment().subtract(2, 'days').toDate() : new Date()
                      }
                      minDate={
                        selectedDataSource?.label !== 'FRD TOP50cc'
                          ? formik.values.startDate
                            ? formik.values.startDate
                            : moment().subtract(6, 'days')
                          : formik.values.startDate
                      }
                      onChange={e => {
                        formik.setFieldValue('endDate', e);
                        setSelectedEndDate(e);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={params => (
                        <TextField
                          {...params}
                          sx={theme => ({
                            [theme.breakpoints.up('lg')]: {
                              margin: '0',
                            },
                            svg: {
                              color: '#54DAFF',
                            },
                            input: {
                              color: 'white',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.75rem',
                                paddingRight: '0rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                                paddingRight: '0rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                                paddingRight: '0rem',
                              },
                            },
                          })}
                        />
                      )}
                      InputAdornmentProps={{ position: 'start' }}
                    />

                    {formik.errors.endDate ? (
                      <div
                        style={{
                          textAlign: 'right',
                          width: '100%',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {formik.values.allAvailable === false && formik.values.range === false && (
              <div>{formik.errors.allAvailable}</div>
            )}
            <Grid item xs={12}>
              <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Percentage Range</label>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                paddingLeft: '0.313rem !important',
              }}
            >
              <Grid
                item
                lg={3}
                xs={12}
                md={12}
                sm={12}
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '1.5rem',
                  [theme.breakpoints.down('lg')]: {
                    display: 'flex',
                    justifyContent: 'start',
                    paddingBottom: '0.8rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '1em',
                  },
                })}
              >
                <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Range</label>
              </Grid>

              <Grid
                item
                lg={4}
                xs={5}
                md={5}
                sm={5}
                sx={theme => ({
                  '&.MuiGrid-item': {
                    paddingTop: '0rem !important',
                  },
                  [theme.breakpoints.up('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                  },
                })}
              >
                <TextField
                  id="rangeStart"
                  name="rangeStart"
                  size="small"
                  type="number"
                  value={formik.values.rangeStart}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#FFFFFF',
                      },
                    },
                  }}
                  sx={theme => ({
                    input: {
                      color: '#FFF',
                      backgroundColor: '#000',
                      border: '0.063rem solid grey',
                      borderRadius: '0.313rem',
                      [theme.breakpoints.up('lg')]: {
                        fontSize: '0.875rem',
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.75rem',
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.688rem',
                      },
                    },
                  })}
                />
              </Grid>
              <Grid
                item
                lg={1}
                xs={2}
                md={2}
                sm={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '0 !important',
                  '&.MuiGrid-item': {
                    paddingTop: '0rem !important',
                  },
                }}
              >
                <label style={{ color: '#B1AFCD', fontWeight: '700' }}>to</label>
              </Grid>
              <Grid
                item
                lg={4}
                xs={5}
                md={5}
                sm={5}
                sx={theme => ({
                  '&.MuiGrid-item': {
                    paddingTop: '0rem !important',
                  },
                  [theme.breakpoints.up('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                  },
                })}
              >
                <TextField
                  id="rangeEnd"
                  name="rangeEnd"
                  size="small"
                  type="number"
                  value={formik.values.rangeEnd}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#FFFFFF',
                      },
                    },
                  }}
                  sx={theme => ({
                    input: {
                      color: '#FFF',
                      backgroundColor: '#000',
                      border: '0.063rem solid grey',
                      borderRadius: '0.313rem',
                      [theme.breakpoints.up('lg')]: {
                        fontSize: '0.875rem',
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.75rem',
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.688rem',
                      },
                    },
                  })}
                />
              </Grid>

              {formik.errors.rangeStart && formik.touched.rangeStart ? (
                <Box
                  sx={theme => ({
                    color: 'red',
                    fontSize: '0.625rem',
                    justifyContent: 'center',
                    display: 'flex',
                    padding: '0 0 0 11.5rem',
                    [theme.breakpoints.up('lg')]: {
                      padding: '0 0 0 11.5rem',
                    },
                    [theme.breakpoints.down('lg')]: {
                      padding: '0 0 0 6.5rem',
                    },
                    [theme.breakpoints.up('md')]: {
                      padding: '0 0 0 4.5rem',
                    },
                    [theme.breakpoints.down('md')]: {
                      padding: '0 0 0 4.5rem',
                    },
                  })}
                >
                  {formik.errors.rangeStart}
                </Box>
              ) : null}

              {formik.errors.rangeEnd && formik.touched.rangeEnd ? (
                <Box
                  sx={theme => ({
                    display: 'flex',
                    color: 'red',
                    fontSize: '0.625rem',
                    paddingLeft: formik.errors.rangeStart ? '8rem' : '22rem',
                    [theme.breakpoints.up('lg')]: {
                      paddingLeft: formik.errors.rangeStart ? '8rem' : '22rem',
                    },
                    [theme.breakpoints.down('lg')]: {
                      padding: '0 0 0 9.9rem',
                    },
                    [theme.breakpoints.down('md')]: {
                      padding: '0 0 0 6.9rem',
                    },
                  })}
                >
                  {formik.errors.rangeEnd}
                </Box>
              ) : null}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={theme => ({
                paddingLeft: '0.313rem !important',
                display: 'flex',
                justifyContent: 'end',
                [theme.breakpoints.up('lg')]: {
                  display: 'flex',
                },
                [theme.breakpoints.down('lg')]: {
                  paddingRight: '0rem',
                },
                [theme.breakpoints.down('md')]: {
                  paddingRight: '0rem',
                },
              })}
            >
              <Grid
                item
                lg={1}
                xs={2}
                md={2}
                sm={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  paddingTop: '0 !important',
                  '&.MuiGrid-item': {
                    paddingTop: '0rem !important',
                  },
                }}
              >
                <label
                  style={{
                    color: '#B1AFCD',
                    fontWeight: '700',
                    paddingRight: '0.625rem',
                  }}
                >
                  Increment
                </label>
              </Grid>
              <Grid
                item
                lg={4}
                xs={5}
                md={5}
                sm={5}
                sx={theme => ({
                  '&.MuiGrid-item': {
                    paddingTop: '0rem !important',
                  },
                  [theme.breakpoints.up('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('lg')]: {
                    display: 'flex',
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                  },
                })}
              >
                <TextField
                  id="increment"
                  name="increment"
                  size="small"
                  type="number"
                  value={formik.values.increment}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#FFFFFF',
                      },
                    },
                  }}
                  sx={theme => ({
                    input: {
                      color: '#FFF',
                      backgroundColor: '#000',
                      border: '0.063rem solid grey',
                      borderRadius: '0.313rem',
                      [theme.breakpoints.up('lg')]: {
                        fontSize: '0.875rem',
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '0.75rem',
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '0.688rem',
                      },
                    },
                  })}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              spacing={2}
              marginTop="0.125rem"
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0rem 0rem 0rem 0.938rem !important',
              }}
            >
              {formik.errors.increment && formik.touched.increment ? (
                <div
                  style={{
                    textAlign: 'right',
                    color: 'red',
                    padding: '0.313rem 0.313rem 0 0',
                    width: '100%',
                    fontSize: '0.625rem',
                  }}
                >
                  {formik.errors.increment}
                </div>
              ) : null}
            </Grid>
            <Grid
              container
              xs={12}
              marginTop="0.125rem !important"
              sx={theme => ({
                width: '100%',
                padding: '0.625rem 0rem 0rem 0.938rem !important',
                [theme.breakpoints.down('lg')]: {
                  flexWrap: 'nowrap',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '0.5rem 0rem 0rem 1.063rem !important',
                },
              })}
            >
              <Grid item sm={12} md={12} lg={6} sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  // :::::::: Needed in Future ::::::
                  // disabled={openBulkModal && (!formik.isValid || !formik.dirty || isGetFrdDataLoading)}
                  disabled={openBulkModal && (!formik.isValid || !formik.dirty)}
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed  !important',
                      pointerEvents: 'all !important',
                      backgroundColor: 'grey  !important',
                      color: 'white  !important',
                      border: 'none !important',
                      padding: '0.4rem !important',
                    },
                    color: '#FFFFFF  !important',
                    backgroundColor: '#000000  !important',
                    border: '0.125rem solid #3FD2F2  !important',
                    borderRadius: '3px !important',
                    width: '98%',
                    padding: '0.3rem !important',
                    textTransform: 'unset !important',
                  }}
                  startIcon={!isRunLoading && <img src={runButton} width={15} height={15} alt={''} />}
                  type="submit"
                >
                  {/* :::::::: Needed in Future :::::: */}
                  {/* {isRunLoading || isGetFrdDataLoading ? ( */}
                  {isRunLoading ? (
                    <CircularProgress size={26} />
                  ) : openEditBulkModel && !isEmpty(modelDetail) ? (
                    'Update'
                  ) : (
                    'Run'
                  )}
                </Button>
              </Grid>

              <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={theme => ({
                  width: '100%',
                  [theme.breakpoints.down('lg')]: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    justifyContent: 'centre',
                  },
                })}
              >
                <Button
                  variant="outlined"
                  onClick={() => formik.resetForm()}
                  sx={theme => ({
                    [theme.breakpoints.down('lg')]: {
                      width: '90%',
                    },
                    [theme.breakpoints.down('md')]: {
                      width: '90%',
                    },
                    color: '#FFFFFF !important',
                    backgroundColor: '#000000 !important',
                    border: '0.125rem solid #3FD2F2 !important',
                    width: '95%',
                    padding: '0.3rem !important',
                    textTransform: 'unset !important',
                  })}
                  startIcon={<img src={resetButton} width={15} height={15} alt={'resetButton'} />}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <SparkModal
          openAbandonModalFromBulkModel={openAbandonModalFromBulkModel}
          setOpenAbandonModalFromBulkModel={setOpenAbandonModalFromBulkModel}
          setOpenBulkModal={setOpenBulkModal}
        />
      </Box>
    </Modal>
  );
};

export default BulkModal;
