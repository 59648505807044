import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Drawer } from '@mui/material';
import classNames from 'classnames';
import InstancesPage from './InstancesPage';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import doublearrow from 'assets/doublearrow.svg';
import { isModelApplied } from 'actions/sparkAction';
import { isEmpty } from 'lodash';

const SparkInstances = props => {
  const {
    classes,
    open,
    handleDrawerOpen,
    showInstanceDetailPage,
    setShowInstanceDetailPage,
    setShowModelSelectionScreen,
    showModelSelectionScreen,
    selectedSparkModelData,
    setSelectedSparkModelData,
    modelIdOfApplyModel,
    setModelIdOfApplyModel,
    dataFromApplyModel,
  } = props;

  const dispatch = useDispatch();

  const [onClick, setOnClick] = useState(false);
  const [value, setValue] = useState(1);
  const [selectedModelIdFromModelSelection, setSelectedModelIdFromModelSelection] = useState('');

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="right"
        className={classNames(classes?.drawer, {
          [classes?.drawerOpen]: open,
          [classes?.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes?.drawerOpen]: open,
            [classes?.drawerClose]: !open,
            [classes?.overFlowHidden]: showInstanceDetailPage,
          }),
        }}
        open={open}
      >
        <Box
          sx={{
            border: 1,
            height: open ? 'auto' : '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#040d3e',
          }}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 700,
              padding: '1rem',
              color: '#ffff',
              fontSize: '0.8rem',
              borderBottom: open ? '1px solid #FFF' : '',
              borderTop: open ? '1px solid #FFF' : '',
              [theme.breakpoints.down('md')]: {
                paddingLeft: '0.5rem',
              },
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                fontSize: '1rem',
                color: '#B1AFCD',
              }}
            >
              <img
                src={doublearrow}
                width={20}
                height={20}
                className={!open ? classes.imageRotate : ''}
                onClick={() => {
                  handleDrawerOpen();
                  !isEmpty(dataFromApplyModel) &&
                    dispatch(
                      isModelApplied({
                        ...dataFromApplyModel,
                        openSparkInstanceDrawer: false,
                      }),
                    );
                }}
              />
              <Box>
                {open
                  ? showInstanceDetailPage || showModelSelectionScreen || onClick
                    ? 'INSTANCE DETAILS'
                    : 'SPARK INSTANCES'
                  : ''}
              </Box>
            </Box>
            {open && (onClick || dataFromApplyModel?.openSparkInstanceTabSection || showInstanceDetailPage) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                  cursor: 'pointer',
                  color: 'white',
                }}
                onClick={() => {
                  setValue(1);
                  setOnClick(false);
                  setShowInstanceDetailPage(false);
                  setShowModelSelectionScreen(false);
                  setSelectedModelIdFromModelSelection('');
                  setModelIdOfApplyModel('');
                  dataFromApplyModel &&
                    dispatch(
                      isModelApplied({
                        ...dataFromApplyModel,
                        openSparkInstanceTabSection: false,
                        modelIdOfApplyModel: modelIdOfApplyModel,
                      }),
                    );
                }}
              >
                <Box sx={{ display: 'flex', fontSize: '0.9rem' }}>
                  <FaLongArrowAltLeft />
                </Box>
                <Box sx={{ fontSize: '0.9rem' }}>Go Back</Box>
              </Box>
            )}
          </Box>
          {open && (
            <InstancesPage
              setOnClick={setOnClick}
              onClick={onClick}
              value={value}
              setValue={setValue}
              setShowInstanceDetailPage={setShowInstanceDetailPage}
              showModelSelectionScreen={showModelSelectionScreen}
              setShowModelSelectionScreen={setShowModelSelectionScreen}
              selectedSparkModelData={selectedSparkModelData}
              setSelectedSparkModelData={setSelectedSparkModelData}
              selectedModelIdFromModelSelection={selectedModelIdFromModelSelection}
              setSelectedModelIdFromModelSelection={setSelectedModelIdFromModelSelection}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default SparkInstances;
