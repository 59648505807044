import { exchanges } from './ActionTypes';

export const fetchExchanges = () => ({
  type: exchanges.FETCH_EXCHANGES,
});

export const fetchExchangesSuccess = payload => ({
  type: exchanges.FETCH_EXCHANGES_SUCCESS,
  payload,
});

export const fetchExchangesFailure = payload => ({
  type: exchanges.FETCH_EXCHANGES_FAIL,
  payload,
});

export const getFrdCryptoList = () => ({
  type: exchanges.GET_FRD_CRYPTO_LIST,
});

export const getFrdCryptoListSuccess = payload => ({
  type: exchanges.GET_FRD_CRYPTO_LIST_SUCCESS,
  payload,
});

export const getFrdCryptoListFail = payload => ({
  type: exchanges.GET_FRD_CRYPTO_LIST_FAIL,
  payload,
});

export const getFrdData = payload => ({
  type: exchanges.GET_FRD_DATA,
  payload,
});

export const getFrdDataSuccess = payload => ({
  type: exchanges.GET_FRD_DATA_SUCCESS,
  payload,
});

export const getFrdDataFail = payload => ({
  type: exchanges.GET_FRD_DATA_FAIL,
  payload,
});
