import * as React from 'react';
import { Button, Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const DataLoadErrorModal = props => {
  const { openDataLoadErrorModal, setOpenDataLoadErrorModal } = props;

  const handleCloseModel = () => {
    setOpenDataLoadErrorModal(false);
    localStorage.setItem('showDataLoadErrorModel', JSON.stringify(false));
    const event = new Event('showDataLoadErrorModelChangedClosed');
    window.dispatchEvent(event);
  };

  return (
    <Modal
      open={openDataLoadErrorModal}
      sx={{
        maxWidth: '25%',
        height: 'max-content',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          color: '#fff',
          border: '0.125rem solid #62647A',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '4rem',
          background: '#040d3e',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontSize: '1.2rem !important',
            }}
          >
            Data Load Error
          </Typography>
          <span style={{ cursor: 'pointer' }} onClick={() => handleCloseModel()}>
            <CloseIcon />
          </span>
        </Box>

        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          The requested pricing data is not available.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around !important',
            paddingBottom: '2rem !important',
          }}
        >
          <Button
            sx={{
              borderRadius: '0.5rem !important',
              border: '1px solid #3FD2F2 !important',
              color: 'white !important',
              background: 'black !important',
              padding: '0.4rem 3rem !important',
            }}
            onClick={() => handleCloseModel()}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DataLoadErrorModal;
