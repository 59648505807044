import {
  sparkInstanceClearModels,
  sparkInstanceMetrics,
  sparkInstanceRunModels,
  sparkInstanceStopAllModels,
  sparkPromoteModel,
  sparkModels,
  navigation,
} from './ActionTypes';

export const getSparkModels = payload => {
  return {
    type: sparkModels.GET_SPARK_MODELS,
    payload,
  };
};

export const getSparkModelsSuccess = payload => ({
  type: sparkModels.GET_SPARK_MODELS_SUCCESS,
  payload,
});

export const getSparkModelsFail = payload => ({
  type: sparkModels.GET_SPARK_MODELS_FAIL,
  payload,
});

export const getSparkLimitedModels = payload => {
  return {
    type: sparkModels.GET_SPARK_LIMITED_MODELS,
    payload,
  };
};

export const getSparkLimitedModelsSuccess = payload => ({
  type: sparkModels.GET_SPARK_LIMITED_MODELS_SUCCESS,
  payload,
});

export const getSparkLimitedModelsFail = payload => ({
  type: sparkModels.GET_SPARK_LIMITED_MODELS_FAIL,
  payload,
});

export const sparkInstanceRunModelSuccess = payload => ({
  type: sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL_SUCCESS,
  payload,
});

export const sparkInstanceRunModelFail = payload => ({
  type: sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL_FAIL,
  payload,
});

export const sparkInstanceStopAllModelSuccess = payload => ({
  type: sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS_SUCCESS,
  payload,
});

export const sparkInstanceStopAllModelFail = payload => ({
  type: sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS_FAIL,
  payload,
});

export const getSparkInstanceMetrics = payload => ({
  type: sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS,
  payload,
});

export const getSparkInstanceMetricsSuccess = payload => ({
  type: sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS_SUCCESS,
  payload,
});

export const getSparkInstanceMetricsFail = payload => ({
  type: sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS_FAIL,
  payload,
});

export const sparkInstanceClearModelSuccess = payload => ({
  type: sparkInstanceClearModels.SPARK_INSTANCE_CLEAR_MODEL_SUCCESS,
  payload,
});

export const sparkInstanceClearModelFail = payload => ({
  type: sparkInstanceClearModels.SPARK_INSTANCE_CLEAR_MODEL_FAIL,
  payload,
});

export const sparkPromoteSuccess = data => {
  return {
    type: sparkPromoteModel.SPARK_PROMOTE_MODEL_SUCCESS,
    payload: data,
  };
};

export const sparkPromoteFailure = () => {
  return {
    type: sparkPromoteModel.SPARK_PROMOTE_MODEL_FAIL,
    payload,
  };
};

export const getSparkModalById = payload => ({
  type: sparkModels.GET_SPARK_MODEL_BY_ID,
  payload,
});

export const getSparkModalByIdSuccess = payload => ({
  type: sparkModels.GET_SPARK_MODEL_BY_ID_SUCCESS,
  payload,
});

export const getSparkModalByIdFail = payload => ({
  type: sparkModels.GET_SPARK_MODEL_BY_ID_FAIL,
  payload,
});

export const handleBeakerSuccess = payload => ({
  type: sparkModels.HANDLE_BEAKER_SUCCESS,
  payload,
});

export const handleBeakerFail = payload => ({
  type: sparkModels.HANDLE_BEAKER_FAIL,
  payload,
});

export const isModelApplied = payload => ({
  type: navigation.IS_MODEL_APPLIED,
  payload,
});
