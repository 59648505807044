import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { map } from 'lodash';
import useAuth from 'security/hooks/useAuth';
import { cookie } from 'helpers/cookies';
import { USER_ROLE } from 'constants/token';

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const accessToken = auth?.accessToken;
  const role = cookie.get(USER_ROLE.ROLE);
  var roles;

  // if (accessToken) {
  //     roles = JSON.parse(window.atob(auth.accessToken?.split(".")[1])).roles;
  // }
  if (accessToken && role) {
    roles = map(role, item => item.name);
  }

  return roles?.find(role => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth.accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
