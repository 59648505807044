import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getSparkInstanceMetricsFail,
  getSparkInstanceMetricsSuccess,
  sparkInstanceClearModelFail,
  sparkInstanceClearModelSuccess,
  sparkInstanceRunModelFail,
  sparkInstanceRunModelSuccess,
  sparkInstanceStopAllModelFail,
  sparkInstanceStopAllModelSuccess,
  sparkPromoteSuccess,
  sparkPromoteFailure,
  getSparkModalByIdSuccess,
  getSparkModalByIdFail,
  handleBeakerSuccess,
  handleBeakerFail,
  getSparkModelsFail,
  getSparkModelsSuccess,
  getSparkLimitedModelsFail,
  getSparkLimitedModelsSuccess,
} from 'actions/sparkAction';
import {
  sparkInstanceClearModels,
  sparkInstanceMetrics,
  sparkInstanceRunModels,
  sparkInstanceStopAllModels,
  sparkModels,
  sparkPromoteModel,
} from 'actions/ActionTypes';
import { API } from 'apis';

function* createSparkInstanceRunModel(action) {
  try {
    const response = yield call(API.createSparkInstanceRunModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(sparkInstanceRunModelSuccess(response.data.data));
    } else {
      yield put(
        sparkInstanceRunModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      sparkInstanceRunModelFail({
        error: e.message,
      }),
    );
  }
}

function* getSparkInstanceStopAllModel(action) {
  try {
    const response = yield call(API.getSparkInstanceStopAllModels, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        sparkInstanceStopAllModelSuccess({
          stopAllModels: response.data.data,
        }),
      );
    } else {
      yield put(
        sparkInstanceStopAllModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      sparkInstanceStopAllModelFail({
        error: e?.message,
      }),
    );
  }
}

function* getSparkInstanceMetrics(action) {
  try {
    const response = yield call(API.getSparkInstanceMetrics, action.payload);
    if (response.status === 200) {
      yield put(
        getSparkInstanceMetricsSuccess({
          sparkModelDetail: response.data.data,
        }),
      );
    } else {
      yield put(
        getSparkInstanceMetricsFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getSparkInstanceMetricsFail({
        error: e.message,
      }),
    );
  }
}

function* sparkInstanceClearModel(action) {
  try {
    const response = yield call(API.sparkInstanceClearModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        sparkInstanceClearModelSuccess({
          stopAllModels: response.data.data,
        }),
      );
    } else {
      yield put(
        sparkInstanceClearModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      sparkInstanceClearModelFail({
        error: e.message,
      }),
    );
  }
}

function* sparkPromoteSaga(action) {
  try {
    const response = yield call(API.sparkPromote, action.payload);
    action.cb && action.cb(response);

    if (response.status === 200) {
      yield put(
        sparkPromoteSuccess({
          sparkPromoteModel: response.data.sparkModel,
        }),
      );
    } else {
      yield put(
        sparkPromoteFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      sparkPromoteFailure({
        error: e.message,
      }),
    );
  }
}

function* getSparkModelById(action) {
  try {
    const response = yield call(API.getSparkModalById, action.payload);

    if (response.status === 200) {
      yield put(getSparkModalByIdSuccess(response.data));
    } else {
      yield put(
        getSparkModalByIdFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getSparkModalByIdFail({
        error: e.message,
      }),
    );
  }
}

function* handleBeaker(action) {
  try {
    const response = yield call(API.handleBeaker, action.payload);
    action.cb && action.cb(response);

    if (response.status === 200) {
      yield put(handleBeakerSuccess(response.data));
    } else {
      yield put(
        handleBeakerFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      handleBeakerFail({
        error: e.message,
      }),
    );
  }
}

function* getSparkModelsSaga(action) {
  try {
    const response = yield call(API.getSparkModels, action.payload);
    action.cb && action.cb(response);

    if (response.status === 200) {
      yield put(getSparkModelsSuccess(response.data.data));
    } else {
      yield put(
        getSparkModelsFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getSparkModelsFail({
        error: e.message,
      }),
    );
  }
}

function* getSparkLimitedModelsSaga(action) {
  try {
    const response = yield call(API.getSparkLimitedModels, action.payload);
    action.cb && action.cb(response);

    if (response.status === 200) {
      yield put(getSparkLimitedModelsSuccess(response.data.data));
    } else {
      yield put(
        getSparkLimitedModelsFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getSparkLimitedModelsFail({
        error: e.message,
      }),
    );
  }
}

function* sparkSaga() {
  yield all([takeLatest(sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL, createSparkInstanceRunModel)]);
  yield all([takeLatest(sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS, getSparkInstanceStopAllModel)]);
  yield all([takeLatest(sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS, getSparkInstanceMetrics)]);
  yield all([takeLatest(sparkInstanceClearModels.SPARK_INSTANCE_CLEAR_MODEL, sparkInstanceClearModel)]);
  yield all([takeLatest(sparkPromoteModel.SPARK_PROMOTE_MODEL, sparkPromoteSaga)]);
  yield all([takeLatest(sparkModels.GET_SPARK_MODEL_BY_ID, getSparkModelById)]);
  yield all([takeLatest(sparkModels.HANDLE_BEAKER, handleBeaker)]);
  yield all([takeLatest(sparkModels.GET_SPARK_MODELS, getSparkModelsSaga)]);
  yield all([takeLatest(sparkModels.GET_SPARK_LIMITED_MODELS, getSparkLimitedModelsSaga)]);
}

export default sparkSaga;
