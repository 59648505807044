// // Datafeed implementation, will be added later
import Datafeed from './datafeed.js';
import { models } from 'actions/ActionTypes';
import moment from 'moment';
import { isChartDrawingEdited } from 'actions/simAction';

export function initOnReady(modelSymbol, dispatch, chartDrawingData) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const startDate = moment(_.get(modelSymbol, 'startTime', ''));
  const endDate = moment(_.get(modelSymbol, 'endTime', ''));
  const modelType = localStorage.getItem('modelType');
  const duration = endDate.diff(startDate, 'days');
  const isSparkModel = modelType === 'sparkModel';
  const isBulkModel = modelSymbol?.modelType === 'bulkModel';

  window.tvWidget = new TradingView.widget({
    symbol:
      modelSymbol && !isBulkModel
        ? `${_.upperFirst(modelSymbol?.exchange?.name === 'binance' ? 'coinbase' : modelSymbol?.exchange?.name)}:` +
          modelSymbol?.pair?.primaryCrypto?.symbol.concat(['/'], modelSymbol?.pair?.currency?.name)
        : 'BULK MODEL',
    // 'Coinbase:BTC/USDT', // default symbol
    // symbol: 'Bitcoin:BTC/USD', // default symbol
    interval: 1, // default interval
    fullscreen: false, // displays the chart in the fullscreen mode
    container: 'tv_chart_container',
    datafeed: Datafeed,
    library_path: 'charting_library/',
    theme: 'Dark',
    width: '100%',
    disabled_features: ['use_localstorage_for_settings'],
    enabled_features: ['study_templates'],
    charts_storage_url: 'https://saveload.tradingview.com',
    charts_storage_api_version: '1.1',
    client_id: 'tradingview.com',
    user_id: 'public_user_id',
    timezone: 'US/Mountain',
    // timezone: timeZone === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZone,
    timeframe: !isSparkModel
      ? duration === 1
        ? '1D'
        : duration >= 2 && duration <= 30
        ? '1M'
        : duration > 30 && duration <= 90
        ? '3M'
        : '1Y'
      : '',
  });

  window.tvWidget.onChartReady(() => {
    // For: Prevent the scrolling
    const chartContainer = document.getElementById('tv_chart_container');
    if (chartContainer && modelSymbol?.modelType === 'bulkModel') {
      chartContainer.style.pointerEvents = 'none'; // Prevent interaction
    } else {
      chartContainer.style.pointerEvents = 'all';
    }

    try {
      if (chartDrawingData) {
        // Load the drawing data onto the chart
        window.tvWidget.load(chartDrawingData);
      } else {
        console.log('No chart drawing data available for the selected note.');
      }
    } catch (e) {
      console.log('Error loading chart drawing data:', e);
    }

    // Get the custom button element and add an event listener to trigger the save function
    const customSaveButton = document.getElementById('custom_save_button');
    customSaveButton.addEventListener('click', () => {
      // Export the chart's current state
      window.tvWidget.save(chartData => {
        dispatch({ type: models.CHART_DATA, payload: chartData });
      });
    });

    window.tvWidget.subscribe('drawing_event', data => {
      window.tvWidget.save(chartData => {
        const isChartDataChanged = !_.isEqual(chartDrawingData, chartData);
        // dispatch({
        //   type: models.IS_CHART_DRAWING_CHANGED,
        //   payload: isChartDataChanged,
        // });
        dispatch(isChartDrawingEdited(isChartDataChanged));
      });
    });
  });
}
