import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from 'security/hooks/useLogout';
import useAuth from 'security/hooks/useAuth';

const Home = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth();
  // const roles = JSON.parse(window.atob(auth.accessToken?.split(".")[1])).roles;

  const signOut = async () => {
    await logout();
    navigate('/login');
  };
  return <></>;
};

export default Home;
