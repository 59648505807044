import React, { useEffect } from 'react';
import { AxiosAuthClient } from '../clients/AxiosClient';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';

const useAxiosAuth = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = AxiosAuthClient.interceptors.request.use(
      config => {
        if (!config.headers[`Authorization`]) {
          config.headers[`Authorization`] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error),
    );
    const responseIntercept = AxiosAuthClient.interceptors.response.use(
      response => response,
      async error => {
        console.log(error.config);
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          console.log(JSON.stringify(prevRequest.header));
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return AxiosAuthClient(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      AxiosAuthClient.interceptors.response.eject(requestIntercept);
      AxiosAuthClient.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return AxiosAuthClient;
};

export default useAxiosAuth;
