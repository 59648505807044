import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@material-ui/icons';

const ClassificationCard = props => {
  const { name, amounts, icons, classificationDetails, onClick } = props;
  return (
    <Box
      sx={{
        padding: '1rem',
        border: '1px solid #3FD2F2',
        borderRight: 'unset',
        cursor: 'pointer',
        direction: 'ltr',
      }}
      onClick={onClick}
    >
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6}>
          <Stack direction="column" spacing={2}>
            <Typography component="div" sx={{ color: '#fff' }}>
              {name}
            </Typography>
            <Stack direction="row" spacing={1}>
              {icons}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContentL: 'center' }}>
          <Box>
            <Stack direction={'column'} spacing={1}>
              {amounts}
            </Stack>
          </Box>
        </Grid>
        <Grid item lg={1} xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowForwardIos style={{ color: '#3FD2F2', cursor: 'pointer' }} />
        </Grid>
        <Grid item lg={12} xs={12}>
          {classificationDetails}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClassificationCard;
