const setLocalStorage = async (name, value) => {
  localStorage.setItem(name, value);
};

const getLocalStorage = async name => {
  return localStorage.getItem(name);
};

const removeLocalStorage = async name => {
  localStorage.removeItem(name);
};

const localStorageClear = async () => {
  localStorage.clear();
};

export { setLocalStorage, getLocalStorage, removeLocalStorage, localStorageClear };
