import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Modal, SvgIcon, Typography } from '@mui/material';
import moment from 'moment';
import { isEmpty, map, get, find, includes } from 'lodash';
import { getSparkLimitedModels, getSparkModels } from 'actions/sparkAction';
import Instances from '../spark/Instance';

function CloseIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.6em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
      </svg>
    </SvgIcon>
  );
}

const ApplyModel = ({
  setShowTab,
  setIsOpenApplyModel,
  setModelStatus,
  setModelId,
  isApplyModel,
  setOnClick,
  setModelStartTime,
  setModelHaltTime,
  selectedApplyModelData,
  selectedCheckBox,
}) => {
  const dispatch = useDispatch();
  const [isSelectedModelUnique, setIsSelectedModelUnique] = useState(false);
  const sparkLimitedModelsData = useSelector(state => state.spark.sparkLimitedModels);
  const getAllModel = useSelector(state => state.spark.sparkModels);

  const getLimitedSparkModals = useCallback(() => {
    dispatch(getSparkLimitedModels());
  }, []);

  useEffect(() => {
    getLimitedSparkModals();

    dispatch(getSparkModels({ sort: 'currentVersion', order: 'desc' }));
  }, []);

  useEffect(() => {
    if (!isEmpty(sparkLimitedModelsData)) {
      setIsSelectedModelUnique(
        !includes(
          map(sparkLimitedModelsData, model => model.id),
          !isEmpty(selectedCheckBox) && !isEmpty(getAllModel)
            ? get(
                find(getAllModel, model => get(model, 'simModelId.id', '') === selectedCheckBox[0]),
                'id',
                '',
              )
            : '',
        ),
      );
    }
  }, [sparkLimitedModelsData]);

  return (
    <Box>
      <Modal
        open={isApplyModel}
        sx={theme => ({
          maxWidth: '23%',
          // height: 400,
          margin: 'auto',
          [theme.breakpoints.up('lg')]: {
            maxWidth: '23%',
          },
          [theme.breakpoints.down('lg')]: {
            maxWidth: '35%',
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: '50%',
          },
        })}
      >
        <Grid
          sx={{
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            border: '0.125rem solid #62647A',
            padding: '0 1rem 1rem',
            background: '#040d3e',
            marginTop: '3vh',
            maxHeight: '92vh',
          }}
        >
          {/* Header Section */}
          <Grid
            sx={{
              fontWeight: '400',
              fontSize: '1.2rem',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem 0.5rem 0 0 ',
              background: '#040d3e',
              position: 'sticky',
              top: 0,
              zIndex: '9',
            }}
          >
            {'Select Instance'}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpenApplyModel(false);
              }}
            >
              <CloseIcon />
            </span>
          </Grid>

          {/* Instance Section */}
          <Grid
            item
            sx={{
              paddingRight: '0.6rem',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0.438rem',
              },
              '&::-webkit-scrollbar-track': {
                background: 'gray',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#3FD2F2',
                borderRadius: 2,
              },
            }}
          >
            {!isEmpty(sparkLimitedModelsData) && !isEmpty(getAllModel) ? (
              map(sparkLimitedModelsData, (data, key) => {
                const diff = moment(data.durationTimer).diff(data.modelStartTime);
                // const instanceName = (
                //   <div style={{ width: '5rem', marginLeft: '-2rem' }}>
                //     Instance 1
                //   </div>
                // );
                return (
                  <Instances
                    data={data}
                    selectedModelKey={key + 1}
                    selectedApplyModelData={selectedApplyModelData}
                    isSelectedModelUnique={isSelectedModelUnique}
                    instanceName={`Instance ${key + 1}`}
                    title={get(data, 'name', '-')}
                    status={get(data, 'modelStatus', '-')}
                    currentVersion={get(data, 'currentVersion', '-')}
                    reason={
                      data.modelStatus === 'running'
                        ? get(data, 'modelStartReason', '-')
                        : data.modelStatus === 'halted'
                        ? get(data, 'modelStopReason', '-')
                        : data.modelStatus === 'idle' && get(data, 'modelStartReason', '-')
                    }
                    duration={data.modelStatus === 'running' ? get(data, 'durationTimer', '-') : '-'}
                    cryptoPairs={`${get(data.pair, 'primaryCrypto.symbol')}-${get(data.pair, 'currency.name')}`}
                    simModelId={get(data, 'simModelId', '-')}
                    initiated={'Corey Z'}
                    textFontColor={
                      data.modelStatus === 'running'
                        ? '#50E3C2'
                        : data.modelStatus === 'halted'
                        ? '#E3507A'
                        : data.modelStatus === 'idle' && '#B1AFCD'
                    }
                    isRunningModel={data.modelStatus === 'running' ? true : false}
                    isChangeModel={data.modelStatus === 'halted' ? true : false}
                    isApplyModel={data.modelStatus === 'idle' ? true : false}
                    modelStatusBackgroundColor={
                      data.modelStatus === 'running'
                        ? '#4B4B4B'
                        : data.modelStatus === 'halted'
                        ? '#CDA40F'
                        : data.modelStatus === 'idle' && '#34501A'
                    }
                    buttonBackgroundColor={
                      data.modelStatus === 'running'
                        ? '#BEBEBE'
                        : data.modelStatus === 'halted'
                        ? '#DCC06E'
                        : data.modelStatus === 'idle' && '#A0C581'
                    }
                    setOnClick={setOnClick}
                    id={get(data, 'id')}
                    selectedSimModelToApplyId={
                      !isEmpty(selectedCheckBox) && !isEmpty(getAllModel)
                        ? get(
                            find(getAllModel, model => get(model, 'simModelId.id', '') === selectedCheckBox[0]),
                            'id',
                            '',
                          )
                        : ''
                    }
                    setModelId={setModelId}
                    setModelStatus={setModelStatus}
                    modelStartTime={
                      (data.modelStatus === 'running' || data.modelStatus === 'halted') &&
                      data.modelStartTime &&
                      data.modelStartTime
                    }
                    modelHaltTime={
                      (data.modelStatus === 'running' || data.modelStatus === 'halted') &&
                      data.modelHaltTime &&
                      data.modelHaltTime
                    }
                    setModelStartTime={setModelStartTime}
                    setModelHaltTime={setModelHaltTime}
                    modelUpValue={get(data, 'up', 0)}
                    modelDownValue={get(data, 'down', 0)}
                    modelStopLossValue={get(data, 'stopLoss', 0)}
                    bias={get(data, 'bias', '')}
                    rebuyMode={get(data, 'rebuyMode', '')}
                  />
                );
              })
            ) : (
              <Box minHeight="82vh" display="flex" justifyContent="center" alignItems="center">
                <Typography> No Instance Available</Typography>
              </Box>
            )}
          </Grid>

          {/* NOTE ::::::: Cancel Button maybe needed in future */}
          {/* <Grid item>
            <Box sx={{ width: '100%' }}>
              <Button
                variant='outlined'
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#000000',
                  border: '2px solid #F1A305',
                  width: '98%',
                  marginTop: '1rem',
                  textTransform: 'unset !important',
                  display: isPending ? 'none' : 'block',
                }}
                onClick={() => {
                  setShowTab(true);
                  setIsOpenApplyModel(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Modal>
    </Box>
  );
};
export default ApplyModel;
