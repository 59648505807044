import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  bulkModel,
  classificationTypes,
  coinTypes,
  cryptoPair,
  models,
  notes,
  createHistoricalModel,
  simInstanceRunModels,
} from 'actions/ActionTypes';
import {
  deleteModelSuccess,
  deleteModelFailure,
  fetchClassificationFailure,
  fetchClassificationSuccess,
  fetchCoinFailure,
  fetchCoinSuccess,
  fetchCryptoPairFailure,
  fetchCryptoPairSuccess,
  createHistoricalModelSuccess,
  createHistoricalModelFail,
  fetchNoteSuccess,
  fetchNoteFailure,
  createNoteFailure,
  createNoteSuccess,
  updateNoteSuccess,
  updateNoteFailure,
  getModelSuccess,
  getModelFailure,
  updateModelSuccess,
  updateModelFailure,
  deleteNoteFailure,
  deleteNoteSuccess,
  createBulkModelSuccess,
  createBulkModelFail,
  getModelTradeDataSuccess,
  getModelTradeDataFailure,
  getRunningModelSuccess,
  getRunningModelFail,
  getResultDownloadFailure,
  updateAndRunModelSuccess,
  updateAndRunModelFailure,
  getBulkModelSuccess,
  getBulkModelFailure,
  simInstanceRunModelFail,
  simInstanceRunModelSuccess,
  stopSimModelFail,
  stopSimModelSuccess,
  createRunHistoricalModelSuccess,
  createRunHistoricalModelFail,
} from 'actions/simAction';
import { API } from 'apis';

function* createSimInstanceRunModel(action) {
  try {
    const response = yield call(API.createSimInstanceRunModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        simInstanceRunModelSuccess({
          sparkInstanceRunModels: response.data.data,
        }),
      );
    } else {
      yield put(
        simInstanceRunModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      simInstanceRunModelFail({
        error: e.message,
      }),
    );
  }
}

function* fetchClassificationSaga() {
  try {
    const response = yield call(API.getClassifications);
    yield put(
      fetchClassificationSuccess({
        classifications: response.data.data,
      }),
    );
  } catch (e) {
    yield put(
      fetchClassificationFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchCoinsSaga() {
  try {
    const response = yield call(API.getCoins);
    if (response.status === 200) {
      yield put(
        fetchCoinSuccess({
          coins: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchCoinFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchCoinFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchCryptoPairSaga() {
  try {
    const response = yield call(API.getCryptoPair);
    if (response.status === 200) {
      yield put(
        fetchCryptoPairSuccess({
          cryptopairs: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchCryptoPairFailure({
          error: e.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchCryptoPairFailure({
        error: e.message,
      }),
    );
  }
}

function* createHistoricalModelSaga(action) {
  try {
    const response = yield call(API.createHistoricalModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        createHistoricalModelSuccess({
          models: response.data.data,
        }),
      );
    } else {
      yield put(
        createHistoricalModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createHistoricalModelFail({
        error: e.message,
      }),
    );
  }
}

function* getModelSaga(action) {
  try {
    const response = yield call(API.getModelById, action.payload);
    if (response.status === 200) {
      yield put(
        getModelSuccess({
          modelDetail: response.data.data,
        }),
      );
    } else {
      yield put(
        getModelFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getModelFailure({
        error: e.message,
      }),
    );
  }
}

function* getBulkModel(action) {
  try {
    const response = yield call(API.getBulkModelById, action.payload);
    if (response.status === 200) {
      yield put(
        getBulkModelSuccess({
          modelDetail: response.data.data,
        }),
      );
    } else {
      yield put(
        getBulkModelFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getBulkModelFailure({
        error: e.message,
      }),
    );
  }
}

function* updateModelSaga(action) {
  try {
    const response = yield call(API.updateModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        updateModelSuccess({
          models: response.data.data,
        }),
      );
    } else {
      yield put(
        updateModelFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      updateModelFailure({
        error: e.message,
      }),
    );
  }
}

function* updateAndRunModelSaga(action) {
  try {
    const response = yield call(API.updateAndRunModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        updateAndRunModelSuccess({
          models: response.data.data,
        }),
      );
    } else {
      yield put(
        updateAndRunModelFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      updateAndRunModelFailure({
        error: e.message,
      }),
    );
  }
}

function* deleteModelSaga(action) {
  try {
    const response = yield call(API.deleteModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        deleteModelSuccess({
          models: action.payload,
        }),
      );
    } else {
      yield put(
        deleteModelFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      deleteModelFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchNotesSaga(action) {
  try {
    const response = yield call(API.getAllNotes, action.payload);
    if (response.status === 200) {
      yield put(
        fetchNoteSuccess({
          notes: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchNoteFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchNoteFailure({
        error: e.message,
      }),
    );
  }
}

function* createNoteSaga(action) {
  try {
    const response = yield call(API.createNote, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        createNoteSuccess({
          notes: response.data.data,
        }),
      );
    } else {
      yield put(
        createNoteFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createNoteFailure({
        error: e.message,
      }),
    );
  }
}

function* updateNoteSaga(action) {
  try {
    const response = yield call(API.updateNote, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        updateNoteSuccess({
          notes: response.data.data,
        }),
      );
    } else {
      yield put(
        updateNoteFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      updateNoteFailure({
        error: e.message,
      }),
    );
  }
}

function* deleteNoteSaga(action) {
  try {
    const response = yield call(API.deleteNote, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        deleteNoteSuccess({
          notes: action.payload,
        }),
      );
    } else {
      yield put(
        deleteNoteFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      deleteNoteFailure({
        error: e.message,
      }),
    );
  }
}

function* createBulkModelSaga(action) {
  try {
    const response = yield call(API.createBulkModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        createBulkModelSuccess({
          bulkModelDetail: response.data.data,
        }),
      );
    } else {
      yield put(
        createBulkModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createBulkModelFail({
        error: e.message,
      }),
    );
  }
}

function* createRunHistoricalModelSaga(action) {
  try {
    const response = yield call(API.createRunHistoricalModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        createRunHistoricalModelSuccess({
          analyzeCoinHistoricalModelDetails: response.data.data,
        }),
      );
    } else {
      yield put(
        createRunHistoricalModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createRunHistoricalModelFail({
        error: e.message,
      }),
    );
  }
}

function* getModelTradeDataSaga(action) {
  try {
    const response = yield call(API.getModelTradeData, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(getModelTradeDataSuccess(response.data.data));
    } else {
      yield put(
        getModelTradeDataFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getModelTradeDataFailure({
        error: e.message,
      }),
    );
  }
}

function* getRunningModelSaga(action) {
  try {
    const response = yield call(API.getRunningModel, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        getRunningModelSuccess({
          runningModel: response.data.data,
        }),
      );
    } else {
      yield put(
        getRunningModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getRunningModelFail({
        error: e.message,
      }),
    );
  }
}

function* getResultDownload(action) {
  try {
    const response = yield call(API.getResultDownloadById, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(getResultDownloadSuccess({}));
    } else {
      yield put(
        getResultDownloadFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      getResultDownloadFailure({
        error: e.message,
      }),
    );
  }
}

function* stopSimModel(action) {
  try {
    const response = yield call(API.stopSimModel, action.payload);
    action.cb && action.cb(response);

    if (response.status === 200) {
      yield put(stopSimModelSuccess(response.data.data));
    } else {
      yield put(
        stopSimModelFail({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      stopSimModelFail({
        error: e.message,
      }),
    );
  }
}

function* simSaga() {
  yield all([takeLatest(simInstanceRunModels.SIM_INSTANCE_RUN_MODEL, createSimInstanceRunModel)]);
  yield all([takeLatest(classificationTypes.FETCH_CLASSIFICATION_REQUEST, fetchClassificationSaga)]);
  yield all([takeLatest(coinTypes.FETCH_COIN_REQUEST, fetchCoinsSaga)]);
  yield all([takeLatest(cryptoPair.FETCH_CRYPTO_PAIR_REQUEST, fetchCryptoPairSaga)]);
  yield all([takeLatest(createHistoricalModel.CREATE_HISTORICAL_MODEL, createHistoricalModelSaga)]);

  yield all([takeLatest(models.GET_MODEL, getModelSaga)]);
  yield all([takeLatest(bulkModel.GET_BULK_MODEL, getBulkModel)]);
  yield all([takeLatest(models.GET_RESULT_DOWNLOAD, getResultDownload)]);

  yield all([takeLatest(models.DELETE_MODEL, deleteModelSaga)]);
  yield all([takeLatest(models.UPDATE_MODEL, updateModelSaga)]);
  yield all([takeLatest(models.UPDATE_AND_RUN_MODEL, updateAndRunModelSaga)]);
  yield all([takeLatest(notes.FETCH_NOTES, fetchNotesSaga)]);
  yield all([takeLatest(notes.CREATE_NOTE, createNoteSaga)]);
  yield all([takeLatest(notes.UPDATE_NOTE, updateNoteSaga)]);
  yield all([takeLatest(notes.DELETE_NOTE, deleteNoteSaga)]);
  yield all([takeLatest(bulkModel.CREATE_BULK_MODEL, createBulkModelSaga)]);
  yield all([takeLatest(createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL, createRunHistoricalModelSaga)]);
  yield all([takeEvery(models.GET_MODEL_TRADE_DATA, getModelTradeDataSaga)]);
  yield all([takeLatest(models.GET_RUNNING_MODEL, getRunningModelSaga)]);
  yield all([takeLatest(models.STOP_SIM_MODEL, stopSimModel)]);
}

export default simSaga;
