import { API_PATHNAME } from 'constants/api_url';
import { TOKEN_NAME } from 'constants/token';
import { removeLocalStorage } from 'helpers/localStorage';
import useAuth from './useAuth';
import { cookie } from 'helpers/cookies';
import { post } from 'utils/api';

const useLogout = () => {
  const { auth, setAuth } = useAuth();

  const logout = async () => {
    try {
      const response = await post('/v1/client/auth/logout', {
        refreshToken: cookie.get(TOKEN_NAME.REFRESH_TOKEN),
      });
      if (response.status === 200) {
        setAuth({});
        removeLocalStorage(TOKEN_NAME.ACCESS_TOKEN);
        cookie.remove(TOKEN_NAME.REFRESH_TOKEN);
        cookie.remove(TOKEN_NAME.ACCESS_TOKEN);
        cookie.clean();
        window.location.href = API_PATHNAME.LOGIN;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
