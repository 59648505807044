import axios from 'axios';
import { cookie } from 'helpers/cookies';
import { TOKEN_NAME } from 'constants/token';

const BASE_URL = 'http://localhost:8082/';
class AxiosClient {
  constructor() {
    if (AxiosClient.instance) {
      return AxiosClient.instance;
    }
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: { Authorization: `Bearer ${cookie.get(TOKEN_NAME.ACCESS_TOKEN)}` },
    });
    AxiosClient.instance = this;
    return this;
  }

  get(url, config = {}) {
    return this.axiosInstance.get(url, config);
  }

  post(url, data, config = {}) {
    return this.axiosInstance.post(url, data, config);
  }
}

export const AxiosAuthClient = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const instance = new AxiosClient();
export default instance;
