import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Paper, ListItemIcon, Checkbox, ListItemText, Box, Divider, Grid, Select } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty, round, map, filter, get } from 'lodash';
import moment from 'moment';
import Pusher from 'pusher-js';
import upicon from 'assets/upicon.svg';
import downicon from 'assets/downicon.svg';
import classrightsideicon from 'assets/classrightside.svg';
import { EnumTypeOfModel } from 'constants/model-enums';
import { EnumPusherChannelName, EnumPusherEventName } from 'constants/pusher-enums';
import { getModelTradeData } from 'actions/simAction';
import { formatNumberWithLocale, pusher } from 'utils/common';

const TableComponent = ({ selectedModelData, modelTradeData, isTradeDataFromSim }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.sim.modelLoading);

  const [itemValue, setItemValue] = useState();
  const [pageHeight, setPageHeight] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const isBulkModel = selectedModelData?.modelType === 'bulkModel';
  //Used for sorting
  const simTableData = [];

  useEffect(() => {
    if (!isEmpty(selectedModelData)) {
      if (selectedModelData?.modelType === 'historicalModel') {
        dispatch(
          getModelTradeData({
            id: selectedModelData?.id,
            modelType: EnumTypeOfModel.HISTORICAL_MODEL,
          }),
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedModelData) && selectedModelData?.modelType === 'sparkModel') {
      dispatch(
        getModelTradeData({
          id: selectedModelData?.id,
          modelType: EnumTypeOfModel.SPARK_MODEL,
        }),
      );
    }
  }, [selectedModelData]);

  // ::::: Pusher Functionality  :::::
  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;
    if (selectedModelData && selectedModelData.id) {
      const channel = pusher.subscribe(EnumPusherChannelName.TRADE_CALCULATIONS);
      channel.bind(EnumPusherEventName.SPARK_TABLE, function (data) {
        if (data.message.modelId === selectedModelData.id) {
          dispatch(
            getModelTradeData({
              id: selectedModelData.id,
              modelType: EnumTypeOfModel.SPARK_MODEL,
            }),
          );
        }
      });
      return () => {
        pusher.unsubscribe(EnumPusherChannelName.TRADE_CALCULATIONS);
      };
    }
  }, [selectedModelData]);

  useEffect(() => {
    // Function to update the page height
    function updatePageHeight() {
      const windowHeight = window.innerHeight;
      setPageHeight(windowHeight);
    }
    // Add an event listener to update the page height when the window is resized
    window.addEventListener('resize', updatePageHeight);
    // Initial page height calculation
    updatePageHeight();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  const columns = [
    {
      field: 'date',
      headerName: 'DATE',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        if (!isEmpty(selectedModelData)) {
          return (
            <strong>
              <span role="img" aria-label="enjoy">
                <img
                  src={downicon}
                  width={8}
                  height={12}
                  style={{ marginTop: '4px', cursor: 'pointer' }}
                  // NOTE :::::: All onClick are Commented because they maybe needed in future ::::::::
                  // onClick={() =>
                  //   setData(
                  //     [...simTableData].sort((a, b) => {
                  //       a = new Date(a.entryDate);
                  //       b = new Date(b.entryDate);
                  //       return a > b ? -1 : a < b ? 1 : 0;
                  //     })
                  //   )
                  // }
                />
                <img
                  src={upicon}
                  width={6}
                  height={8}
                  style={{
                    marginTop: '4px',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  // onClick={() =>
                  //   setData(
                  //     [...simTableData].sort((a, b) => {
                  //       a = new Date(a.entryDate);
                  //       b = new Date(b.entryDate);
                  //       return a > b ? 1 : a < b ? -1 : 0;
                  //     })
                  //   )
                  // }
                />
              </span>
              {'DATE '}
            </strong>
          );
        }
      },
    },

    {
      field: 'time',
      headerName: 'TIME',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData(
              //     [...simTableData].sort((a, b) => {
              //       const firstDate = new Date(
              //         moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
              //           ' ' +
              //           moment(a.entryTime || a.exitTime).format('hh:mm:ss')
              //       );
              //       const secondDate = new Date(
              //         moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
              //           ' ' +
              //           moment(b.entryTime || b.exitTime).format('hh:mm:ss')
              //       );
              //       return secondDate.getTime() - firstDate.getTime();
              //     })
              //   )
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData(
              //     [...simTableData].sort((a, b) => {
              //       const firstDate = new Date(
              //         moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
              //           ' ' +
              //           moment(a.entryTime || a.exitTime).format('hh:mm:ss')
              //       );
              //       const secondDate = new Date(
              //         moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
              //           ' ' +
              //           moment(b.entryTime || b.exitTime).format('hh:mm:ss')
              //       );
              //       return firstDate.getTime() - secondDate.getTime();
              //     })
              //   )
              // }
            />
          </span>
          {'TIME '}
        </strong>
      ),
    },

    {
      field: 'action',
      headerName: 'ACTION',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: params => (
        <strong style={{ marginLeft: '1rem' }}>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData(
              //     [...simTableData].sort((a, b) =>
              //       a.action > b.action ? -1 : 1
              //     )
              //   )
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData(
              //     [...simTableData].sort((a, b) =>
              //       a.action > b.action ? 1 : -1
              //     )
              //   )
              // }
            />
          </span>
          {'ACTION '}
          <img
            src={classrightsideicon}
            width={15}
            height={15}
            style={{ marginTop: '4px', marginLeft: '5px', cursor: 'pointer' }}
            // onClick={() => {
            //   handleOpenPopup();
            // }}
          />
          {openPopup && (
            <Select value={params.value} inputProps={{ IconComponent: () => null }}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'right',
                  flexDirection: 'column',
                  background: '#000000',
                  border: '2px solid #010D0F',
                  zIndex: '1',
                  color: '#FFFFFF',
                }}
              >
                <MenuItem sx={{ p: '0', paddingRight: '1rem' }} onClick={() => setItemValue('BUY')}>
                  <ListItemIcon>
                    <Checkbox icon={<CheckBoxOutlineBlankIcon htmlColor="#175982" />} />
                  </ListItemIcon>
                  <ListItemText sx={{ textAlign: 'center' }}>Buy</ListItemText>
                </MenuItem>
                <MenuItem sx={{ p: '0', paddingRight: '1rem' }} onClick={() => setItemValue('SELL')}>
                  <ListItemIcon>
                    <Checkbox icon={<CheckBoxOutlineBlankIcon htmlColor="#175982" />} />
                  </ListItemIcon>
                  <ListItemText sx={{ textAlign: 'center' }}>Sell</ListItemText>
                </MenuItem>
                <MenuItem sx={{ p: '0', paddingRight: '1rem' }} onClick={() => setItemValue('STOP')}>
                  <ListItemIcon>
                    <Checkbox icon={<CheckBoxOutlineBlankIcon htmlColor="#175982" />} />
                  </ListItemIcon>
                  <ListItemText sx={{ textAlign: 'center' }}>Stop</ListItemText>
                </MenuItem>
              </Box>
            </Select>
          )}
        </strong>
      ),
    },

    {
      field: 'price',
      headerName: 'PRICE',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.price - a.price))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.price - b.price))
              // }
            />
          </span>
          {'PRICE'}
        </strong>
      ),
    },

    {
      field: 'win',
      headerName: 'WIN',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.win - a.win))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.win - b.win))
              // }
            />
          </span>
          {'WIN'}
        </strong>
      ),
    },

    {
      field: 'win$',
      headerName: 'WIN$',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.win$ - a.win$))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.win$ - b.win$))
              // }
            />
          </span>
          {'WIN ($)'}
        </strong>
      ),
    },

    {
      field: 'loss',
      headerName: 'LOSS',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.loss - a.loss))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.loss - b.loss))
              // }
            />
          </span>
          {'LOSS'}
        </strong>
      ),
    },

    {
      field: 'loss$',
      headerName: 'LOSS$',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.loss$ - a.loss$))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.loss$ - b.loss$))
              // }
            />
          </span>
          {'LOSS ($)'}
        </strong>
      ),
    },

    {
      field: 'buy',
      headerName: 'BUY',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.buy - a.buy))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.buy - b.buy))
              // }
            />
          </span>
          {'BUY'}
        </strong>
      ),
    },

    {
      field: 'sell',
      headerName: 'SELL',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.sell - a.sell))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.sell - b.sell))
              // }
            />
          </span>
          {'SELL'}
        </strong>
      ),
    },

    {
      field: 'fee',
      headerName: 'FEE',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <strong>
          <span role="img" aria-label="enjoy">
            <img
              src={downicon}
              width={8}
              height={12}
              style={{ marginTop: '4px', cursor: 'pointer' }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => b.sell - a.sell))
              // }
            />
            <img
              src={upicon}
              width={6}
              height={8}
              style={{
                marginTop: '4px',
                marginRight: '5px',
                cursor: 'pointer',
              }}
              // onClick={() =>
              //   setData([...simTableData].sort((a, b) => a.sell - b.sell))
              // }
            />
          </span>
          {'FEE'}
        </strong>
      ),
    },
  ];

  const feesColumn = filter(columns, ele => ele.field !== 'fee');

  const rows = isBulkModel
    ? []
    : isTradeDataFromSim && !isEmpty(modelTradeData)
    ? map(modelTradeData?.simTableData, (data, idx) => ({
        id: idx,
        date: moment(data.entryDate).tz('America/Denver').format('MM-DD-YYYY'),
        time: moment.tz(data.entryTime, 'HH:mm:ss', 'UTC').clone().tz('America/Denver').format('HH:mm:ss'),
        action: data.action,
        win: round(data.win, 2),
        win$: round(data.win$, 2),
        price: round(data.price, 2),
        loss: round(data.loss, 2),
        loss$: round(data.loss$, 2),
        buy: round(data.buy, 2),
        sell: round(data.sell, 2),
        fee: round(data.fee, 2),
      }))
    : !isTradeDataFromSim && !isEmpty(modelTradeData?.simTableData)
    ? map(modelTradeData?.simTableData, (data, idx) => ({
        id: idx,
        date: moment(data.entryDate).tz('America/Denver').format('MM-DD-YYYY'),
        time: moment.tz(data.entryTime, 'HH:mm:ss', 'UTC').clone().tz('America/Denver').format('HH:mm:ss'),
        action: data.action,
        win: round(data.win, 2),
        win$: round(data.win$, 2),
        price: round(data.price, 2),
        loss: round(data.loss, 2),
        loss$: round(data.loss$, 2),
        buy: round(data.buy, 2),
        sell: round(data.sell, 2),
        fee: round(data.fee, 2),
      }))
    : [];

  // NOTE ::::: NEEDED IN FUTURE ::::
  // const handleOpenPopup = () => {
  //   setOpenPopup(!openPopup);
  // };

  // NOTE :::::: Maybe Needed in Future ::::::::
  // const simTableData = [];
  // (isEmpty(tableData?.trade)
  //   ? singleModelTradeData?.trades
  //   : tableData?.trade
  // )?.map((data) => {
  //   if (data.entryPrice) {
  //     simTableData.push({
  //       entryTime: data.entryTime,
  //       entryDate: data.entryTime,
  //       action: 'BUY',
  //       win: 0,
  //       price: data.entryPrice,
  //       loss: 0,
  //       buy: 1,
  //       sell: 0,
  //     });
  //   }
  //   if (data.exitPrice && data.reasonForExit === 'stop-loss') {
  //     simTableData.push({
  //       entryTime: data.exitTime,
  //       entryDate: data.exitTime,
  //       action: 'STOP LOSS',
  //       win: 0,
  //       price: data.entryPrice,
  //       loss: 1,
  //       buy: 0,
  //       sell: 1,
  //     });
  //   }

  //   if (data.exitPrice && data.reasonForExit === 'profit-exit') {
  //     simTableData.push({
  //       entryTime: data.exitTime,
  //       entryDate: data.exitTime,
  //       action: 'SELL',
  //       win: 1,
  //       price: data.entryPrice,
  //       loss: 0,
  //       buy: 0,
  //       sell: 1,
  //     });
  //   }
  // });

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              background: '#262540',
              paddingTop: '11rem',
              paddingBottom: '11rem',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={isTradeDataFromSim ? feesColumn : columns}
            rowHeight={40}
            pageSize={Math.floor((pageHeight - 722) / 39)}
            autoHeight
            disableSelectionOnClick
            hideFooterPagination={rows?.length ? false : true}
            localeText={{
              noRowsLabel: 'No data available',
            }}
            getRowClassName={params => (params.row.id % 2 === 0 ? 'even-row' : 'odd-row')}
            filterModel={{
              items: [
                {
                  columnField: 'action',
                  operatorValue: 'contains',
                  value: itemValue,
                },
              ],
            }}
            sx={{
              background: '#262540',
              color: '#79D4D4',
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiTablePagination-root': {
                color: '#79D4D4',
              },
              '& .MuiDataGrid-cell--textLeft': {
                justifyContent: 'center !important',
              },
            }}
          />
        )}
      </Paper>
      <Grid
        container
        xs={12}
        sx={{
          background: '#171616',
          padding: '0.5rem',
        }}
      >
        <Grid item xs={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 3.5rem',
            }}
          >
            <Box sx={{ color: '#7877B1', fontWeight: '600' }}>WIN Rate:</Box>
            <Box sx={{ color: '#50E3C2' }}>
              {isBulkModel ? 'NA' : modelTradeData?.winRate ? `${round(modelTradeData?.winRate, 2)}%` : 'NA'}
            </Box>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ background: '#939393' }} />
        <Grid item xs={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 3.5rem',
            }}
          >
            <Box sx={{ color: '#7877B1', fontWeight: '600' }}>RRR:</Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ color: '#7877B1' }}>1: </Box>
              <Box sx={{ color: '#E3507A', marginLeft: '0.7rem' }}>
                {isTradeDataFromSim
                  ? selectedModelData?.riskToRewardRatio
                    ? round(selectedModelData?.riskToRewardRatio, 2)
                    : 'NA'
                  : !isBulkModel
                  ? `${round(get(selectedModelData, 'riskToRewardRatio', 'NA'), 2)}`
                  : 'NA'}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ background: '#939393' }} />
        <Grid item xs={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 3.5rem',
            }}
          >
            <Box sx={{ color: '#7877B1', fontWeight: '600' }}>Profitability:</Box>
            <Box
              sx={{
                color: isBulkModel
                  ? 'white'
                  : !isTradeDataFromSim
                  ? modelTradeData?.runningProfitOrLoss
                    ? modelTradeData?.runningProfitOrLoss >= 0
                      ? '#00FFD1'
                      : 'red'
                    : 'white'
                  : modelTradeData?.runningProfitOrLoss
                  ? modelTradeData?.runningProfitOrLoss >= 0
                    ? '#00FFD1'
                    : 'red'
                  : 'white',
              }}
            >
              {isBulkModel
                ? 'NA'
                : modelTradeData?.runningProfitOrLoss
                ? formatNumberWithLocale(round(modelTradeData?.runningProfitOrLoss, 2), 'decimal')
                : 'NA'}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableComponent;
