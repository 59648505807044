import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography, Switch, Stack, styled, Tabs, Tab, TextField, Button } from '@mui/material';
import { Add } from '@material-ui/icons';
import { enUS } from '@mui/material/locale';
import { MdDoNotDisturb } from 'react-icons/md';
import { isEmpty, omit } from 'lodash';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { newInitValue } from './AlertBoxInitialValue';
import { cookie } from 'helpers/cookies';
import { TOKEN_NAME } from 'constants/token';
import { useStyles } from 'components/common/utils';
import { fetchAlert, fetchAlertGroup } from 'actions/lunaAction';
import { alerts } from 'actions/ActionTypes';
import { toast } from 'react-toastify';
// import * as memo from "immer";

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
const localeSettings = {
  locale: {
    short: 'en',
    full: 'en-IN',
    material: enUS,
    mui: enUS,
  },
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger',
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger',
  },
};

const config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    ...localeSettings,

    valueSourcesInfo: {
      value: {
        label: 'Value',
      },
      field: {
        label: 'Field',
        widget: 'field',
      },
      func: {
        label: 'Function',
        widget: 'func',
      },
    },
    // canReorder: true,
    // canRegroup: true,
    // showNot: true,
    // showLabels: true,
    maxNesting: 3,
    canLeaveEmptyGroup: true, //after deletion
  },
  fields: {
    Price: {
      label: 'Price',
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
    Volume: {
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
    MarketCap: {
      type: '!group',
      valueSources: ['value'],
      subfields: {
        Percentage: {
          label: 'Percentage',
          type: 'number',
        },
        Over: {
          label: 'Over',
          type: '!group',
          subfields: {
            Days: {
              label: 'Days',
              type: 'number',
            },
            Hour: {
              label: 'Hour',
              type: 'number',
            },
            Minute: {
              label: 'Minute',
              type: 'number',
            },
            Week: {
              label: 'Week',
              type: 'number',
            },
          },
        },
      },
    },
  },
};

const AlertSchema = Yup.object().shape({
  name: Yup.string().required('Alert Name is required!'),
});

const AddAlertBoxQueryBuilder = props => {
  const { setAddedNewAlert, alertGroupId } = props;
  // You can load query value from your backend storage (for saving see `Query.onChange()`)
  const emptyInitValue = { id: QbUtils.uuid(), type: 'group' };
  const queryValue = newInitValue && Object.keys(newInitValue).length > 0 ? newInitValue : emptyInitValue;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const classes = useStyles();
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config,
  });
  const accessToken = cookie.get(TOKEN_NAME.ACCESS_TOKEN);
  const ownerId = JSON.parse(window.atob(accessToken.split('.')[1])).sub;

  const onChange = (immutableTree, config) => {
    setState({ tree: immutableTree, config: config });
  };

  const renderBuilder = propsData => {
    // const mainAddRule = propsData.actions.addRule;
    // memo.current._actions = propsData.actions;
    // const rootPath = [QbUtils.uuid()];
    // const rootPath = [state.tree.get("id")];
    // memo.current._actions.addRule = (path, properties, type, children) => {
    //     // console.log('action called', path, properties, type, children)
    //     // console.log('action called')
    //     // mainAddRule(path, properties, type, children)
    //     // bprops.actions.addGroup(rootPath, {
    //     //   mode: 'array',
    //     //
    //     // })
    //     mainAddRule(
    //         rootPath,
    //         {
    //             field: "Price",
    //             operator: "not_equal",
    //             value: [
    //                 null
    //             ],
    //             valueSrc: [
    //                 "value"
    //             ],
    //             valueType: [
    //                 "text"
    //             ]
    //         },
    //         "rule",
    //         children,
    //         // [
    //         //     {
    //         //         type: "rule",
    //         //         properties: {
    //         //             field: "Price.Percentage",
    //         //             operator: "greater_or_equal",
    //         //             value: ["number"],
    //         //             valueSrc: ["value"],
    //         //             valueType: ["number"]
    //         //         }
    //         //     },
    //         //     {
    //         //         type: "rule_group",
    //         //         id: rootPath,
    //         //         properties: {
    //         //             // conjunction: "AND",
    //         //             // not: false,
    //         //             field: "Price.Over"
    //         //         },
    //         //         children1: [
    //         //             {
    //         //                 type: "rule",
    //         //                 id: rootPath,
    //         //                 properties: {
    //         //                     field: "Price.Over.Days",
    //         //                     operator: "equal",
    //         //                     value: ["number"],
    //         //                     valueSrc: ["value"],
    //         //                     valueType: ["number"]
    //         //                 }
    //         //             }
    //         //         ]
    //         //     }
    //         // ]
    //     );
    // }
    // const mainAddGroup = propsData.actions.addGroup;
    // memo.current._actions = propsData.actions;
    // const rootPath1 = [QbUtils.uuid()];
    // // const rootPath = [state.tree.get("id")];
    // memo.current._actions.addGroup = (path, properties, children) => {
    //     // console.log('action called', path, properties, type, children)
    //     // console.log('action called')
    //     // mainAddRule(path, properties, type, children)
    //     // bprops.actions.addGroup(rootPath, {
    //     //   mode: 'array',
    //     //
    //     // })
    //     mainAddGroup(
    //         rootPath1,
    //         {
    //             field: "Days",
    //             operator: "equal",
    //             value: ["number"],
    //             valueSrc: ["value"],
    //             valueType: ["number"],
    //             operatorOptions: {
    //                 Days: {
    //                     label: "Days",
    //                     type: "number",
    //                 },
    //                 Hour: {
    //                     label: "Hour",
    //                     type: "number",
    //                 },
    //                 Minute: {
    //                     label: "Minute",
    //                     type: "number",
    //                 },
    //                 Week: {
    //                     label: "Week",
    //                     type: "number",
    //                 },
    //             },
    //         },
    //         "rule",
    //         children,
    //         // [
    //         //     {
    //         //         type: "rule",
    //         //         properties: {
    //         //             field: "Price.Percentage",
    //         //             operator: "greater_or_equal",
    //         //             value: ["number"],
    //         //             valueSrc: ["value"],
    //         //             valueType: ["number"]
    //         //         }
    //         //     },
    //         //     {
    //         //         type: "rule_group",
    //         //         id: rootPath,
    //         //         properties: {
    //         //             // conjunction: "AND",
    //         //             // not: false,
    //         //             field: "Price.Over"
    //         //         },
    //         //         children1: [
    //         //             {
    //         //                 type: "rule",
    //         //                 id: rootPath,
    //         //                 properties: {
    //         //                     field: "Price.Over.Days",
    //         //                     operator: "equal",
    //         //                     value: ["number"],
    //         //                     valueSrc: ["value"],
    //         //                     valueType: ["number"]
    //         //                 }
    //         //             }
    //         //         ]
    //         //     }
    //         // ]
    //     );
    // }
    return (
      <Box sx={{ margin: '1rem 0' }}>
        <Builder {...propsData} />
      </Box>
    );
  };

  // const TextStyle: SxProps<Theme> = {
  //     ...SecondaryFontFamily,
  //     fontWeight: 500,
  //     fontSize: '1.125rem',
  //     lineHeight: '1.375rem',
  //     color: theme => theme.misc.inputPlaceholderColor,
  // };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 43,
    height: 26,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: '2px 6px',
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#ba02ba',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      borderRadius: 13,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fad41e',
      boxSizing: 'border-box',
    },
  }));

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const jsonLogic = JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config).logic);

  const addAlert = useCallback(
    data => {
      if (jsonLogic && alertGroupId && ownerId) {
        const alertGroupData = {
          ...data,
          user: ownerId,
          alertGroup: alertGroupId,
          logic: jsonLogic,
        };
        // dispatch(createAlert(alertGroupData))
        dispatch({
          type: alerts.CREATE_ALERT,
          payload: alertGroupData,
          cb: res => {
            if (res) {
              if (res.status === 200) {
                toast.success('Alert added successfully');
                dispatch(fetchAlertGroup());
                dispatch(fetchAlert());
                setTimeout(() => {
                  setAddedNewAlert(false);
                  dispatch(fetchAlertGroup());
                  dispatch(fetchAlert());
                }, 1000);
              }
            }
          },
        });
      }
    },
    [alertGroupId, ownerId, state.tree, state.config, jsonLogic],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          description: '',
          notes: '',
          isAlertOrWarning: false,
        }}
        validationSchema={AlertSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (isEmpty(values.description) && isEmpty(values.notes)) {
            const alertData = omit(values, ['description', 'notes']);
            addAlert(alertData);
          } else if (isEmpty(values.description)) {
            const alertData = omit(values, ['description']);
            addAlert(alertData);
          } else if (isEmpty(values.notes)) {
            const alertData = omit(values, ['notes']);
            addAlert(alertData);
          } else {
            addAlert(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid
                sx={{
                  width: '100%',
                  border: '2px solid #3FD2F2',
                  padding: '1rem',
                  flexGrow: 1,
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Grid
                  container
                  spacing={{ md: 2, lg: 3 }}
                  sx={{ padding: '1rem 0', display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        component="div"
                        variant="h6"
                        sx={{
                          fontWeight: '600',
                          color: '#fff',
                          fontSize: '1.3rem',
                          pr: '2rem',
                          borderBottom: '2px solid #fff',
                        }}
                      >
                        Create Alert
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box>
                      <TextField
                        placeholder="Enter Alert Name"
                        size="small"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        fullWidth
                        inputProps={{
                          sx: {
                            '&::placeholder': {
                              color: '#FFFFFF',
                            },
                          },
                        }}
                        sx={theme => ({
                          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid  #3FD2F2',
                            borderRadius: '5px 5px 0 0',
                          },
                          input: {
                            color: '#fff',
                            background: '#000000',
                            border: '2px solid #3FD2F2',
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '14px',
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: '12px',
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: '11px',
                            },
                          },
                        })}
                      />
                    </Box>
                    {errors.name && touched.name ? (
                      <div
                        style={{
                          textAlign: 'left',
                          color: 'red',
                          padding: '5px 5px 0 0',
                          width: '100%',
                          fontSize: '0.8rem',
                        }}
                      >
                        {errors.name}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography component="div" sx={{ color: '#fff' }}>
                        Alert
                      </Typography>
                      <AntSwitch
                        name="isAlertOrWarning"
                        value={values.isAlertOrWarning}
                        onChange={event => setFieldValue('isAlertOrWarning', !event.target.checked)}
                        defaultChecked
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      <Typography component="div" sx={{ color: '#fff' }}>
                        Warning
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={{ md: 1, lg: 1 }}>
                  <Grid
                    item
                    lg={9}
                    md={12}
                    sx={{
                      background: 'white',
                      borderRadius: '5px',
                      width: '100%',
                      maxWidth: 'unset !important',
                    }}
                  >
                    <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
                  </Grid>
                  <Grid item lg={3} md={11}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        border: '1px solid #3FD2F2',
                      }}
                    >
                      <Tabs
                        aria-label="Tabs where each tab needs to be selected manually"
                        onChange={handleChangeTab}
                        value={value}
                        variant="fullWidth"
                        sx={theme => ({
                          justifyContent: 'space-around',
                        })}
                        indicatorColor={'#3FD2F2'}
                        textColor={value === 0 ? '#FFD41F' : '#B1AFCD'}
                      >
                        <Tab
                          label="Description"
                          className={classes.commonTextStyle}
                          sx={theme => ({
                            borderRight: '1px solid #3FD2F2',
                            borderBottom: '1px solid #3FD2F2',
                            color: value === 0 ? '#FFD41F' : '#B1AFCD',
                            [theme.breakpoints.down('md')]: {
                              paddingTop: '6px',
                              paddingBottom: '6px',
                            },
                          })}
                        />
                        <Tab
                          label="Notes"
                          className={classes.commonTextStyle}
                          sx={theme => ({
                            color: value === 1 ? '#FFD41F' : '#B1AFCD',
                            borderBottom: '1px solid #3FD2F2',
                            [theme.breakpoints.down('md')]: {
                              paddingTop: '6px',
                              paddingBottom: '6px',
                            },
                          })}
                        />
                      </Tabs>
                      {value === 0 && (
                        <Box>
                          <TextField
                            id="description"
                            name="description"
                            size="small"
                            multiline
                            rows={6}
                            value={values.description}
                            onChange={handleChange}
                            fullWidth
                            sx={theme => ({
                              input: {
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                            inputProps={{ sx: { color: '#fff' } }}
                          />
                        </Box>
                      )}
                      {value === 1 && (
                        <Box>
                          <TextField
                            id="day"
                            name="notes"
                            size="small"
                            multiline
                            rows={6}
                            value={values.notes}
                            onChange={handleChange}
                            fullWidth
                            sx={theme => ({
                              input: {
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '14px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                            inputProps={{ sx: { color: '#fff' } }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Button
                    sx={{
                      color: '#FFFFFF',
                      backgroundColor: '#000000',
                      border: '2px solid #3FD2F2',
                      textTransform: 'unset !important',
                      marginTop: '1rem',
                    }}
                    type="submit"
                    startIcon={<Add />}
                    disabled={isSubmitting}
                  >
                    Create New Alert
                  </Button>
                  <Button
                    sx={{
                      color: '#FFFFFF',
                      backgroundColor: '#000000',
                      border: '2px solid #3FD2F2',
                      textTransform: 'unset !important',
                      marginTop: '1rem',
                      marginLeft: '1rem',
                    }}
                    startIcon={<MdDoNotDisturb />}
                    onClick={() => setAddedNewAlert(false)}
                  >
                    Cancel
                  </Button>
                </Box>
                {/*<Box sx={{marginTop: '1rem'}}>*/}
                {/*    <Typography component={'div'} sx={{color: '#fff'}}>Owner: John Smith</Typography>*/}
                {/*</Box>*/}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddAlertBoxQueryBuilder;
