import { get, post, put, deleteRequest, download } from 'utils/api';

export const getClassifications = () => {
  return get(`/v1/client/classifications/`);
};

export const createBulkModel = data => {
  return post(`/v1/client/bulk-backtest`, data);
};

export const createRunHistoricalModel = data => {
  return post(`/v2/client/strategies/analyzeCoinHistoricalModel`, data, 300000);
};

export const createSimInstanceRunModel = data => {
  return post(`/v1/client/historical-model/run-sim-model/${data?.historicalModelId}`);
};

export const getCoins = () => {
  return get(`/v1/client/crypto/`);
};

export const getCryptoPair = () => {
  return get(`/v1/client/pair/`);
};

export const createHistoricalModel = data => {
  return post(`/v1/client/historical-model/`, data);
};

export const getModelById = id => {
  return get(`/v1/client/historical-model/${id}`);
};

export const getBulkModelById = id => {
  return get(`/v1/client/bulk-backtest/${id}`);
};

export const deleteModel = data => {
  function convertArrayToURLParams(key, data) {
    var encodedParams = [];

    for (var i = 0; i < data.length; i++) {
      var encodedValue = encodeURIComponent(data[i]);
      encodedParams.push(key + '[]=' + encodedValue);
    }

    var urlParams = encodedParams.join('&');
    return urlParams;
  }

  var encodedParams = convertArrayToURLParams('id', data);
  return deleteRequest(`/v1/client/historical-model/remove-many-historical-model?${encodedParams}`);
};

export const getModelData = () => {
  return get(`/v1/client/historical-model-data/`);
};

export const getAllNotes = id => {
  return get(`/v1/client/historical-model-note?historicalModelId=${id}`);
};

export const createNote = data => {
  return post(`/v1/client/historical-model-note/`, data);
};

export const updateModel = data => {
  const id = data.id;
  delete data['id'];
  return put(`/v1/client/historical-model/${id}`, data);
};

export const updateAndRunModel = data => {
  const id = data.id;
  delete data['id'];
  return put(`/v1/client/historical-model/update-and-run/${id}`, data);
};

export const updateNote = data => {
  const id = data.id;
  delete data['id'];
  return put(`/v1/client/historical-model-note/${id}`, data);
};

export const deleteNote = id => {
  return deleteRequest(`/v1/client/historical-model-note/${id}`);
};

export const getModelTradeData = data => {
  const id = data.id;
  delete data['id'];
  return get(`/v1/client/historical-model/${id}/load-trade-data`, data);
};

export const getRunningModel = data => {
  return get(`/v1/client/historical-model/get-list-with-filter`, data);
};

export const getResultDownloadById = id => {
  return download(`/v1/client/historical-model/excel-export/${id}`);
};

export const stopSimModel = historicalModelId => {
  return get(`/v1/client/historical-model/stop-sim-model?historicalModelId=${historicalModelId}`);
};

export const sim = {
  getClassifications,
  getCoins,
  getCryptoPair,
  createHistoricalModel,
  getModelById,
  getBulkModelById,
  getResultDownloadById,
  deleteModel,
  getModelData,
  getAllNotes,
  createNote,
  updateNote,
  updateModel,
  updateAndRunModel,
  deleteNote,
  createBulkModel,
  createRunHistoricalModel,
  getModelTradeData,
  getRunningModel,
  createSimInstanceRunModel,
  stopSimModel,
};
