import { all, call, put, takeLatest } from 'redux-saga/effects';
import { alertGroup, alerts } from 'actions/ActionTypes';
import { API } from 'apis';
import {
  createAlertFailure,
  createAlertGroupFailure,
  createAlertGroupSuccess,
  createAlertSuccess,
  deleteAlertFailure,
  deleteAlertSuccess,
  fetchAlertFailure,
  fetchAlertGroupFailure,
  fetchAlertGroupSuccess,
  fetchAlertSuccess,
} from 'actions/lunaAction';

function* fetchAlertsSaga() {
  try {
    const response = yield call(API.getAlerts);
    if (response.status === 200) {
      yield put(
        fetchAlertSuccess({
          alerts: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchAlertFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchAlertFailure({
        error: e.message,
      }),
    );
  }
}

function* createAlertSaga(action) {
  try {
    const response = yield call(API.createAlert, action.payload);
    if (response.status === 200) {
      action.cb && action.cb(response);
      yield put(
        createAlertSuccess({
          alerts: response.data.data,
        }),
      );
    } else {
      yield put(
        createAlertFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createAlertFailure({
        error: e.message,
      }),
    );
  }
}

function* deleteAlertSaga(action) {
  try {
    const response = yield call(API.deleteAlert, action.payload);
    if (response.status === 200) {
      action.cb && action.cb(response);
      yield put(
        deleteAlertSuccess({
          alerts: action.payload,
        }),
      );
    } else {
      yield put(
        deleteAlertFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      deleteAlertFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchAlertGroupSaga() {
  try {
    const response = yield call(API.getAlertGroup);
    if (response.status === 200) {
      yield put(
        fetchAlertGroupSuccess({
          alertGroup: response.data.data,
        }),
      );
    } else {
      yield put(
        fetchAlertGroupFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      fetchAlertGroupFailure({
        error: e.message,
      }),
    );
  }
}

function* createAlertGroupSaga(action) {
  try {
    const response = yield call(API.createAlertGroup, action.payload);
    if (response.status === 200) {
      action.cb && action.cb(response);
      yield put(
        createAlertGroupSuccess({
          alertGroup: response.data.data,
        }),
      );
    } else {
      yield put(
        createAlertGroupFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      createAlertGroupFailure({
        error: e.message,
      }),
    );
  }
}

function* lunaSaga() {
  yield all([takeLatest(alerts.FETCH_ALERTS, fetchAlertsSaga)]);
  yield all([takeLatest(alerts.CREATE_ALERT, createAlertSaga)]);
  yield all([takeLatest(alerts.DELETE_ALERT, deleteAlertSaga)]);
  yield all([takeLatest(alertGroup.FETCH_ALERT_GROUP, fetchAlertGroupSaga)]);
  yield all([takeLatest(alertGroup.CREATE_ALERT_GROUP, createAlertGroupSaga)]);
}

export default lunaSaga;
