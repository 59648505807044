import { all, call, put, takeLatest } from 'redux-saga/effects';
import { dailyPerformanceData } from 'actions/ActionTypes';
import {
  getDailyPerformanceDataFailure,
  getDailyPerformanceDataSuccess,
  storeDailyPerformanceDataFailure,
  storeDailyPerformanceDataSuccess,
} from 'actions/dashboardAction';
import { API } from 'apis';

function* storeDailyPerformanceDataSaga(action) {
  try {
    const response = yield call(API.storeDailyPerformanceData, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        storeDailyPerformanceDataSuccess({
          storePerformanceData: response.data.data,
        }),
      );
    } else {
      yield put(
        storeDailyPerformanceDataFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      storeDailyPerformanceDataFailure({
        error: e.message,
      }),
    );
  }
}

function* getDailyPerformanceDataSaga(action) {
  try {
    const response = yield call(API.getDailyPerformanceData, action.payload);
    action.cb && action.cb(response);
    if (response.status === 200) {
      yield put(
        getDailyPerformanceDataSuccess({
          getPerformanceData: response.data.data,
        }),
      );
    } else {
      yield put(
        getDailyPerformanceDataFailure({
          error: response.message,
        }),
      );
    }
  } catch (e) {
    yield put(
      storeDailyPerformanceDataFailure({
        error: e.message,
      }),
    );
  }
}

function* dashboardSaga() {
  yield all([takeLatest(dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_REQUEST, storeDailyPerformanceDataSaga)]);
  yield all([takeLatest(dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_REQUEST, getDailyPerformanceDataSaga)]);
}

export default dashboardSaga;
