// Make requests to CryptoCompare API
// import AxiosClient from "../../security/clients/AxiosClient";
// import header from "../../security/hooks/useHeader";
// import {get} from 'utils/api/index'

import axios from 'axios';
import { API_URL } from '../../constants/api_url';
import { TOKEN_NAME } from '../../constants/token';
import { toast } from 'react-toastify';

export async function makeApiRequest(path) {
  try {
    const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
    return response.json();
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error?.status}`);
  }
}

export async function getFrdDataForChart(symbolName, dateFrom, dateTo) {
  const token = localStorage.getItem(TOKEN_NAME.ACCESS_TOKEN);
  try {
    let data;

    await axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: API_URL + `/v1/client/frd-coin-data-store/get-frd-data`,
      data: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        cryptoName: symbolName,
      },
    }).then(res => {
      if (res.status === 200) {
        data = res;
      }
      return res;
    });
    return data;
  } catch (error) {
    toast.error('Data not available for selected dates');
    throw new Error(`CryptoCompare request error: ${error?.status}`);
  }
}

// export async function makeApiRequest() {
// 	try {
// 		return get(`/v1/client/pair/`);
// 	} catch (error) {
// 		console.error(error);
// 	}
// }
//
// export async function getHistoricalModelData() {
// 	try {
// 		return get(`/v1/client/historicalmodeldata/paginated`);
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
