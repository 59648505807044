const value = {
  id: '8b899b8a-0123-4456-b89a-b183caaf916f',
  type: 'group',
  children1: [
    {
      type: 'rule_group',
      id: '8a98a8aa-89ab-4cde-b012-3183cab1faa9',
      properties: {
        conjunction: 'AND',
        not: false,
        field: 'Price',
      },
      children1: [
        {
          type: 'rule',
          id: 'a9b89bab-89ab-4cde-b012-3183cc2fddd6',
          properties: {
            field: 'Price.Percentage',
            operator: 'greater_or_equal',
            value: [13.5],
            valueSrc: ['value'],
            valueType: ['number'],
          },
        },
        {
          type: 'rule_group',
          id: '9889a8a8-89ab-4cde-b012-3183cc300cd7',
          properties: {
            conjunction: 'AND',
            not: false,
            field: 'Price.Over',
          },
          children1: [
            {
              type: 'rule',
              id: '8ababa88-cdef-4012-b456-7183cc302d7e',
              properties: {
                field: 'Price.Over.Days',
                operator: 'equal',
                value: [12],
                valueSrc: ['value'],
                valueType: ['number'],
              },
            },
          ],
        },
      ],
    },
  ],
};

export const newInitValue = {
  id: '8b899b8a-0123-4456-b89a-b183caaf916f',
  type: 'group',
  children1: [
    {
      type: 'rule_group',
      id: '8a98a8aa-89ab-4cde-b012-3183cab1faa9',
      properties: {
        conjunction: 'AND',
        not: false,
        field: 'Price',
      },
      children1: [
        {
          type: 'rule',
          id: 'a9b89bab-89ab-4cde-b012-3183cc2fddd6',
          properties: {
            field: 'Price.Percentage',
            operator: 'greater_or_equal',
            value: [0],
            valueSrc: ['value'],
            valueType: ['number'],
          },
        },
        {
          type: 'rule_group',
          id: '9889a8a8-89ab-4cde-b012-3183cc300cd7',
          properties: {
            conjunction: 'AND',
            not: false,
            field: 'Price.Over',
          },
          children1: [
            {
              type: 'rule',
              id: '8ababa88-cdef-4012-b456-7183cc302d7e',
              properties: {
                field: 'Price.Over.Days',
                operator: 'equal',
                value: [0],
                valueSrc: ['value'],
                valueType: ['number'],
              },
            },
          ],
        },
      ],
    },
  ],
};

export default value;
