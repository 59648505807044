import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty, head, find, get, some, map, includes } from 'lodash';
import { Box, Button, Grid, Tabs, Tab, CircularProgress, Typography } from '@mui/material';
import { coinPair } from 'utils/common';
import { sparkInstanceStopAllModels } from 'actions/ActionTypes';
import { getSparkLimitedModels, getSparkModels } from 'actions/sparkAction';
import Instances from './Instance';
import ModelSelection from './ModelSelection';
import SparkMetrics from './SparkMetrics';
import SparkSettings from './SparkSettings';
import moment from 'moment';

function LiveTimer({ duration = '0', startTime }) {
  const [elapsedTime, setElapsedTime] = useState(0);

  // Function to convert '00:00:00:20' to seconds
  function getDurationInSeconds(duration) {
    const [days, hours, minutes, seconds] = duration?.split(':')?.map(Number);
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
  }

  useEffect(() => {
    let timer;
    let initialElapsedTime = startTime;
    const storageKey = `timer-${duration}`;

    // Check if localStorage has the elapsed time for this specific duration
    const storedElapsedTime = localStorage.getItem(storageKey);

    // ::::::: MAYBE NEEDED IN FUTURE :::: For Setting Timer to Pause when the Page Changes
    // if (storedElapsedTime !== null) {
    //   initialElapsedTime = Number(storedElapsedTime);
    // }

    // If the duration prop matches the stored key, reset elapsed time
    if (storageKey === storedElapsedTime) {
      initialElapsedTime = 0;
    }

    if (initialElapsedTime >= storedElapsedTime) {
      setElapsedTime(storedElapsedTime);
    }

    setElapsedTime(initialElapsedTime);

    // if (initialElapsedTime < getDurationInSeconds(duration)) {
    timer = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
      const newElapsedTime = initialElapsedTime + 1;

      // Update localStorage with the new elapsed time
      localStorage.setItem(storageKey, newElapsedTime.toString());

      initialElapsedTime = newElapsedTime;

      if (initialElapsedTime >= getDurationInSeconds(duration)) {
        // Duration is over, remove timer from localStorage
        localStorage.removeItem(storageKey);
        clearInterval(timer);
      }
    }, 1000);
    // }
    return () => {
      clearInterval(timer);
    };
  }, [duration]);

  // Helper function to format elapsed time as 'DD:HH:MM:SS' format
  function formatElapsedTime(elapsedTime) {
    const days = Math.floor(elapsedTime / (3600 * 24));
    const hours = Math.floor((elapsedTime % (3600 * 24)) / 3600);
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = elapsedTime % 60;
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  // Helper function to pad single digits with a leading zero
  function padZero(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  return <div>{formatElapsedTime(elapsedTime)}</div>;
}

const InstancesPage = props => {
  const {
    setOnClick,
    onClick,
    value,
    setValue,
    setShowInstanceDetailPage,
    setShowModelSelectionScreen,
    showModelSelectionScreen,
    setSelectedSparkModelData,
    selectedSparkModelData,
    selectedModelIdFromModelSelection,
    setSelectedModelIdFromModelSelection,
  } = props;
  const dispatch = useDispatch();
  const [modelStatus, setModelStatus] = useState('running');
  const [modelId, setModelId] = useState('');
  const [selectedModelIdFromInstanceDetails, setSelectedModelIdFromInstanceDetails] = useState('');
  const [clearInstanceButton, setClearInstanceButton] = useState(false);
  const [modelRiskToRewardRatio, setModelRiskToRewardRatio] = useState('');
  const [modelType, setModelType] = useState('');
  const [selectedModelKey, setSelectedModelKey] = useState('');
  const [displayedModels, setDisplayedModels] = useState([]);
  const [isSelectedModelUnique, setIsSelectedModelUnique] = useState(false);

  // :::::::::::::: Maybe Needed In Future :::::::::
  // const [modelStartTime, setModelStartTime] = useState('');
  // const [modelStopTime, setModelStopTime] = useState('');

  const sparkLimitedModelsData = useSelector(state => state.spark.sparkLimitedModels);
  const getAllSparkModels = useSelector(state => state.spark.sparkModels);
  const dataFromApplyModel = useSelector(state => state.spark.isModelApplied);
  const isPending = useSelector(state => state.sim.pending);
  const isStopSparkModelLoading = useSelector(state => state.spark.isStopSparkModelLoading);
  const isGetSparkLimitedModelsDataLoading = useSelector(state => state.spark.isGetSparkLimitedModelsDataLoading);

  const isPerformanceTab = value === 0;
  const isSettingsTab = value === 1;

  useEffect(() => {
    if (!isEmpty(sparkLimitedModelsData) && isEmpty(modelId) && !dataFromApplyModel?.modelIdOfApplyModel) {
      setSelectedSparkModelData(head(sparkLimitedModelsData));
    }
  }, [sparkLimitedModelsData, modelId]);

  useEffect(() => dispatch(getSparkModels()), []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sparkInstanceStopAllModel = useCallback(() => {
    dispatch({
      type: sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            dispatch(getSparkLimitedModels());
            toast.success('All Spark models stopped successfully');
          }
        }
      },
    });
  }, []);

  // Add a function to handle replacing a model at a specific index
  const handleReplaceModel = newModelId => {
    const newModel = getAllSparkModels.find(item => item.id === newModelId);

    if (newModel) {
      // Create a copy of the displayed models and replace the model at the specified index with the new one
      const newDisplayedModels = [...displayedModels];
      newDisplayedModels[selectedModelKey] = newModel;

      // Update the displayed models state
      setDisplayedModels(newDisplayedModels);
    }
  };

  function convertTimeToSeconds(timeString) {
    if (timeString === undefined) {
      return 0;
    }
    // Split the timeString into components
    const timeComponents = timeString.split(':');

    if (timeComponents.length !== 4) {
      return 0;
    }

    // Extract days, hours, minutes, and seconds
    const days = parseInt(timeComponents[0], 10);
    const hours = parseInt(timeComponents[1], 10);
    const minutes = parseInt(timeComponents[2], 10);
    const seconds = parseInt(timeComponents[3], 10);

    // Calculate the total time duration
    const duration = moment.duration({
      days,
      hours,
      minutes,
      seconds,
    });

    // Get the total time in seconds
    const totalSeconds = duration.asSeconds();

    return totalSeconds;
  }

  return (
    <>
      {showModelSelectionScreen ? (
        <ModelSelection
          onPressClick={modelId => {
            setIsSelectedModelUnique(
              !includes(
                map(sparkLimitedModelsData, model => model.id),
                modelId,
              ),
            );
            setModelId(modelId);
            setSelectedModelIdFromModelSelection(modelId);
            setShowInstanceDetailPage(true);
            setShowModelSelectionScreen(false);
          }}
        />
      ) : (
        <>
          <Grid sx={onClick || dataFromApplyModel?.openSparkInstanceTabSection ? { display: 'none' } : {}}>
            <>
              {isGetSparkLimitedModelsDataLoading ? (
                <Box
                  sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <>
                  {!isEmpty(sparkLimitedModelsData) ? (
                    map(sparkLimitedModelsData, (data, key) => {
                      const sameModelExist = find(
                        getAllSparkModels,
                        model => model?.simModelId?.id === data?.simModelId?.id && model?.id !== data?.id,
                      )
                        ? true
                        : false;

                      const currentDate = new Date();
                      const modelStartDate = new Date(data.modelStartTime);
                      const timeElapsedInSeconds = Math.floor((currentDate - modelStartDate) / 1000);
                      const durationTimerInSeconds = convertTimeToSeconds(data?.durationTimer);
                      const startTime = durationTimerInSeconds
                        ? timeElapsedInSeconds < durationTimerInSeconds
                          ? timeElapsedInSeconds
                          : durationTimerInSeconds
                        : timeElapsedInSeconds;

                      const isDurationTimer = data.durationTimer && data.durationTimer !== '0';
                      const isMaxDrawdown = data.maxDrawdown && data.maxDrawdown !== '0';
                      const isLossRate =
                        data.losses && data.losses !== '0' && data.rollingTrades && data.rollingTrades !== '0';

                      return (
                        <Instances
                          key={key}
                          selectedModelKey={key + 1}
                          setSelectedModelKey={setSelectedModelKey}
                          setClearInstanceButton={setClearInstanceButton}
                          modelData={data}
                          data={displayedModels}
                          handleReplaceModel={handleReplaceModel}
                          instanceName={`Instance ${key + 1}`}
                          title={get(data, 'name', '-')}
                          currentVersion={get(data, 'currentVersion', '-')}
                          version={get(data, 'simModelId.version', '')}
                          promotedStatus={get(data, 'promotedStatus', '-')}
                          sameModelExist={sameModelExist}
                          status={get(data, 'modelStatus', '-')}
                          reason={
                            data.modelStatus === 'running'
                              ? get(data, 'modelStartReason', '-')
                              : data.modelStatus === 'halted'
                              ? get(data, 'modelStopReason', '-')
                              : data.modelStatus === 'idle' && get(data, 'modelStartReason', '-')
                          }
                          duration={
                            data.modelStatus === 'running' && (isDurationTimer || isMaxDrawdown || isLossRate) ? (
                              <LiveTimer duration={data.durationTimer} startTime={startTime} />
                            ) : (
                              get(data, 'durationTimer', '-')
                            )
                          }
                          initiated={get(data, 'createdBy.name', '-')}
                          textFontColor={
                            data.modelStatus === 'running'
                              ? '#50E3C2'
                              : data.modelStatus === 'halted'
                              ? '#E3507A'
                              : data.modelStatus === 'idle' && '#B1AFCD'
                          }
                          isRunningModel={false}
                          cryptoPairs={coinPair(data)}
                          setOnClick={setOnClick}
                          id={get(data, 'id')}
                          setSelectedModelIdFromInstanceDetails={setSelectedModelIdFromInstanceDetails}
                          setModelId={setModelId}
                          setModelRiskToRewardRatio={setModelRiskToRewardRatio}
                          setModelType={setModelType}
                          setModelStatus={setModelStatus}
                          riskToRewardRatio={get(data, 'riskToRewardRatio', '')}
                          modelType={get(data, 'modelType', '')}
                          modelStartTime={get(data, 'startTime', '')}
                          modelStopTime={get(data, 'endTime', '')}
                          modelUpValue={get(data, 'up', 0)}
                          modelDownValue={get(data, 'down', 0)}
                          modelStopLossValue={get(data, 'stopLoss', 0)}
                          bias={get(data, 'bias', '')}
                          rebuyMode={get(data, 'rebuyMode', '')}
                          modelId={modelId}
                          selectedSparkModelData={selectedSparkModelData}
                          setSelectedSparkModelData={setSelectedSparkModelData}
                        />
                      );
                    })
                  ) : (
                    <Box
                      sx={{
                        height: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography color="white">No Models Available</Typography>
                    </Box>
                  )}
                  {!isEmpty(sparkLimitedModelsData) && (
                    <Box
                      container
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0.5rem 0 0 1rem',
                      }}
                    >
                      <Box sx={{ width: '100%' }}>
                        <Button
                          variant="outlined"
                          disabled={
                            isStopSparkModelLoading ||
                            !some(sparkLimitedModelsData, {
                              modelStatus: 'running',
                            })
                          }
                          onClick={() => {
                            sparkInstanceStopAllModel();
                          }}
                          sx={{
                            color: '#FFFFFF',
                            backgroundColor: '#000000',
                            border: '2px solid #F1A305',
                            width: '98%',
                            marginTop: '3rem',
                            textTransform: 'unset ',
                            display: isPending ? 'none' : 'block',
                            '&.Mui-disabled': {
                              color: '#FFF',
                              border: '2px solid #F1A305',
                            },
                            '&:disabled': {
                              cursor: 'not-allowed',
                              pointerEvents: 'all',
                              backgroundColor: 'grey',
                              color: 'white',
                              border: 'none',
                            },
                          }}
                        >
                          {isStopSparkModelLoading && <CircularProgress size={17} />}
                          Stop All
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Grid xs={12} />
                  <Grid sx={{ mt: '40%' }} />
                </>
              )}
            </>
          </Grid>
          <Box
            sx={onClick || dataFromApplyModel?.openSparkInstanceTabSection ? { width: '100%' } : { display: 'none' }}
          >
            <Tabs
              aria-label="Tabs where each tab needs to be selected manually"
              onChange={handleChange}
              value={value}
              variant="fullWidth"
              sx={{
                justifyContent: 'space-around',
                height: '3rem',
              }}
              textColor={isPerformanceTab ? '#FFD41F' : '#B1AFCD'}
              TabIndicatorProps={{ style: { backgroundColor: '#FFD41F' } }}
            >
              <Tab
                iconPosition="start"
                label="Performance"
                sx={theme => ({
                  color: isPerformanceTab ? '#FFD41F' : '#B1AFCD',
                  [theme.breakpoints.down('md')]: {
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  },
                })}
              />
              <Tab
                iconPosition="start"
                label="Settings"
                sx={theme => ({
                  color: isSettingsTab ? '#FFD41F' : '#B1AFCD',
                  [theme.breakpoints.down('md')]: {
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  },
                })}
              />
            </Tabs>
            {isPerformanceTab && (
              <SparkMetrics
                modelId={modelId}
                modelStatus={modelStatus}
                modelIdOfApplyModel={dataFromApplyModel?.modelIdOfApplyModel}
              />
            )}
            {isSettingsTab && (
              <SparkSettings
                modelId={modelId}
                onClick={onClick}
                setOnClick={setOnClick}
                setModelId={setModelId}
                isSelectedModelUnique={isSelectedModelUnique}
                clearInstanceButton={clearInstanceButton}
                setClearInstanceButton={setClearInstanceButton}
                modelRiskToRewardRatio={modelRiskToRewardRatio}
                modelType={modelType}
                isSparkStartModel={
                  dataFromApplyModel?.modelStatus
                    ? dataFromApplyModel?.modelStatus !== 'running'
                    : modelStatus !== 'running'
                }
                handleReplaceModel={handleReplaceModel}
                selectedModelKey={selectedModelKey}
                modelIdOfApplyModel={dataFromApplyModel?.modelIdOfApplyModel}
                setShowInstanceDetailPage={setShowInstanceDetailPage}
                setShowModelSelectionScreen={setShowModelSelectionScreen}
                showModelSelectionScreen={showModelSelectionScreen}
                selectedSparkModelData={selectedSparkModelData}
                setSelectedSparkModelData={setSelectedSparkModelData}
                selectedModelIdFromInstanceDetails={selectedModelIdFromInstanceDetails}
                selectedModelIdFromModelSelection={selectedModelIdFromModelSelection}
                setSelectedModelIdFromModelSelection={setSelectedModelIdFromModelSelection}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default InstancesPage;
