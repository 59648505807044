import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from 'apis';
import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from 'actions/authAction';

function* login(action) {
  try {
    const response = yield call(API.login, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: response.data.data });
  } catch (e) {
    yield put({ type: LOGIN_FAIL, payload: { error: e } });
  }
}

function* auth() {
  yield takeLatest(LOGIN, login);
}

export default auth;
