import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Autocomplete,
  Button,
  TextField,
  Grid,
  Paper,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { map } from 'lodash';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Search, Email, Warning, LastPage, FirstPage, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useStyles } from 'components/common/utils';
import NotificationDetail from 'components/notification/NotificationDetail';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

const columns = [
  { id: 'type', label: 'Type', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'redirect',
    label: 'Redirect',
    align: 'center',
    minWidth: 50,
  },
  {
    id: 'utcTime',
    label: 'UTC Time',
    align: 'center',
  },
  {
    id: 'localTime',
    label: 'Local Time',
    align: 'center',
  },
];

function createData(type, redirect, utcTime, localTime) {
  const name = (
    <Stack direction={'column'} spacing={0.5}>
      <Typography component={'span'}>SPARK Stopped</Typography>
      <Typography component={'span'}>By User James</Typography>
    </Stack>
  );
  return { type, name, redirect, utcTime, localTime };
}

const rows = [
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
  createData(
    <Warning fontSize="large" style={{ color: 'red' }} />,
    <Warning fontSize="large" style={{ color: 'red' }} />,
    'Sat 07/23/22 12:00:00 UTC',
    'Sat 07/23/22 06:00:00 MST',
  ),
];

const Notification = () => {
  const [value, setValue] = React.useState('liveFeed');
  const [valueOfTab, setValueOfTab] = useState(0);
  const [search, setSearch] = React.useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };

  const filters = [
    { label: 'SPARK' },
    { label: 'LUNA' },
    { label: 'ANGEL' },
    { label: 'SENTRY' },
    { label: 'FORCE' },
    { label: 'ADMIN' },
  ];

  const notificationType = [
    { label: 'Faults' },
    { label: 'Warnings' },
    { label: 'Alerts' },
    { label: 'Events' },
    { label: 'System' },
  ];

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPage style={{ color: '#fff' }} /> : <FirstPage style={{ color: '#fff' }} />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight style={{ color: '#fff' }} />
          ) : (
            <KeyboardArrowLeft style={{ color: '#fff' }} />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft style={{ color: '#fff' }} />
          ) : (
            <KeyboardArrowRight style={{ color: '#fff' }} />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage style={{ color: '#fff' }} /> : <LastPage style={{ color: '#fff' }} />}
        </IconButton>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '1.5rem', background: '#040d3e' }}>
      <Grid container spacing={2}>
        <Grid item lg={2} md={12} sx={{ marginBottom: '1rem !important' }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={6}>
              <Box sx={{ marginBottom: '2rem' }}>
                <Typography
                  variant={'h6'}
                  component="div"
                  sx={{ fontWeight: '700', marginBottom: '1rem', color: '#fff' }}
                >
                  Filter Logs
                </Typography>
                <FormGroup>
                  {map(filters, data => {
                    return (
                      <FormControlLabel
                        sx={{ color: '#fff' }}
                        control={<Checkbox style={{ marginRight: '0.5rem', color: '#fff' }} />}
                        label={data.label}
                      />
                    );
                  })}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item lg={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl>
                  <FormLabel
                    id="radio-buttons-group-label"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.25rem',
                      color: '#fff !important',
                    }}
                  >
                    Timeframes
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      sx={{ color: '#fff' }}
                      value="liveFeed"
                      control={<Radio sx={{ color: '#fff' }} />}
                      label="Live Feed"
                    />
                    <FormControlLabel
                      sx={{ color: '#fff' }}
                      value="newEvents "
                      control={<Radio sx={{ color: '#fff' }} />}
                      label="New Events"
                    />
                    <Stack direction={'row'} spacing={1}>
                      <FormControlLabel
                        sx={{ color: '#fff', marginRight: 0 }}
                        value="other"
                        control={<Radio sx={{ color: '#fff' }} />}
                        label=""
                      />
                      <Autocomplete
                        disablePortal
                        id="days"
                        name="days"
                        options={[
                          {
                            value: 'Today',
                            label: 'Today',
                          },
                        ]}
                        size="small"
                        sx={theme => ({
                          borderRadius: 2,
                          width: '100%',
                          marginLeft: '0px !important',
                          color: '#fff',
                        })}
                        renderInput={params => (
                          <TextField
                            sx={theme => ({
                              svg: {
                                color: '#54DAFF',
                              },
                              border: '1px solid #3FD2F2',
                              borderRadius: '4px',
                              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #484850',
                                borderRadius: '5px 5px 0 0',
                              },
                              input: {
                                color: 'white',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '12px',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '11px',
                                },
                              },
                            })}
                            {...params}
                            label=""
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction={'column'} spacing={1}>
                      <FormControlLabel
                        sx={{ color: '#fff' }}
                        value="dateRange"
                        control={<Radio sx={{ color: '#fff' }} />}
                        label="Date Range"
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingTop: '0 !important',
                              '&.MuiGrid-item': {
                                paddingTop: '0px !important',
                              },
                            }}
                          >
                            <label
                              style={{
                                color: '#fff',
                                fontWeight: '700',
                              }}
                            >
                              From
                            </label>
                          </Grid>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sx={theme => ({
                              '&.MuiGrid-item': {
                                paddingTop: '0px !important',
                              },
                            })}
                          >
                            <DatePicker
                              label=""
                              name="startDate"
                              value={startDate}
                              onChange={e => {
                                setStartDate(moment(e).format());
                              }}
                              maxDate={new Date()}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  sx={theme => ({
                                    svg: {
                                      color: 'white',
                                    },
                                    input: {
                                      color: 'white',
                                      [theme.breakpoints.up('lg')]: {
                                        fontSize: '12px',
                                      },
                                      [theme.breakpoints.down('lg')]: {
                                        fontSize: '12px',
                                        paddingRight: '0px',
                                      },
                                      [theme.breakpoints.down('md')]: {
                                        fontSize: '11px',
                                        paddingRight: '0px',
                                      },
                                    },
                                    background: '#000000',
                                    border: '1px solid #3FD2F2',
                                    borderRadius: '4px',
                                  })}
                                />
                              )}
                              InputAdornmentProps={{
                                position: 'start',
                                color: '#54DAFF',
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingTop: '0 !important',
                              '&.MuiGrid-item': {
                                paddingTop: '0px !important',
                              },
                            }}
                          >
                            <label style={{ color: '#fff', fontWeight: '700' }}>To</label>
                          </Grid>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sx={theme => ({
                              '&.MuiGrid-item': {
                                paddingTop: '0px !important',
                              },
                            })}
                          >
                            <DatePicker
                              label=""
                              name="endDate"
                              value={endDate}
                              onChange={e => {
                                setEndDate(moment(e).format());
                              }}
                              maxDate={new Date()}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  sx={theme => ({
                                    svg: {
                                      color: 'white',
                                    },
                                    input: {
                                      color: 'white',
                                      [theme.breakpoints.up('lg')]: {
                                        fontSize: '12px',
                                      },
                                      [theme.breakpoints.down('lg')]: {
                                        fontSize: '12px',
                                        paddingRight: '0px',
                                      },
                                      [theme.breakpoints.down('md')]: {
                                        fontSize: '11px',
                                        paddingRight: '0px',
                                      },
                                    },
                                    background: '#000000',
                                    border: '1px solid #3FD2F2',
                                    borderRadius: '4px',
                                  })}
                                />
                              )}
                              InputAdornmentProps={{ position: 'start' }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <Button
                  startIcon={<BsFileEarmarkArrowDownFill />}
                  variant="outlined"
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: '#000000',
                    border: '2px solid #3FD2F2',
                    textTransform: 'unset !important',
                    marginTop: '1.5rem',
                  }}
                >
                  Export Selected
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={10} md={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Paper
              component="form"
              sx={theme => ({
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 500,
                [theme.breakpoints.down('md')]: {
                  width: 350,
                },
                background: '#000000',
                border: '1px solid #3FD2F2',
              })}
            >
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <Search style={{ color: '#3FD2F2' }} />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1, color: '#fff' }}
                value={search}
                placeholder="Search by Name, Date, Time, or Ticker/Name"
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />
            </Paper>
            <Button
              startIcon={<Email />}
              variant="outlined"
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#000000',
                border: '2px solid #3FD2F2',
                textTransform: 'unset !important',
              }}
            >
              Mark all Read
            </Button>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '1rem' }}>
            {map(notificationType, data => {
              return (
                <FormControlLabel
                  sx={{ color: '#fff' }}
                  control={<Checkbox sx={{ color: '#fff' }} />}
                  label={data.label}
                />
              );
            })}
          </Box>
          <Grid container spacing={0} sx={{ marginTop: '0.5rem' }}>
            <Grid item lg={9} md={7} sx={{ padding: '0px !important', border: '2px solid #3FD2F2' }}>
              <Paper sx={{ width: '100%', overflow: 'hidden', background: '#131E68', color: '#fff' }}>
                <TableContainer
                  sx={theme => ({
                    height: 700,
                    '&::-webkit-scrollbar': {
                      width: '7px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#171616',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#175982',
                      borderRadius: 2,
                    },
                  })}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ background: '#131E68', color: '#fff' }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map(column => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align} sx={{ color: '#fff' }}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  sx={{ color: '#fff' }}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Paper>
            </Grid>
            <Grid
              lg={3}
              md={5}
              sx={theme => ({
                border: '2px solid #3FD2F2',
                borderLeft: 'unset',
                [theme.breakpoints.down('md')]: {
                  borderLeft: '2px solid',
                },
              })}
            >
              <NotificationDetail handleChangeTab={handleChangeTab} valueOfTab={valueOfTab} classes={classes} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Notification;
