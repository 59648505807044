import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import TableComponent from 'components/common/TableComponent';

const BottomTab = ({ open, classes, selectedModelData }) => {
  const modelTradeData = useSelector(state => state.sim.modelTradeData);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {/* <Box sx={{ background: '#000000', color: '#FFF' }}>
        <Tabs
          aria-label="Tabs where each tab needs to be selected manually"
          onChange={handleChange}
          value={value}
          variant="fullWidth"
          sx={{
            justifyContent: 'space-around',
          }}
          textColor={value === 0 ? '#FFD41F' : '#B1AFCD'}
          TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
        >
          <Tab
            label="All"
            sx={{
              color: value === 0 ? '#FFD41F' : '#B1AFCD',
              maxWidth: '15%',
              borderRight: '0.5px solid rgba(208, 215, 255, 0.5)',
            }}
          />
        </Tabs>
      </Box> */}
      <Box sx={{ height: '100%' }}>
        <TableComponent classes={classes} selectedModelData={selectedModelData} modelTradeData={modelTradeData} />
      </Box>
    </Box>
  );
};

export default BottomTab;
