import React from 'react';
import useAuth from './useAuth';
import { cookie } from 'helpers/cookies';
import { TOKEN_NAME } from 'constants/token';
import { setLocalStorage } from 'helpers/localStorage';
import { post } from 'utils/api';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const refreshToken = cookie.get(TOKEN_NAME.REFRESH_TOKEN);
  return async () => {
    const response = await post(
      '/v1/client/auth/refresh-tokens',
      {
        refreshToken: refreshToken,
      },
      {
        withCredentials: false,
      },
    );
    setLocalStorage(TOKEN_NAME.ACCESS_TOKEN, response.data.data.access.token);
    cookie.set(TOKEN_NAME.ACCESS_TOKEN, response.data.data.access.token);
    cookie.set(TOKEN_NAME.REFRESH_TOKEN, response?.data?.data.refresh.token);
    const username = JSON.parse(window.atob(response?.data?.data.access.token.split('.')[1])).sub; /**/
    setAuth(prev => {
      return {
        ...prev,
        username: username,
        accessToken: response.data.data.access.token,
      };
    });
    return response.data.data.access.token;
  };
};

export default useRefreshToken;
