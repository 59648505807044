import { usersTypes } from './ActionTypes';

export const fetchUsersRequest = () => ({
  type: usersTypes.FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = payload => ({
  type: usersTypes.FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = payload => ({
  type: usersTypes.FETCH_USERS_FAILURE,
  payload,
});
