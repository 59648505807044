import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { upperFirst, round } from 'lodash';
import { BsBoxes } from 'react-icons/bs';
import InfoIcon from '../common/InfoIcon';
import { AiOutlineStar } from 'react-icons/ai';
import { PiInfinityBold, PiRecycleBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import BullIcon from '../../assets/ico_bull.svg';
import BearIcon from '../../assets/ico_bear.svg';
import RangeIcon from '../../assets/ico_range.svg';
import { USER_ROLE } from 'constants/token';
import { cookie } from 'helpers/cookies';
import { isModelApplied } from 'actions/sparkAction';

function OptimizeBlueIcon() {
  return (
    <SvgIcon
      sx={{
        width: '1.3em !important',
        margin: '0 !important',
        height: '1.5em !important',
      }}
    >
      <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.10982 18.2549L4.71434 15.657C4.16266 15.3309 3.82593 14.73 3.82593 14.0814V8.88927C3.82593 8.24071 4.16625 7.63978 4.71434 7.31367L9.10982 4.71577C9.6615 4.38966 10.3386 4.38966 10.8902 4.71577L15.2857 7.31367C15.8374 7.63978 16.1741 8.24071 16.1741 8.88927V14.0814C16.1741 14.73 15.8338 15.3309 15.2857 15.657L10.8902 18.2549C10.3386 18.581 9.6615 18.581 9.10982 18.2549Z"
          fill="#00FFD1"
        />
        <path
          d="M0.268673 10.7616C0.121798 10.7616 0 10.6371 0 10.4868V10.0764C0 9.92622 0.121798 9.80164 0.268673 9.80164C0.415547 9.80164 0.537345 9.92622 0.537345 10.0764V10.4868C0.537345 10.6371 0.415547 10.7616 0.268673 10.7616Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99821 22.9707C9.63998 22.9707 9.28533 22.8754 8.97367 22.6922L1.02454 17.9947C0.390471 17.621 0 16.9285 0 16.181V11.3516C0 11.2014 0.121798 11.0768 0.268673 11.0768C0.415547 11.0768 0.537345 11.2014 0.537345 11.3516V16.181C0.537345 16.7343 0.827512 17.2436 1.29321 17.5184L9.24592 22.2159C9.71521 22.4907 10.2884 22.4907 10.7577 22.2159L18.7104 17.5184C19.1761 17.2399 19.4662 16.7306 19.4662 16.181V6.78971C19.4662 6.23642 19.1761 5.7271 18.7104 5.45229L10.7541 0.75482C10.2848 0.480007 9.71162 0.480007 9.24234 0.75482L1.29321 5.45229C0.827512 5.73076 0.537345 6.24008 0.537345 6.78971V9.20806C0.537345 9.35829 0.415547 9.48287 0.268673 9.48287C0.121798 9.48287 0 9.36196 0 9.20806V6.78971C0 6.04222 0.390471 5.34969 1.02454 4.97595L8.97725 0.278478C9.61132 -0.0952674 10.3923 -0.0952674 11.0263 0.278478L18.979 4.97595C19.6131 5.34969 20.0036 6.04222 20.0036 6.78971V16.181C20.0036 16.9285 19.6131 17.621 18.979 17.9947L11.0263 22.6922C10.7111 22.8754 10.36 22.9707 9.99821 22.9707Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99828 18.7715C9.64004 18.7715 9.2854 18.6762 8.97374 18.493L4.57825 15.8951C3.94418 15.5214 3.55371 14.8289 3.55371 14.0814V8.88924C3.55371 8.14174 3.94418 7.44922 4.57825 7.07547L8.97374 4.47757C9.6078 4.10383 10.3887 4.10383 11.0228 4.47757L15.4183 7.07547C16.0524 7.44922 16.4428 8.14174 16.4428 8.88924V14.0814C16.4428 14.8289 16.0524 15.5214 15.4183 15.8951L11.0228 18.493C10.7112 18.6762 10.3601 18.7715 9.99828 18.7715ZM9.99828 4.74872C9.73318 4.74872 9.47168 4.81834 9.24241 4.95392L4.84692 7.55181C4.38122 7.82663 4.09106 8.33961 4.09106 8.88924V14.0814C4.09106 14.6347 4.38122 15.144 4.84692 15.4188L9.24241 18.0167C9.71169 18.2915 10.2849 18.2915 10.7541 18.0167L15.1496 15.4188C15.6153 15.144 15.9055 14.631 15.9055 14.0814V8.88924C15.9055 8.33595 15.6153 7.82663 15.1496 7.55181L10.7541 4.95392C10.5249 4.81834 10.2634 4.74872 9.99828 4.74872Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99816 23C9.85129 23 9.72949 22.8754 9.72949 22.7252V18.5004C9.72949 18.3502 9.85129 18.2256 9.99816 18.2256C10.145 18.2256 10.2668 18.3502 10.2668 18.5004V22.7252C10.2668 22.8754 10.1486 23 9.99816 23Z"
          fill="#10FAC2"
        />
        <path
          d="M15.9628 8.29936C15.8159 8.29936 15.6941 8.17478 15.6941 8.02455C15.6941 7.92561 15.7442 7.83767 15.8266 7.78638L19.3588 5.64284C19.4878 5.56589 19.6525 5.60619 19.7278 5.7381C19.803 5.87001 19.7636 6.03857 19.6346 6.11551C19.6346 6.11551 19.6346 6.11551 19.631 6.11551L16.0989 8.25905C16.0595 8.28837 16.0093 8.29936 15.9628 8.29936Z"
          fill="#10FAC2"
        />
        <path
          d="M4.03717 8.29938C3.9906 8.29938 3.94045 8.28472 3.90104 8.26274L0.379639 6.12652C0.250676 6.04591 0.211271 5.87736 0.286499 5.74911C0.361727 5.62087 0.526513 5.5769 0.651894 5.65384L4.1733 7.79006C4.30226 7.867 4.34167 8.03556 4.26644 8.16747C4.21987 8.24808 4.13031 8.29938 4.03717 8.29938Z"
          fill="#10FAC2"
        />
        <path
          d="M9.99816 11.762C9.85129 11.762 9.72949 11.6374 9.72949 11.4872V4.59854C9.72949 4.44831 9.85129 4.32373 9.99816 4.32373C10.145 4.32373 10.2668 4.44831 10.2668 4.59854V11.4872C10.2668 11.6374 10.1486 11.762 9.99816 11.762Z"
          fill="#315048"
        />
        <path
          d="M15.8123 15.4079C15.7621 15.4079 15.712 15.3932 15.669 15.3639L10.3815 11.9526C10.1523 11.806 9.85851 11.806 9.62566 11.9526L4.33818 15.3639C4.2128 15.4445 4.04802 15.4079 3.96921 15.2796C3.8904 15.1514 3.92622 14.9828 4.0516 14.9022L9.33908 11.4909C9.74388 11.2307 10.2597 11.2307 10.6681 11.4909L15.9556 14.9022C16.081 14.9828 16.1204 15.1514 16.038 15.2796C15.9914 15.3603 15.9054 15.4079 15.8123 15.4079Z"
          fill="#315048"
        />
      </svg>
    </SvgIcon>
  );
}

function CIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '2em',
          marginBottom: '0',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.2em',
          marginBottom: '9.5rem',
          fontSize: '1.35rem',
        },
        [theme.breakpoints.down('md')]: {
          width: '1.2em',
          marginBottom: '9rem',
          fontSize: '1.1rem',
        },
      })}
    >
      <svg
        width="30px"
        height="25px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        class="iconify iconify--emojione"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="32" cy="32" r="30" fill="#2C80B9"></circle>
        <path
          d="M23.3 20.4c2.3-2.4 5.3-3.6 8.9-3.6c4.8 0 8.4 1.6 10.6 4.8c1.2 1.8 1.9 3.6 2 5.4h-6.1c-.4-1.4-.9-2.4-1.5-3.1c-1.1-1.3-2.7-1.9-4.8-1.9c-2.2 0-3.9.9-5.1 2.7c-1.2 1.8-1.9 4.3-1.9 7.5c0 3.3.7 5.7 2 7.3c1.3 1.6 3 2.4 5 2.4c2.1 0 3.7-.7 4.8-2.1c.6-.7 1.1-1.9 1.5-3.4h6c-.5 3.2-1.8 5.7-4 7.7c-2.1 2-4.9 3-8.2 3c-4.1 0-7.4-1.3-9.7-4c-2.4-2.7-3.5-6.4-3.5-11.1c-.1-5 1.3-8.9 4-11.6"
          fill="#FFF"
        ></path>
      </svg>
    </SvgIcon>
  );
}

function RightArrow() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.5em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '0.8em',
        },
      })}
    >
      <svg
        fill="#FFF"
        height="20px"
        width="20px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47.255 47.255"
      >
        <g>
          <path
            d="M12.314,47.255c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l21.92-21.92l-21.92-21.92
		c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L35.648,22.92c0.391,0.391,0.391,1.023,0,1.414L13.021,46.962
		C12.825,47.157,12.57,47.255,12.314,47.255z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '100%',
    minHeight: 180,
    border: '1px solid grey',
    fontSize: 17,
    color: '#B1AFCD',
    display: 'flex',
    alignItems: 'center',
  },
  containerTall: {
    minHeight: 250,
  },
});

const Instances = props => {
  const classes = useStyles();
  const {
    data,
    status,
    instanceName,
    duration,
    reason,
    title,
    textFontColor,
    isRunningModel = false,
    isChangeModel = false,
    isApplyModel = false,
    modelStatusBackgroundColor,
    buttonBackgroundColor,
    setOnClick,
    setModelStatus,
    cryptoPairs,
    id,
    selectedSimModelToApplyId,
    riskToRewardRatio,
    modelType,
    setModelId,
    setModelRiskToRewardRatio,
    modelUpValue,
    modelDownValue,
    modelStopLossValue,
    currentVersion,
    bias,
    rebuyMode,
    selectedSparkModelData,
    setSelectedSparkModelData = () => {},
    setModelType = () => {},
    version,
    sameModelExist,
    setClearInstanceButton,
    selectedModelKey,
    setSelectedModelKey = () => {},
    isSelectedModelUnique,
    setSelectedModelIdFromInstanceDetails,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = cookie.get(USER_ROLE.USER);

  return (
    <>
      <Box
        display="flex"
        container
        direction="row"
        xs={12}
        md={12}
        sx={{
          border: id === selectedSparkModelData?.id ? '0.2rem solid #3FD2F2 !important' : 'none',
        }}
      >
        <Grid
          item
          xs={1}
          md={1}
          onClick={() => {
            setSelectedSparkModelData && setSelectedSparkModelData({ id, riskToRewardRatio, modelType });
          }}
        >
          <div className={classes.container}>
            <Box
              sx={theme => ({
                position: 'relative',
                left: '10',
                transform: 'rotate(270deg)',
                transformOrigin: '(0,0)',
                [theme.breakpoints.up('lg')]: {
                  marginLeft: '-90px !important',
                },
                [theme.breakpoints.down('lg')]: {
                  marginLeft: '-28px !important',
                },
                [theme.breakpoints.down('md')]: {
                  marginLeft: '-30px !important',
                },
              })}
            >
              <Typography display="flex" gap="1rem" minWidth="13rem" justifyContent="center" alignItems="center">
                <Box sx={{ transform: 'rotate(90deg)' }}>
                  <AiOutlineStar style={{ color: '#F6B900' }} />
                </Box>
                {instanceName}
              </Typography>
            </Box>
            <Typography
              sx={{
                padding: '0 0 12rem 0rem',
                position: 'absolute',
                top: '0.8rem',
                left: '1.1rem',
                zIndex: 1,
              }}
            >
              <CIcon />
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={!isRunningModel && !isChangeModel && !isApplyModel ? 10 : 11}
          md={!isRunningModel && !isChangeModel && !isApplyModel ? 10 : 11}
          onClick={() => {
            setSelectedSparkModelData && setSelectedSparkModelData({ id, riskToRewardRatio, modelType });
          }}
        >
          <Grid xs={12} md={12}>
            <Typography
              className={classes.container}
              sx={theme => ({
                minHeight: '46px !important',
                padding: '0.65rem',
                fontWeight: '500',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start !important',
                [theme.breakpoints.up('lg')]: {
                  fontSize: 17,
                  paddingLeft: '1rem',
                },
                [theme.breakpoints.down('lg')]: {
                  fontSize: 14,
                  paddingLeft: '1.1rem',
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: 11,
                  paddingLeft: '1.1rem',
                },
              })}
            >
              <Box
                sx={{
                  maxWidth: '25rem !important',
                  overflow: 'hidden !important',
                  whiteSpace: 'normal',
                }}
              >
                {title}
              </Box>
              <Box
                sx={{
                  fontSize: '0.9rem',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexShrink: 0 }}>v{currentVersion}.0 </Box>
                <Box sx={{ display: 'flex', flexShrink: 0 }}>
                  {sameModelExist && version > currentVersion ? (
                    <Box sx={{ display: 'flex', flexShrink: 0 }}>
                      (
                      <Typography
                        style={{
                          color: '#CF9031',
                          fontSize: '0.8rem',
                          fontWeight: 600,
                        }}
                      >
                        WARNING:{' '}
                      </Typography>
                      {'  '}v{version}.0 Model Available)
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Typography>
          </Grid>
          <Grid xs={12} md={12} direction="row">
            <Box
              sx={theme => ({
                height: '100%',
                minHeight: 150,
                border: '1px solid grey',
                fontSize: 20,
                color: '#B1AFCD',
                textAlign: 'center',
                alignItems: 'center',
                [theme.breakpoints.up('lg')]: {
                  display: 'flex',
                },
                [theme.breakpoints.down('lg')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid xs={12} md={8} style={{ maxWidth: '100%' }}>
                <Box
                  sx={theme => ({
                    [theme.breakpoints.up('lg')]: {
                      borderRight: '1px solid grey',
                      height: '12rem',
                    },
                    [theme.breakpoints.down('lg')]: {
                      borderBottom: '1px solid grey',
                    },
                    [theme.breakpoints.down('md')]: {
                      borderBottom: '1px solid grey',
                    },
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '7px !important',
                      gap: '0.5rem !important',
                    }}
                  >
                    <OptimizeBlueIcon />
                    <Typography
                      sx={{
                        fontWeight: '600 !important',
                        marginTop: '0.4rem !important',
                      }}
                    >
                      {cryptoPairs}
                    </Typography>
                  </Box>

                  <Box
                    sx={theme => ({
                      display: 'flex',
                      [theme.breakpoints.up('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '5px 0px 0px 3px',
                      },
                    })}
                  >
                    <Typography
                      sx={theme => ({
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '0.95rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.95rem',
                        },
                      })}
                    >
                      Status:
                    </Typography>
                    <Typography
                      sx={theme => ({
                        color: textFontColor,
                        marginLeft: '0.2rem !important',
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.95rem',
                        },
                      })}
                    >
                      {upperFirst(status)}
                    </Typography>
                  </Box>

                  <Box
                    sx={theme => ({
                      display: 'flex',
                      [theme.breakpoints.up('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '5px 0px 0px 3px',
                      },
                    })}
                  >
                    <Typography
                      sx={theme => ({
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.95rem',
                        },
                      })}
                    >
                      Reason:
                    </Typography>
                    <Tooltip title={reason}>
                      <Box
                        sx={theme => ({
                          color: textFontColor,
                          marginLeft: '0.2rem',
                          width: '100px !important',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          textAlign: 'left',
                          wordWrap: 'break-word',
                          whiteSpace: 'wrap',
                          WebkitLineClamp: 2, // Number of lines to show
                          WebkitBoxOrient: 'vertical',

                          [theme.breakpoints.up('lg')]: {
                            fontSize: '1rem',
                          },
                          [theme.breakpoints.down('lg')]: {
                            fontSize: '1rem',
                          },
                          [theme.breakpoints.down('md')]: {
                            fontSize: '0.9rem',
                          },
                        })}
                      >
                        {reason}
                      </Box>
                    </Tooltip>
                  </Box>

                  <Box
                    sx={theme => ({
                      display: 'flex',
                      [theme.breakpoints.up('lg')]: {
                        padding: '13px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '5px 0px 0px 3px',
                      },
                    })}
                  >
                    <Typography
                      sx={theme => ({
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.95rem',
                        },
                      })}
                    >
                      Duration:
                    </Typography>
                    <Typography
                      sx={theme => ({
                        marginLeft: '0.2rem !important',
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.9rem',
                        },
                      })}
                    >
                      {duration}
                    </Typography>
                  </Box>

                  <Box
                    sx={theme => ({
                      display: 'flex',
                      [theme.breakpoints.up('lg')]: {
                        padding: '5px 5px 0px 10px',
                      },
                      [theme.breakpoints.down('lg')]: {
                        padding: '5px 5px 5px 10px',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '5px 0px 5px 3px',
                      },
                    })}
                  >
                    <Typography
                      sx={theme => ({
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.95rem',
                        },
                      })}
                    >
                      Initiated:
                    </Typography>
                    <Typography
                      sx={theme => ({
                        color: '#B1AFCD',
                        marginLeft: '0.2rem !important',
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '1rem',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.9rem',
                        },
                      })}
                    >
                      {upperFirst(user)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 1.5,
                    marginBottom: '-0.5rem',
                    paddingTop: '0.5rem',
                  }}
                >
                  <Box>
                    {bias === 'bull' ? (
                      <img src={BullIcon} alt="bull icon" />
                    ) : bias === 'bear' ? (
                      <img src={BearIcon} alt="bear icon" />
                    ) : bias === 'range_bound' ? (
                      <img src={RangeIcon} alt="range icon" />
                    ) : (
                      <Box fontSize="1.5rem" display="flex" justifyContent="center" alignItems="center">
                        <BsBoxes style={{ color: '#1CAFFE', width: '2.5rem' }} />
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {rebuyMode === 'fixedRebuy' ? (
                      <PiRecycleBold color="white" size={25} />
                    ) : (
                      <PiInfinityBold color="white" size={25} />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid grey',
                    background: '#000',
                    padding: '0.4rem',
                    margin: '0.5rem',
                  }}
                >
                  <InfoIcon>U</InfoIcon>
                  <Typography>{`${round(modelUpValue, 2)}%`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid grey',
                    background: '#000',
                    padding: '0.4rem',
                    margin: '0.5rem',
                  }}
                >
                  <InfoIcon>D</InfoIcon>
                  <Typography>{`${round(modelDownValue, 2)}%`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid grey',
                    background: '#000',
                    padding: '0.4rem',
                    margin: '0.5rem',
                  }}
                >
                  <InfoIcon>SL</InfoIcon>
                  <Typography>{`${round(modelStopLossValue, 2)}%`}</Typography>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {!isRunningModel && !isChangeModel && !isApplyModel && (
          <Grid item xs={1} md={1}>
            <div
              className={classes.container}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedModelKey(selectedModelKey);
                setOnClick(true);
                setModelStatus(status);
                setModelId(id);
                setSelectedModelIdFromInstanceDetails(id);
                setModelRiskToRewardRatio(riskToRewardRatio);
                setModelType(modelType);
                setClearInstanceButton(false);
                // TODO :::: NEEDED IN FUTURE ::::::::::
                // (status === 'running' || status === 'halted') &&
                //   setModelStartTime(modelStartTime);
                // (status === 'running' || status === 'halted') && setModelStopTime(modelStopTime);
              }}
            >
              <RightArrow />
            </div>
          </Grid>
        )}
      </Box>
      {(isRunningModel || isChangeModel || isApplyModel) && (
        <Grid xs={12}>
          <div
            style={{
              border: '1px solid grey',
              height: '2rem',
              background: modelStatusBackgroundColor,
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              cursor: isRunningModel ? 'not-allowed' : 'pointer',
            }}
          >
            <Button
              disabled={isRunningModel}
              onClick={
                () => {
                  navigate(`/spark`);
                  dispatch(
                    isModelApplied({
                      modelIdOfApplyModel: selectedSimModelToApplyId,
                      openSparkInstanceDrawer: true,
                      openSparkInstanceTabSection: true,
                      modelStatus: data.modelStatus,
                      ...(isSelectedModelUnique && {
                        selectedInstanceNumber: selectedModelKey,
                      }),
                    }),
                  );
                }
                // NOTE ::::: NEEDED IN FUTURE :::::::
                // setModelStartTime(modelStartTime);
                // setModelStopTime(modelStopTime);
              }
              sx={{
                color: '#000',
                fontSize: 18,
                background: buttonBackgroundColor,
              }}
            >
              {isRunningModel ? 'Running' : isChangeModel ? 'CHANGE' : isApplyModel && 'APPLY'}
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};
export default Instances;
