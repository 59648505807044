import {
  classificationTypes,
  coinTypes,
  cryptoPair,
  models,
  createHistoricalModel,
  notes,
  bulkModel,
  simInstanceRunModels,
} from './ActionTypes';

export const simInstanceRunModel = payload => ({
  type: simInstanceRunModels.SIM_INSTANCE_RUN_MODEL,
  payload,
});

export const simInstanceRunModelSuccess = payload => ({
  type: simInstanceRunModels.SIM_INSTANCE_RUN_MODEL_SUCCESS,
  payload,
});

export const simInstanceRunModelFail = payload => ({
  type: simInstanceRunModels.SIM_INSTANCE_RUN_MODEL_FAIL,
  payload,
});

export const fetchClassificationRequest = () => ({
  type: classificationTypes.FETCH_CLASSIFICATION_REQUEST,
});

export const fetchClassificationSuccess = payload => ({
  type: classificationTypes.FETCH_CLASSIFICATION_SUCCESS,
  payload,
});

export const fetchClassificationFailure = payload => ({
  type: classificationTypes.FETCH_CLASSIFICATION_FAILURE,
  payload,
});

export const fetchCoinRequest = () => ({
  type: coinTypes.FETCH_COIN_REQUEST,
});

export const fetchCoinSuccess = payload => ({
  type: coinTypes.FETCH_COIN_SUCCESS,
  payload,
});

export const fetchCoinFailure = payload => ({
  type: coinTypes.FETCH_COIN_FAILURE,
  payload,
});

export const fetchCryptoPairRequest = () => ({
  type: cryptoPair.FETCH_CRYPTO_PAIR_REQUEST,
});

export const fetchCryptoPairSuccess = payload => ({
  type: cryptoPair.FETCH_CRYPTO_PAIR_SUCCESS,
  payload,
});

export const fetchCryptoPairFailure = payload => ({
  type: cryptoPair.FETCH_CRYPTO_PAIR_FAILURE,
  payload,
});

export const createHistoricalModelSuccess = payload => ({
  type: createHistoricalModel.CREATE_HISTORICAL_MODEL_SUCCESS,
  payload,
});

export const createHistoricalModelFail = payload => ({
  type: createHistoricalModel.CREATE_HISTORICAL_MODEL_FAIL,
  payload,
});

export const getModel = payload => ({
  type: models.GET_MODEL,
  payload,
});

export const getModelSuccess = payload => ({
  type: models.GET_MODEL_SUCCESS,
  payload,
});

export const getModelFailure = payload => ({
  type: models.GET_MODEL_FAILURE,
  payload,
});

export const getBulkModel = payload => ({
  type: bulkModel.GET_BULK_MODEL,
  payload,
});

export const getBulkModelSuccess = payload => ({
  type: bulkModel.GET_BULK_MODEL_SUCCESS,
  payload,
});

export const getBulkModelFailure = payload => ({
  type: bulkModel.GET_BULK_MODEL_FAILURE,
  payload,
});

export const getResultDownload = payload => {
  return {
    type: models.GET_RESULT_DOWNLOAD,
    payload,
  };
};

export const getResultDownloadSuccess = payload => ({
  type: models.GET_RESULT_DOWNLOAD_SUCCESS,
  payload,
});

export const getResultDownloadFailure = payload => ({
  type: models.GET_RESULT_DOWNLOAD_FAILURE,
  payload,
});

export const updateModel = payload => ({
  type: models.UPDATE_MODEL,
  payload,
});

export const updateModelSuccess = payload => ({
  type: models.UPDATE_MODEL_SUCCESS,
  payload,
});

export const updateModelFailure = payload => ({
  type: models.UPDATE_MODEL_FAIL,
  payload,
});

export const updateAndRunModelSuccess = payload => ({
  type: models.UPDATE_AND_RUN_MODEL_SUCCESS,
  payload,
});

export const updateAndRunModelFailure = payload => ({
  type: models.UPDATE_AND_RUN_MODEL_FAIL,
  payload,
});

export const deleteModelSuccess = payload => ({
  type: models.DELETE_MODEL_SUCCESS,
  payload,
});

export const deleteModelFailure = payload => ({
  type: models.DELETE_MODEL_FAILURE,
  payload,
});

export const fetchNotes = payload => ({
  type: notes.FETCH_NOTES,
  payload,
});

export const fetchNoteSuccess = payload => ({
  type: notes.FETCH_NOTES_SUCCESS,
  payload,
});

export const fetchNoteFailure = payload => ({
  type: notes.FETCH_NOTES_FAIL,
  payload,
});

export const createNote = payload => ({
  type: notes.CREATE_NOTE,
  payload,
});

export const createNoteSuccess = payload => ({
  type: notes.CREATE_NOTE_SUCCESS,
  payload,
});

export const createNoteFailure = payload => ({
  type: notes.CREATE_NOTE_FAIL,
  payload,
});

export const updateNoteSuccess = payload => ({
  type: notes.UPDATE_NOTE_SUCCESS,
  payload,
});

export const updateNoteFailure = payload => ({
  type: notes.UPDATE_NOTE_FAIL,
  payload,
});

export const deleteNoteSuccess = payload => ({
  type: notes.DELETE_NOTE_SUCCESS,
  payload,
});

export const deleteNoteFailure = payload => ({
  type: notes.DELETE_NOTE_FAIL,
  payload,
});

export const createBulkModel = payload => ({
  type: bulkModel.CREATE_BULK_MODEL,
  payload,
});

export const createBulkModelSuccess = payload => ({
  type: bulkModel.CREATE_BULK_MODEL_SUCCESS,
  payload,
});

export const createBulkModelFail = payload => ({
  type: bulkModel.CREATE_BULK_MODEL_FAIL,
  payload,
});

export const createRunHistoricalModelSuccess = payload => ({
  type: createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL_SUCCESS,
  payload,
});

export const createRunHistoricalModelFail = payload => ({
  type: createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL_FAIL,
  payload,
});

export const getModelTradeData = payload => ({
  type: models.GET_MODEL_TRADE_DATA,
  payload,
});

export const getModelTradeDataSuccess = payload => ({
  type: models.GET_MODEL_TRADE_DATA_SUCCESS,
  payload,
});

export const getModelTradeDataFailure = payload => ({
  type: models.GET_MODEL_TRADE_DATA_FAIL,
  payload,
});

export const getRunningModel = payload => {
  return {
    type: models.GET_RUNNING_MODEL,
    payload,
  };
};

export const getRunningModelSuccess = payload => {
  return {
    type: models.GET_RUNNING_MODEL_SUCCESS,
    payload,
  };
};

export const getRunningModelFail = payload => {
  ({
    type: models.GET_RUNNING_MODEL_FAIL,
    payload,
  });
};

export const isNoteEdit = payload => ({
  type: notes.IS_NOTE_EDIT,
  payload,
});

export const isNoteTextEdited = payload => ({
  type: notes.IS_NOTE_TEXT_EDITED,
  payload,
});

export const isNoteEditedFromOutside = payload => ({
  type: notes.IS_NOTE_EDITED_FROM_OUTSIDE,
  payload,
});

export const isChartDrawingEdited = payload => ({
  type: models.IS_CHART_DRAWING_CHANGED,
  payload,
});

export const stopSimModelSuccess = payload => ({
  type: models.STOP_SIM_MODEL_SUCCESS,
  payload,
});

export const stopSimModelFail = payload => ({
  type: models.STOP_SIM_MODEL_FAIL,
  payload,
});
