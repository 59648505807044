import {
  classificationTypes,
  coinTypes,
  cryptoPair,
  models,
  createHistoricalModel,
  notes,
  bulkModel,
  simInstanceRunModels,
} from 'actions/ActionTypes';
import { concat } from 'lodash';

const initialState = {
  pending: false,
  modelLoading: false,
  noteLoading: false,
  classifications: [],
  coins: [],
  models: [],
  createAnalyzeHistoricalModel: {},
  notes: [],
  modelDetail: {},
  cryptopairs: [],
  runningModels: [],
  error: null,
  modelTradeData: [],
  isNoteEdit: false,
  isNoteTextEdited: false,
  isNoteEditedFromOutside: false,
  isGetBulkModelLoading: false,
  isGetSingleModelLoading: false,
  isRunModelLoading: false,
  isGetModelTradeDataLoading: false,
  isDeleteModelLoading: false,
  currentSelectedModel: {},
  chartData: {},
  isStopSimModelLoading: false,
  isModelUpdateLoading: false,
  isChartDrawingChanged: false,
  isGetRunningModelLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case classificationTypes.FETCH_CLASSIFICATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case classificationTypes.FETCH_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        classifications: action.payload.classifications,
        error: null,
      };
    case classificationTypes.FETCH_CLASSIFICATION_FAILURE:
      return {
        ...state,
        pending: false,
        classifications: [],
        error: action.payload.error,
      };
    case coinTypes.FETCH_COIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case coinTypes.FETCH_COIN_SUCCESS:
      return {
        ...state,
        pending: false,
        coins: action.payload.coins,
        error: null,
      };
    case coinTypes.FETCH_COIN_FAILURE:
      return {
        ...state,
        pending: false,
        coins: [],
        error: action.payload.error,
      };
    case cryptoPair.FETCH_CRYPTO_PAIR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case cryptoPair.FETCH_CRYPTO_PAIR_SUCCESS:
      return {
        ...state,
        pending: false,
        cryptopairs: action.payload.cryptopairs,
        error: null,
      };
    case cryptoPair.FETCH_CRYPTO_PAIR_FAILURE:
      return {
        ...state,
        pending: false,
        cryptopair: [],
        error: action.payload.error,
      };
    case createHistoricalModel.CREATE_HISTORICAL_MODEL:
      return {
        ...state,
        pending: true,
      };
    case createHistoricalModel.CREATE_HISTORICAL_MODEL_SUCCESS:
      return {
        ...state,
        runningModels: [...state.runningModels, action.payload.models],
        pending: false,
      };
    case createHistoricalModel.CREATE_HISTORICAL_MODEL_FAIL:
      return {
        pending: false,
        error: action.payload.error,
      };
    case models.GET_MODEL:
      return {
        ...state,
        isGetSingleModelLoading: true,
        modelDetail: {},
      };
    case models.GET_MODEL_SUCCESS:
      return {
        ...state,
        isGetSingleModelLoading: false,
        modelDetail: action.payload.modelDetail,
      };
    case models.GET_MODEL_FAILURE:
      return {
        ...state,
        isGetSingleModelLoading: false,
        modelDetail: {},
        error: action.payload.error,
      };
    case bulkModel.GET_BULK_MODEL:
      return {
        ...state,
        isGetBulkModelLoading: true,
      };
    case bulkModel.GET_BULK_MODEL_SUCCESS:
      return {
        ...state,
        isGetBulkModelLoading: false,
        modelDetail: action.payload.modelDetail,
      };
    case bulkModel.GET_BULK_MODEL_FAILURE:
      return {
        ...state,
        isGetBulkModelLoading: false,
        modelDetail: {},
        error: action.payload.error,
      };
    case models.SELECT_MODEL_IN_SUMMARY:
      return {
        ...state,
        currentSelectedModel: action.payload,
      };
    case models.CHART_DATA:
      return {
        ...state,
        chartData: action.payload,
      };

    case models.IS_CHART_DRAWING_CHANGED:
      return {
        ...state,
        isChartDrawingChanged: action.payload,
      };
    case models.GET_MODEL_TRADE_DATA:
      return {
        ...state,
        isGetModelTradeDataLoading: true,
      };
    case models.GET_MODEL_TRADE_DATA_SUCCESS:
      return {
        ...state,
        isGetModelTradeDataLoading: false,
        modelTradeData: action.payload,
        createAnalyzeHistoricalModel: {},
      };
    case models.GET_MODEL_TRADE_DATA_FAIL:
      return {
        ...state,
        isGetModelTradeDataLoading: false,
        modelTradeData: [],
        error: action.payload.error,
      };
    case models.UPDATE_MODEL:
      return {
        ...state,
        isModelUpdateLoading: true,
      };
    case models.UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        isModelUpdateLoading: false,
        runningModels: state.runningModels.map(data =>
          data.id === action.payload.models.id ? action.payload.models : data,
        ),
      };
    case models.UPDATE_MODEL_FAIL:
      return {
        ...state,
        isModelUpdateLoading: false,
        error: action.payload.error,
      };

    // Update & Run API
    case models.UPDATE_AND_RUN_MODEL:
      return {
        ...state,
        pending: true,
      };
    case models.UPDATE_AND_RUN_MODEL_SUCCESS:
      return {
        ...state,
        pending: false,
        runningModels: state.runningModels.map(data =>
          data.id === action.payload.models.id ? action.payload.models : data,
        ),
      };
    case models.UPDATE_AND_RUN_MODEL_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case models.DELETE_MODEL: {
      return {
        ...state,
        isDeleteModelLoading: true,
      };
    }
    case models.DELETE_MODEL_SUCCESS: {
      return {
        ...state,
        isDeleteModelLoading: false,
        models: state.models.filter(data => data.id !== action.payload.models),
      };
    }
    case models.DELETE_MODEL_FAILURE: {
      return {
        ...state,
        isDeleteModelLoading: false,
        error: action.payload.error_message,
      };
    }

    case models.GET_RUNNING_MODEL:
      return {
        ...state,
        isGetRunningModelLoading: true,
      };
    case models.GET_RUNNING_MODEL_SUCCESS:
      return {
        ...state,
        isGetRunningModelLoading: false,
        runningModels: action.payload.runningModel,
      };
    case models.GET_RUNNING_MODEL_FAIL:
      return {
        ...state,
        isGetRunningModelLoading: false,
        error: action.payload.error,
      };

    case notes.IS_NOTE_EDIT: {
      return {
        ...state,
        isNoteEdit: action.payload,
      };
    }

    case notes.IS_NOTE_TEXT_EDITED: {
      return {
        ...state,
        isNoteTextEdited: action.payload,
      };
    }

    case notes.IS_NOTE_EDITED_FROM_OUTSIDE: {
      return {
        ...state,
        isNoteEditedFromOutside: action.payload,
      };
    }
    case notes.FETCH_NOTES:
      return {
        ...state,
        noteLoading: true,
      };
    case notes.FETCH_NOTES_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notes: action.payload.notes,
      };
    case notes.FETCH_NOTES_FAIL:
      return {
        ...state,
        noteLoading: false,
        notes: [],
        error: action.payload.error,
      };
    case notes.CREATE_NOTE:
      return {
        ...state,
        noteLoading: true,
      };
    case notes.CREATE_NOTE_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notes: concat(state.notes, action.payload.notes),
      };
    case notes.CREATE_NOTE_FAIL:
      return {
        ...state,
        noteLoading: false,
        error: action.payload.error,
      };
    case createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL: {
      return {
        ...state,
        modelLoading: true,
      };
    }
    case createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL_SUCCESS:
      return {
        ...state,
        modelLoading: false,
        createAnalyzeHistoricalModel: action.payload.analyzeCoinHistoricalModelDetails,
      };
    case createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL_FAIL:
      return {
        ...state,
        modelLoading: false,
        error: action.payload.error,
      };
    case notes.UPDATE_NOTE:
      return {
        ...state,
        noteLoading: true,
      };
    case notes.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notes: state.notes.map(data => (data.id === action.payload.notes.id ? action.payload.notes : data)),
      };
    case notes.UPDATE_NOTE_FAIL:
      return {
        ...state,
        noteLoading: false,
        error: action.payload.error,
      };
    case notes.DELETE_NOTE: {
      return {
        ...state,
        noteLoading: true,
      };
    }
    case notes.DELETE_NOTE_SUCCESS: {
      return {
        ...state,
        noteLoading: false,
        notes: state.notes.filter(data => data.id !== action.payload.notes),
      };
    }
    case notes.DELETE_NOTE_FAIL: {
      return {
        ...state,
        noteLoading: false,
        error: action.payload.error,
      };
    }
    case bulkModel.CREATE_BULK_MODEL:
      return {
        ...state,
        pending: true,
      };
    case bulkModel.CREATE_BULK_MODEL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case bulkModel.CREATE_BULK_MODEL_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case simInstanceRunModels.SIM_INSTANCE_RUN_MODEL:
      return {
        ...state,
        isRunModelLoading: true,
      };
    case simInstanceRunModels.SIM_INSTANCE_RUN_MODEL_SUCCESS:
      return {
        ...state,
        isRunModelLoading: false,
        runningModels: state.runningModels.map(data =>
          data.id === action.payload.updatedHistoricalModel.id ? action.payload.updatedHistoricalModel : data,
        ),
      };
    case simInstanceRunModels.SIM_INSTANCE_RUN_MODEL_FAIL:
      return {
        ...state,
        isRunModelLoading: false,
        error: action.payload.error,
      };
    case models.STOP_SIM_MODEL:
      return {
        ...state,
        isStopSimModelLoading: true,
      };
    case models.STOP_SIM_MODEL_SUCCESS:
      return {
        ...state,
        isStopSimModelLoading: false,
      };
    case models.STOP_SIM_MODEL_FAIL:
      return {
        ...state,
        isStopSimModelLoading: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};
