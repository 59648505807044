import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Typography, Box, Paper, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import downicon from 'assets/downicon.svg';
import upicon from 'assets/upicon.svg';
import { isEmpty, round, size, map } from 'lodash';
import { formatNumberWithLocale } from 'utils/common';

const TableComponent = ({ month, tableData }) => {
  const [itemValue, setItemValue] = useState();
  const [data, setData] = useState([]);
  // NOTE :::: COMMENTED BECAUSE GETTING ERROR FOR NOW (MAYBE USED IN FUTURE)
  //   const { selectedModelId } = useHistoricalModelContextData();
  const isStorePerformanceDataPending = useSelector(state => state.dashboard.isStorePerformanceDataPending);
  const isGetPerformanceDataPending = useSelector(state => state.dashboard.isGetPerformanceDataPending);

  const simTableData = [];

  const columns1 = [
    {
      field: 'days',
      headerName: 'DAYS',
      flex: 2.5,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="DAYS">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      a = new Date(a.entryDate);
                      b = new Date(b.entryDate);
                      return a > b ? -1 : a < b ? 1 : 0;
                    }),
                  )
                }
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      a = new Date(a.entryDate);
                      b = new Date(b.entryDate);
                      return a > b ? 1 : a < b ? -1 : 0;
                    }),
                  )
                }
              />
            </span>
            {'DAYS'}
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const days = params.row.days;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={days}>
            <div style={{ width: '5rem', textAlign: 'center !important' }}>{days}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'portfolio',
      headerName: 'PORTFOLIO',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="PORTFOLIO">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      const firstDate = new Date(
                        moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(a.entryTime || a.exitTime).format('hh:mm:ss'),
                      );
                      const secondDate = new Date(
                        moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(b.entryTime || b.exitTime).format('hh:mm:ss'),
                      );
                      return secondDate.getTime() - firstDate.getTime();
                    }),
                  )
                }
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      const firstDate = new Date(
                        moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(a.entryTime || a.exitTime).format('hh:mm:ss'),
                      );
                      const secondDate = new Date(
                        moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(b.entryTime || b.exitTime).format('hh:mm:ss'),
                      );
                      return firstDate.getTime() - secondDate.getTime();
                    }),
                  )
                }
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'PORTFOLIO'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const portfolio = params.row.portfolio;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={portfolio}>
            <div>{portfolio}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'additions',
      headerName: 'ADDITIONS',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: params => (
        <Tooltip title="ADDITIONS">
          <strong style={{ cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => (a.action > b.action ? -1 : 1)))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => (a.action > b.action ? 1 : -1)))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'ADDITIONS'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const additions = params.row.additions;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={additions}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{additions}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'ending',
      headerName: 'ENDING',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="ENDING">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.price - a.price))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.price - b.price))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'ENDING'.slice(0, 4)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const ending = params.row.ending;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={ending}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{ending}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'profit',
      headerName: 'PROFIT',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="PROFIT">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.win - a.win))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.win - b.win))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'PROFIT'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const profit = params.row.profit;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={profit}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{profit}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'roi',
      headerName: 'ROI',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="ROI">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.win$ - a.win$))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.win$ - b.win$))}
              />
            </span>
            {'ROI'}
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const roi = params.row.roi;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={roi}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{roi}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'totalFees',
      headerName: 'TOTAL FEES',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="TOTAL FEES">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.loss - a.loss))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.loss - b.loss))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'TOTAL FEES'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const totalFees = params.row.totalFees;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={totalFees}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{totalFees}</div>
          </Tooltip>
        );
      },
    },
  ];

  const columns2 = [
    {
      field: 'date',
      headerName: 'DATE',
      flex: 2.5,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="DATE">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      a = new Date(a.entryDate);
                      b = new Date(b.entryDate);
                      return a > b ? -1 : a < b ? 1 : 0;
                    }),
                  )
                }
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      a = new Date(a.entryDate);
                      b = new Date(b.entryDate);
                      return a > b ? 1 : a < b ? -1 : 0;
                    }),
                  )
                }
              />
            </span>
            {'DATE '}
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const date = params.row.date;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={date}>
            <div>{date}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'portfolio',
      headerName: 'PORTFOLIO',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="PORTFOLIO">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      const firstDate = new Date(
                        moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(a.entryTime || a.exitTime).format('hh:mm:ss'),
                      );
                      const secondDate = new Date(
                        moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(b.entryTime || b.exitTime).format('hh:mm:ss'),
                      );
                      return secondDate.getTime() - firstDate.getTime();
                    }),
                  )
                }
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setData(
                    [...simTableData].sort((a, b) => {
                      const firstDate = new Date(
                        moment(a.entryTime || a.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(a.entryTime || a.exitTime).format('hh:mm:ss'),
                      );
                      const secondDate = new Date(
                        moment(b.entryTime || b.exitTime).format('MM-DD-YYYY') +
                          ' ' +
                          moment(b.entryTime || b.exitTime).format('hh:mm:ss'),
                      );
                      return firstDate.getTime() - secondDate.getTime();
                    }),
                  )
                }
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'PORTFOLIO'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const portfolio = params.row.portfolio;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={portfolio}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{portfolio}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'additions',
      headerName: 'ADDITIONS',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: params => (
        <Tooltip title="ADDITIONS">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => (a.action > b.action ? -1 : 1)))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => (a.action > b.action ? 1 : -1)))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'ADDITIONS'.slice(0, 4)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const additions = params.row.additions;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={additions}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{additions}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'ending',
      headerName: 'ENDING',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="ENDING">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.price - a.price))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.price - b.price))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'ENDING'.slice(0, 4)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const ending = params.row.ending;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={ending}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{ending}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'deltaInDollars',
      headerName: 'DELTAINDOLLARS',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="DELTA IN DOLLARS">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.win - a.win))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.win - b.win))}
              />
            </span>
            {'$Δ'}
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const deltaInDollars = params.row.deltaInDollars;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={deltaInDollars}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{deltaInDollars}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'deltaInPercent',
      headerName: 'DELTAINPERCENT',
      flex: 2,
      editable: true,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="DELTA IN PERCENT">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.win$ - a.win$))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.win$ - b.win$))}
              />
            </span>
            {'Δ%'}
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const deltaInPercent = params.row.deltaInPercent;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={deltaInPercent}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{deltaInPercent}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'fees',
      headerName: 'FEES',
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="FEES">
          <strong style={{ cursor: 'pointer' }}>
            <span role="img" aria-label="enjoy">
              <img
                src={downicon}
                width={8}
                height={12}
                style={{ marginTop: '4px', cursor: 'pointer' }}
                onClick={() => setData([...simTableData].sort((a, b) => b.loss - a.loss))}
              />
              <img
                src={upicon}
                width={6}
                height={8}
                style={{
                  marginTop: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setData([...simTableData].sort((a, b) => a.loss - b.loss))}
              />
            </span>
            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {'FEES'.slice(0, 3)}
            </span>
          </strong>
        </Tooltip>
      ),
      renderCell: params => {
        const fees = params.row.fees;

        // Add a Tooltip to the cell content
        return (
          <Tooltip title={fees}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{fees}</div>
          </Tooltip>
        );
      },
    },
  ];

  const rows1 = isEmpty(tableData)
    ? []
    : isEmpty(tableData[0])
    ? []
    : [
        {
          id: 1,
          days: size(tableData[1]?.dailyPerformance),
          portfolio: `$${round(tableData[0]?.portfolio, 2)}`,
          additions: `${formatNumberWithLocale(round(tableData[0]?.additions, 2), 'decimal')}`,
          ending: `$${round(tableData[0]?.endings, 2)}`,
          profit: `$${round(tableData[0]?.daily_dollar_change, 2)}`,
          roi: `${round(tableData[0]?.daily_percentage_change, 2)}%`,
          totalFees: `${formatNumberWithLocale(round(tableData[0]?.fees, 2), 'decimal')}`,
        },
      ];

  const rows2 = isEmpty(tableData)
    ? []
    : isEmpty(tableData[1])
    ? []
    : map(tableData[1]?.dailyPerformance, (data, idx) => ({
        id: idx,
        date: moment(data.start_date).format('MM-DD-YYYY'),
        portfolio: `$${round(data.portfolio, 2)}`,
        additions: `${formatNumberWithLocale(round(data.additions, 2), 'decimal')}`,
        ending: `$${round(data.endings, 2)}`,
        deltaInDollars: `${formatNumberWithLocale(round(data.daily_dollar_change, 2), 'decimal')}`,
        deltaInPercent: `${round(data.daily_percentage_change, 2)}%`,
        fees: `${formatNumberWithLocale(round(data.fees, 2), 'decimal')}`,
      }));
  const monthlyDate = month ? moment(month, 'YYYY-MM').format('MMMM-YYYY') : 'NA';

  return (
    <>
      {isStorePerformanceDataPending || isGetPerformanceDataPending ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            background: '#262540',
            paddingTop: '11rem',
            paddingBottom: '11rem',
            width: '33.438rem',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
            }}
          >
            {monthlyDate}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <Box style={{ width: '100%' }}>
                <DataGrid
                  rows={rows1}
                  columns={columns1}
                  rowHeight={!isEmpty(rows1) ? 40 : 20}
                  headerHeight={40}
                  getRowId={row => row.id}
                  autoHeight
                  hideFooter
                  disableSelectionOnClick
                  minHeight="50%"
                  pageSize={10}
                  getRowClassName={params => (params.row.id % 2 === 0 ? 'even-row' : 'odd-row')}
                  localeText={{
                    noRowsLabel: 'No data available',
                  }}
                  filterModel={{
                    items: [
                      {
                        columnField: 'action',
                        operatorValue: 'contains',
                        value: itemValue,
                      },
                    ],
                  }}
                  sx={{
                    background: '#262540',
                    color: '#79D4D4',
                    '.MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
          <Typography
            sx={{
              fontWeight: '800',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
            }}
          >
            Daily Performance
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <Box style={{ minHeight: 240, width: 535 }}>
                <DataGrid
                  rows={rows2}
                  columns={columns2}
                  rowHeight={40}
                  headerHeight={40}
                  getRowId={row => row.id}
                  autoHeight
                  disableSelectionOnClick
                  minHeight="50%"
                  pageSize={5}
                  hideFooterPagination={rows2?.length ? false : true}
                  localeText={{
                    noRowsLabel: 'No data available',
                  }}
                  getRowClassName={params => (params.row.id % 2 === 0 ? 'even-row' : 'odd-row')}
                  filterModel={{
                    items: [
                      {
                        columnField: 'action',
                        operatorValue: 'contains',
                        value: itemValue,
                      },
                    ],
                  }}
                  sx={{
                    background: '#262540',
                    color: '#79D4D4',
                    '.MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-main': {
                      minHeight: '240px !important',
                    },
                    '& .MuiTablePagination-toolbar': {
                      height: '20px',
                      color: '#79D4D4',
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TableComponent;
