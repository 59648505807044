import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  Modal,
  Radio,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import resetButton from 'assets/btnicons/reset.svg';
import saveButton from 'assets/btnicons/saveas.svg';
import overwitebutton from 'assets/btnicons/overwrite.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  isEmpty,
  isEqual,
  map,
  trim,
  includes,
  head,
  split,
  get,
  last,
  upperFirst,
  find,
  mapValues,
  filter,
} from 'lodash';
import { toast } from 'react-toastify';
import { createHistoricalModel, models } from 'actions/ActionTypes';
import { SvgIcon } from '@mui/material';
import SparkModal from './SparkModal';
import { getFrdData } from 'actions/classificationAction';
import { TbRefreshAlert } from 'react-icons/tb';
import { makeApiRequest } from '../chart/helpers';
import { normalizeEndDate } from 'utils/common';

function CloseIcon() {
  return (
    <SvgIcon
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          width: '1.6em',
        },
        [theme.breakpoints.down('lg')]: {
          width: '1.1em',
        },
        [theme.breakpoints.down('md')]: {
          width: '1em',
        },
      })}
    >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
      </svg>
    </SvgIcon>
  );
}

const SingleModel = ({
  openEditModal = false,
  setOpenEditModal = () => {},
  openSingleModal = false,
  setOpenSingleModal = () => {},
  setSingleModelResponse = () => {},
  openUpdateModel = false,
  setOpenUpdateModel = () => {},
  setIsTableData = () => {},
  duplicateModalName,
  setSelectedCheckBox = () => {},
  setSelectedBox = () => {},
  setValue = () => {},
  ascDescOrder,
  selectedBox,
  allClassification,
}) => {
  const dispatch = useDispatch();
  const [singleModelRunButtonClick, setSingleModelRunButtonClick] = useState(false);
  const [saveButtonClick, setSaveButtonClick] = useState(false);
  const [updateButtonClick, setUpdateButtonClick] = useState(false);
  const [openAbandonModalFromSingleModal, setOpenAbandonModalFromSingleModal] = useState(false);
  const modelDetail = useSelector(state => state.sim.modelDetail);
  const [availableDates, setAvailableDates] = useState([]);

  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedDataSource, setSelectedDataSource] = useState({});
  const coinTextFsym = trim(head(split(get(selectedCoin, 'label', ''), '-')));
  const coinTextTsym = trim(last(split(get(selectedCoin, 'label', ''), '-')));
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [isBlur, setIsBlur] = useState({
    isPair: false,
    isBias: false,
    isExchange: false,
    isStartDate: false,
    isEndDate: false,
  });
  const exchangesData = useSelector(state => state.classification.exchanges);
  const frdData = useSelector(state => state.classification.frdData);
  const cryptoList = useSelector(state => state.classification.cryptoList);
  const cryptoPairsData = useSelector(state => state.sim.cryptopairs);
  const isModelLoading = useSelector(state => state.sim.modelLoading);
  // :::::::: Needed in Future ::::::
  // const isGetFrdDataLoading = useSelector(state => state.classification.isGetFrdDataLoading);
  const exchangeOptions = map(exchangesData, item => {
    return {
      label: upperFirst(item.name),
      value: item.id,
    };
  });
  const pairOptions = map(cryptoPairsData, item => {
    return {
      label: `${item.primaryCrypto.symbol} - ${item.currency.name}`,
      value: item.id,
    };
  });

  const biasOptionsData = [
    {
      label: 'Bull Mode',
      value: 'bull',
    },
    {
      label: 'Bear Mode',
      value: 'bear',
    },
    {
      label: 'Range Mode',
      value: 'range_bound',
    },
  ];

  const biasOptions = map(biasOptionsData, item => {
    return {
      label: item.label,
      value: item.value,
    };
  });

  const getAllModelWithFilter = useSelector(state => state.sim.runningModels);

  const fetchAvailableDates = async () => {
    const startDate = '2005-01-01';
    const endDate = new Date().toISOString().split('T')[0];
    try {
      const startTimestamp = new Date(startDate).getTime() / 1000;
      const endTimestamp = new Date(endDate).getTime() / 1000;
      const response = await makeApiRequest(
        `data/histoday?fsym=${coinTextFsym || modelDetail?.pair?.primaryCrypto?.symbol}&tsym=${
          coinTextTsym || modelDetail?.pair?.currency?.name
        }&limit=2000&aggregate=1&allData=true&fromTs=${startTimestamp}&toTs=${endTimestamp}`,
      );

      // Extract dates from the response where data is available
      const datesWithData = response.Data.map(entry => new Date(entry.time * 1000).toDateString());
      setAvailableDates(datesWithData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const isDateDisabled = date => {
    const dateString = date.toDateString();
    return !availableDates.includes(dateString);
  };

  const getModelsWithFilter = useCallback(() => {
    dispatch({
      type: models.GET_RUNNING_MODEL,
      payload:
        selectedBox === 'A-Z'
          ? { sort: 'name', order: ascDescOrder ? 'asc' : 'desc' }
          : selectedBox === 'Starred'
          ? { isStarred: true }
          : selectedBox === 'Bias' && { bias: allClassification },
    });
  }, []);

  // :::::::: Needed in Future ::::::  Used for Date Validation for Frd
  // useEffect(() => {
  //   if (selectedStartDate && selectedEndDate && selectedDataSource?.label === 'FRD TOP50cc') {
  //     const bodyData = {
  //       dateFrom: moment(selectedStartDate).format('YYYY-MM-DD'),
  //       dateTo: moment(selectedEndDate).format('YYYY-MM-DD'),
  //       cryptoName: coinTextFsym || modelDetail?.pair?.primaryCrypto?.symbol,
  //     };
  //     dispatch(getFrdData(bodyData));
  //   }
  // }, [selectedStartDate, selectedEndDate, selectedDataSource]);

  useEffect(() => {
    if (!isEmpty(modelDetail) && isEmpty(selectedDataSource) && isEmpty(selectedCoin)) {
      setSelectedDataSource(find(exchangeOptions, item => item.value === modelDetail?.exchange?.id));
      setSelectedCoin(find(pairOptions, item => item.value === modelDetail?.pair?.id));
    }
  }, [modelDetail, pairOptions, selectedDataSource, selectedCoin]);

  useEffect(() => {
    if (
      !isEmpty(selectedDataSource) &&
      !isEmpty(selectedCoin) &&
      (selectedDataSource?.label === 'Live Coinbase' ||
        selectedDataSource?.label === 'Live Binance' ||
        selectedDataSource?.label === 'Coinbase' ||
        selectedDataSource?.label === 'Binance')
    ) {
      fetchAvailableDates();
    }
  }, [selectedDataSource, selectedCoin, modelDetail]);

  useEffect(() => {
    if (!isEmpty(frdData) && selectedDataSource?.label === 'FRD TOP50cc') {
      const datesWithData = map(frdData, item => moment(item.openTime).format('ddd MMM DD YYYY'));
      setAvailableDates(datesWithData);
    }
  }, [frdData]);

  const validate = values => {
    const errors = {};
    if (!values.pair) {
      errors.pair = '*required';
    }
    if (values.range && !values.startDate) {
      errors.startDate = '*required';
    } else if (
      values.range &&
      !isEmpty(availableDates) &&
      values?.exchange?.label !== 'FRD TOP50cc' &&
      !includes(availableDates, moment(values.startDate).format('ddd MMM DD YYYY'))
    ) {
      errors.startDate = 'Data is not available for this date, please choose another date.';
    }
    if (values.range && (!values.endDate || values.endDate === 'Invalid date')) {
      errors.endDate = '*required';
    } else if (
      values.startDate &&
      values.endDate &&
      moment(values.startDate).format('YYYY-MM-DD') >= moment(values.endDate).format('YYYY-MM-DD')
    ) {
      errors.endDate = 'must be greater than start date';
    } else if (
      values.range &&
      !isEmpty(availableDates) &&
      values?.exchange?.label !== 'FRD TOP50cc' &&
      !includes(availableDates, moment(values.endDate).format('ddd MMM DD YYYY'))
    ) {
      errors.endDate = 'Data is not available for this date, please choose another date.';
    }

    if (trim(values.modelName).length === 0) {
      errors.modelName = '*required';
    } else if (trim(values.modelName).length <= 5) {
      errors.modelName = 'Name should be longer than 5 characters, please enter a longer name.';
    } else if (
      values.modelName &&
      includes(
        map(
          filter(getAllModelWithFilter, item => item?.id !== modelDetail?.id),
          ele => trim(ele?.name),
        ),
        trim(values?.modelName),
      )
    ) {
      errors.modelName = 'Name already exists, please enter a new unique name.';
    } else if (
      values.modelName &&
      !openUpdateModel &&
      includes(
        map(getAllModelWithFilter, ele => trim(ele.name)),
        trim(values.modelName),
      )
    ) {
      errors.modelName = 'Name already exists, please enter a new unique name.';
    }

    if (values.bias === null) {
      errors.bias = '*required';
    }

    if (values.quantity === null) {
      errors.quantity = '*required';
    }

    if (values.quantity && values.quantity < 0) {
      errors.quantity = 'invalid number';
    }

    if (values.down === null) {
      errors.down = '*required';
    }
    if (values.down && values.down < 0) {
      errors.down = 'invalid number';
    }

    if (values.up === null) {
      errors.up = '*required';
    } else if (values.up && values.up < 0) {
      errors.up = 'invalid number';
    }

    if (!values.stopLoss) {
      errors.stopLoss = '*required';
    }
    if (values.stopLoss && values.stopLoss < 0) {
      errors.stopLoss = 'invalid number';
    }
    if (!values.exchange) {
      errors.exchange = '*required';
    }
    return errors;
  };

  const createSingleModel = useCallback(data => {
    dispatch({
      type: createHistoricalModel.CREATE_HISTORICAL_MODEL,
      payload: data,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            setOpenSingleModal(false);
            setOpenEditModal(false);
            setOpenUpdateModel(false);
            setSelectedCheckBox([]);
            toast.success('Historical Model added successfully');
            getModelsWithFilter();
          }
        }
      },
    });
  }, []);

  const updateSingleModel = useCallback(data => {
    dispatch({
      type: models.UPDATE_MODEL,
      payload: data,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            setOpenEditModal(false);
            setOpenUpdateModel(false);
            setOpenSingleModal(false);
            setUpdateButtonClick(false);
            setSelectedCheckBox([]);
            setSelectedBox({});
            toast.success('Historical Model updated successfully');
            getModelsWithFilter();
          }
        } else toast.failure('failed');
      },
    });
  }, []);

  const createRunSingleModel = useCallback(data => {
    dispatch({
      type: createHistoricalModel.CREATE_RUN_HISTORICAL_MODEL,
      payload: data,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            setValue(0);
            setOpenSingleModal(false) || setOpenEditModal(false) || setOpenUpdateModel(false);
            toast.success('Single model run successfully');
            setSingleModelResponse(get(res, 'data.data', {}));
            setIsTableData(false);
          }
        }
      },
    });
  }, []);

  const updateAndRunSingleModel = useCallback(data => {
    dispatch({
      type: models.UPDATE_AND_RUN_MODEL,
      payload: data,
      cb: res => {
        if (res) {
          if (res.status === 200) {
            setValue(0);
            setOpenSingleModal(false);
            setOpenEditModal(false);
            setOpenUpdateModel(false);
            setSingleModelRunButtonClick(false);
            getModelsWithFilter();
            toast.success('Single model run and updated successfully');
            setSingleModelResponse(get(res, 'data.data', {}));
            // setTableData(get(res, 'data.data', {}));
            setIsTableData(false);
          }
        }
      },
    });
  }, []);

  const initialValues =
    (openUpdateModel || openEditModal) && !isEmpty(modelDetail)
      ? {
          modelName: openUpdateModel ? trim(modelDetail.name) : duplicateModalName,
          bias: {
            label: get(
              find(biasOptions, data => modelDetail.bias === data.value),
              'label',
              '',
            ),
            value: get(
              find(biasOptions, data => modelDetail.bias === data.value),
              'value',
              '',
            ),
          },
          pair: {
            label: get(
              find(pairOptions, data => modelDetail.pair.id === data.value),
              'label',
              '',
            ),
            value: get(
              find(pairOptions, data => modelDetail.pair.id === data.value),
              'value',
              '',
            ),
          },
          exchange: {
            label: get(
              find(exchangeOptions, data => modelDetail?.exchange?.id === data.value),
              'label',
              '',
            ),
            value: get(
              find(exchangeOptions, data => modelDetail?.exchange?.id === data.value),
              'value',
              '',
            ),
          },
          coins: '',
          coinsText: modelDetail.pair?.primaryCrypto?.symbol.concat(['/'], modelDetail.pair.currency.name),
          dataSource: modelDetail.pair?.primaryCrypto?.symbol.concat(['/'], modelDetail.pair.currency.name),
          dataSourceText: '',
          allAvailable: modelDetail.backTestAnalysisDateType === 'allAvailable',
          fixedRebuy: modelDetail?.rebuyMode === 'fixedRebuy',
          compoundRebuy: modelDetail?.rebuyMode === 'compoundRebuy',
          range: modelDetail.backTestAnalysisDateType === 'range',
          quantity: modelDetail.quantity && '$ ' + parseFloat(modelDetail.quantity).toFixed(2),
          down: modelDetail.down && parseFloat(modelDetail.down).toFixed(4) + '%',
          up: modelDetail.up && parseFloat(modelDetail.up).toFixed(4) + '%',
          stopLoss: modelDetail.stopLoss && parseFloat(modelDetail.stopLoss).toFixed(4) + '%',
          startDate: modelDetail.startTime,
          endDate: normalizeEndDate(modelDetail.endTime),
          increment: '',
          high: '',
          low: '',
        }
      : {
          modelName: '',
          bulkName: '',
          bias: null,
          pair: '',
          exchange: '',
          dataSourceText: '',
          allAvailable: true,
          fixedRebuy: true,
          compoundRebuy: false,
          range: false,
          quantity: '',
          down: '',
          up: '',
          stopLoss: '',
          startDate: '',
          endDate: '',
          increment: '',
          high: '',
          low: '',
        };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: ({ resetForm, setSubmitting, errors }) => {
      const rebuy = formik.values.fixedRebuy ? 'fixedRebuy' : formik.values.compoundRebuy ? 'compoundRebuy' : '';
      const backTestAnalysisDateType = formik.values.allAvailable ? 'allAvailable' : 'range';
      const parsedDown = parseFloat(formik.values.down.trim().replace(/[^\d.-]/g, ''));
      formik.values.down = isNaN(parsedDown) ? formik.values.down : parsedDown;
      const parsedUp = parseFloat(formik.values.up.trim().replace(/[^\d.-]/g, ''));
      formik.values.up = isNaN(parsedDown) ? formik.values.up : parsedUp;
      const parsedStopLoss = parseFloat(formik.values.stopLoss.trim().replace(/[^\d.-]/g, ''));
      formik.values.stopLoss = isNaN(parsedDown) ? formik.values.stopLoss : parsedStopLoss;
      const parsedQuantity = parseFloat(formik.values.quantity.replace(/[,|$]/g, ''));
      formik.values.quantity = isNaN(parsedQuantity) ? formik.values.quantity : parsedQuantity;

      const singleModelData = {
        name: formik.values.modelName,
        bias: formik.values.bias?.value,
        exchangeId: formik.values.exchange?.value,
        pairId: formik.values.pair?.value,
        dateFrom: formik.values.range
          ? formik.values.startDate
            ? moment(formik.values.startDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment().subtract(1, 'months').format('YYYY-MM-DD'),
        dateTo: formik.values.range
          ? formik.values.endDate
            ? moment(formik.values.endDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment(new Date()).format('YYYY-MM-DD'),
        quantity: formik.values.quantity,
        down: formik.values.down,
        up: formik.values.up,
        stopLoss: formik.values.stopLoss,
        rebuyMode: rebuy,
        backTestAnalysisDateType,
      };

      // Save & Run API
      singleModelRunButtonClick &&
        (openSingleModal || openEditModal) &&
        createRunSingleModel(singleModelData, resetForm);

      singleModelRunButtonClick && setIsTableData(true);

      const overwriteModelData = {
        name: formik.values.modelName,
        id: modelDetail.id,
        bias: formik.values.bias?.value,
        exchange: formik.values.exchange?.value,
        pair: formik.values.pair?.value,
        startTime: formik.values.range
          ? formik.values.startDate
            ? moment(formik.values.startDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endTime: formik.values.range
          ? formik.values.endDate
            ? moment(formik.values.endDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment(new Date()).format('YYYY-MM-DD'),
        quantity: formik.values.quantity,
        down: formik.values.down,
        up: formik.values.up,
        stopLoss: formik.values.stopLoss,
        rebuyMode: rebuy,
        backTestAnalysisDateType,
      };

      // Overwrite & Run API
      singleModelRunButtonClick && openUpdateModel && updateAndRunSingleModel(overwriteModelData, resetForm);

      // Overwrite API
      updateButtonClick && openUpdateModel && dispatch(updateSingleModel(overwriteModelData));

      const saveData = {
        name: formik.values.modelName,
        pair: formik?.values?.pair.value,
        exchange: formik?.values?.exchange.value,
        bias: formik?.values?.bias.value,
        startTime: formik.values.range
          ? formik.values.startDate
            ? moment(formik.values.startDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endTime: formik.values.endDate
          ? formik.values.startDate
            ? moment(formik.values.endDate).format('YYYY-MM-DD')
            : ''
          : formik.values.allAvailable && moment(new Date()).format('YYYY-MM-DD'),
        quantity: formik.values.quantity,
        down: formik.values.down,
        up: formik.values.up,
        stopLoss: formik.values.stopLoss,
        rebuyMode: rebuy,
        backTestAnalysisDateType,
      };

      // Save API
      saveButtonClick && (openSingleModal || openEditModal) && createSingleModel(saveData);

      setSubmitting(false);
    },
  });

  const handleArrowClick = (fieldName, isIncrement) => {
    const currentValue = parseFloat(formik.values[fieldName]) || 0;
    const updatedValue = isIncrement ? currentValue + 0.0001 : currentValue - 0.0001;
    formik.setFieldValue(fieldName, updatedValue.toFixed(4) + '%');
  };

  const handleBlurValues = fieldName => event => {
    const inputValue = event.target.value?.trim().replace(/[^\d.-]/g, '');
    if (!isNaN(parseFloat(inputValue))) {
      const formattedValue = parseFloat(inputValue).toFixed(4) + '%';
      formik.setFieldValue(fieldName, formattedValue);
    } else {
      formik.setFieldValue(fieldName, '');
    }
    formik.handleBlur(event);
  };

  const trimmedFormikValues = mapValues(formik.values, (value, key) => {
    if (key === 'endDate' || key === 'startDate') {
      return moment(value).format('YYYY-MM-DD');
    }
    return typeof value === 'string' || typeof value === 'number' ? trim(value) : value;
  });

  const initialValuesEndDate = moment(formik?.initialValues?.endDate).format('YYYY-MM-DD');
  const initialValuesStartDate = moment(formik?.initialValues?.startDate).format('YYYY-MM-DD');

  const updatedInitialValues = {
    ...formik.initialValues,
    endDate: initialValuesEndDate,
    startDate: initialValuesStartDate,
  };

  const compareValues = isEqual(trimmedFormikValues, updatedInitialValues);

  return (
    <Modal
      open={openSingleModal || openEditModal || openUpdateModel}
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <Box
        sx={theme => ({
          color: '#fff',
          padding: '1rem',
          background: '#040d3e',

          margin: 'auto',
          marginTop: '1rem',
          [theme.breakpoints.up('lg')]: {
            maxWidth: '25%',
          },
          [theme.breakpoints.down('lg')]: {
            maxWidth: '38%',
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: '50%',
          },
        })}
      >
        <form onSubmit={formik.handleSubmit}>
          {(openUpdateModel && isEmpty(modelDetail)) || (openUpdateModel && isEmpty(modelDetail)) ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <div>
              <Grid
                sx={{
                  fontSize: '1.2rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '0.2rem',
                }}
              >
                {openSingleModal ? 'New Single Model' : (openUpdateModel || openEditModal) && 'Edit Single Model'}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (
                      (openUpdateModel || openEditModal || openSingleModal) &&
                      !isEqual(initialValues, formik.values)
                    ) {
                      setOpenAbandonModalFromSingleModal(true);
                    } else {
                      setOpenUpdateModel(false);
                      setOpenEditModal(false);
                      setOpenSingleModal(false);
                    }
                    // setOpenSingleModal(false);
                  }}
                >
                  <CloseIcon />
                </span>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                padding="0.625rem"
                sx={theme => ({
                  [theme.breakpoints.down('lg')]: {
                    padding: '0.313rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    marginTop: '0.100rem',
                  },
                })}
              >
                <>
                  <Box
                    item
                    xs={12}
                    sx={theme => ({
                      width: '90%',
                      padding: '0.625rem 0.938rem 0.313rem 0.938rem',
                      [theme.breakpoints.down('lg')]: {
                        padding: '0.313rem 0.625rem 0.063rem 0.625rem',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '0.313rem 0.625rem 0.063rem 0.625rem',
                      },
                    })}
                  >
                    <Input
                      id="standard-adornment-weight"
                      name="modelName"
                      placeholder="Model Name"
                      value={formik.values.modelName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={openEditModal}
                      autoFocus
                      inputProps={
                        formik.errors.modelName
                          ? { style: { borderBottom: '2px solid red' } }
                          : { style: { color: 'white' } }
                      }
                      sx={theme => ({
                        width: '100%',
                        color: '#FFF',
                        '& .Mui-disabled': {
                          cursor: 'not-allowed',
                          '-webkit-text-fill-color': 'grey',
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '0.688rem',
                        },
                        [theme.breakpoints.up('lg')]: {
                          fontSize: '1.25rem',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '0.75rem',
                        },
                      })}
                    />
                    {formik.errors.modelName && formik.touched.modelName ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.modelName}
                      </div>
                    ) : null}
                    <Box
                      sx={{
                        color: '#f1c40f',
                        fontSize: '0.625rem',
                      }}
                    >
                      Version{' '}
                      {openUpdateModel ? `${modelDetail?.version + 1}.0` : (openSingleModal || openEditModal) && '1.0'}
                    </Box>
                  </Box>

                  <Grid item xs={12}>
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Model Conditions</label>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="bias"
                      name="bias"
                      freeSolo={false}
                      options={biasOptionsData}
                      size="small"
                      sx={{
                        backgroundColor: 'black',
                        borderRadius: 2,
                      }}
                      onChange={(e, value) => {
                        formik.setFieldValue('bias', value);
                      }}
                      onBlur={() => {
                        setIsBlur({
                          ...isBlur,
                          isBias: true,
                        });
                      }}
                      value={formik.values.bias}
                      inputValue={formik.values.bias ? formik.values.bias.label : ''}
                      renderInput={params => (
                        <TextField
                          sx={theme => ({
                            svg: {
                              color: '#31EABD',
                            },
                            input: {
                              color: '#FFF',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.875rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                              },
                            },
                          })}
                          {...params}
                          placeholder="Bias"
                        />
                      )}
                    />
                  </Grid>

                  {formik.errors.bias && isBlur.isBias ? (
                    <div
                      style={{
                        textAlign: 'right',
                        color: 'red',
                        padding: '0.313rem 0.313rem 0 0',
                        width: '100%',
                        fontSize: '0.625rem',
                      }}
                    >
                      {formik.errors.bias}
                    </div>
                  ) : null}
                </>

                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="pair"
                    freeSolo={false}
                    name="pair"
                    options={pairOptions}
                    size="small"
                    sx={{
                      backgroundColor: 'black',
                      borderRadius: 2,
                    }}
                    value={formik.values.pair}
                    inputValue={formik.values.pair ? formik.values.pair.label : ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('pair', value);
                      setSelectedCoin(value);
                      if (selectedCoin) {
                        formik.setFieldValue('exchange', '');
                        formik.setFieldValue('endDate', null);
                        formik.setFieldValue('startDate', null);
                        // formik.setFieldError('pair', '');
                        setIsBlur({ ...isBlur, isPair: false });
                        setSelectedStartDate('');
                        setSelectedEndDate('');
                      }
                    }}
                    onBlur={() => {
                      setIsBlur({ ...isBlur, isPair: true });
                    }}
                    renderInput={params => (
                      <TextField
                        sx={theme => ({
                          svg: {
                            color: '#31EABD',
                          },
                          input: {
                            color: '#FFF',
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '0.875rem',
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: '0.75rem',
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: '0.688rem',
                            },
                          },
                        })}
                        {...params}
                        placeholder="Coins-Token"
                      />
                    )}
                  />
                </Grid>

                {formik.errors.pair && isBlur.isPair ? (
                  <div
                    style={{
                      textAlign: 'right',
                      color: 'red',
                      padding: '0.313rem 0.313rem 0 0',
                      width: '100%',
                      fontSize: '0.625rem',
                    }}
                  >
                    {formik.errors.pair}
                  </div>
                ) : null}
                {formik.values.pair && (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={!formik.values.pair}
                        disablePortal
                        id="exchange"
                        name="exchange"
                        options={
                          includes(cryptoList, trim(head(split(formik.values.pair.label, '-'))))
                            ? map(exchangesData, item => {
                                return {
                                  label: item.name === 'FRD TOP50cc' ? item.name : `Live ${upperFirst(item.name)}`,
                                  value: item.id,
                                };
                              })
                            : map(
                                filter(exchangeOptions, item => item.label !== 'FRD TOP50cc'),
                                item => {
                                  return {
                                    label: `Live ${upperFirst(item.label)}`,
                                    value: item.value,
                                  };
                                },
                              )
                        }
                        size="small"
                        sx={{
                          backgroundColor: 'black',
                          borderRadius: 2,
                        }}
                        value={formik.values.exchange}
                        onChange={(e, value) => {
                          formik.setFieldValue('exchange', value);
                          setSelectedDataSource(value);
                        }}
                        onBlur={() => {
                          setIsBlur({
                            ...isBlur,
                            isExchange: true,
                            // isPair: false,
                          });
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Data Source"
                            // label={!formik.values.pair ? "Please select coins/Token first" : "Data Source"}
                            // InputLabelProps={{
                            //   style: { color: '#7b7b7b', fontSize: '0.9rem' },
                            // }}
                            onClick={() =>
                              setIsBlur({
                                ...isBlur,
                                // isPair: false,
                              })
                            }
                            sx={theme => ({
                              svg: {
                                color: '#31EABD',
                              },
                              input: {
                                color: '#FFF',
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '0.875rem',
                                },
                                [theme.breakpoints.down('lg')]: {
                                  fontSize: '0.75rem',
                                },
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '0.688rem',
                                },
                              },
                            })}
                          />
                        )}
                      />
                    </Grid>
                    {formik.errors.exchange && isBlur.isExchange ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          width: '100%',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.exchange}
                      </div>
                    ) : null}
                  </>
                )}

                <Grid item xs={12}>
                  <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Date Range</label>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    paddingLeft: '0.313rem !important',
                  }}
                >
                  <Radio
                    checked={formik.values.allAvailable}
                    name="allAvailable"
                    value={formik.values.allAvailable}
                    onChange={value => {
                      formik.setFieldValue('allAvailable', value);
                      formik.setFieldValue('range', !value);
                    }}
                    sx={{
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                        color: '#404878',
                      },
                    }}
                  />
                  <label style={{ color: '#B1AFCD', fontWeight: '700' }}>All Available</label>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    paddingLeft: '0.313rem !important',
                  }}
                >
                  <Grid item lg={3} xs={12} md={12} sm={12}>
                    <Radio
                      checked={formik.values.range}
                      value={formik.values.range}
                      onChange={value => {
                        formik.setFieldValue('range', value);
                        formik.setFieldValue('allAvailable', !value);
                      }}
                      name="range"
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: '#404878',
                        },
                      }}
                    />
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Range</label>
                  </Grid>
                  {formik.values.range && (
                    <>
                      <Grid
                        item
                        lg={4}
                        xs={5}
                        md={5}
                        sm={5}
                        sx={theme => ({
                          '&.MuiGrid-item': {
                            paddingTop: '0rem !important',
                          },
                          [theme.breakpoints.up('lg')]: {
                            display: 'flex',
                          },
                          [theme.breakpoints.down('lg')]: {
                            display: 'contents',
                          },
                          [theme.breakpoints.down('md')]: {
                            display: 'contents',
                          },
                        })}
                      >
                        <div
                          onBlur={() => {
                            setIsBlur({ ...isBlur, isStartDate: true });
                          }}
                        >
                          <DatePicker
                            shouldDisableDate={selectedDataSource?.label !== 'FRD TOP50cc' && isDateDisabled}
                            label=""
                            value={formik.values.startDate}
                            name="startDate"
                            maxDate={
                              selectedDataSource?.label === 'FRD TOP50cc'
                                ? moment().subtract(2, 'days').toDate()
                                : new Date()
                            }
                            minDate={
                              selectedDataSource?.label !== 'FRD TOP50cc'
                                ? moment().subtract(6, 'days')
                                : new Date(2005, 0, 1)
                            }
                            onChange={e => {
                              formik.setFieldValue('startDate', e);
                              setSelectedStartDate(e);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                onBlur={isBlur.isStartDate ? formik.handleBlur : null}
                                sx={theme => ({
                                  [theme.breakpoints.up('lg')]: {
                                    marginLeft: '0rem',
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    marginLeft: '1rem',
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    marginLeft: '1rem',
                                  },
                                  svg: {
                                    color: '#54DAFF',
                                  },
                                  input: {
                                    color: 'white',
                                    [theme.breakpoints.up('lg')]: {
                                      fontSize: '0.75rem',
                                      paddingRight: '0rem',
                                    },
                                    [theme.breakpoints.down('lg')]: {
                                      fontSize: '0.75rem',
                                      paddingRight: '0rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                      fontSize: '0.688rem',
                                      paddingRight: '0rem',
                                    },
                                  },
                                })}
                              />
                            )}
                            InputAdornmentProps={{
                              position: 'start',
                              color: '#54DAFF',
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid
                        item
                        lg={1}
                        xs={2}
                        md={2}
                        sm={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '0 !important',
                          '&.MuiGrid-item': {
                            paddingTop: '0rem !important',
                          },
                        }}
                      >
                        <label style={{ color: '#B1AFCD', fontWeight: '700' }}>to</label>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={5}
                        md={5}
                        sm={5}
                        sx={theme => ({
                          '&.MuiGrid-item': {
                            paddingTop: '0rem !important',
                          },
                          [theme.breakpoints.up('lg')]: {
                            display: 'flex',
                          },
                          [theme.breakpoints.down('lg')]: {
                            display: 'contents',
                          },
                          [theme.breakpoints.down('md')]: {
                            display: 'contents',
                          },
                        })}
                      >
                        <div
                          onBlur={() => {
                            setIsBlur({ ...isBlur, isEndDate: true });
                          }}
                        >
                          <DatePicker
                            name="endDate"
                            shouldDisableDate={selectedDataSource?.label !== 'FRD TOP50cc' && isDateDisabled}
                            value={formik.values.endDate && normalizeEndDate(formik.values.endDate)}
                            maxDate={
                              selectedDataSource?.label === 'FRD TOP50cc'
                                ? moment().subtract(2, 'days').toDate()
                                : new Date()
                            }
                            minDate={
                              selectedDataSource?.label !== 'FRD TOP50cc'
                                ? formik.values.startDate
                                  ? formik.values.startDate
                                  : moment().subtract(6, 'days')
                                : formik.values.startDate
                            }
                            onChange={e => {
                              formik.setFieldValue('endDate', moment(e).format('YYYY-MM-DDTHH:mm:ss.SSS'));
                              setSelectedEndDate(moment(e).format('YYYY-MM-DDTHH:mm:ss.SSS'));
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                onBlur={formik.handleBlur}
                                sx={theme => ({
                                  [theme.breakpoints.up('lg')]: {
                                    margin: '0',
                                  },
                                  svg: {
                                    color: '#54DAFF',
                                  },
                                  input: {
                                    color: 'white',
                                    [theme.breakpoints.up('lg')]: {
                                      fontSize: '0.75rem',
                                      paddingRight: '0rem',
                                    },
                                    [theme.breakpoints.down('lg')]: {
                                      fontSize: '0.75rem',
                                      paddingRight: '0rem',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                      fontSize: '0.688rem',
                                      paddingRight: '0rem',
                                    },
                                  },
                                })}
                              />
                            )}
                            InputAdornmentProps={{ position: 'start' }}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                  {formik.errors.startDate && isBlur.isStartDate && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '0.625rem',
                        justifyContent: 'center',
                        width: '100%',
                        display: 'flex',
                      }}
                    >
                      {formik.errors.startDate}
                    </div>
                  )}
                  {formik.errors.endDate && isBlur.isEndDate && (
                    <div
                      style={{
                        textAlign: 'right',
                        color: 'red',
                        fontSize: '0.625rem',
                        width: '100%',
                      }}
                    >
                      {formik.errors.endDate}
                    </div>
                  )}
                </Grid>
                <>
                  <Grid item xs={12}>
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Variables</label>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    marginTop="0.125rem"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0rem 0rem 0rem 0.938rem !important',
                    }}
                  >
                    <Grid item xs={2}>
                      <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Q</label>
                    </Grid>

                    <Grid item xs={10} sx={{ width: '100%' }}>
                      <Box sx={{ background: 'black' }}>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: 'black',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <TiArrowSortedUp
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => {
                                      const inputValue = formik.values.quantity.trim().replace(/[^\d.-]/g, '');
                                      const parsedValue = parseFloat(inputValue) || 0;

                                      const increasedValue = parsedValue + 100;

                                      formik.setFieldValue(
                                        'quantity',
                                        '$ ' +
                                          ' ' +
                                          increasedValue.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                                      );
                                      formik.setFieldTouched('quantity', true);
                                    }}
                                  />
                                  <TiArrowSortedDown
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => {
                                      const inputValue = formik.values.quantity.trim().replace(/[^\d.-]/g, '');
                                      const parsedValue = parseFloat(inputValue) || 0;

                                      const decreasedValue = parsedValue - 100;

                                      formik.setFieldValue(
                                        'quantity',
                                        '$' +
                                          ' ' +
                                          decreasedValue.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                                      );

                                      formik.setFieldTouched('quantity', true);
                                    }}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          id="quantity"
                          name="quantity"
                          placeholder="Quantity"
                          size="small"
                          type="text"
                          value={formik.values.quantity}
                          onChange={formik.handleChange}
                          onBlur={event => {
                            if (!isNaN(parseFloat(formik.values.quantity))) {
                              const formattedValue = parseFloat(formik.values.quantity).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              });
                              formik.setFieldValue('quantity', formattedValue);
                            }
                            formik.handleBlur(event);
                          }}
                          fullWidth
                          inputProps={{
                            sx: {
                              '&::placeholder': {
                                color: '#FFFFFF',
                              },
                            },
                          }}
                          sx={theme => ({
                            input: {
                              color: '#FFF',
                              backgroundColor: '#000',
                              border: '0.063rem',
                              borderRadius: '0.313rem',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.875rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                              },
                            },
                          })}
                        />
                      </Box>
                    </Grid>
                    {formik.errors.quantity && formik.touched.quantity ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          width: '100%',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.quantity}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid sx={{ paddingLeft: '2.5rem' }}>
                    <Radio
                      checked={formik.values.fixedRebuy}
                      name="fixedRebuy"
                      value={formik.values.fixedRebuy}
                      onChange={value => {
                        formik.setFieldValue('fixedRebuy', value);
                        formik.setFieldValue('compoundRebuy', !value);
                      }}
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: '#404878',
                        },
                      }}
                    />
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Fixed Rebuy</label>

                    <Radio
                      checked={formik.values.compoundRebuy}
                      value={formik.values.compoundRebuy}
                      onChange={value => {
                        formik.setFieldValue('compoundRebuy', value);
                        formik.setFieldValue('fixedRebuy', !value);
                      }}
                      name="compoundRebuy"
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: '#404878',
                        },
                      }}
                    />
                    <label style={{ color: '#B1AFCD', fontWeight: '700' }}>Compound Rebuy</label>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    marginTop="0.125rem"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0rem 0rem 0rem 0.938rem !important',
                    }}
                  >
                    <Grid item xs={2}>
                      <label style={{ color: '#B1AFCD', fontWeight: '700' }}>D</label>
                    </Grid>
                    <Grid item xs={10} sx={{ width: '100%' }}>
                      <Box sx={{ background: 'black' }}>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: 'black',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <TiArrowSortedUp
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('down', true)}
                                  />
                                  <TiArrowSortedDown
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('down', false)}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          id="down"
                          name="down"
                          placeholder="Down %"
                          size="small"
                          type="text"
                          value={formik.values.down}
                          onChange={formik.handleChange}
                          onBlur={handleBlurValues('down')}
                          fullWidth
                          inputProps={{
                            sx: {
                              '&::placeholder': {
                                color: '#FFFFFF',
                              },
                            },
                          }}
                          sx={theme => ({
                            input: {
                              color: '#FFF',
                              backgroundColor: '#000',
                              border: '0.063rem ',
                              borderRadius: '0.313rem',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.875rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                              },
                            },
                          })}
                        />
                      </Box>
                    </Grid>
                    {formik.errors.down && formik.touched.down ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          width: '100%',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.down}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    marginTop="0.125rem"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0rem 0rem 0rem 0.938rem !important',
                    }}
                  >
                    <Grid item xs={2}>
                      <label style={{ color: '#B1AFCD', fontWeight: '700' }}>U</label>
                    </Grid>
                    <Grid item xs={10} sx={{ width: '100%' }}>
                      <Box sx={{ background: 'black' }}>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: 'black',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <TiArrowSortedUp
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('up', true)}
                                  />
                                  <TiArrowSortedDown
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('up', false)}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          id="up"
                          name="up"
                          placeholder="Up"
                          size="small"
                          type="text"
                          value={formik.values.up}
                          onChange={formik.handleChange}
                          onBlur={handleBlurValues('up')}
                          fullWidth
                          inputProps={{
                            sx: {
                              '&::placeholder': {
                                color: '#FFFFFF',
                              },
                            },
                          }}
                          sx={theme => ({
                            input: {
                              color: '#FFF',
                              backgroundColor: '#000',
                              border: '0.063rem ',
                              borderRadius: '0.313rem',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.875rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                              },
                            },
                          })}
                        />
                      </Box>
                    </Grid>
                    {formik.errors.up && formik.touched.up ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          width: '100%',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.up}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    marginTop="0.125rem"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0rem 0rem 0rem 0.938rem !important',
                    }}
                  >
                    <Grid item xs={2}>
                      <label style={{ color: '#B1AFCD', fontWeight: '700' }}>SL</label>
                    </Grid>
                    <Grid item xs={10} sx={{ width: '100%' }}>
                      <Box sx={{ background: 'black' }}>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: 'black',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <TiArrowSortedUp
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('stopLoss', true)}
                                  />
                                  <TiArrowSortedDown
                                    style={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      backgroundColor: 'black',
                                    }}
                                    onClick={() => handleArrowClick('stopLoss', false)}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          id="stopLoss"
                          name="stopLoss"
                          placeholder="Stop Loss"
                          size="small"
                          type="text"
                          value={formik.values.stopLoss}
                          onChange={formik.handleChange}
                          onBlur={handleBlurValues('stopLoss')}
                          fullWidth
                          inputProps={{
                            sx: {
                              '&::placeholder': {
                                color: '#FFFFFF',
                              },
                            },
                          }}
                          sx={theme => ({
                            input: {
                              color: '#FFF',
                              backgroundColor: '#000',
                              border: '0.063rem ',
                              borderRadius: '0.313rem',
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '0.875rem',
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: '0.75rem',
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: '0.688rem',
                              },
                            },
                          })}
                        />
                      </Box>
                    </Grid>
                    {formik.errors.stopLoss && formik.touched.stopLoss ? (
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'red',
                          padding: '0.313rem 0.313rem 0 0',
                          width: '100%',
                          fontSize: '0.625rem',
                        }}
                      >
                        {formik.errors.stopLoss}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    marginTop="0.125rem"
                    sx={theme => ({
                      width: '100%',
                      padding: '0.625rem 0rem 0rem 0.938rem !important',
                      [theme.breakpoints.down('lg')]: {
                        flexWrap: 'nowrap',
                      },
                      [theme.breakpoints.down('md')]: {
                        padding: '0.5rem 0rem 0rem 1.063rem !important',
                      },
                    })}
                  >
                    <Grid item sm={12} md={12} lg={6} sx={{ width: '100%' }}>
                      <Button
                        variant="outlined"
                        type="submit"
                        sx={theme => ({
                          color: '#FFFFFF !important',
                          backgroundColor: '#000000  !important',
                          borderRadius: '3px !important',
                          border: openSingleModal
                            ? '0.125rem solid #3FD2F2  !important'
                            : '0.125rem solid #FF1730 !important',
                          width: '95%',
                          padding: '0.35rem !important',
                          textTransform: 'unset !important',
                          [theme.breakpoints.down('lg')]: {
                            width: '90%',
                          },
                          [theme.breakpoints.down('md')]: {
                            width: '90%',
                          },
                          '&.Mui-disabled': {
                            color: '#FFF !important',
                            border: 'none !important',
                          },
                          '&:disabled': {
                            cursor: 'not-allowed !important',
                            pointerEvents: 'all !important',
                            backgroundColor: 'grey !important',
                            color: 'white !important',
                            border: 'none !important',
                          },
                        })}
                        startIcon={
                          openSingleModal ? (
                            <img src={saveButton} width={15} height={15} />
                          ) : (
                            <img src={overwitebutton} width={15} height={15} />
                          )
                        }
                        disabled={
                          (openSingleModal && (!formik.isValid || !formik.dirty || isModelLoading)) ||
                          (openUpdateModel && compareValues && (!formik.isValid || !formik.dirty || isModelLoading)) ||
                          (openEditModal && (!formik.isValid || isModelLoading))
                        }
                        onClick={() => {
                          (openSingleModal || openEditModal) && setSaveButtonClick(true);
                          openUpdateModel && setUpdateButtonClick(true);
                          setSingleModelRunButtonClick(false);
                        }}
                      >
                        {/* :::::::: Needed in Future :::::: */}
                        {/* {isGetFrdDataLoading && <CircularProgress size={20} />} */}
                        {openSingleModal || openEditModal ? 'Save' : openUpdateModel && 'Overwrite'}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={6}
                      sx={theme => ({
                        width: '100%',
                        [theme.breakpoints.down('lg')]: {
                          display: 'flex',
                          justifyContent: 'center',
                        },
                        [theme.breakpoints.down('md')]: {
                          display: 'flex',
                          justifyContent: 'centre',
                        },
                      })}
                    >
                      <Button
                        variant="outlined"
                        type="reset"
                        onClick={formik.resetForm}
                        sx={theme => ({
                          [theme.breakpoints.down('lg')]: {
                            width: '90%',
                          },
                          [theme.breakpoints.down('md')]: {
                            width: '90%',
                          },
                          color: '#FFFFFF !important',
                          backgroundColor: '#000000 !important',
                          border: '0.125rem solid #3FD2F2 !important',
                          borderRadius: '3px !important',
                          width: '95%',
                          padding: '0.3rem !important',
                          textTransform: 'unset !important',
                        })}
                        startIcon={<img src={resetButton} width={15} height={15} alt={'resetButton'} />}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>

                  <Box
                    container
                    xs={12}
                    marginTop="0.125rem !important"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0.625rem 0rem 0rem 0.938rem !important',
                    }}
                  >
                    <Box item xs={6} sx={{ width: '100%' }}>
                      <Button
                        variant="outlined"
                        sx={{
                          color: '#FFFFFF !important',
                          backgroundColor: '#000000 !important',
                          borderRadius: '3px !important',
                          border: openSingleModal
                            ? '0.125rem solid #3FD2F2 !important'
                            : '0.125rem solid #FF1730 !important',
                          width: '98%',
                          padding: '0.3rem !important',
                          textTransform: 'unset !important',
                          '&:disabled': {
                            cursor: 'not-allowed !important',
                            pointerEvents: 'all !important',
                            backgroundColor: 'grey !important',
                            color: 'white !important',
                            border: 'none !important',
                          },
                        }}
                        disabled={
                          (openSingleModal && (!formik.isValid || !formik.dirty || isModelLoading)) ||
                          (openUpdateModel && compareValues && (!formik.isValid || !formik.dirty || isModelLoading)) ||
                          (openEditModal && (!formik.isValid || isModelLoading))
                        }
                        startIcon={
                          openSingleModal || openEditModal ? (
                            <TbRefreshAlert style={{ color: '#2ECEC2' }} />
                          ) : (
                            openUpdateModel && <TbRefreshAlert style={{ color: 'red' }} />
                          )
                        }
                        type="submit"
                        onClick={() => {
                          setSingleModelRunButtonClick(true);
                          setSaveButtonClick(false);
                        }}
                      >
                        {isModelLoading ? (
                          <CircularProgress size={20} />
                        ) : openSingleModal || openEditModal ? (
                          'Save & Run'
                        ) : (
                          openUpdateModel && 'Overwrite & Run'
                        )}
                      </Button>
                    </Box>
                  </Box>
                </>
              </Grid>
            </div>
          )}
        </form>
        <SparkModal
          openAbandonModalFromSingleModal={openAbandonModalFromSingleModal}
          setOpenAbandonModalFromSingleModal={setOpenAbandonModalFromSingleModal}
          setOpenUpdateModel={setOpenUpdateModel}
          setOpenEditModal={setOpenEditModal}
          setOpenSingleModal={setOpenSingleModal}
        />
      </Box>
    </Modal>
  );
};

export default SingleModel;
