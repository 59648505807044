import { dailyPerformanceData } from './ActionTypes';

export const storeDailyPerformanceDataSuccess = payload => ({
  type: dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_SUCCESS,
  payload,
});

export const storeDailyPerformanceDataFailure = payload => ({
  type: dailyPerformanceData.STORE_DAILY_PERFORMANCE_DATA_FAILURE,
  payload,
});

export const getDailyPerformanceData = () => ({
  type: dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_REQUEST,
});

export const getDailyPerformanceDataSuccess = payload => ({
  type: dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_SUCCESS,
  payload,
});

export const getDailyPerformanceDataFailure = payload => ({
  type: dailyPerformanceData.GET_DAILY_PERFORMANCE_DATA_FAILURE,
  payload,
});
