import axios from 'axios';
import { startsWith } from 'lodash';
import { toast } from 'react-toastify';
import { localStorageClear, setLocalStorage } from 'helpers/localStorage';
import { TOKEN_NAME } from 'constants/token';
import { cookie } from 'helpers/cookies';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const authHeader = () => {
  const token = cookie.get(TOKEN_NAME.ACCESS_TOKEN);
  return { Authorization: `Bearer ${token}` };
};

const url = (path, params) => {
  const sections = path.split(':');
  const sectionsWithParams = sections.map(section => (startsWith(section, '/') ? section : params[section]));
  const pathWithParams = sectionsWithParams.join('');
  return process.env.REACT_APP_API_URL + pathWithParams;
};

const getHeaders = auth => {
  let headers = { ...defaultHeaders };

  if (auth) {
    headers = { ...headers, ...authHeader() };
  }
  return headers;
};

const apiService = axios.create({});

export const get = (path, params = {}, auth = true) =>
  apiService.get(url(path, params), {
    params,
    headers: getHeaders(auth),
  });

export const post = (path, params = {}, timeout, auth = true) =>
  apiService.post(url(path, params), params, { headers: getHeaders(auth), timeout: timeout });

export const put = (path, params = {}, auth = true) =>
  apiService.put(url(path, params), params, { headers: getHeaders(auth) });

export const patch = (path, params = {}, auth = true) =>
  apiService.patch(url(path, params), params, { headers: getHeaders(auth) });

export const deleteRequest = (path, params = {}, auth = true) =>
  apiService.delete(url(path, params), {
    params,
    headers: getHeaders(auth),
  });

export const upload = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, {
    headers: { ...getHeaders(auth), 'content-type': 'multipart/form-data' },
  });

export const download = (path, params = {}, auth = true) =>
  apiService.get(url(path, params), {
    responseType: 'blob',
    params,
    headers: getHeaders(auth),
  });

export const downloadFile = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, {
    responseType: 'blob',
    headers: getHeaders(auth),
  });

var done = false;

const getRefreshToken = (function () {
  return function () {
    if (!done) {
      const refreshToken = cookie.get(TOKEN_NAME.REFRESH_TOKEN);
      done = true;
      return post('/v1/client/auth/refresh-tokens', { refreshToken: refreshToken });
    }
  };
})();

apiService.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }

    if (error && error.response && error.response.status === 498) {
      //for: get refresh token
      const response = await getRefreshToken();
      cookie.set(TOKEN_NAME.REFRESH_TOKEN, response?.data?.data.refresh.token);
      setLocalStorage(TOKEN_NAME.ACCESS_TOKEN, response.data.data.access.token);
      cookie.set(TOKEN_NAME.ACCESS_TOKEN, response.data.data.access.token);
      done = false;
    } else if (error && error.response && error.response.status === 401) {
      await localStorageClear();
      cookie.clean();
      window.location.href = '/login';
    } else {
      return Promise.reject(error.response);
    }
    return Promise.reject(error.response);
  },
);
