import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/Spark.module.scss';
import { isEmpty, find } from 'lodash';
import Pusher from 'pusher-js';
import { pusher } from 'utils/common';
import { EnumPusherChannelName, EnumPusherEventName } from 'constants/pusher-enums';
import { useStyles } from 'components/common/utils';
import { initOnReady } from 'components/chart/main';
import { getModelTradeData } from 'actions/simAction';
import { EnumTypeOfModel } from 'constants/model-enums';
import { fetchExchanges } from 'actions/classificationAction';
import { getSparkLimitedModels } from 'actions/sparkAction';
import BottomTab from 'components/spark/BottomTab';
import DataLoadErrorModal from 'components/common/DataLoadErrorModal';
import SparkInstances from 'components/spark/SparkInstances';

const Spark = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [modelIdOfApplyModel, setModelIdOfApplyModel] = useState('');
  const [selectedSparkModelData, setSelectedSparkModelData] = useState('');
  const [showInstanceDetailPage, setShowInstanceDetailPage] = useState(false);
  const [openDataLoadErrorModal, setOpenDataLoadErrorModal] = useState(false);
  const [showModelSelectionScreen, setShowModelSelectionScreen] = useState(false);
  const [showDataLoadErrorModel, setShowDataLoadErrorModel] = useState(
    JSON.parse(localStorage.getItem('showDataLoadErrorModel') || 'false'),
  );

  const dataFromApplyModel = useSelector(state => state.spark.isModelApplied);
  const modelTradeData = useSelector(state => state?.sim?.modelTradeData?.simTableData);
  const sparkModels = useSelector(state => state.spark.sparkLimitedModels);
  const exchangesData = useSelector(state => state.classification.exchanges);
  const selectedSparkModel = find(sparkModels, item => item.id === selectedSparkModelData.id);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getSparkModels = useCallback(() => {
    dispatch(getSparkLimitedModels());
  }, []);

  const getExchange = useCallback(() => {
    dispatch(fetchExchanges());
  }, []);

  useEffect(() => {
    if (isEmpty(exchangesData)) {
      getExchange();
    }
  }, [exchangesData]);

  useEffect(() => {
    getSparkModels();
  }, []);

  // ::::: Pusher Functionality  :::::
  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;

    const channel = pusher.subscribe(EnumPusherChannelName.MODEL_STATUS);

    channel.bind(EnumPusherEventName.SPARK_MODEL_STATUS, function (data) {
      dispatch(getSparkLimitedModels());
    });

    return () => {
      pusher.unsubscribe(EnumPusherChannelName.MODEL_STATUS);
    };
  }, []);

  useEffect(() => {
    // Function to handle changes in localStorage
    const handleStorageChange = e => {
      if (e.key === 'showDataLoadErrorModel') {
        setShowDataLoadErrorModel(JSON.parse(e.newValue || 'false'));
      }
    };

    // Add a 'storage' event listener to listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Add a custom event listener to listen for the custom event
    const handleCustomEvent = () => {
      // Update the state when the custom event is triggered
      setShowDataLoadErrorModel(true);
    };

    const handleCustomEventForClosed = () => {
      // Update the state when the custom event is triggered
      setShowDataLoadErrorModel(false);
    };

    window.addEventListener('showDataLoadErrorModelChanged', handleCustomEvent);
    window.addEventListener('showDataLoadErrorModelChangedClosed', handleCustomEventForClosed);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('showDataLoadErrorModelChanged', handleCustomEvent);
      window.removeEventListener('showDataLoadErrorModelChangedClosed', handleCustomEventForClosed);
    };
  }, []);

  useEffect(() => {
    if (showDataLoadErrorModel) {
      setOpenDataLoadErrorModal(true);
    }
  }, [showDataLoadErrorModel]);

  useEffect(() => {
    if (!isEmpty(selectedSparkModel)) {
      if (selectedSparkModel?.simModelId?.modelType === 'historicalModel') {
        dispatch(
          getModelTradeData({
            id: selectedSparkModel?.id,
            modelType: EnumTypeOfModel.SPARK_MODEL,
          }),
        );
      }
    }
  }, [selectedSparkModel]);

  useEffect(() => {
    initOnReady(selectedSparkModel, dispatch);
    localStorage.setItem('analyze_historical_model', JSON.stringify(modelTradeData));
    localStorage.setItem('modelType', 'sparkModel');
  }, [selectedSparkModel, modelTradeData]);

  useEffect(() => {
    if (!isEmpty(selectedSparkModel)) {
      localStorage.setItem('symbol', selectedSparkModel?.pair?.primaryCrypto?.symbol);

      // ::::: Maybe Needed In Future ::::::::
      // localStorage.setItem('dateFrom', selectedSparkModel?.startTime);
      // localStorage.setItem('dateTo', selectedSparkModel?.endTime);
      // localStorage.setItem('modelStatus', selectedSparkModel?.modelStatus);

      localStorage.setItem(
        'dateFrom',
        selectedSparkModel?.performanceOnEveryRunTime[0]?.startDate
          ? selectedSparkModel?.performanceOnEveryRunTime[0]?.startDate
          : selectedSparkModel?.modelStartTime
          ? selectedSparkModel?.modelStartTime
          : selectedSparkModel?.startTime,
      );
      localStorage.setItem(
        'dateTo',
        selectedSparkModel?.modelHaltTime ? selectedSparkModel?.modelHaltTime : selectedSparkModel?.endTime,
      );
    }
  }, [selectedSparkModel]);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.content}>
          <div id="tv_chart_container" className={styles.chart} />
          <div className={[styles.note, open ? styles.openTabCss : styles.closedTabCss]}>
            <BottomTab
              open={open}
              classes={classes}
              selectedModelData={selectedSparkModelData}
              setSelectedSparkModelData={setSelectedSparkModelData}
            />
          </div>
        </div>
        <div className={[styles.sidebar, open ? styles.openSideTab : styles.closeSideTab]}>
          <SparkInstances
            classes={classes}
            open={open || dataFromApplyModel?.openSparkInstanceDrawer}
            handleDrawerOpen={handleDrawerOpen}
            showInstanceDetailPage={showInstanceDetailPage}
            setShowInstanceDetailPage={setShowInstanceDetailPage}
            showModelSelectionScreen={showModelSelectionScreen}
            setShowModelSelectionScreen={setShowModelSelectionScreen}
            selectedSparkModelData={selectedSparkModelData}
            setSelectedSparkModelData={setSelectedSparkModelData}
            modelIdOfApplyModel={modelIdOfApplyModel}
            setModelIdOfApplyModel={setModelIdOfApplyModel}
            dataFromApplyModel={dataFromApplyModel}
          />
        </div>
        <DataLoadErrorModal
          openDataLoadErrorModal={openDataLoadErrorModal}
          setOpenDataLoadErrorModal={setOpenDataLoadErrorModal}
        />
      </div>
    </>
  );
};

export default Spark;
