// All actions types declare here

export const usersTypes = {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
};

export const classificationTypes = {
  FETCH_CLASSIFICATION_REQUEST: 'FETCH_CLASSIFICATION_REQUEST',
  FETCH_CLASSIFICATION_SUCCESS: 'FETCH_CLASSIFICATION_SUCCESS',
  FETCH_CLASSIFICATION_FAILURE: 'FETCH_CLASSIFICATION_FAILURE',
};

export const coinTypes = {
  FETCH_COIN_REQUEST: 'FETCH_COIN_REQUEST',
  FETCH_COIN_SUCCESS: 'FETCH_COIN_SUCCESS',
  FETCH_COIN_FAILURE: 'FETCH_COIN_FAILURE',
};

export const cryptoPair = {
  FETCH_CRYPTO_PAIR_REQUEST: 'FETCH_CRYPTO_PAIR_REQUEST',
  FETCH_CRYPTO_PAIR_SUCCESS: 'FETCH_CRYPTO_PAIR_SUCCESS',
  FETCH_CRYPTO_PAIR_FAILURE: 'FETCH_CRYPTO_PAIR_FAILURE',
};

export const createHistoricalModel = {
  CREATE_HISTORICAL_MODEL: 'CREATE_HISTORICAL_MODEL',
  CREATE_HISTORICAL_MODEL_SUCCESS: 'CREATE_HISTORICAL_MODEL_SUCCESS',
  CREATE_HISTORICAL_MODEL_FAIL: 'CREATE_HISTORICAL_MODEL_FAIL',

  CREATE_RUN_HISTORICAL_MODEL: 'CREATE_RUN_HISTORICAL_MODEL',
  CREATE_RUN_HISTORICAL_MODEL_SUCCESS: 'CREATE_RUN_HISTORICAL_MODEL_SUCCESS',
  CREATE_RUN_HISTORICAL_MODEL_FAIL: 'CREATE_RUN_HISTORICAL_MODEL_FAIL',
};

export const dailyPerformanceData = {
  STORE_DAILY_PERFORMANCE_DATA_REQUEST: 'STORE_DAILY_PERFORMANCE_DATA_REQUEST',
  STORE_DAILY_PERFORMANCE_DATA_SUCCESS: 'STORE_DAILY_PERFORMANCE_DATA_SUCCESS',
  STORE_DAILY_PERFORMANCE_DATA_FAILURE: 'STORE_DAILY_PERFORMANCE_DATA_FAILURE',

  GET_DAILY_PERFORMANCE_DATA_REQUEST: 'GET_DAILY_PERFORMANCE_DATA_REQUEST',
  GET_DAILY_PERFORMANCE_DATA_SUCCESS: 'GET_DAILY_PERFORMANCE_DATA_SUCCESS',
  GET_DAILY_PERFORMANCE_DATA_FAILURE: 'GET_DAILY_PERFORMANCE_DATA_FAILURE',
};

export const models = {
  GET_MODEL: 'GET_MODEL',
  GET_MODEL_SUCCESS: 'GET_MODEL_SUCCESS',
  GET_MODEL_FAILURE: 'GET_MODEL_FAILURE',

  GET_RESULT_DOWNLOAD: 'GET_RESULT_DOWNLOAD',
  GET_RESULT_DOWNLOAD_SUCCESS: 'GET_RESULT_DOWNLOAD_SUCCESS',
  GET_RESULT_DOWNLOAD_FAILURE: 'GET_RESULT_DOWNLOAD_FAILURE',

  UPDATE_MODEL: 'UPDATE_MODEL',
  UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
  UPDATE_MODEL_FAIL: 'UPDATE_MODEL_FAIL',

  UPDATE_AND_RUN_MODEL: 'UPDATE_AND_RUN_MODEL',
  UPDATE_AND_RUN_MODEL_SUCCESS: 'UPDATE_AND_RUN_MODEL_SUCCESS',
  UPDATE_AND_RUN_MODEL_FAIL: 'UPDATE_AND_RUN_MODEL_FAIL',

  DELETE_MODEL: 'DELETE_MODEL',
  DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
  DELETE_MODEL_FAILURE: 'DELETE_MODEL_FAILURE',

  GET_MODEL_TRADE_DATA: 'GET_MODEL_TRADE_DATA',
  GET_MODEL_TRADE_DATA_SUCCESS: 'GET_MODEL_TRADE_DATA_SUCCESS',
  GET_MODEL_TRADE_DATA_FAIL: 'GET_MODEL_TRADE_DATA_FAIL',

  GET_RUNNING_MODEL: 'GET_RUNNING_MODEL',
  GET_RUNNING_MODEL_SUCCESS: 'GET_RUNNING_MODEL_SUCCESS',
  GET_RUNNING_MODEL_FAIL: 'GET_RUNNING_MODEL_FAIL',

  SELECT_MODEL_IN_SUMMARY: 'SELECT_MODEL_IN_SUMMARY',

  CHART_DATA: 'CHART_DATA',

  IS_CHART_DRAWING_CHANGED: 'IS_CHART_DRAWING_CHANGED',

  STOP_SIM_MODEL: 'STOP_SIM_MODEL',
  STOP_SIM_MODEL_SUCCESS: 'STOP_SIM_MODEL_SUCCESS',
  STOP_SIM_MODEL_FAIL: 'STOP_SIM_MODEL_FAIL',
};

export const simInstanceRunModels = {
  SIM_INSTANCE_RUN_MODEL: 'SIM_INSTANCE_RUN_MODEL',
  SIM_INSTANCE_RUN_MODEL_SUCCESS: 'SIM_INSTANCE_RUN_MODEL_SUCCESS',
  SIM_INSTANCE_RUN_MODEL_FAIL: 'SIM_INSTANCE_RUN_MODEL_FAIL',
};

export const notes = {
  FETCH_NOTES: 'FETCH_NOTES',
  FETCH_NOTES_SUCCESS: 'FETCH_NOTES_SUCCESS',
  FETCH_NOTES_FAIL: 'FETCH_NOTES_FAIL',

  CREATE_NOTE: 'CREATE_NOTE',
  CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
  CREATE_NOTE_FAIL: 'CREATE_NOTE_FAIL',

  UPDATE_NOTE: 'UPDATE_NOTE',
  UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAIL: 'UPDATE_NOTE_FAIL',

  DELETE_NOTE: 'DELETE_NOTE',
  DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAIL: 'DELETE_NOTE_FAIL',

  IS_NOTE_EDIT: 'IS_NOTE_EDIT',
  IS_NOTE_TEXT_EDITED: 'IS_NOTE_TEXT_EDITED',
  IS_NOTE_EDITED_FROM_OUTSIDE: 'IS_NOTE_EDITED_FROM_OUTSIDE',
};

export const alerts = {
  FETCH_ALERTS: 'FETCH_ALERTS',
  FETCH_ALERTS_SUCCESS: 'FETCH_ALERTS_SUCCESS',
  FETCH_ALERTS_FAIL: 'FETCH_ALERTS_FAIL',

  GET_ALERT: 'GET_ALERT',
  GET_ALERT_SUCCESS: 'GET_ALERT_SUCCESS',
  GET_ALERT_FAIL: 'GET_ALERT_FAIL',

  CREATE_ALERT: 'CREATE_ALERT',
  CREATE_ALERT_SUCCESS: 'CREATE_ALERT_SUCCESS',
  CREATE_ALERT_FAIL: 'CREATE_ALERT_FAIL',

  UPDATE_ALERT: 'UPDATE_ALERT',
  UPDATE_ALERT_SUCCESS: 'UPDATE_ALERT_SUCCESS',
  UPDATE_ALERT_FAIL: 'UPDATE_ALERT_FAIL',

  DELETE_ALERT: 'DELETE_ALERT',
  DELETE_ALERT_SUCCESS: 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_FAIL: 'DELETE_ALERT_FAIL',
};

export const alertGroup = {
  FETCH_ALERT_GROUP: 'FETCH_ALERT_GROUP',
  FETCH_ALERT_GROUP_SUCCESS: 'FETCH_ALERT_GROUP_SUCCESS',
  FETCH_ALERT_GROUP_FAIL: 'FETCH_ALERT_GROUP_FAIL',

  GET_ALERT_GROUP: 'GET_ALERT_GROUP',
  GET_ALERT_GROUP_SUCCESS: 'GET_ALERT_GROUP_SUCCESS',
  GET_ALERT_GROUP_FAIL: 'GET_ALERT_GROUP_FAIL',

  CREATE_ALERT_GROUP: 'CREATE_ALERT_GROUP',
  CREATE_ALERT_GROUP_SUCCESS: 'CREATE_ALERT_GROUP_SUCCESS',
  CREATE_ALERT_GROUP_FAIL: 'CREATE_ALERT_GROUP_FAIL',

  UPDATE_ALERT_GROUP: 'UPDATE_ALERT_GROUP',
  UPDATE_ALERT_GROUP_SUCCESS: 'UPDATE_ALERT_GROUP_SUCCESS',
  UPDATE_ALERT_GROUP_FAIL: 'UPDATE_ALERT_GROUP_FAIL',

  DELETE_ALERT_GROUP: 'DELETE_ALERT_GROUP',
  DELETE_ALERT_GROUP_SUCCESS: 'DELETE_ALERT_GROUP_SUCCESS',
  DELETE_ALERT_GROUP_FAIL: 'DELETE_ALERT_GROUP_FAIL',
};

export const exchanges = {
  FETCH_EXCHANGES: 'FETCH_EXCHANGES',
  FETCH_EXCHANGES_SUCCESS: 'FETCH_EXCHANGES_SUCCESS',
  FETCH_EXCHANGES_FAIL: 'FETCH_EXCHANGES_FAIL',

  GET_FRD_CRYPTO_LIST: 'GET_FRD_CRYPTO_LIST',
  GET_FRD_CRYPTO_LIST_SUCCESS: 'GET_FRD_CRYPTO_LIST_SUCCESS',
  GET_FRD_CRYPTO_LIST_FAIL: 'GET_FRD_CRYPTO_LIST_FAIL',

  GET_FRD_DATA: 'GET_FRD_DATA',
  GET_FRD_DATA_SUCCESS: 'GET_FRD_DATA_SUCCESS',
  GET_FRD_DATA_FAIL: 'GET_FRD_DATA_FAIL',
};

export const bulkModel = {
  CREATE_BULK_MODEL: 'CREATE_BULK_MODEL',
  CREATE_BULK_MODEL_SUCCESS: 'CREATE_BULK_MODEL_SUCCESS',
  CREATE_BULK_MODEL_FAIL: 'CREATE_BULK_MODEL_FAIL',

  GET_BULK_MODEL: 'GET_BULK_MODEL',
  GET_BULK_MODEL_SUCCESS: 'GET_BULK_MODEL_SUCCESS',
  GET_BULK_MODEL_FAILURE: 'GET_BULK_MODEL_FAILURE',
};

export const sparkInstanceRunModels = {
  SPARK_INSTANCE_RUN_MODEL: 'SPARK_INSTANCE_RUN_MODEL',
  SPARK_INSTANCE_RUN_MODEL_SUCCESS: 'SPARK_INSTANCE_RUN_MODEL_SUCCESS',
  SPARK_INSTANCE_RUN_MODEL_FAIL: 'SPARK_INSTANCE_RUN_MODEL_FAIL',
};

export const sparkInstanceStopAllModels = {
  SPARK_INSTANCE_STOP_ALL_MODELS: 'SPARK_INSTANCE_STOP_ALL_MODELS',
  SPARK_INSTANCE_STOP_ALL_MODELS_SUCCESS: 'SPARK_INSTANCE_STOP_ALL_MODELS_SUCCESS',
  SPARK_INSTANCE_STOP_ALL_MODELS_FAIL: 'SPARK_INSTANCE_STOP_ALL_MODELS_FAIL',
};

export const sparkInstanceMetrics = {
  GET_SPARK_INSTANCE_METRICS: 'GET_SPARK_INSTANCE_METRICS',
  GET_SPARK_INSTANCE_METRICS_SUCCESS: 'GET_SPARK_INSTANCE_METRICS_SUCCESS',
  GET_SPARK_INSTANCE_METRICS_FAIL: 'GET_SPARK_INSTANCE_METRICS_FAIL',
};

export const sparkInstanceClearModels = {
  SPARK_INSTANCE_CLEAR_MODEL: 'SPARK_INSTANCE_CLEAR_MODEL',
  SPARK_INSTANCE_CLEAR_MODEL_SUCCESS: 'SPARK_INSTANCE_CLEAR_MODEL_SUCCESS',
  SPARK_INSTANCE_CLEAR_MODEL_FAIL: 'SPARK_INSTANCE_CLEAR_MODEL_FAIL',
};

export const sparkPromoteModel = {
  SPARK_PROMOTE_MODEL: 'SPARK_PROMOTE_MODEL',
  SPARK_PROMOTE_MODEL_SUCCESS: 'SPARK_PROMOTE_MODEL_SUCCESS',
  SPARK_PROMOTE_MODEL_FAIL: 'SPARK_PROMOTE_MODEL_FAIL',
};

export const sparkModels = {
  GET_SPARK_MODELS: 'GET_SPARK_MODELS',
  GET_SPARK_MODELS_SUCCESS: 'GET_SPARK_MODELS_SUCCESS',
  GET_SPARK_MODELS_FAIL: 'GET_SPARK_MODELS_FAIL',

  GET_SPARK_LIMITED_MODELS: 'GET_SPARK_LIMITED_MODELS',
  GET_SPARK_LIMITED_MODELS_SUCCESS: 'GET_SPARK_LIMITED_MODELS_SUCCESS',
  GET_SPARK_LIMITED_MODELS_FAIL: 'GET_SPARK_LIMITED_MODELS_FAIL',

  GET_SPARK_MODEL_BY_ID: 'GET_SPARK_MODEL_BY_ID',
  GET_SPARK_MODEL_BY_ID_SUCCESS: 'GET_SPARK_MODEL_BY_ID_SUCCESS',
  GET_SPARK_MODEL_BY_ID_FAIL: 'GET_SPARK_MODEL_BY_ID_FAIL',

  HANDLE_BEAKER: 'HANDLE_BEAKER',
  HANDLE_BEAKER_SUCCESS: 'HANDLE_BEAKER_SUCCESS',
  HANDLE_BEAKER_FAIL: 'HANDLE_BEAKER_FAIL',
};

export const navigation = {
  IS_MODEL_APPLIED: 'IS_MODEL_APPLIED',
};
