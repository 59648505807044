import { get, post } from 'utils/api';

export const createSparkInstanceRunModel = data => {
  const selectedInstanceNumber = data?.selectedInstanceNumber;
  delete data['selectedInstanceNumber'];
  return selectedInstanceNumber
    ? post(`/v1/client/sparc-instance/v2/run-model?selectedInstanceNumber=${selectedInstanceNumber}`, data)
    : post(`/v1/client/sparc-instance/v2/run-model`, data);
};

export const getSparkInstanceStopAllModels = data => {
  return get(`/v1/client/spark/stop-spark-model`, data);
};

export const getSparkInstanceMetrics = id => {
  return get(`/v1/client/sparc-instance/v2/get-metrics/${id}`);
};

export const sparkInstanceClearModel = data => {
  return post(`/v1/client/sparc-instance/clear-halt-condition-model`, data);
};

export const sparkPromote = data => {
  return post(`/v1/client/historical-model/promote-to-spark/`, data);
};

export const getSparkModalById = id => {
  return get(`/v1/client/spark/${id}`);
};

export const handleBeaker = id => {
  return get(`/v1/client/spark/${id}/beaker`);
};

export const getSparkModels = data => {
  return get(`/v1/client/spark`, data);
};

export const getSparkLimitedModels = data => {
  return get(`/v1/client/spark/spark-Instances`, data);
};

export const spark = {
  createSparkInstanceRunModel,
  getSparkInstanceStopAllModels,
  getSparkInstanceMetrics,
  sparkInstanceClearModel,
  sparkPromote,
  getSparkModalById,
  handleBeaker,
  getSparkModels,
  getSparkLimitedModels,
};
