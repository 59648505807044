import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import { Header } from '../stories/Header';
import NavBar from './NavBar';

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    overflowY: 'scroll',
    background: 'black',
    '&::-webkit-scrollbar': {
      width: '0.438rem',
    },
    '&::-webkit-scrollbar-track': {
      background: '#171616',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#175982',
      borderRadius: 2,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: '#040d3e',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#094e8e',
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width: '10.938rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '10rem',
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit,
    justifyContent: 'flex-end',
    padding: '0 0.5rem',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 6,
    overflow: 'hidden',
    '@media screen and (max-height: 800px)': {
      overflow: 'auto',
    },
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    background: '#040d3e',
    flexGrow: 1,
  },
  list: {
    paddingTop: '0',
  },
  ListItemText: {
    margin: '0',
  },
  MuiListItemIcon: {
    minWidth: '0',
  },
  listItem: {
    fontSize: '0.75rem',
    color: '#ffff',
    paddingRight: '5.369rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.688rem',
      paddingRight: '3.147rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      paddingRight: '2.738rem',
    },
  },
  listButton: {
    display: 'flex',
    gap: '0.7rem',
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0.625rem !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0.313rem !important',
    },
  },
  simTitleClose: {
    display: 'flex !important',
    flexDirection: 'row !important',
    // ml: open ? 28 : 10,
    marginLeft: '5rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '4.688rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4.375rem',
    },
  },
  simTitleOpen: {
    display: 'flex',
    flexDirection: 'row',
    // ml: open ? 28 : 10,
    marginLeft: '14rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10.938rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10rem',
    },
  },
}));

const Layout = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const openEl = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header classes={classes} open={open} />
      <NavBar classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} />
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
