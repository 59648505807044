import React from 'react';
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Cached, PermMedia, Warning } from '@material-ui/icons';
import { BsFileEarmarkArrowDownFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { map } from 'lodash';

const trigger = [
  { date: 'November 4th,2022', time: '1.17 AM MST' },
  { date: 'November 5th,2022', time: '1.20 AM MST' },
  { date: 'November 6th,2022', time: '1.45 AM MST' },
  { date: 'November 7th,2022', time: '2.00 AM MST' },
  { date: 'November 8th,2022', time: '2.00 AM MST' },
  { date: 'November 9th,2022', time: '2.00 AM MST' },
  { date: 'November 10th,2022', time: '2.00 AM MST' },
  { date: 'November 12th,2022', time: '2.00 AM MST' },
  { date: 'November 12th,2022', time: '2.00 AM MST' },
  { date: 'November 22th,2022', time: '2.00 AM MST' },
  { date: 'November 27th,2022', time: '2.00 AM MST' },
  { date: 'November 17th,2022', time: '2.00 AM MST' },
  { date: 'November 17th,2022', time: '2.00 AM MST' },
  { date: 'November 27th,2022', time: '2.00 AM MST' },
];

const NotificationDetail = props => {
  const { handleChangeTab, valueOfTab, classes } = props;
  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1.5px solid #3FD2F2',
            padding: '0.5rem',
          }}
        >
          <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <Warning style={{ color: '#ed9e0c' }} />
            <Typography sx={{ color: '#ed9e0c', fontWeight: '500' }} variant={'h6'} component={'div'}>
              ETH Breakout Alert
            </Typography>
          </Stack>
          <Warning style={{ color: '#ed9e0c' }} />
        </Box>
        <Box>
          <Grid container xs={12} spacing={2} sx={{ width: '100%', margin: '0.5rem' }}>
            <Grid item xl={6} lg={12} sx={{ padding: 'unset !important' }}>
              <NotificationInfo name={'Class/Coin'} detail={'Etherium(ETH)'} />
              <NotificationInfo name={'Owner'} detail={'John Smith'} />
              <NotificationInfo name={'Class/Coin'} detail={'Etherium(ETH)'} />
              <NotificationInfo name={'Description'} detail={'Eth.....'} />
            </Grid>
            <Grid item xl={6} lg={12} sx={{ marginTop: '1rem' }}>
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#000000',
                  border: '2px solid #3FD2F2',
                  textTransform: 'unset !important',
                }}
                endIcon={<BsFillArrowRightCircleFill />}
              >
                CoinBase
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Tabs
          aria-label="Tabs where each tab needs to be selected manually"
          onChange={handleChangeTab}
          value={valueOfTab}
          variant="fullWidth"
          sx={theme => ({
            justifyContent: 'space-around',
            border: '2px solid #3FD2F2',
          })}
          indicatorColor={'#3FD2F2'}
          textColor={valueOfTab === 0 ? '#FFD41F' : '#B1AFCD'}
        >
          <Tab
            label="Trigger History"
            className={classes.commonTextStyle}
            sx={theme => ({
              borderRight: '1px solid #3FD2F2',
              color: valueOfTab === 0 ? '#FFD41F' : '#B1AFCD',
              [theme.breakpoints.down('md')]: {
                paddingTop: '6px',
                paddingBottom: '6px',
              },
            })}
          />
          <Tab
            label="Notes"
            className={classes.commonTextStyle}
            sx={theme => ({
              borderRight: '1px solid #1a2147',
              color: valueOfTab === 1 ? '#FFD41F' : '#B1AFCD',
              [theme.breakpoints.down('md')]: {
                paddingTop: '6px',
                paddingBottom: '6px',
              },
            })}
          />
        </Tabs>
        {valueOfTab === 0 && (
          <Box sx={{ padding: '1rem' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <PermMedia style={{ color: '#3FD2F2' }} />
                <BsFileEarmarkArrowDownFill size={19} color={'#3FD2F2'} />
                <Cached style={{ color: '#3FD2F2' }} />
              </Stack>
            </Box>
            <Box
              sx={{
                border: '1px solid #fff',
                marginTop: '1rem',
                padding: '1rem',
                maxHeight: 250,
                overflow: 'scroll',
                overflowX: 'unset',
                '&::-webkit-scrollbar': {
                  width: '7px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#171616',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#175982',
                  borderRadius: 2,
                },
              }}
            >
              {map(trigger, data => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid',
                      marginBottom: '0.5rem',
                      color: '#fff',
                    }}
                  >
                    <Typography component={'div'}>{data.date}</Typography>
                    <Typography component={'div'}>{data.time}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {valueOfTab === 1 && (
          <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
            <Typography variant={'h6'} sx={{ color: '#fff' }} component={'div'}>
              Notes is Coming.......
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NotificationDetail;

const NotificationInfo = props => {
  const { name, detail } = props;
  return (
    <Grid container xs={12} spacing={0.5} sx={{ width: '100%', margin: '0.5rem', color: '#fff' }}>
      <Grid item xs={12}>
        <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
          {name}:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'}>{detail}</Typography>
      </Grid>
    </Grid>
  );
};
