import {
  sparkInstanceMetrics,
  sparkPromoteModel,
  sparkModels,
  sparkInstanceRunModels,
  sparkInstanceStopAllModels,
  navigation,
} from 'actions/ActionTypes';

const initialState = {
  sparkModelDetail: {},
  error: null,
  data: null,
  sparkPromoteModel: [],
  sparkModel: [],
  mostRecentVersionModel: [],
  sparkModels: [],
  sparkLimitedModels: [],
  isGetSparkModelLoading: false,
  isBeakerLoading: false,
  isSparkPromoteModelLoading: false,
  isStartSparkModelLoading: false,
  isStopSparkModelLoading: false,
  isGetSparkModelsDataLoading: false,
  isGetSparkLimitedModelsDataLoading: false,
  isGetSparkMetricsDataLoading: false,
  isModelApplied: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS:
      return {
        ...state,
        isGetSparkMetricsDataLoading: true,
      };
    case sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS_SUCCESS:
      return {
        ...state,
        isGetSparkMetricsDataLoading: false,
        sparkModelDetail: action.payload.sparkModelDetail.tradesObject,
      };
    case sparkInstanceMetrics.GET_SPARK_INSTANCE_METRICS_FAIL:
      return {
        ...state,
        isGetSparkMetricsDataLoading: false,
        sparkModelDetail: {},
        error: action.payload.error,
      };
    case sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS:
      return {
        ...state,
        isStopSparkModelLoading: true,
      };
    case sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS_SUCCESS:
      return {
        ...state,
        isStopSparkModelLoading: false,
      };
    case sparkInstanceStopAllModels.SPARK_INSTANCE_STOP_ALL_MODELS_FAIL:
      return {
        ...state,
        isStopSparkModelLoading: false,
      };
    case sparkPromoteModel.SPARK_PROMOTE_MODEL:
      return {
        ...state,
        isSparkPromoteModelLoading: true,
      };
    case sparkPromoteModel.SPARK_PROMOTE_MODEL_SUCCESS:
      return {
        ...state,
        isSparkPromoteModelLoading: false,
        data: action.payload,
      };
    case sparkPromoteModel.SPARK_PROMOTE_MODEL_FAIL:
      return {
        ...state,
        isSparkPromoteModelLoading: false,
        error: action.payload.error,
      };
    case sparkModels.GET_SPARK_MODEL_BY_ID:
      return {
        ...state,
        isGetSparkModelLoading: true,
      };
    case sparkModels.GET_SPARK_MODEL_BY_ID_SUCCESS:
      return {
        ...state,
        isGetSparkModelLoading: false,
        sparkModel: action.payload.data,
      };
    case sparkModels.GET_SPARK_MODEL_BY_ID_FAIL:
      return {
        ...state,
        isGetSparkModelLoading: false,
        error: action.payload.error,
      };
    case sparkModels.HANDLE_BEAKER:
      return {
        ...state,
        isBeakerLoading: true,
      };
    case sparkModels.HANDLE_BEAKER_SUCCESS:
      return {
        ...state,
        isBeakerLoading: false,
        mostRecentVersionModel: action.payload.data,
      };
    case sparkModels.HANDLE_BEAKER_FAIL:
      return {
        ...state,
        isBeakerLoading: false,
        error: action.payload.error,
      };
    case sparkModels.GET_SPARK_MODELS:
      return {
        ...state,
        isGetSparkModelsDataLoading: true,
      };
    case sparkModels.GET_SPARK_MODELS_SUCCESS:
      return {
        ...state,
        isGetSparkModelsDataLoading: false,
        sparkModels: action.payload,
      };
    case sparkModels.GET_SPARK_MODELS_FAIL:
      return {
        ...state,
        isGetSparkModelsDataLoading: false,
        error: action.payload.error,
      };

    case sparkModels.GET_SPARK_LIMITED_MODELS:
      return {
        ...state,
        isGetSparkLimitedModelsDataLoading: true,
      };
    case sparkModels.GET_SPARK_LIMITED_MODELS_SUCCESS:
      return {
        ...state,
        isGetSparkLimitedModelsDataLoading: false,
        sparkLimitedModels: action.payload,
      };
    case sparkModels.GET_SPARK_LIMITED_MODELS_FAIL:
      return {
        ...state,
        isGetSparkLimitedModelsDataLoading: false,
        error: action.payload.error,
      };

    case sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL:
      return {
        ...state,
        isStartSparkModelLoading: true,
      };
    case sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL_SUCCESS:
      return {
        ...state,
        isStartSparkModelLoading: false,
        sparkLimitedModels: state.sparkLimitedModels.map(data =>
          data.id === action.payload.id ? action.payload : data,
        ),
      };
    case sparkInstanceRunModels.SPARK_INSTANCE_RUN_MODEL_FAIL:
      return {
        ...state,
        isStartSparkModelLoading: false,
        error: action.payload.error,
      };

    case navigation.IS_MODEL_APPLIED: {
      return {
        ...state,
        isModelApplied: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
