import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Button, Divider, Drawer, Tab, Tabs } from '@mui/material';
import Modal from '@mui/material/Modal';
import Cancel from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import doublearrow from 'assets/doublearrow.svg';
import icoBear from 'assets/ico_bear.svg';
import icoBull from 'assets/ico_bull.svg';
import icoRange from 'assets/ico_range.svg';
import ApplyModel from './ApplyModel';
import Models from './Models';
import Results from './Results';
import {
  fetchClassificationRequest,
  fetchCoinRequest,
  fetchCryptoPairRequest,
  isNoteEditedFromOutside,
} from 'actions/simAction';
import SparkModel from 'components/spark/SparkModel';
import SparkModelFromSim from 'components/sim/SparkModal';
import SparkMetrics from 'components/spark/SparkMetrics';
import SparkSettings from 'components/spark/SparkSettings';

const Performance = ({
  classes,
  open,
  setOpen,
  handleDrawerOpen,
  setOpenBulkModal,
  setOpenSingleModal,
  setOpenDuplicateModal,
  setOpenSparkPromoteModal,
  setOpenDeleteModal,
  setOpenDangerModal,
  setAbandonChangesModal,
  isTextEdited,
  openDataLoadErrorModal,
  setOpenDataLoadErrorModal,
  setSelectedModelDataForChart,
  setIsModelSelected,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isNoteEdit = useSelector(state => state.sim.isNoteEdit);
  const isNoteTextEdited = useSelector(state => state.sim.isNoteTextEdited);
  const isChartDrawingChanged = useSelector(state => state.sim.isChartDrawingChanged);

  const [isApplyModel, setIsOpenApplyModel] = useState(false);
  const [selectedApplyModelData, setSelectedApplyModelData] = useState({});
  const [onClick, setOnClick] = useState(false);
  const [modelStatus, setModelStatus] = useState('');
  const [modelId, setModelId] = useState('');
  const [modelStartTime, setModelStartTime] = useState('');
  const [modelHaltTime, setModelHaltTime] = useState('');
  const [showTab, setShowTab] = useState(false);
  const [value, setValue] = useState(0);
  const [applyModelTab, setApplyModelTab] = useState(1);
  const [applymode, setApplyMode] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openAbandonModelFromPerformance, setOpenAbandonModelFromPerformance] = useState(false);
  const [isModelTabOpenFromBeaker, setIsModelTabOpenFromBeaker] = useState(false);
  const [loadModelId, setLoadModelId] = useState('');

  const isResultsTab = value === 0;
  const isModelsTab = value === 1;

  useEffect(() => {
    if (location?.state?.isModelTabOpen) {
      setValue(1);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchClassificationRequest());
    dispatch(fetchCoinRequest());
    dispatch(fetchCryptoPairRequest());
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue === 0 && !isModelTabOpenFromBeaker) {
      setIsModelTabOpenFromBeaker(true);
    }
    setValue(newValue);
    setApplyModelTab(newValue);
  };

  return (
    <>
      <Drawer
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'hidden !important',
          },
        }}
        variant="permanent"
        anchor="right"
        className={classNames(classes?.drawer, {
          [classes?.drawerOpen]: open,
          [classes?.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes?.drawerOpen]: open,
            [classes?.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <Box
          sx={{
            border: 1,
            height: open ? 'auto' : '100%',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#040d3e',
          }}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 700,
              padding: 1,
              color: '#ffff',
              fontSize: '0.75rem',
              paddingLeft: '1rem',
              [theme.breakpoints.down('md')]: {
                paddingLeft: '0.5rem',
              },
            })}
          >
            <img
              src={doublearrow}
              width={20}
              height={20}
              className={!open ? classes.imageRotate : ''}
              onClick={() => {
                handleDrawerOpen();
              }}
            />
            {open && (
              <Box
                className={classes.commonTextStyle}
                sx={theme => ({
                  marginLeft: '1rem',
                  fontSize: '0.875rem',
                  color: '#B1AFCD',
                  cursor: 'pointer',
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '0.625rem',
                  },
                })}
              >
                <span onClick={() => setOnClick(false)}>
                  {onClick || isApplyModel ? 'APPLY MODEL' : 'SIMULATION CONTROLS'}
                </span>
              </Box>
            )}
          </Box>
          {/* NOTE :::: Changes not done here because, this section in needed to be removed from here, need confirmation from Khushali Mam ::::: */}
          {open && onClick ? (
            <>
              <Box sx={{ width: '100%' }}>
                <Tabs
                  aria-label="Tabs where each tab needs to be selected manually"
                  onChange={handleChange}
                  value={applyModelTab}
                  variant="fullWidth"
                  textColor={applyModelTab === 0 ? '#FFD41F' : '#B1AFCD'}
                  TabIndicatorProps={{ style: { backgroundColor: '#FFD41F' } }}
                >
                  <Tab
                    iconPosition="start"
                    label="Metrics"
                    sx={theme => ({
                      color: applyModelTab === 0 ? '#FFD41F' : '#B1AFCD',
                      height: '3.125rem',
                      minHeight: '3.125rem !important',
                      [theme.breakpoints.down('md')]: {
                        paddingTop: '0.375rem',
                        paddingBottom: '0.375rem',
                      },
                    })}
                  />
                  <Tab
                    iconPosition="start"
                    label="Settings"
                    sx={theme => ({
                      color: applyModelTab === 1 ? '#FFD41F  !important' : '#B1AFCD !important',
                      height: '3.125rem  !important',
                      minHeight: '3.125rem !important',
                      [theme.breakpoints.down('md')]: {
                        paddingTop: '0.375rem',
                        paddingBottom: '0.375rem',
                      },
                    })}
                  />
                </Tabs>
              </Box>
              {applyModelTab === 0 && <SparkMetrics modelId={modelId} modelStatus={modelStatus} />}
              {applyModelTab === 1 &&
                (modelStatus === 'running' ? (
                  <SparkModel
                    modelId={modelId}
                    isSparkStartModel={false}
                    modelStartTime={modelStartTime}
                    modelHaltTime={modelHaltTime}
                    setIsOpenApplyModel={setIsOpenApplyModel}
                    setOnClick={setOnClick}
                  />
                ) : modelStatus === 'halted' ? (
                  <SparkModel
                    modelId={modelId}
                    isSparkStartModel={true}
                    modelStartTime={modelStartTime}
                    modelHaltTime={modelHaltTime}
                    setIsOpenApplyModel={setIsOpenApplyModel}
                    setOnClick={setOnClick}
                  />
                ) : (
                  <SparkSettings />
                ))}
            </>
          ) : (
            open && (
              <>
                <Divider
                  sx={{
                    marginTop: 1,
                    background: '#2E5D78',
                  }}
                />
                {isApplyModel ? (
                  <ApplyModel
                    setShowTab={setShowTab}
                    isApplyModel={isApplyModel}
                    setIsOpenApplyModel={setIsOpenApplyModel}
                    selectedApplyModelData={selectedApplyModelData}
                    setModelStatus={setModelStatus}
                    setModelId={setModelId}
                    setOnClick={setOnClick}
                    setModelStartTime={setModelStartTime}
                    setModelHaltTime={setModelHaltTime}
                    selectedCheckBox={selectedCheckBox}
                  />
                ) : (
                  <Box sx={{ width: '100%' }}>
                    <Tabs
                      aria-label="Tabs where each tab needs to be selected manually"
                      onChange={() => {
                        if (isNoteEdit && (isNoteTextEdited || isChartDrawingChanged)) {
                          dispatch(isNoteEditedFromOutside(true));
                          setOpenAbandonModelFromPerformance(true);
                        } else {
                          handleChange(_, isModelsTab ? 0 : 1);
                        }
                      }}
                      value={value}
                      variant="fullWidth"
                      sx={{
                        justifyContent: 'space-around',
                      }}
                      textColor={isResultsTab ? '#FFD41F' : '#B1AFCD'}
                      TabIndicatorProps={{
                        style: { backgroundColor: '#FFD41F' },
                      }}
                    >
                      <Tab
                        label="Results"
                        onClick={() => {
                          setLoadModelId('');
                          if (isNoteEdit && !isNoteTextEdited && !isChartDrawingChanged) {
                            setIsModelSelected(true);
                          }
                        }}
                        className={classes.commonTextStyle}
                        sx={theme => ({
                          color: isResultsTab ? '#FFD41F !important' : '#B1AFCD !important',
                          [theme.breakpoints.down('md')]: {
                            paddingTop: '0.375rem',
                            paddingBottom: '0.375rem',
                          },
                        })}
                      />
                      <Tab
                        label="Models"
                        className={classes.commonTextStyle}
                        sx={theme => ({
                          color: isModelsTab ? '#FFD41F !important' : '#B1AFCD !important',
                          [theme.breakpoints.down('md')]: {
                            paddingTop: '0.375rem',
                            paddingBottom: '0.375rem',
                          },
                        })}
                      />
                    </Tabs>
                  </Box>
                )}
                {(!isApplyModel || (showTab && !isApplyModel)) && isResultsTab && (
                  <Results
                    setSelectedModelDataForChart={setSelectedModelDataForChart}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setIsModelSelected={setIsModelSelected}
                    loadModelId={loadModelId}
                    setLoadModelId={setLoadModelId}
                  />
                )}
                {(!isApplyModel || (showTab && !isApplyModel)) && isModelsTab && (
                  <Models
                    setOpenDangerModal={setOpenDangerModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenSparkPromoteModal={setOpenSparkPromoteModal}
                    setOpenDuplicateModal={setOpenDuplicateModal}
                    setOpenBulkModal={setOpenBulkModal}
                    setOpenSingleModal={setOpenSingleModal}
                    setIsModelSelected={setIsModelSelected}
                    applymode={applymode}
                    setApplyMode={setApplyMode}
                    classes={classes}
                    setIsOpenApplyModel={setIsOpenApplyModel}
                    setSelectedApplyModelData={setSelectedApplyModelData}
                    setAbandonChangesModal={setAbandonChangesModal}
                    isTextEdited={isTextEdited}
                    openDataLoadErrorModal={openDataLoadErrorModal}
                    setOpenDataLoadErrorModal={setOpenDataLoadErrorModal}
                    setApplyModelTab={setApplyModelTab}
                    value={value}
                    setValue={setValue}
                    selectedCheckBox={selectedCheckBox}
                    setSelectedCheckBox={setSelectedCheckBox}
                    // props for opening spark settings tab directly
                    setOpen={setOpen}
                    setOnClick={setOnClick}
                    setModelStatus={setModelStatus}
                    isModelTabOpenFromBeaker={isModelTabOpenFromBeaker}
                    loadModelId={loadModelId}
                    setLoadModelId={setLoadModelId}
                  />
                )}
              </>
            )
          )}
        </Box>
      </Drawer>
      <Box
        sx={{
          bottom: '0',
          position: 'fixed',
          float: 'left',
          width: '100%',
          height: '100%',
          zIndex: '1000',
          border: 'none',
        }}
      >
        <Modal
          open={applymode}
          sx={{
            background: '#131E68',
            width: '25rem',
            height: '21.875rem',
            margin: 'auto',
          }}
        >
          <Box sx={{ border: 'none' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Cancel
                style={{
                  marginTop: '-0.75rem',
                  marginRight: '-0.688rem',
                  color: '#FFFFFF',
                }}
                onClick={() => {
                  setApplyMode(!applymode);
                }}
              />
            </Box>

            <Box
              sx={{
                marginTop: '10%',
                marginLeft: '10%',
              }}
            >
              <label style={{ color: '#FFFFFF', fontSize: '1.25rem' }}>Apply Mode</label>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '10%',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#000000',
                  border: '0.063rem solid #636771',
                  width: '50%',
                  margin: '0.313rem 0',
                  fontSize: '0.625rem',
                  height: '2.5rem',
                }}
                startIcon={<img src={icoBull} width={25} height={25} />}
              >
                Bull Mode Apply
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#000000',
                  border: '0.063rem solid #636771',
                  width: '50%',
                  margin: '0.313rem 0',
                  fontSize: '0.625rem',
                  height: '2.5rem',
                }}
                startIcon={<img src={icoRange} width={25} height={25} />}
              >
                Range Mode Apply
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#000000',
                  border: '0.063rem solid #636771',
                  width: '50%',
                  margin: '0.313rem 0',
                  fontSize: '0.625rem',
                  height: '2.5rem',
                }}
                startIcon={<img src={icoBear} width={30} height={30} />}
              >
                Bear Mode Apply
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: '9%',
                marginRight: '5%',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  border: 'none',
                  margin: '0.313rem 0.313rem 0.313rem 0 ',
                  fontSize: '0.625rem',
                }}
                onClick={() => {
                  setApplyMode(!applymode);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#0072F8',
                  border: '0.063rem solid #3FD2F2',
                  fontSize: '0.625rem',
                }}
                onClick={() => {
                  setApplyMode(!applymode);
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <SparkModelFromSim
          openAbandonModelFromPerformance={openAbandonModelFromPerformance}
          setOpenAbandonModelFromPerformance={setOpenAbandonModelFromPerformance}
          value={value}
          setValue={setValue}
          handleChange={handleChange}
        />
      </Box>
    </>
  );
};

export default Performance;
