import { exchanges } from 'actions/ActionTypes';

const initialState = {
  pending: false,
  exchanges: [],
  cryptoList: [],
  frdData: [],
  isGetCryptoListLoading: false,
  isGetFrdDataLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case exchanges.FETCH_EXCHANGES:
      return {
        ...state,
        pending: true,
      };
    case exchanges.FETCH_EXCHANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        exchanges: action.payload.exchanges,
      };
    case exchanges.FETCH_EXCHANGES_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case exchanges.GET_FRD_CRYPTO_LIST:
      return {
        ...state,
        isGetCryptoListLoading: true,
      };
    case exchanges.GET_FRD_CRYPTO_LIST_SUCCESS:
      return {
        ...state,
        isGetCryptoListLoading: false,
        cryptoList: action.payload.cryptoNames,
      };
    case exchanges.GET_FRD_CRYPTO_LIST_FAIL:
      return {
        ...state,
        isGetCryptoListLoading: false,
        error: action.payload.error,
      };
    case exchanges.GET_FRD_DATA:
      return {
        ...state,
        isGetFrdDataLoading: true,
      };
    case exchanges.GET_FRD_DATA_SUCCESS:
      return {
        ...state,
        isGetFrdDataLoading: false,
        frdData: action.payload,
      };
    case exchanges.GET_FRD_DATA_FAIL:
      return {
        ...state,
        isGetFrdDataLoading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
