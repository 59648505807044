import React from 'react';
import ReactDOM from 'react-dom';
import Hafnium from './Hafnium';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import store from 'utils/store/store';
import { AuthProvider } from './security/contexts/AuthProvider';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Routes>
            <Route path="/*" element={<Hafnium />} />
          </Routes>
        </LocalizationProvider>
      </AuthProvider>
    </Provider>
    <ToastContainer position="top-right" theme="colored" />
  </BrowserRouter>,

  document.getElementById('root'),
);
