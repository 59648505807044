import { get, post } from 'utils/api';

export const getAlerts = data => {
  return get(`/v1/client/alert`, data);
};

export const createAlert = data => {
  return post(`/v1/client/alert`, data);
};

export const deleteAlert = id => {
  return post(`/v1/client/alert/${id}/delete`);
};

export const getAlertGroup = data => {
  return get(`/v1/client/alertGroup`, data);
};

export const createAlertGroup = data => {
  return post(`/v1/client/alertGroup`, data);
};

export const luna = {
  getAlerts,
  createAlert,
  deleteAlert,
  getAlertGroup,
  createAlertGroup,
};
