import { makeStyles } from '@material-ui/core';

export const startsWith = (sourceStr, search_string) => {
  if (!search_string) {
    return true;
  }

  return (sourceStr || '').toLowerCase().indexOf(search_string.toLowerCase()) >= 0;
};

export const useStyles = makeStyles(theme => ({
  drawer: {
    width: 350,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#094e8e',
  },
  drawerOpen: {
    marginTop: '50px',
    width: 520,
    background: '#040d3e !important',
    [theme.breakpoints.down('md')]: {
      width: 250,
    },
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&::-webkit-scrollbar': {
      width: '8px',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: '#171616',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#175982',
      borderRadius: 2,
    },
  },
  overFlowHidden: {
    overflowY: 'hidden',
  },
  drawerClose: {
    marginTop: '50px',
    background: '#040d3e',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  imageRotate: {
    transform: 'rotate(-180deg)',
  },
  commonTextStyle: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11px !important',
    },
  },
  optimizedImg: {
    [theme.breakpoints.up('lg')]: {
      width: 35,
      height: 35,
    },
    [theme.breakpoints.down('lg')]: {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down('md')]: {
      width: 20,
      height: 20,
    },
  },
  optimizedCommonStyle: {
    color: '#7877B1 !important',
    fontWeight: '600',
    [theme.breakpoints.up('lg')]: {
      fontSize: '10px !important',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
}));
