import { combineReducers } from 'redux';
import simReducer from './simReducer';
import usersReducer from './usersReducer';
import authReducer from './authReducer';
import lunaReducer from './lunaReducer';
import classificationReducer from './classificationReducer';
import sparkReducer from './sparkReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  users: usersReducer,
  sim: simReducer,
  auth: authReducer,
  luna: lunaReducer,
  classification: classificationReducer,
  spark: sparkReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
