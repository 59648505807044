export const EnumPusherChannelName = {
  TRADE_CALCULATIONS: 'trade_calculations',
  MODEL_STATUS: 'model_status',
};

export const EnumPusherEventName = {
  SPARK_TABLE: 'spark_table',
  SIM_MODEL_STATUS: 'sim_model_status',
  SPARK_MODEL_STATUS: 'spark_model_status',
};
