import { get } from 'utils/api';

export const storeDailyPerformanceData = () => {
  return get(`/v1/client/daily-performance/store-daily-performance`);
};

export const getDailyPerformanceData = () => {
  return get(`/v1/client/daily-performance/performance/2`);
};

export const dashboard = {
  storeDailyPerformanceData,
  getDailyPerformanceData,
};
