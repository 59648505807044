import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import exporticon from 'assets/Export.svg';
import { isNoteEditedFromOutside } from 'actions/simAction';
import NoteComponent from './NoteComponent';
import SparkModal from './SparkModal';
import TableComponent from 'components/common/TableComponent';

const BottomTab = ({
  classes,
  deleteNoteModal,
  setDeleteNoteModal,
  abandonChangesModal,
  setAbandonChangesModal,
  setIsTextEdited,
  selectedId,
  setSelectedId,
  noteEditMode,
  setNoteEditMode,
  isModelSelected,
  setIsModelSelected,
  selectedModelData,
  modelTradeData,
}) => {
  const dispatch = useDispatch();

  const isNoteEdit = useSelector(state => state.sim.isNoteEdit);
  const isNoteTextEdited = useSelector(state => state.sim.isNoteTextEdited);
  const isChartDrawingChanged = useSelector(state => state.sim.isChartDrawingChanged);

  const [value, setValue] = useState(0);
  const [openAbandonModelFromBottomTab, setOpenAbandonModelFromBottomTab] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ background: '#000000', color: '#FFF' }}>
        <Tabs
          aria-label="Tabs where each tab needs to be selected manually"
          onChange={() => {
            if (isNoteEdit && (isNoteTextEdited || isChartDrawingChanged)) {
              setOpenAbandonModelFromBottomTab(true);
              dispatch(isNoteEditedFromOutside(true));
            } else {
              handleChange(_, value === 0 ? 1 : 0);
            }
          }}
          value={value}
          variant="fullWidth"
          sx={{
            justifyContent: 'space-around',
          }}
          textColor={value === 0 ? '#FFD41F' : '#B1AFCD'}
          TabIndicatorProps={{ style: { backgroundColor: '#FFD41F' } }}
        >
          <Tab
            label="Notes"
            sx={{
              color: value === 0 ? '#FFD41F !important' : '#B1AFCD !important',
              maxWidth: '21.5%',
            }}
          />
          <Tab
            label="Table"
            sx={{
              color: value === 1 ? '#FFD41F !important' : '#B1AFCD !important',
              maxWidth: '25%',
            }}
          />
          {value === 1 && (
            <Box width="50%" display="flex" justifyContent="end" alignItems="right">
              <Box display="flex" justifyContent="center" alignItems="center" gap="0.5rem">
                <img src={exporticon} width={13} height={13} />
                <label style={{ color: '#FFC247' }}>Export</label>
              </Box>
            </Box>
          )}
        </Tabs>
      </Box>
      {value === 0 && (
        <NoteComponent
          classes={classes}
          deleteNoteModal={deleteNoteModal}
          setDeleteNoteModal={setDeleteNoteModal}
          abandonChangesModal={abandonChangesModal}
          setAbandonChangesModal={setAbandonChangesModal}
          setIsTextEdited={setIsTextEdited}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          noteEditMode={noteEditMode}
          setNoteEditMode={setNoteEditMode}
          isModelSelected={isModelSelected}
          setIsModelSelected={setIsModelSelected}
        />
      )}
      {value === 1 && (
        <TableComponent
          selectedModelData={selectedModelData}
          modelTradeData={modelTradeData}
          isTradeDataFromSim={true}
        />
      )}
      <SparkModal
        openAbandonModelFromBottomTab={openAbandonModelFromBottomTab}
        setOpenAbandonModelFromBottomTab={setOpenAbandonModelFromBottomTab}
        handleChangeFromBottomTab={handleChange}
        valueFromBottomTab={value}
      />
    </Box>
  );
};

export default BottomTab;
