import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import styles from './styles/Login.module.scss';
import { Avatar, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { MdLock } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN } from 'actions/authAction';

const Login = () => {
  const dispatch = useDispatch();
  const from = '/dashboard';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loginData = useSelector(state => state.auth.loginData);
  const isLoginLoading = useSelector(state => state.auth.isLoginLoading);
  const classes = clsx({
    [styles.container]: true,
  });

  useEffect(() => {
    if (!isEmpty(loginData) && !isLoginLoading) {
      window.location.href = from;
    }
  }, [loginData, isLoginLoading, from]);

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({ type: LOGIN, payload: { email: email, password: password } });
  };

  return (
    <>
      <div className={classes}>
        <Paper elevation={5} className={styles.paper}>
          <Avatar sx={{ width: 75, height: 75 }}>
            <MdLock />
          </Avatar>
          <h1> Sign In </h1>
          <form className={styles.form} onSubmit={handleSubmit}>
            <TextField
              className={styles.input}
              label="Email"
              variant="filled"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              className={styles.input}
              type="password"
              autoComplete="current-password"
              label="Password"
              variant="filled"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isLoginLoading}
              sx={{
                color: '#FFFFFF !important',
                backgroundColor: '#0072F8 !important',
                marginTop: '1rem',
                '&:disabled': {
                  color: '#fff !important',
                  filter: 'brightness(60%)',
                  cursor: 'not-allowed !important',
                  pointerEvents: 'all !important',
                },
              }}
            >
              {isLoginLoading && <CircularProgress size={17} />}
              Log In
            </Button>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default Login;
