import { alertGroup, alerts } from './ActionTypes';

export const fetchAlert = () => ({
  type: alerts.FETCH_ALERTS,
});

export const fetchAlertSuccess = payload => ({
  type: alerts.FETCH_ALERTS_SUCCESS,
  payload,
});

export const fetchAlertFailure = payload => ({
  type: alerts.FETCH_ALERTS_FAIL,
  payload,
});

export const createAlert = payload => ({
  type: alerts.CREATE_ALERT,
  payload,
});

export const createAlertSuccess = payload => ({
  type: alerts.CREATE_ALERT_SUCCESS,
  payload,
});

export const createAlertFailure = payload => ({
  type: alerts.CREATE_ALERT_FAIL,
  payload,
});

export const updateAlert = payload => ({
  type: alerts.UPDATE_ALERT,
  payload,
});

export const updateAlertSuccess = payload => ({
  type: alerts.UPDATE_ALERT_SUCCESS,
  payload,
});

export const updateAlertFailure = payload => ({
  type: alerts.UPDATE_ALERT_FAIL,
  payload,
});

export const deleteAlert = payload => ({
  type: alerts.DELETE_ALERT,
  payload,
});

export const deleteAlertSuccess = payload => ({
  type: alerts.DELETE_ALERT_SUCCESS,
  payload,
});

export const deleteAlertFailure = payload => ({
  type: alerts.DELETE_ALERT_FAIL,
  payload,
});

export const fetchAlertGroup = () => ({
  type: alertGroup.FETCH_ALERT_GROUP,
});

export const fetchAlertGroupSuccess = payload => ({
  type: alertGroup.FETCH_ALERT_GROUP_SUCCESS,
  payload,
});

export const fetchAlertGroupFailure = payload => ({
  type: alertGroup.FETCH_ALERT_GROUP_FAIL,
  payload,
});

export const createAlertGroup = payload => ({
  type: alertGroup.CREATE_ALERT_GROUP,
  payload,
});

export const createAlertGroupSuccess = payload => ({
  type: alertGroup.CREATE_ALERT_GROUP_SUCCESS,
  payload,
});

export const createAlertGroupFailure = payload => ({
  type: alertGroup.CREATE_ALERT_GROUP_FAIL,
  payload,
});
