import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import { FaBalanceScale } from 'react-icons/fa';
import { LuReceipt } from 'react-icons/lu';
import { FaSackDollar } from 'react-icons/fa6';

const Metrics = props => {
  const { text1, text2, total, winValue, rrrValue, trdsValue, title } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        p="10px"
        sx={theme => ({
          [theme.breakpoints.down('lg')]: {
            padding: '5px',
            marginTop: '5px',
          },
          [theme.breakpoints.down('md')]: {
            padding: '5px',
            marginTop: '5px',
          },
        })}
      >
        <Grid item xs={6} sx={{ marginRight: '4rem' }}>
          <label style={{ color: '#B1AFCD', fontWeight: '700' }}>{title}</label>
          {/* <label style={{ color: '#B1AFCD', fontWeight: '700' }}>{title}</label> */}
          <Grid
            sx={theme => ({
              borderBottom: '2px solid gray',
              [theme.breakpoints.up('lg')]: {
                width: '15vw',
              },
              [theme.breakpoints.down('lg')]: {
                width: '27vw',
              },
              [theme.breakpoints.down('md')]: {
                width: '27vw',
              },
            })}
            container
          >
            <Grid sx={{ display: 'flex', flexDirection: 'column', color: 'white' }} item lg={2} md={2}>
              <Typography>
                <FaBalanceScale style={{ fontSize: '1.5rem' }} />
                {/* <FaBalanceScale style={{ fontSize: '1.5rem' }} /> */}
              </Typography>
              <Typography>
                <LuReceipt style={{ fontSize: '1.5rem' }} />
                {/* <LuReceipt style={{ fontSize: '1.5rem' }} /> */}
              </Typography>
              <Typography sx={{ marginTop: '0.5rem' }}>
                <FaSackDollar style={{ fontSize: '1.5rem' }} />
              </Typography>
            </Grid>
            <Grid item lg={4} md={3}>
              <Typography
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  color: '#B1AFCD',
                }}
              >
                {text1}
              </Typography>
              <Typography
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  color: '#B1AFCD',
                  marginTop: '0.4rem',
                }}
              >
                {text2}
              </Typography>
              <Typography sx={{ borderBottom: '3px solid gray', marginTop: '0.6rem' }}></Typography>
              <Typography
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  color: '#50E3C2',
                  marginTop: '0.4rem',
                }}
              >
                {total}
              </Typography>
            </Grid>
            <Grid
              item
              lg={5}
              md={6}
              sx={theme => ({
                marginRight: '5px',
                [theme.breakpoints.up('lg')]: {
                  paddingLeft: '2rem',
                },
                [theme.breakpoints.down('lg')]: {
                  paddingLeft: '1rem',
                },
                [theme.breakpoints.down('md')]: {
                  paddingLeft: '1rem',
                },
              })}
            >
              <Typography
                sx={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  color: '#7877B1',
                }}
              >
                WIN:
                <Typography sx={{ color: '#50E3C2', marginLeft: '0.3rem' }}>{winValue}</Typography>
              </Typography>
              {/* <Typography
                sx={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  color: '#7877B1',
                  marginTop: '0.4rem',
                }}
              >
                RRR:
                <Typography sx={{ color: '#E3507A', marginLeft: '0.3rem' }}>{rrrValue}</Typography>
              </Typography> */}
              <Typography
                sx={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  color: '#7877B1',
                  marginRight: '0.2rem',
                }}
              >
                TRDs:
                <Typography sx={{ color: '#50E3C2', marginLeft: '0.3rem' }}>{trdsValue}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Metrics;
